import { useLoadItem } from "shared/hooks/useLoadItem";

import { ARTICLE_API, PRODUCT_TYPE_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { useUpdateArticle } from "../hooks/article";
import { Article } from "Warehouse/types";
import { FullModal } from "components/FullModal";
import { UpdateArticleForm } from "./UpdateArticleForm";

export const ShowArticleModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Article>(id, ARTICLE_API, "articles");
  const { mutateAsync } = useUpdateArticle();

  return data && checkPermissions(["rosina.view_producttype"]) ? (
    <FullModal
      title="Modifica articolo"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateArticleForm article={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
