import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { Header } from "components/headers/Header";
import { useAddProductType, useOptionsProductType, useSearchProductTypes } from "../hooks/productType";
import { ProductTypeFilter } from "Warehouse/types";
import { AddProductTypeForm } from "../components/AddProductTypeForm";
import { ProductTypeListPage } from "../components/ProductTypeListPage";

export const ProductType = () => {
  const { paramsObj, onSearch } = useFilter<ProductTypeFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchProductTypes(paramsObj);
  const { mutateAsync } = useAddProductType();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<ProductTypeFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsProductType();
  return (
    <>
      {data && data.results && options && (
        <ProductTypeListPage
          options={options}
          productTypes={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi tipo prodotto" toggle={toggle} isOpen={isOpen}>
        <AddProductTypeForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
