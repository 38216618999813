import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Article } from "Warehouse/types";
import { useOptionsArticle } from "../hooks/article";
import { ArticleForm } from "./ArticleForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Article, unknown>;
}

export const AddArticleForm = ({ submitFunction }: Props) => {
  const { control, errors, register, handleSubmit, setError } = useForm<Article>(
    {
      defaultValues: {},
    }
  );

  const { data: options } = useOptionsArticle();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Articolo aggiunto!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && (
        <ArticleForm
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
