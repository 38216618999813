import { Alert, Card, CardBody } from "reactstrap";
import classNames from "classnames";
import { MESSAGES } from "utils/constants";
import { Column } from "react-table";
import { DestinyTractionPalletSubject } from "PalletsManagment/types";
import { SimpleTable } from "components/tables/SimpleTable";

interface Props {
  destinyTractionPallet: DestinyTractionPalletSubject[];
  columns: Column[];
}

export const PalletsSubjectPage = ({
  destinyTractionPallet,
  columns,
}: Props) => {
  return (
    <>
      <div className="row ">
        <div className={classNames("col-12")}>
          {destinyTractionPallet.length === 0 ? (
            <Alert color="primary">{MESSAGES.GENERAL.NO_ITEMS}</Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <SimpleTable data={destinyTractionPallet} columns={columns} />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
