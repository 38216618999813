import { useToggler } from "shared/hooks/useToggler";

import { Price, PriceFilter } from "../types/price";
import { FullModal } from "components/FullModal";
import { PRICES_API } from "utils/constants";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { AddPriceForm } from "Items/components/AddPriceForm";
import {
  useAddPrice,
  useColumnsPrices,
  useOptionsPrice,
} from "Items/hooks/price";
import { PriceListPage } from "Items/components/PriceListPage";
import { useFilter } from "shared/hooks/useFilter";

export const Prices = () => {
  const { paramsObj, onSearch } = useFilter<PriceFilter>();
  const { data, page, pageCount, search, goToPage } = useSearchPaginated<Price>(
    PRICES_API,
    "prices"
  );

  const { mutateAsync } = useAddPrice();
  const { value: isOpen, toggle } = useToggler();

  const columns = useColumnsPrices();

  const searchItems = (data: Partial<PriceFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsPrice();
  return (
    <>
      {data && data.results && options && (
        <PriceListPage
          options={options}
          onAddClick={toggle}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          columns={columns}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
          prices={data.results}
        />
      )}

      <FullModal toggle={toggle} isOpen={isOpen} title="Aggiungi prezzo">
        <AddPriceForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
