import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "shared/context/auth/auth-context";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import history from "historyRouter";
import "App.css";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer } from "react-toastify";
import { PrivateRoutes } from "components/PrivateRoutes";
import React from "react";
import { Login } from "Login";
import { IsLoadingProvider } from "shared/context/loading";
import { GlobalModalsProvider } from "shared/context/globalModals";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <IsLoadingProvider>
          <Router history={history}>
            <GlobalModalsProvider>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Redirect exact from="/" to="/login" />
                <PrivateRoutes />
              </Switch>
            </GlobalModalsProvider>
          </Router>
        </IsLoadingProvider>
      </AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </QueryClientProvider>
  );
}

export default App;
