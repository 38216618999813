import { CollapsableCard } from "components/CollapsableCard";
import { DownloadFile } from "components/DownloadFile";
import { SimpleTable } from "components/tables/SimpleTable";
import { useReportDeliveriesByArea } from "Distribution/hooks/delivery";
import React, { useMemo } from "react";
import { Alert, Button } from "reactstrap";
import { DELIVERIES_API, MESSAGES } from "utils/constants";

export const DeliveriesByAreas = () => {
  const { data } = useReportDeliveriesByArea();
  const columns = useMemo(
    () => [
      {
        accessor: "receiver__business_name",
        Header: "Destinatario",
        sortType: "basic",
      },
      {
        accessor: "receiver__locality",
        Header: "Località",
        sortType: "basic",
      },
      {
        accessor: "weight_total",
        Header: "Peso",
        sortType: "basic",
        Cell: (cell: any) => cell.value ? cell.value.toFixed(2) : 0.0,
      },
      {
        accessor: "deliveries_n",
        Header: "N. missioni",
        sortType: "basic",
      },
      {
        accessor: "packages_number_total",
        Header: "N. colli",
        sortType: "basic",
        Cell: (cell: any) => cell.value ? cell.value.toFixed(2) : 0.0,
      },
    ],
    []
  );
  const stats = useMemo(() => {
    const initial = { weight: 0, deliveries_n: 0, packages_number: 0 };
    if (data)
      return Object.entries(data).reduce((previous, [key, value]) => {
        return {
          weight: previous.weight + value.weight,
          deliveries_n: previous.deliveries_n + value.deliveries_n,
          packages_number: previous.packages_number + value.packages_number,
        };
      }, initial);
    return initial;
  }, [data]);
  return (
    <>
      <div className="d-flex flex-row justify-content-end my-3">
        <DownloadFile
          ButtonDownload={() => (
            <Button type={"button"} size="sm" color="primary">
              Scarica PDF
            </Button>
          )}
          urlDownload={`${DELIVERIES_API}get_report_by_area_pdf/`}
        />
      </div>
      <div
        className="card"
        style={{ marginBottom: "1rem", fontWeight: "bold" }}
      >
        <div className="card-header bg-success text-white">
          <div className=" row flex-grow-1 ">
            <div className="col-4">
              <span>TOTALE:</span>
            </div>
            <div className="col-8">
              <div className="d-flex flex-row justify-content-start flex-grow-1">
                <span className="mr-3">Peso: {stats.weight.toFixed(2)}</span>
                <span className="mr-3">N. missioni: {stats.deliveries_n}</span>
                <span className="mr-3">
                  N. colli: {stats.packages_number.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data ? (
        Object.entries(data).map(([key, value]) => {
          return (
            <CollapsableCard
              classNameHeader={"card_header_grey"}
              key={key}
              title={
                <div className="row flex-grow-1">
                  <div className="col-4">
                    <span>{key}</span>
                  </div>
                  <div className="col-8">
                    <div className="d-flex flex-row justify-content-start flex-grow-1">
                      <span className="mr-3">
                        Peso: {value.weight.toFixed(2)}
                      </span>
                      <span className="mr-3">
                        N. missioni: {value.deliveries_n}
                      </span>
                      <span className="mr-3">
                        N. colli: {value.packages_number.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="p-2">
                {value.provinces.map((province) => (
                  <CollapsableCard
                    classNameHeader={"card_header_primary"}
                    key={province.receiver__province}
                    title={
                      <div className="row flex-grow-1">
                        <div className="col-4">
                          <span>{province.receiver__province}</span>
                        </div>
                        <div className="col-8">
                          <div className="d-flex flex-row justify-content-start flex-grow-1">
                            <span className="mr-3">
                              Peso: {province.weight_total.toFixed(2)}
                            </span>
                            <span className="mr-3">
                              N. missioni: {province.deliveries_n}
                            </span>
                            <span className="mr-3">
                              N. colli:
                              {province.packages_number_total.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <SimpleTable data={province.receivers} columns={columns} />
                  </CollapsableCard>
                ))}
              </div>

              {/* <SimpleTable data={value.provinces} columns={columns} /> */}
            </CollapsableCard>
          );
        })
      ) : (
        <Alert color="primary">{MESSAGES.GENERAL.NO_ITEMS}</Alert>
      )}
    </>
  );
};
