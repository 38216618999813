import { useLoadItem } from "shared/hooks/useLoadItem";

import { FLOOR_API, PLACE_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { useUpdatePlace } from "../hooks/place";
import { Place } from "Warehouse/types";
import { FullModal } from "components/FullModal";
import { UpdatePlaceForm } from "./UpdatePlaceForm";

export const ShowPlaceModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Place>(id, PLACE_API, "places");
  const { mutateAsync } = useUpdatePlace();

  return data && checkPermissions(["rosina.view_place"]) ? (
    <FullModal
      title="Modifica posto"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdatePlaceForm place={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
