import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { CheckboxField } from "components/forms/CheckboxField";

import { FormField } from "components/forms/FormField";
import { Tabs } from "components/Tabs";
import React from "react";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const ChargeForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Cliente</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.client_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.CLIENT}
                      control={control}
                      defaultValue={
                        defaultValues?.client_id
                          ? defaultValues?.client_id
                          : null
                      }
                      name="client_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Quantità</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Quantità"
                        type="number"
                        register={register}
                        name="quantity"
                        errorMessage={errors.quantity?.message}
                      />
                    ) : defaultValues.quantity && !editable ? (
                      defaultValues.quantity
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Quantità reale</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Quantità reale"
                        type="number"
                        register={register}
                        name="real_quantity"
                        errorMessage={errors.real_quantity?.message}
                      />
                    ) : defaultValues.real_quantity && !editable ? (
                      defaultValues.real_quantity
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Prezzo</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.price_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.PRICE}
                      control={control}
                      defaultValue={
                        defaultValues?.price_id ? defaultValues?.price_id : null
                      }
                      name="price_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Importo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Importo"
                        type="number"
                        register={register}
                        name="amount"
                        errorMessage={errors.amount?.message}
                      />
                    ) : defaultValues.amount && !editable ? (
                      defaultValues.amount
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Imponibile</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Imponibile"
                        type="number"
                        register={register}
                        name="taxable"
                        errorMessage={errors.taxable?.message}
                      />
                    ) : defaultValues.taxable && !editable ? (
                      defaultValues.taxable
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Causale</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Causale"
                        type="text"
                        register={register}
                        name="causal"
                        errorMessage={errors.causal?.message}
                      />
                    ) : defaultValues.causal && !editable ? (
                      defaultValues.causal
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Data fattura</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Data fatturazione"
                        type="date"
                        register={register}
                        name="charge_date"
                        errorMessage={errors.invoice_date?.message}
                      />
                    ) : defaultValues.invoice_date && !editable ? (
                      defaultValues.invoice_date
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Destinatario</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.receiver_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.SUBJECT}
                      control={control}
                      defaultValue={
                        defaultValues?.receiver_id
                          ? defaultValues?.receiver_id
                          : null
                      }
                      name="receiver_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Missione</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.delivery_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.DELIVERY}
                      control={control}
                      defaultValue={
                        defaultValues?.delivery_id
                          ? defaultValues?.delivery_id
                          : null
                      }
                      name="delivery_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Ordine trazione</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.order_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.ORDERS}
                      control={control}
                      defaultValue={
                        defaultValues?.order_id ? defaultValues?.order_id : null
                      }
                      name="order_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Fattura</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.invoice_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.INOVICE}
                      control={control}
                      defaultValue={
                        defaultValues?.invoice_id
                          ? defaultValues?.invoice_id
                          : null
                      }
                      name="invoice_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Crea riga singola</b>
                  </td>
                  <td>
                    <CheckboxField
                      disabled={!editable}
                      register={register}
                      name="one_by_one"
                      errorMessage={errors.one_by_one?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nascondi</b>
                  </td>
                  <td>
                    <CheckboxField
                      disabled={!editable}
                      register={register}
                      name="hide"
                      errorMessage={errors.hide?.message}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note"]}>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
