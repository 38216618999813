import { UpdateChargeForm } from "Billing/components/UpdateChargeForm";
import { useUpdateCharge } from "Billing/hooks/charges";
import { Charge } from "Billing/types/charge";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { CHARGES_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowChargeModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Charge>(id, CHARGES_API, "charges");
  const { mutateAsync } = useUpdateCharge();

  return data && checkPermissions(["rosina.view_charge"]) ? (
    <FullModal
      title="Modifica addebito"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateChargeForm charge={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
