import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Tabs } from "components/Tabs";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { Edit2, X } from "react-feather";
import { Button } from "reactstrap";
import { getBase64 } from "utils/helpers";
import { DateTimeField } from "components/forms/DateTimeField";
import { BooleanField } from "components/forms/BooleanField";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  watch: any;
}

export const NoteForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  watch,
}: Props) => {
  const refButton = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string | undefined>(
    defaultValues?.file ? defaultValues?.file : null
  );

  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Missione</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.DELIVERY}
                      control={control}
                      defaultValue={
                        defaultValues?.delivery_id
                          ? defaultValues?.delivery_id
                          : null
                      }
                      errorMessage={errors.delivery_id?.message}
                      name="delivery_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>File</td>
                  <td>
                    <Controller
                      name="file"
                      control={control}
                      defaultValue={null}
                      render={({ onChange, value }) => (
                        <div className="d-flex flex-column">
                          <span>{fileName}</span>
                          {errors.file?.message && (
                            <span style={{ color: "red" }}>
                              {errors.file?.message}
                            </span>
                          )}
                          <div className="d-flex flex-row mt-2">
                            {editable && (
                              <>
                                <Button
                                  className="edit-buttons"
                                  color="primary"
                                  onClick={() => {
                                    if (
                                      refButton.current &&
                                      refButton.current !== null
                                    )
                                      refButton.current.click();
                                  }}
                                >
                                  <Edit2 size={13} />
                                </Button>
                                <Button
                                  className="edit-buttons"
                                  onClick={() => {
                                    setFileName("");
                                    onChange(null);
                                  }}
                                  color="danger"
                                >
                                  <X size={13} />
                                </Button>
                              </>
                            )}
                            <input
                              ref={refButton}
                              onChange={(data) => {
                                if (data.target.files) {
                                  getBase64(data.target.files[0]).then(
                                    (file) => {
                                      setFileName(
                                        data.target.files
                                          ? data.target.files[0].name
                                          : ""
                                      );
                                      onChange(file);
                                    }
                                  );
                                } else onChange(data);
                              }}
                              type="file"
                              accept=".tiff,.tif,.pdf,.png,.jpeg,.jpg"
                              hidden
                            />
                          </div>
                        </div>
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>JSON</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="JSON"
                        type="textarea"
                        register={register}
                        name="json"
                        errorMessage={errors.json?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.json}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Finale</b>
                  </td>
                  <td>
                    <input
                      ref={register}
                      disabled={!editable}
                      type="checkbox"
                      name="final"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Inviata</b>
                  </td>
                  <td>
                    <DateTimeField
                      disabled={!editable}
                      control={control}
                      name="sent"
                      errorMessage={errors.sent?.message}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note"]}>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
