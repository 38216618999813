import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { Header } from "components/headers/Header";
import { useAddProduct, useOptionsProduct, useSearchProducts } from "../hooks/product";
import { ProductFilter } from "Warehouse/types";
import { AddProductForm } from "../components/AddProductForm";
import { ProductListPage } from "../components/ProductListPage/ProductListPage";

export const Product = () => {
  const { paramsObj, onSearch } = useFilter<ProductFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchProducts(paramsObj);
  const { mutateAsync } = useAddProduct();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<ProductFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsProduct();
  return (
    <>
      {data && data.results && options && (
        <ProductListPage
          options={options}
          products={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi tipo prodotto" toggle={toggle} isOpen={isOpen}>
        <AddProductForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
