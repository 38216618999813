import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useMemo } from "react";
import { useMutation, useQueryClient, UseQueryOptions } from "react-query";
import { toast } from "react-toastify";
import { useGlobalModals } from "shared/context/globalModals";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { UpdatePayload } from "shared/types";
import { client } from "utils/auth-client";
import { ITEMS_API } from "utils/constants";
import { Item } from "../types/item";

const url = ITEMS_API;
const key = "items";

export function useAddItem() {
  const queryClient = useQueryClient();

  return useMutation(
    (item: Item) =>
      client(url, {
        method: "POST",
        data: item,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
    }
  );
}

export function useDeleteItem() {
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) =>
      client(`${url}${id}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
      onError: () => {
        toast.error("Impossibile eliminare");
      },
    }
  );
}
export function useSearchItems(
  parameters: Partial<Item> & { page: number },
  config?: UseQueryOptions<
    any,
    unknown,
    {
      results: Item[];
      count: number;
    }
  >
) {
  return useSearchPaginated<Item>(url, key, parameters, config);
}
export function useUpdateItem() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdatePayload<Item>) =>
      client(`${url}${data.id}/`, {
        method: "PATCH",
        data: data.newValues,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
    }
  );
}

export function useItemColumns() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteItem } = useDeleteItem();

  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteItem(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_item"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("item", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },
      {
        Header: "Codice",
        accessor: "code",
        sortType: "basic",
      },
    ];
  }, [deleteItem, setActiveItem]);
}
