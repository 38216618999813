import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { STATUS_API } from "utils/constants";
import { getDisplayDate, getDisplayDateSplit, getDisplayValue } from "utils/helpers";
import { Status } from "../../types";

const key = "status";
const url = STATUS_API;

export function useOptionsStatus() {
  return useOptions(url, key);
}

export function useAddStatus() {
  return useAdd<Status>(url, key);
}

export function useDeleteStatus() {
  return useDelete(url, key);
}

export function useUpdateStatus() {
  return useUpdate<Status>(url, key);
}

export function useListStatus(
  params?: any,
  config?: UseQueryOptions<any, unknown, Status[]>
) {
  return useList(url, key, params, config);
}

export function useSearchStatuss(params?: any) {
  return useSearchPaginated<Status>(url, key, params);
}

export function useStatus(
  id: string,
  config?: UseQueryOptions<any, unknown, Status>
) {
  return useGetItem<Status>(id, url, key, config);
}

export function useStatusColumns(
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteStatus } = useDeleteStatus();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteStatus(id);
    };
    return [
      {
        Header: "Posto",
        accessor: "place",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.place)
            return (
              <>
                <div>
                  {`${cell.row.original.place.id}`}
                </div>
                <div>
                  {`${cell.row.original.place.label.replace(cell.row.original.place.id + "-", "")}`}
                </div>
              </>
            );
          return "";
        },
      },
      {
        Header: "Articolo",
        accessor: "article",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.article)
            return (
              <>
                <div>
                  {`${cell.row.original.article.id}`}
                </div>
                <div>
                  {`${cell.row.original.article.product_code}`}
                </div>
              </>
            );
          return "";
        },
      },
      {
        Header: "Prodotto",
        accessor: "product",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.article)
            return (
              <>
                {`${cell.row.original.product.name}`}
              </>
            );
          return "";
        },
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.article)
            return (
              <>
                <div>
                  {`${cell.row.original.client.id}`}
                </div>
                <div>
                  {`${cell.row.original.client.business_name}`}
                </div>
              </>
            );
          return "";
        },
      },
      {
        Header: "Stato",
        accessor: "status",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("status", cell.value, options),
      },
      {
        accessor: "date",
        Header: "Data",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDateSplit(cell.value),

      },
      {
        Header: "Note",
        accessor: "note",
        sortType: "basic",
      },
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_status"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("status", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
    ];
  }, [deleteStatus, options, setActiveItem]);
}

export function useStatusColumns2(
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteStatus } = useDeleteStatus();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteStatus(id);
    };
    return [
      {
        Header: "Info",
        accessor: "place",
        sortType: "basic",
        disableFilters: true,
        Cell: (cell: any) => {
          if (cell.row.original)
            return (
              <>
                <div>
                  {`${cell.row.original.place ? cell.row.original.place.label : "-"}`}
                </div>
                <div>
                  {
                    cell.row.original.article ? (
                      <>
                        <div>{cell.row.original.article.id}-{cell.row.original.article.product_code} [{cell.row.original.product.name}]</div>
                        <div>{cell.row.original.client.business_name}</div>
                      </>
                    ) : <>
                      <div>-</div>
                      <div>-</div>
                    </>
                  }
                </div>
                <div>
                  {
                    cell.row.original.note ? cell.row.original.note : ""
                  }
                </div>
              </>
            );
          return "";
        },
      },
      {
        Header: "Stato/data",
        accessor: "date",
        sortType: "basic",
        disableFilters: true,
        Cell: (cell: any) => {
          if (cell.row.original)
            return (
              <>
                <div>
                  {getDisplayValue("status", cell.row.original.status, options)}
                </div>
                <div>
                  {getDisplayDateSplit(cell.row.original.date)}
                </div>
              </>
            );
          return "";
        },
      },
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        disableFilters: true,
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_status"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("status", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
    ];
  }, [deleteStatus, options, setActiveItem]);
}
