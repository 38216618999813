import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Tabs } from "components/Tabs";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { ImageField } from "components/forms/ImageField";
import React from "react";
import { SelectField } from "components/forms/SelectField";
import { DateTimeField } from "components/forms/DateTimeField";

interface Props {
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  options: any;
}

export const DestinyTractionPalletForm = ({
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  options,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Soggetto</b>
                  </td>
                  <td>
                    <Autocomplete
                      url={AUTOCOMPLETE_URLS.SUBJECT}
                      defaultValue={
                        defaultValues?.subject_id
                          ? defaultValues?.subject_id
                          : null
                      }
                      disabled={!editable}
                      name={`subject_id`}
                      control={control}
                      errorMessage={errors.subject_id?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo</b>
                  </td>
                  <td>
                    <SelectField
                      register={register}
                      disabled={!editable}
                      name={`type`}
                      options={options.type.choices}
                      errorMessage={errors.type?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo pallet</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.PALLET}
                      control={control}
                      defaultValue={
                        defaultValues?.pallet_type_id
                          ? defaultValues?.pallet_type_id
                          : null
                      }
                      errorMessage={errors.pallet_type_id?.message}
                      name="pallet_type_id"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Immagine</b>
                  </td>
                  <td>
                    <ImageField
                      errorMessage={errors.image?.message}
                      disabled={!editable}
                      control={control}
                      name={"image"}
                      file={defaultValues?.image}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table_col">
              <tbody>
                <tr>
                  <td>
                    <b>Data</b>
                  </td>
                  <td>
                    <DateTimeField
                      control={control}
                      disabled={!editable}
                      name={`date`}
                      errorMessage={errors.date?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Buono</b>
                  </td>
                  <td>
                    <FormField
                      type={"number"}
                      register={register}
                      disabled={!editable}
                      name={"voucher"}
                      placeholder="Quantità buono"
                      errorMessage={errors.voucher?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Resi</b>
                  </td>
                  <td>
                    <FormField
                      type={"number"}
                      register={register}
                      disabled={!editable}
                      name={"returned"}
                      placeholder="Quantità reso"
                      errorMessage={errors.returned?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Giacenze</b>
                  </td>
                  <td>
                    <FormField
                      type={"number"}
                      register={register}
                      disabled={!editable}
                      name={"stock"}
                      placeholder="Quantità giacenza"
                      errorMessage={errors.stock?.message}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note"]}>
              <div>
                <table className={classNames("table_col", "mb-2")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
