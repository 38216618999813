import { useLeafletContext } from "@react-leaflet/core";
import L, { FeatureGroup, Layer } from "leaflet";
import { useEffect, useRef } from "react";
import "leaflet-draw";

interface Props {
  drawOptions?: L.Control.DrawOptions;
  edit?: false | L.DrawOptions.EditHandlerOptions;
  defaultMarker?: { lat: number; lng: number };
  remove?: false | null;
  onMarkerUpdate?: (latLng: { lat: number; lng: number }) => void;
}

export function DrawControl({
  drawOptions = {},
  edit = false,
  defaultMarker,
  remove = false,
  onMarkerUpdate,
}: Props) {
  const context = useLeafletContext();
  const drawLayerRef = useRef<FeatureGroup>();
  const drawControl = useRef<any>();

  useEffect(() => {
    const map = context.map;

    drawLayerRef.current = new L.FeatureGroup();
    map.addLayer(drawLayerRef.current);

    drawControl.current = new L.Control.Draw({
      draw: drawOptions,
      edit: {
        featureGroup: drawLayerRef.current,
        edit: edit,
        remove: remove,
      },
    });
    map.addControl(drawControl.current);

    map.on(L.Draw.Event.CREATED, function (event) {
      var layer = event.layer;

      drawLayerRef.current!.addLayer(layer);
    });

    if (defaultMarker) {
      L.marker(defaultMarker).addTo(drawLayerRef.current);
    }

    map.on(L.Draw.Event.CREATED, function (event) {
      var layer = event.layer;

      drawLayerRef.current!.addLayer(layer);
    });

    map.on("draw:edited", function (e: any) {
      const layers = e.layers;
      layers.eachLayer(function (layer: any) {
        console.log(e);
        if (layer instanceof L.Marker) {
          if (onMarkerUpdate)
            onMarkerUpdate({
              lat: layer.getLatLng().lat,
              lng: layer.getLatLng().lng,
            });
        }
      });
    });
    return () => {
      map.removeEventListener(L.Draw.Event.CREATED);
      map.removeEventListener("draw:edited");
      map.removeControl(drawControl.current);
      map.removeLayer(drawLayerRef.current as Layer);
    };
  }, [context.map, defaultMarker, drawOptions, edit, onMarkerUpdate, remove]);

  return null;
}
