import {
  useDestinyTractionPalletSubjectColumns,
  useListDestinyTractionPalletSubject,
} from "PalletsManagment/hooks/destinyTractionPallet";
import { PalletsSubjectPage } from "PalletsManagment/components/PalletsSubjectPage";

export const PalletsSubject = () => {
  const { data } = useListDestinyTractionPalletSubject();
  const columns = useDestinyTractionPalletSubjectColumns();
  return (
    <>
      {data && (
        <PalletsSubjectPage columns={columns} destinyTractionPallet={data} />
      )}
    </>
  );
};
