import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { FuelConsumptionForm } from "./FuelConsumptionForm";
import { useOptionsFuelConsumption } from "FuelConspumption/hooks/fuelConsumption";
import { FuelConsumption } from "FuelConspumption/types";
import { addServerErrors } from "utils/helpers";
import { MESSAGES } from "utils/constants";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<FuelConsumption>,
    unknown
  >;
  driver: FuelConsumption;
}

export const UpdateFuelConsumptionForm = ({
  submitFunction,
  driver,
}: Props) => {
  const { control, errors, register, handleSubmit, setError } =
    useForm<FuelConsumption>({
      defaultValues: driver,
    });
  const { data: options } = useOptionsFuelConsumption();
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onSend = handleSubmit((data) => {
    submitFunction({ id: driver.id!, newValues: data })
      .then(() => {
        toast.success(MESSAGES.FUEL_CONSUMPTION.UPDATE_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_fuelconsumption"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            {MESSAGES.GENERAL.UPDATE_ITEM}
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <FuelConsumptionForm
          editable={!disabled}
          defaultValues={driver}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      <CheckPermissions permissions={["rosina.change_fuelconsumption"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            {MESSAGES.GENERAL.SEND_FORM}
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
