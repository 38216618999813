import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { filterNonNull } from "utils/helpers";

export function useFilter<T>() {
  const location = useLocation();
  const params = location.search;
  const history = useHistory();
  const getUrl = (data: Partial<T>) => {
    return `${location.pathname}${qs.stringify(filterNonNull(data), {
      skipNulls: true,
      addQueryPrefix: true,
    })}`;
  };

  const onSearch = (data: Partial<T>) => {
    history.replace(getUrl(data));
  };

  const paramsObj = filterNonNull(
    params ? qs.parse(params, { ignoreQueryPrefix: true }) : {}
  );
  return { paramsObj, onSearch };
}
