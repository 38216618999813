import { GroupedChargeListItem } from "Billing/types/charge";
import { CollapsableCard } from "components/CollapsableCard";
import { Pill } from "components/Pill";
import { SimpleTable } from "components/tables/SimpleTable";
import React from "react";
import { Column } from "react-table";

interface Props {
  data: GroupedChargeListItem;
  columns: Column[];
  setSelectedRows?: any;
  selectedRows?: any[];
  sortBy?: string;
}

export const GroupedChargesCard = ({ data, columns, setSelectedRows, selectedRows, sortBy }: Props) => {
  return (
    <CollapsableCard
      title={
        <div
          style={{ flex: "1 1 auto" }}
          className="d-flex flex-row justify-content-between align-items-center"
        >
          <div>
            <b>{data.title}:</b>
            <span className="ml-2">{data.taxable.toFixed(2)}€</span>
          </div>

          {data.allInvoiced && <Pill color="success" text={`Fatturati`} />}
        </div>
      }
    >
      <div className="px-3">
        {Object.values(data.innerElements).map((item) => (
          <CollapsableCard
            classNameHeader={"card_header_primary"}
            key={item.chargeType}
            title={
              <div
                style={{ flex: "auto" }}
                className="d-flex flex-row justify-content-between align-items-center"
              >
                <b>{item.chargeType}</b>
                <div className="d-flex flex-row">
                  {item.allInvoiced && (
                    <Pill color="success" text={`Fatturati`} />
                  )}
                  <Pill text={`${item.total_taxable.toFixed(2)}€`} />
                </div>
              </div>
            }
          >
            <div
              className="p-2"
              style={{
                maxHeight: "700px",
                overflow: "hidden",
                overflowX: "auto",
              }}
            >
              <SimpleTable columns={columns} data={item.charges}
                setSelectedRows={setSelectedRows} selectedRows={selectedRows} />
            </div>
          </CollapsableCard>
        ))}
      </div>
    </CollapsableCard>
  );
};
