import { Autocomplete } from "components/autocompletes/Autocomplete";
import { BooleanField } from "components/forms/BooleanField";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { useState } from "react";
import { DeepPartial, UnpackNestedValue, useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { InputType } from "reactstrap/lib/Input";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { FilterI } from "./types";

interface Props<T> {
  paramsObj: Partial<T>;
  filters: FilterI[];
  onFilterChange: (data: UnpackNestedValue<T>, filters: FilterI[]) => void;
}

export const FilterContent = <T,>({
  filters,
  onFilterChange,
  paramsObj,
}: Props<T>) => {
  const [copyFilters, setCopyFilters] = useState<FilterI[]>(filters);
  const { handleSubmit, register, control } = useForm<T>({
    defaultValues: paramsObj as UnpackNestedValue<DeepPartial<T>>,
  });

  const onSend = handleSubmit((data) => {
    onFilterChange(data, copyFilters);
  });
  return (
    <Form onSubmit={onSend}>
      <div
        className="d-flex flex-row justify-content-between align-items-center p-3"
        style={{ fontWeight: 600, borderBottom: "1px solid rgba(0,0,0,.125)" }}
      >
        <span>Filtri</span>
        <div className="d-flex flex-row">
          <Button type="submit" size="sm" color="primary">
            FATTO
          </Button>
        </div>
      </div>
      <ListGroup style={{
        width: "376px ",
        overflowY: "scroll",
        maxHeight: "350px"
      }} flush>
        {copyFilters.map((item) => {
          return (
            <ListGroupItem key={item.name}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={() => {
                      setCopyFilters((prevFilters) => {
                        const newFilter = [...prevFilters];
                        const elementIndex = newFilter.findIndex(
                          (oldItem) => oldItem.name === item.name
                        );
                        const element = {
                          ...newFilter[elementIndex],
                          active: !newFilter[elementIndex].active,
                        };
                        newFilter.splice(elementIndex, 1, element);
                        return newFilter;
                      });
                    }}
                    checked={item.active}
                  />
                  {item.label}
                </Label>
              </FormGroup>

              {item.active && (
                <div
                  style={{
                    padding: " .75rem 1.25rem"
                  }}
                >
                  {["text", "date", "number"].includes(item.type) && (
                    <FormField
                      required={true}
                      register={register}
                      name={item.name}
                      type={item.type as InputType}
                    />
                  )}
                  {item.type === "options" && item.options !== undefined && (
                    <SelectField
                      required={true}
                      multiple={item.multiple}
                      options={item.options}
                      register={register}
                      name={item.name}
                    />
                  )}
                  {item.type === "boolean" && (
                    <BooleanField
                      required={true}
                      register={register}
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-client" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.CLIENT}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-order" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.ORDERS}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-subject" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.SUBJECT}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-delivery" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.DELIVERY}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-driver" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.DRIVER}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-vehicle" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.VEHICLE}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-item" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.ITEM}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-invoice" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.INOVICE}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-route" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.ROUTE}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-sector" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.SECTOR}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-row" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.ROW}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-floor" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.FLOOR}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-product-type" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.PRODUCT_TYPE}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-product" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.PRODUCT}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-place" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.PLACE}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-article" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.ARTICLE}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-imported-files" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.IMPORTED_FILES}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                  {item.type === "autocomplete-trailer" && (
                    <Autocomplete
                      required={true}
                      url={AUTOCOMPLETE_URLS.TRAILER}
                      control={control}
                      defaultValue={
                        (paramsObj[
                          item.name as keyof Partial<T>
                        ] as unknown as number)
                          ? (paramsObj[
                            item.name as keyof Partial<T>
                          ] as unknown as number)
                          : null
                      }
                      name={item.name}
                    />
                  )}
                </div>
              )
              }
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </Form >
  );
};
