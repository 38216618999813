import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";

import { FuelConsumptionForm } from "./FuelConsumptionForm";
import { FuelConsumption } from "FuelConspumption/types";
import { MESSAGES } from "utils/constants";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    FuelConsumption,
    unknown
  >;
}

export const AddFuelConsumptionForm = ({ submitFunction }: Props) => {
  const { errors, register, handleSubmit, setError, control } = useForm<
    Omit<FuelConsumption, "driver" | "vehicle" | "site" | "file">
  >({
    defaultValues: {
      driver_id: null,
      vehicle_id: null,
      site_id: null,
      file_id: null,
      date: new Date(),
    },
  });

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success(MESSAGES.FUEL_CONSUMPTION.ADD_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <FuelConsumptionForm
        register={register}
        control={control}
        errors={errors}
      />
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          {MESSAGES.GENERAL.NEW_ITEM}
        </Button>
      </div>
    </Form>
  );
};
