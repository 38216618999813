import { AddDriverForm } from "Drivers/components/AddDriverForm";
import { useToggler } from "shared/hooks/useToggler";
import { DriverFilter } from "../types/driver";
import {
  useAddDriver,
  useOptionsDriver,
  useSearchDrivers,
} from "../hooks/driver";
import { DriverListPage } from "Drivers/components/DriverListPage";
import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";

export const Drivers = () => {
  const { paramsObj, onSearch } = useFilter<DriverFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchDrivers(paramsObj);
  const { mutateAsync } = useAddDriver();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<DriverFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsDriver();
  return (
    <>
      {data && data.results && options && (
        <DriverListPage
          options={options}
          drivers={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi autista" toggle={toggle} isOpen={isOpen}>
        <AddDriverForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
