import { SimpleTable } from "components/tables/SimpleTable";
import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useListTrailer } from "Vehicles/hooks/trailer";
import { Trailer } from "Vehicles/types/trailer";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  selectedVehicle: Trailer | undefined;
  setVehicle: (vehicle: Trailer) => void;
}

export const ModalTrailer = ({
  toggle,
  isOpen,
  selectedVehicle,
  setVehicle,
}: Props) => {
  const { data } = useListTrailer({ no_page: true, hide: false });
  const columns = useMemo(() => {
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              onChange={() => {
                setVehicle(cell.row.original);
              }}
              checked={
                selectedVehicle
                  ? cell.row.original.id === selectedVehicle.id
                  : false
              }
            />
          );
        },
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },
      {
        Header: "Targa",
        accessor: "plate",
        sortType: "basic",
      },

      {
        Header: "Dimensioni",
        accessor: "size",
        sortType: "basic",
      },
      {
        Header: "Litri",
        accessor: "liters",
        sortType: "basic",
      },

      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
    ];
  }, [selectedVehicle, setVehicle]);
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <span className="modal-title">Seleziona rimorchio</span>
      </ModalHeader>
      <ModalBody>
        <SimpleTable data={data} columns={columns} />
      </ModalBody>
    </Modal>
  );
};
