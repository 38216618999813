import { useState } from "react";
import { UnpackNestedValue } from "react-hook-form";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useToggler } from "shared/hooks/useToggler";
import { FilterContent } from "./FilterContent";
import { FilterI } from "./types";
import styles from "./Filter.module.css";
import classNames from "classnames";

interface Props<T> {
  filterStructure: FilterI[];
  onFilterChange: (data: Partial<T>) => void;
  paramsObj: Partial<T>;
}

export const Filter = <T,>({
  filterStructure,
  onFilterChange,
  paramsObj,
}: Props<T>) => {
  const [filters, setFilters] = useState<FilterI[]>(filterStructure);
  const numFilterActive = filters
    ? filters.filter((value) => value.active).length
    : 0;

  const onChange = (data: UnpackNestedValue<T>, filters: FilterI[]) => {
    setFilters(filters);
    onFilterChange(data as Partial<T>);
    close();
  };
  const { value: isOpen, toggle, setFalse: close } = useToggler();
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle color="primary" className={styles.filter_button}>
        <div className="d-flex flex-row">
          <div>Filtri</div>
          {numFilterActive > 0 && (
            <div className={classNames(styles.filter_button__counter)}>
              {numFilterActive}
            </div>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu style={{ padding: 0, zIndex: 99999 }}>
        <FilterContent<T>
          paramsObj={paramsObj}
          filters={filters}
          onFilterChange={onChange}
        />
      </DropdownMenu>
    </Dropdown>
  );
};
