import classNames from "classnames";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink } from "reactstrap";

interface Props {
  children: React.ReactElement[] | React.ReactElement;
  titles: string[];
  defaultActive?: number;
}

export const Tabs = (props: Props) => {
  const [activeTab, setActiveTab] = useState<number>(
    props.defaultActive ? props.defaultActive : 0
  );
  useEffect(() => {}, [activeTab]);
  const childrens = Array.isArray(props.children)
    ? props.children.filter((children) => children !== undefined)
    : props.children;
  const toggle = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Card>
      <CardHeader>
        <Nav tabs pills>
          {props.titles.map((title, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === index })}
                  onClick={() => {
                    toggle(index);
                  }}
                >
                  {title}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </CardHeader>
      {Array.isArray(childrens) ? (
        childrens.map((children, index) => (
          <div
            key={index}
            className={classNames("p-2", { "d-none": activeTab !== index })}
          >
            {children}
          </div>
        ))
      ) : (
        <CardBody>{childrens}</CardBody>
      )}
    </Card>
  );
};
