import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Trailer } from "../types/trailer";
import { TrailerForm } from "./TrailerForm";
import { useOptionsTrailer } from "Vehicles/hooks/trailer";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Trailer>,
    unknown
  >;
  trailer: Trailer;
}

export const UpdateTrailerForm = ({ submitFunction, trailer }: Props) => {
  const { errors, register, handleSubmit, setError, control } =
    useForm<Trailer>({ defaultValues: trailer });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const { data: options } = useOptionsTrailer();

  const onSend = handleSubmit((data) => {
    console.log(data);
    submitFunction({ id: trailer.id!, newValues: data })
      .then(() => {
        toast.success("Automezzo modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_trailer"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <TrailerForm
          options={options}
          control={control}
          register={register}
          errors={errors}
          editable={!disabled}
          defaultValues={trailer}
        />
      )}
      <CheckPermissions permissions={["rosina.change_trailer"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
