import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { Tabs } from "components/Tabs";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const RouteForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Stato</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Stato"
                      options={options.status.choices}
                      register={register}
                      errorMessage={errors.status?.message}
                      name="status"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Ordine</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Ordine"
                        type="number"
                        register={register}
                        name="round_order"
                        errorMessage={errors.round_order?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.round_order}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Km totali</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Km totali"
                        type="number"
                        register={register}
                        name="total_distance"
                        errorMessage={errors.total_distance?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.total_distance}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dispositivo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Dispositivo"
                        type="text"
                        register={register}
                        name="device"
                        errorMessage={errors.device?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.device}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data pianificata</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Data pianificata"
                      type="date"
                      register={register}
                      name="planned_date"
                      errorMessage={errors.planned_date?.message}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Inviata</b>
                  </td>
                  <td>
                    <input
                      ref={register}
                      disabled={!editable}
                      type="checkbox"
                      name="sent"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Autista</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.DRIVER}
                      control={control}
                      defaultValue={
                        defaultValues?.driver_id
                          ? defaultValues?.driver_id
                          : null
                      }
                      errorMessage={errors.driver_id?.message}
                      name="driver_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Veicolo</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.vehicle_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.VEHICLE}
                      control={control}
                      defaultValue={
                        defaultValues?.vehicle_id
                          ? defaultValues?.vehicle_id
                          : null
                      }
                      name="vehicle_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Origine</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Origine"
                      options={options.origin.choices}
                      register={register}
                      errorMessage={errors.origin?.message}
                      name="origin"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note"]}>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
