import { UpdateFuelSiteForm } from "FuelConspumption/components/UpdateFuelSiteForm";
import { useUpdateFuelSite } from "FuelConspumption/hooks/fuelSite";
import { FuelSite } from "FuelConspumption/types";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { FUEL_SITE_API } from "utils/constants";
import { FullModal } from "components/FullModal";
import { checkPermissions } from "utils/helpers";

export const ShowFuelSiteModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<FuelSite>(id, FUEL_SITE_API, "fuel-sites");
  const { mutateAsync } = useUpdateFuelSite();

  return data && checkPermissions(["rosina.view_fuelsite"]) ? (
    <FullModal
      title="Modifica fornitore"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateFuelSiteForm fuelSite={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
