import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const FloorForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Nome</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nome"
                        type="text"
                        register={register}
                        name="name"
                        errorMessage={errors.name?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.name}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Riga</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.ROW}
                      control={control}
                      defaultValue={
                        defaultValues?.row_id
                          ? defaultValues?.row_id
                          : null
                      }
                      errorMessage={errors.row_id?.message}
                      name="row_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo coda</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Tipo coda"
                      options={options.tile.choices}
                      register={register}
                      errorMessage={errors.tile?.message}
                      name="tile"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipi prodotti
                    </b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.PRODUCT_TYPE}
                      control={control}
                      defaultValue={
                        defaultValues?.types
                          ? defaultValues?.types
                          : []
                      }
                      multiple
                      errorMessage={errors.types?.message}
                      name="types"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Numero posti</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Numero oggetti"
                        type="number"
                        register={register}
                        name="number"
                        errorMessage={errors.number?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.number}`
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>


          </div>
        </>
      )}
    </>
  );
};
