import { useQuery, UseQueryOptions } from "react-query";
import { client } from "utils/auth-client";

export function useGetItem<T>(
  id: string | number,
  url: string,
  key: string,
  config?: UseQueryOptions<any, unknown, T>
) {
  return useQuery([key, id], () => client(`${url}${id}/`, { method: "GET" }), {
    ...config,
  });
}
