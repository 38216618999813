import classNames from "classnames";
import { PricesClientAutocomplete } from "components/autocompletes/PricesClientAutocomplete";
import { FormField } from "components/forms/FormField";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  getValues?: any;
}

export const PackageForm = ({
  options,
  control,
  register,
  errors,
  getValues,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Prezzo</b>
                  </td>
                  <td>
                    <PricesClientAutocomplete
                      disabled={!editable}
                      control={control}
                      defaultValue={
                        defaultValues?.price_id ? defaultValues?.price_id : null
                      }
                      errorMessage={errors.price_id?.message}
                      name="price_id"
                      getValues={getValues}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Quantità</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Quantità"
                        type="number"
                        register={register}
                        name="quantity"
                        errorMessage={errors.quantity?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.quantity}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Numero colli</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Numero colli"
                        type="number"
                        register={register}
                        name="items_number"
                        errorMessage={errors.items_number?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.items_number}`
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
            <input type="hidden" name="delivery_id" ref={register} />
          </div>
        </>
      )}
    </>
  );
};
