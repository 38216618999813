import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { ProductTypeForm } from "./ProductTypeForm";
import { CheckPermissions } from "components/CheckPermissions";
import { useOptionsProductType } from "../hooks/productType";
import { ProductType } from "Warehouse/types";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<ProductType>,
    unknown
  >;
  productType: ProductType;
}

export const UpdateProductTypeForm = ({ submitFunction, productType }: Props) => {
  const { control, errors, register, handleSubmit, setError } = useForm<ProductType>(
    {
      defaultValues: productType,
    }
  );
  const { data: options } = useOptionsProductType();
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onSend = handleSubmit((data) => {
    submitFunction({ id: productType.id!, newValues: data })
      .then(() => {
        toast.success("Tipo prodotto modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_producttype"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <ProductTypeForm
          editable={!disabled}
          defaultValues={productType}
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      <CheckPermissions permissions={["rosina.change_producttype"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
