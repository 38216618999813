import { useEffect, useState } from "react";
import { getDisplayValue } from "utils/helpers";

import { FullModal } from "components/FullModal";
import { useToggler } from "shared/hooks/useToggler";
import { AddChargeForm } from "../../components/AddChargeForm";
import { useFilter } from "../../../shared/hooks/useFilter";
import { Charge, ChargeFilter, GroupedChargeListItem } from "Billing/types/charge";
import {
  useAddCharge,
  useChargeColumns,
  useOptionsCharge,
  useSearchCharge,
} from "Billing/hooks/charges";
import { ChargesPage } from "Billing/components/ChargesPage/ChargesPage";
import { useOptionsPrice } from "Items/hooks/price";

export const Charges = () => {
  const { data: optionsPrice } = useOptionsPrice();
  const { data: options } = useOptionsCharge();
  const { mutateAsync } = useAddCharge();
  const { paramsObj, onSearch } = useFilter<ChargeFilter>();

  const [selectedRows, setSelectedRows] = useState<Charge[]>([]);

  const [groupedData, setGroupedData] = useState<{
    [key: string]: GroupedChargeListItem;
  }>({});

  const columns = useChargeColumns(options);

  const { value: isOpen, toggle, setTrue: openAdd } = useToggler();
  const {
    data,
    search,
    goNextPage,
    goPrevPage,
    canPrevPage,
    canNextPage,
    page,
    pageCount,
  } = useSearchCharge(paramsObj);
  useEffect(() => {
    optionsPrice &&
      setGroupedData(
        data
          ? data.results.reduce(function (
            rv: {
              [key: string]: GroupedChargeListItem;
            },
            x: any
          ) {
            if (!rv[x.client_id]) {
              rv[x.client_id] = {
                innerElements: {},
                title: x.client?.business_name!,
                amount: 0,
                taxable: 0,
                allInvoiced: !!x.invoice_id,
              };
            }
            if (!rv[x.client_id].innerElements[x.price!.charge_type]) {
              rv[x.client_id].innerElements[x.price!.charge_type] = {
                chargeType: getDisplayValue(
                  "charge_type",
                  x.price!.charge_type,
                  optionsPrice
                ),
                total: 0,
                total_taxable: 0,
                allInvoiced: !!x.invoice_id,
                charges: [],
              };
            }
            rv[x.client_id].amount += x.amount;
            rv[x.client_id].taxable += x.taxable;
            rv[x.client_id].allInvoiced =
              rv[x.client_id].allInvoiced === false ? false : !!x.invoice_id;
            rv[x.client_id].innerElements[x.price!.charge_type].total +=
              x.amount;
            rv[x.client_id].innerElements[x.price!.charge_type].total_taxable +=
              x.taxable;
            rv[x.client_id].innerElements[x.price!.charge_type].allInvoiced =
              rv[x.client_id].innerElements[x.price!.charge_type]
                .allInvoiced === false
                ? false
                : !!x.invoice_id;
            rv[x.client_id].innerElements[x.price!.charge_type].charges.push(
              x
            );

            return rv;
          }, {})
          : {}
      );
  }, [data, optionsPrice]);
  const searchCharges = (data: ChargeFilter) => {
    onSearch(data);
    search(data);
  };

  return (
    <>
      {data && optionsPrice && options && (
        <ChargesPage
          page={page}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          pageCount={pageCount}
          canNextPage={canNextPage}
          canPrevPage={canPrevPage}
          goNextPage={goNextPage}
          goPrevPage={goPrevPage}
          urlParamsObj={paramsObj}
          onSearch={searchCharges}
          onAddClick={openAdd}
          groupedCharges={groupedData}
          optionsPrice={optionsPrice}
          options={options}
          columns={columns}
        />
      )}

      <FullModal toggle={toggle} isOpen={isOpen} title="Aggiungi addebito">
        <AddChargeForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
