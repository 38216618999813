import { LoginPayload } from "shared/types";
import axios from "axios";
import { TOKEN_API } from "utils/constants";
import { IUser } from "shared/context/auth/types";
import { queryClient } from "App";

const localStorageKey = "user";

function getUser() {
  const user = localStorage.getItem(localStorageKey);
  if (user) return JSON.parse(user);
  return undefined;
}

function getAccessToken() {
  const user = localStorage.getItem(localStorageKey);
  if (user) return JSON.parse(user).access;
  return undefined;
}

function getRefreshToken() {
  const user = localStorage.getItem(localStorageKey);
  if (user) return JSON.parse(user).refresh;
  return undefined;
}

async function login(form: LoginPayload) {
  return axios
    .post(TOKEN_API, form)
    .then(async (response) => {
      const data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return Promise.reject(data);
      }
    })
    .then((user: IUser) => {
      localStorage.setItem(localStorageKey, JSON.stringify(user));
      return user;
    });
}

async function logout() {
  queryClient.clear();
  localStorage.removeItem(localStorageKey);
}

export { login, logout, getUser, getAccessToken, getRefreshToken };
