import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { AddSubjectForm } from "Subjects/components/AddSubjectForm";
import { useAddSubject } from "Subjects/hooks";

export const NewSubject = () => {
  const { mutateAsync } = useAddSubject();

  return (
    <Card>
      <CardHeader>
        <h5>Aggiungi soggetto</h5>
      </CardHeader>
      <CardBody>
        <AddSubjectForm onSuccess={() => {}} submitFunction={mutateAsync} />
      </CardBody>
    </Card>
  );
};
