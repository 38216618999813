import { FilterI } from "components/Filter/types";
import { ProductTypeFilter } from "Warehouse/types";

export const createFilterStructure = (params: ProductTypeFilter): FilterI[] => {
  return [
    {
      type: "text",
      name: "name",
      label: "Nome",
      active: params?.name !== undefined,
      value: params?.name,
    },
    {
      type: "text",
      name: "description",
      label: "Descrizione",
      active: params?.description !== undefined,
      value: params?.description,
    },
  ];
};
