import L, { LatLngBounds, LatLngExpression, FeatureGroup, Layer } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { ImageOverlay, MapContainer, Marker, TileLayer } from "react-leaflet";
import Sidebar from "react-sidebar";
import "./DeliveryPlanner.css";
import { useLeafletContext } from "@react-leaflet/core";
import "leaflet-draw";
import randomColor from "randomcolor";

import warehouseImage from "WarehousePlanner/warehouse.png"

import { Link } from "react-router-dom";

import { LoadingScreen } from "components/LoadingScreen";

import _ from "lodash";

import "leaflet-draw";
import { useAdd } from "shared/hooks/useAdd";
import { ROW_API, SECTOR_API } from "utils/constants";
import { Sector } from "../Warehouse/types";
import { Modal, Row } from "reactstrap";
import { useDelete } from "shared/hooks/useDelete";
import { useUpdate } from "shared/hooks/useUpdate";
import { useList } from "shared/hooks/useList";
import { UseQueryOptions } from "react-query";
import { MapLegend } from "./MapLegend";
import { ArrowRight } from "react-feather";


const key = "sectors";
const url = SECTOR_API;

const rows_key = "rows";
const rows_url = ROW_API;

const sector_color = '#6699ff';
const row_color = '#ff5050';


export function useAddSector() {
  return useAdd<Sector>(url, key);
}
export function useDeleteSector() {
  return useDelete(url, key);
}
export function useUpdateSector() {
  return useUpdate<Sector>(url, key);
}
export function useListSector(
  params?: any,
  config?: UseQueryOptions<any, unknown, Sector[]>
) {
  return useList(url, key, params, config);
}

export function useAddRow() {
  return useAdd<Row>(rows_url, rows_key);
}
export function useDeleteRow() {
  return useDelete(rows_url, rows_key);
}
export function useUpdateRow() {
  return useUpdate<Row>(rows_url, rows_key);
}
export function useListRow(
  params?: any,
  config?: UseQueryOptions<any, unknown, Row[]>
) {
  return useList(rows_url, rows_key, params, config);
}

function displayStatus() {


  return (<></>)
}


export function DrawControl() {
  const context = useLeafletContext();

  const drawLayerRef = useRef<FeatureGroup>();
  const drawControl = useRef<any>();

  const { mutateAsync: addSector } = useAddSector();
  const { mutateAsync: deleteSector } = useDeleteSector();
  const { mutateAsync: updateSector } = useUpdateSector();
  const { data: sector_data } = useListSector();

  const { mutateAsync: addRow } = useAddRow();
  const { mutateAsync: deleteRow } = useDeleteRow();
  const { mutateAsync: updateRow } = useUpdateRow();
  const { data: row_data } = useListRow();

  useEffect(() => {
    const map = context.map;

    drawLayerRef.current = new L.FeatureGroup();

    map.addLayer(drawLayerRef.current);

    drawControl.current = new L.Control.Draw({
      draw: {
        polygon: {},
        polyline: false,
        circle: false,
        rectangle: false,
        circlemarker: false,
        marker: false,
      },
      edit: {
        featureGroup: drawLayerRef.current,
      },
    });

    map.addControl(drawControl.current);

    map.on(L.Draw.Event.CREATED, function (event) {

      const layer = event.layer;
      const sector = prompt("Se è un SETTORE lascia vuoto, se è una RIGA, inserisci il settore di appartenenza", undefined);
      let prompt_mess = "Inserisci il nome del SETTORE";
      if (sector) {
        let sector_found = false;
        if (sector_data) {
          for (let s of sector_data) {
            let temp = s as any;
            if (temp.properties.name === sector) {
              sector_found = true;
            }
          }

          if (sector_found !== true) {
            window.alert("Nessun Settore con questo nome trovato")
            return null
          }
        }
        else {
          window.alert("Nessun Settore trovato")
          return null
        }

        prompt_mess = "Inserisci il nome della RIGA";
      }
      const name = prompt(prompt_mess, undefined);


      if (name) {
        const geoLayer = layer.toGeoJSON();

        geoLayer.properties["name"] = name;

        let color = sector_color;
        if (sector) { // Is A Row
          geoLayer.properties["sector_name"] = sector;
          addRow(geoLayer)
            .then(() => {
              console.log(geoLayer)
            })
            .catch((errors) => {
              console.log(errors)
            });
          color = row_color;
        }
        else { // Is A sector
          addSector(geoLayer)
            .then(() => {
              console.log(geoLayer)
            })
            .catch((errors) => {
              console.log(errors)
            });
        }

        layer.setStyle({
          color: color
        });
        drawLayerRef.current!.addLayer(layer);

      } else {
      }
    });

    map.on(L.Draw.Event.EDITED, function (e: any) {
      const layers = e.layers;
      layers.eachLayer(function (layer: any) {
        const geoLayer = layer.toGeoJSON();

        console.log(geoLayer)

        if (geoLayer.properties.sector) {
          updateRow({ id: geoLayer.id!, newValues: geoLayer }, {
            onSuccess: (data) => {
              console.log(geoLayer)
            }
          });
        }
        else {
          updateSector({ id: geoLayer.id!, newValues: geoLayer }, {
            onSuccess: (data) => {
              console.log(geoLayer)
            }
          });
        }
      });
    });

    map.on(L.Draw.Event.DELETED, function (e: any) {
      const layers = e.layers;
      layers.eachLayer(function (layer: any) {
        const geoJson = layer.toGeoJSON();

        if (geoJson.properties.sector) {
          deleteRow(geoJson.id, {
            onSuccess: (data) => {
              console.log(data)
            }
          });
        }
        else {
          deleteSector(geoJson.id, {
            onSuccess: (data) => {
              console.log(geoJson.id)
            }
          });
        }

      });

    });

    return () => {
      map.removeEventListener(L.Draw.Event.CREATED);
      map.removeEventListener(L.Draw.Event.DELETED);
      map.removeEventListener(L.Draw.Event.EDITED);
      map.removeControl(drawControl.current);
      map.removeLayer(drawLayerRef.current as Layer);
    };
  }, [context.map, sector_data, row_data]);

  useEffect(() => {
    if (drawLayerRef.current) {
      drawLayerRef.current.clearLayers();

      L.geoJSON(sector_data as any, {
        onEachFeature: function (feature, layer: any) {
          layer.bindTooltip(`<b>${feature.properties.name}</b>`);
          layer.options.color = sector_color;
        },
      }).eachLayer((l) => drawLayerRef.current!.addLayer(l));

      L.geoJSON(row_data as any, {
        onEachFeature: function (feature, layer: any) {
          let r = `<div><h3 align="center">${feature.properties.name}</h3><table><tr>`;
          for (let s of feature.properties.stats) {
            r += `<td style="vertical-align: top"><h4 align="center">${s.name}</h4>`;
            let p_i = 0
            for (let p of s.places) {
              p_i += 1
              let p_color = "lightblue";
              switch (p) {
                case "BLO":
                case "TOS":
                case "STO":
                  p_color = "lightblue"
                  break;
                default:
                  p_color = "lightgreen"
              }

              r += `<div style="margin: 2px; height: 35px; text-align: center; width: 35px; background-color: ${p_color}">${p_i}</div>`

            }
            r += `</td>`;
          }
          r += `</tr></table></div>`;

          layer.bindTooltip(r);
          layer.options.color = row_color;
        },
      }).eachLayer((l) => drawLayerRef.current!.addLayer(l));

    }
  }, [sector_data, row_data]);

  return null;
}





export const WarehousePlanner = () => {

  const [isLoading, setIsLoading] = useState(false);

  const mapRef = useRef(null);


  return (
    <>

      <div style={{ height: "25px" }} className="d-flex flex-row justify-content-end mb-3" >
        <Link
          style={{ color: "#7166f9", fontSize: "1.2rem" }}
          to="/"
          className="d-flex flex-row justify-content-center align-items-baseline"
        >
          Home
          <span>
            <ArrowRight className="ml-2" size={15} />
          </span>
        </Link>
      </div>

      <LoadingScreen visible={isLoading} />

      <MapContainer
        center={[45, 0]}
        zoom={2}
        style={{ height: "calc(100% - 45px)" }}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          opacity={0}
        />

        <ImageOverlay url={warehouseImage} bounds={new LatLngBounds([[0.0, -180.0], [90, 180.0]])}></ImageOverlay>
        <DrawControl />

        <MapLegend />

      </MapContainer>


    </>
  );
};
