import L from "leaflet";
import blueIconImg from "./assets/marker-icon-blue.png";
import redIconImg from "./assets/marker-icon-red.png";
import orangeIconImg from "./assets/marker-icon-orange.png";
import yellowIconImg from "./assets/marker-icon-yellow.png";
import purpleIconImg from "./assets/marker-icon-violet.png";
import greenIconImg from "./assets/marker-icon-green.png";
import blackIconImg from "./assets/marker-icon-black.png";
import orangeBlueIconImg from "./assets/marker-icon-orange-blue.png";
import orangeGreenIconImg from "./assets/marker-icon-orange-green.png";
import "./Icons.css";
export const blueIcon = new L.Icon({
  iconUrl: blueIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});
export const redIcon = new L.Icon({
  iconUrl: redIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});

export const orangeIcon = new L.Icon({
  iconUrl: orangeIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});

export const orangeBlueIcon = new L.Icon({
  iconUrl: orangeBlueIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});

export const orangeGreenIcon = new L.Icon({
  iconUrl: orangeGreenIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});

export const yellowIcon = new L.Icon({
  iconUrl: yellowIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});

export const purpleIcon = new L.Icon({
  iconUrl: purpleIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});
export const greenIcon = new L.Icon({
  iconUrl: greenIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});

export const blackIcon = new L.Icon({
  iconUrl: blackIconImg,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowUrl: "",
  popupAnchor: [0, -38],
});
export const numberedIcon = (sequences: number[], image: string) =>
  L.divIcon({
    className: "number-icon",
    iconSize: [25, 41],
    iconAnchor: [12.5, 57],
    shadowUrl: "",
    popupAnchor: [0, -38],
    html: `<div class="sequence-icon">
            <div class="circles-sequence">
            ${sequences.map((sequence) => `<span>${sequence}</span>`).join("")}
            </div>
            <img src="${image}"/>
           
            </div>`,
  });
