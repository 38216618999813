import { useLoadItem } from "shared/hooks/useLoadItem";

import { FLOOR_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { useUpdateFloor } from "../hooks/floor";
import { Floor } from "Warehouse/types";
import { FullModal } from "components/FullModal";
import { UpdateFloorForm } from "./UpdateFloorForm";

export const ShowFloorModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Floor>(id, FLOOR_API, "floors");
  const { mutateAsync } = useUpdateFloor();

  return data && checkPermissions(["rosina.view_floor"]) ? (
    <FullModal
      title="Modifica piano"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateFloorForm floor={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
