import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { PLACE_API } from "utils/constants";
import { getDisplayValue } from "utils/helpers";
import { useOptionsStatus } from "Warehouse/Status/hooks/status";
import { Place } from "../../types";

const key = "places";
const url = PLACE_API;

export function useOptionsPlace() {
  return useOptions(url, key);
}

export function useAddPlace() {
  return useAdd<Place>(url, key);
}

export function useDeletePlace() {
  return useDelete(url, key);
}

export function useUpdatePlace() {
  return useUpdate<Place>(url, key);
}

export function useListPlace(
  params?: any,
  config?: UseQueryOptions<any, unknown, Place[]>
) {
  return useList(url, key, params, config);
}

export function useSearchPlaces(params?: any) {
  return useSearchPaginated<Place>(url, key, params);
}

export function usePlace(
  id: string,
  config?: UseQueryOptions<any, unknown, Place>
) {
  return useGetItem<Place>(id, url, key, config);
}

export function usePlaceColumns(
  options: any
) {
  const { data: optionsStatus } = useOptionsStatus();
  const { setActiveItem } = useGlobalModals();
  const { mutate: deletePlace } = useDeletePlace();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deletePlace(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_place"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("place", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Piano",
        accessor: "floor",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.floor)
            return (
              <>
                {`${cell.row.original.floor.label}`}
              </>
            );
          return "";
        },
      },
      {
        Header: "Posizione",
        accessor: "position",
        sortType: "basic",
      },
      {
        Header: "Ultimo stato",
        accessor: "status",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("status", cell.value, optionsStatus),
      },
    ];
  }, [deletePlace, options, optionsStatus, setActiveItem]);
}
