import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Charge } from "Billing/types/charge";
import { useOptionsCharge } from "Billing/hooks/charges";
import { ChargeForm } from "./ChargeForm";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Charge>,
    unknown
  >;
  charge: Charge;
}

export const UpdateChargeForm = ({ submitFunction, charge }: Props) => {
  const { errors, register, handleSubmit, setError, control } = useForm<
    Omit<Charge, "client" | "price" | "receiver" | "invoice" | "delivery">
  >({
    defaultValues: charge,
  });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const { data: options } = useOptionsCharge();

  const onSend = handleSubmit((data) => {
    console.log(data);
    submitFunction({ id: charge.id!, newValues: data })
      .then(() => {
        toast.success("Addebito modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {!charge.invoice_id && (
        <CheckPermissions permissions={["rosina.change_charge"]}>
          <div className="d-flex flex-row justify-content-end">
            <Button size="sm" onClick={toggleDisable} color="primary">
              Modifica
            </Button>
          </div>
        </CheckPermissions>
      )}

      {options && (
        <ChargeForm
          options={options}
          register={register}
          errors={errors}
          control={control}
          defaultValues={charge}
          editable={!disabled}
        />
      )}

      {!charge.invoice_id && (
        <CheckPermissions permissions={["rosina.change_charge"]}>
          <div className="d-flex justify-content-end py-3">
            <Button type="submit" color="success" disabled={disabled}>
              Invia
            </Button>
          </div>
        </CheckPermissions>
      )}
    </Form>
  );
};
