import classNames from "classnames";
import React, { CSSProperties } from "react";
import styles from "./Pill.module.css";

interface Props {
  text: string | number;
  color?: "success" | "active";
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const Pill = ({ text, color, className, style, onClick }: Props) => {
  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      style={style ? style : {}}
      className={classNames(
        styles.pill,
        {
          [styles.success]: color === "success",
          [styles.active]: color === "active",
        },
        className
      )}
    >
      {text}
    </div>
  );
};
