import { useEffect } from "react";
import { useIsLoading } from "shared/context/loading";

import { useGetItem } from "./useGetItem";

export function useLoadItem<T>(
  id: number | string | undefined,
  url: string,
  key: string
) {
  const { setIsLoading } = useIsLoading();
  const { data, isLoading } = useGetItem<T>(id ? id : 0, url, key, {
    enabled: !!id,
  });
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);
  return data;
}
