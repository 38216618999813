import { Filter } from "components/Filter";
import { Alert, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";

import { Table } from "components/tables/Table";
import classNames from "classnames";

import { Segment } from "Traction/types";
import { useSegmentColumns } from "Traction/hooks/segments";

interface Props {
  urlParamsObj: any;
  onSearch: (data: Partial<{ order: number }>) => void;
  segments: Segment[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  options: any;
}

export const SegmentListPage = ({
  urlParamsObj,
  onSearch,
  segments,
  page,
  pageCount,
  goToPage,
  options,
}: Props) => {
  const columns = useSegmentColumns();

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {segments.length === 0 ? (
            <Alert color="primary">Nessuna tratta trovata </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={segments}
                  columns={columns}
                  sortBy="date"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
