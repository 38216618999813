import { SimpleTable } from "components/tables/SimpleTable";
import React, { useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { Minus } from "react-feather";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  onSave: (files: File[]) => void;
}

export const ModalFileUpload = ({ toggle, isOpen, onSave }: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      ".xml,.csv,.txt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });
  const [files, setFiles] = useState<File[]>([]);

  const removeFile = (file: File) => {
    console.log(file);
    setFiles((old) => old.filter((oldFile) => file.name !== oldFile.name));
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "__id",

        Cell: (cell: any) => {
          return (
            <Button
              onClick={() => removeFile(cell.row.original)}
              color="danger"
              className="edit-buttons"
            >
              <Minus size={13} />
            </Button>
          );
        },
      },
      {
        Header: "File",
        accessor: "path",
        sortType: "basic",
      },
      {
        Header: "Dimensione (MB)",
        accessor: "size",
        sortType: "basic",
        Cell: (cell: any) => {
          return (cell.value / (1024 * 1024)).toPrecision(2);
        },
      },
    ],
    []
  );
  const upload = () => {
    onSave(files);
    setFiles([]);
    toggle();
  };
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <span className="modal-title">Seleziona autista</span>
      </ModalHeader>
      <ModalBody>
        <section className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <aside className="mt-3">
            <h4>File selezionati:</h4>
            <SimpleTable columns={columns} data={files} />
          </aside>
        </section>
        <div className="d-flex flex-row justify-content-end">
          <Button disabled={files.length <= 0} onClick={upload} color="success">
            Carica
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
