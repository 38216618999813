import { FilterI } from "components/Filter/types";
import { FuelConsumptionFileFilter } from "FuelConspumption/types";

export const createFilterStructure = (params: FuelConsumptionFileFilter): FilterI[] => {
  return [
    {
      type: "date",
      name: "created_date",
      label: "Dal",
      active: params?.created_date !== undefined,
      value: params?.created_date,
    },
  ];
};
