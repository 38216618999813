import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { Tabs } from "components/Tabs";
import React from "react";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const VehicleForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Descrizione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Descrizione"
                        type="text"
                        register={register}
                        name="description"
                        errorMessage={errors.description?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.description}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Targa</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Targa"
                        type="text"
                        register={register}
                        name="plate"
                        errorMessage={errors.plate?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.plate}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dimensione</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Dimensione"
                      type="number"
                      register={register}
                      name="size"
                      errorMessage={errors.size?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Litri</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Litri"
                      type="number"
                      register={register}
                      name="liters"
                      errorMessage={errors.liters?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bancali</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Bancali"
                      type="number"
                      register={register}
                      name="pallets"
                      errorMessage={errors.pallets?.message}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Proprietario</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Proprietario"
                        type="text"
                        register={register}
                        name="owner"
                        errorMessage={errors.owner?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.owner}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Autista</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.DRIVER}
                      control={control}
                      defaultValue={
                        defaultValues?.driver_id
                          ? defaultValues?.driver_id
                          : null
                      }
                      errorMessage={errors.driver_id?.message}
                      name="driver_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Media Km/l</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Media Km/l"
                      type="number"
                      register={register}
                      name="km_l"
                      errorMessage={errors.km_l?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Stato</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Stato"
                      options={options.status.choices}
                      register={register}
                      errorMessage={errors.status?.message}
                      name="status"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nascondi</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nascondi"
                        type="checkbox"
                        register={register}
                        name="hide"
                        errorMessage={errors.hide?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.hide ? ("Si") : ("No")
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note"]}>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
