import { Charge } from "Billing/types/charge";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { CHARGES_API } from "utils/constants";
import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useGlobalModals } from "shared/context/globalModals";
import { Button } from "reactstrap";
import { getBoleanColumn } from "components/tables/booleanColumn";
import { getSelectableColumn } from "components/tables/selectableColumn";
import { getDisplayOnlyDate, getDisplayValue } from "utils/helpers";

const key = "charges";
const url = CHARGES_API;

export function useOptionsCharge() {
  return useOptions(url, key);
}

export function useAddCharge() {
  return useAdd<Charge>(url, key);
}

export function useDeleteCharge() {
  return useDelete(url, key);
}

export function useUpdateCharge() {
  return useUpdate<Charge>(url, key);
}

export function useListCharge(
  params?: any,
  config?: UseQueryOptions<any, unknown, Charge[]>
) {
  return useList(url, key, params, config);
}

export function useSearchCharge(
  paramsObj: any,
  config?: UseQueryOptions<
    any,
    unknown,
    {
      results: Charge[];
      count: number;
    }
  >
) {
  return useSearchPaginated<Charge>(url, key, paramsObj, config);
}

export function useCharge(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Charge>
) {
  return useGetItem<Charge>(id, url, key, config);
}

export function useChargeColumns(options: any) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteFunction } = useDeleteCharge();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteFunction(id);
    };
    return [
      getSelectableColumn(),
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              {!cell.row.original.invoice_id && (
                <DeleteButton
                  permissions={["rosina.change_charge"]}
                  onClick={() => {
                    onDelete(cell.row.original.id);
                  }}
                />
              )}
              <EditButton
                onClick={() => setActiveItem("charge", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        accessor: "insert_type",
        Header: "Mod.",
        sortType: "basic",
      },
      {
        accessor: "delivery",
        Header: "Miss/Ord",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.row.original.delivery_id ? (
            <Button
              title={"Mostra missione"}
              color="link"
              onClick={() =>
                setActiveItem("delivery", cell.row.original.delivery_id)
              }
            >
              M:{cell.row.original.delivery_id}
            </Button>
          ) : cell.row.original.order_id ? (
            <Button
              title={"Mostra ordine"}
              color="link"
              onClick={() =>
                setActiveItem("order", cell.row.original.order_id)
              }
            >
              O:{cell.row.original.order_id}
            </Button>
          ) : null,
      },
      {
        accessor: "charge_date",
        Header: "Data",
        sortType: "basic",
        Cell: (cell: any) =>
          getDisplayOnlyDate(getDisplayValue("charge_date", cell.value, options)),
      },
      {
        accessor: "merged_info.document_number",
        Header: "DDT",
        sortType: (a: any, b: any) => {

          if (a.original.merged_info.document_number < b.original.merged_info.document_number) {
            return -1;
          } else if (a.original.merged_info.document_number > b.original.merged_info.document_number) {
            return 1;
          };

          return 0;
        },
        Cell: (cell: any) => {
          if (cell.row.original.merged_info.document_number)
            return (
              <>{`${cell.row.original.merged_info.document_number}`}</>
            );
          return "";
        },
      },
      {
        Header: "Des. ID",
        accessor: "merged_info.receiver_id",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.merged_info.receiver_id)
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.merged_info.receiver_id)
                }
              >{`${cell.row.original.merged_info.receiver_id}`}</Button>
            );
          return "";
        },
      },
      {
        Header: "Des. Nome",
        accessor: "merged_info",
        sortType: (a: any, b: any) => {

          if (a.original.merged_info.receiver_business_name < b.original.merged_info.receiver_business_name) {
            return -1;
          } else if (a.original.merged_info.receiver_business_name > b.original.merged_info.receiver_business_name) {
            return 1;
          };

          return 0;
        },
        Cell: (cell: any) => {
          if (cell.row.original.merged_info.receiver_business_name)
            return (
              <>{`${cell.row.original.merged_info.receiver_business_name}`}</>
            );
          return "";
        },
      },
      {
        accessor: "client",
        Header: "Cliente",
        sortType: "basic",
        Cell: (cell: any) => (
          <Button
            title={"Mostra soggetto"}
            color="link"
            onClick={() =>
              setActiveItem("subject", cell.row.original.client.id)
            }
          >
            {cell.row.original.client.business_name}
          </Button>
        ),
      },
      {
        accessor: "real_quantity",
        Header: "Quantità reale",
        sortType: "basic",
      },
      {
        accessor: "quantity",
        Header: "Quantità add.",
        sortType: "basic",
      },
      {
        accessor: "price",
        Header: "Prezzo",
        sortType: "basic",
        Cell: (cell: any) => (
          <Button
            title={"Mostra prezzo"}
            color="link"
            onClick={() => setActiveItem("price", cell.row.original.price.id)}
          >
            {cell.row.original.price.price}€
          </Button>
        ),
      },
      {
        accessor: "taxable",
        Header: "Imponibile",
        sortType: "basic",
      },
      {
        accessor: "amount",
        Header: "Importo",
        sortType: "basic",
      },
      {
        accessor: "item",
        Header: "Articolo",
        sortType: "basic",
        Cell: (cell: any) => {
          return cell.row.original.price.item ? (
            <Button
              title={"Mostra articolo"}
              color="link"
              onClick={() =>
                setActiveItem("item", cell.row.original.price.item.id)
              }
            >
              {cell.row.original.price.item.code}
            </Button>
          ) : (
            ""
          );
        },
      },
      {
        accessor: "related_delivery",
        Header: "Collegate",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.row.original.related_delivery ? (
            cell.row.original.related_delivery.map((x: number) => {
              return (
                <Button
                  key={x}
                  title={"Mostra missione"}
                  color="link"
                  onClick={() =>
                    setActiveItem("delivery", x)
                  }
                >
                  {x}
                </Button>)
            })

          ) : null,
      },
      {
        accessor: "invoice_id",
        Header: "Fattura",
        sortType: "basic",
        Cell: (cell: any) => (
          <Button
            title={"Mostra fattura"}
            color="link"
            onClick={() => setActiveItem("invoice", cell.value)}
          >
            {cell.value}
          </Button>
        ),
      },
      getBoleanColumn(
        {
          accessor: "hide",
          Header: "Nascondi",
          sortType: "basic",
        }
      ),
    ];
  }, [deleteFunction, setActiveItem]);
}
