import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Package } from "../types";
import { Delivery } from "Distribution/types/delivery";
import { PackageForm } from "./PackageForm";
import { MESSAGES } from "utils/constants";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Package, unknown>;
  delivery: Delivery;
}

export const AddPackageForm = ({ submitFunction, delivery }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
    getValues,
  } = useForm<Omit<Package, "price">>({
    defaultValues: { price_id: null, delivery_id: delivery.id },
  });

  const onSend = handleSubmit((data) => {
    const confirm = window.confirm(MESSAGES.PACKAGES.EDIT_MESSAGE);
    confirm &&
      submitFunction(data)
        .then(() => {
          toast.success("Articolo aggiunto!");
        })
        .catch((errors) => {
          const data = errors.response.data;
          addServerErrors(data, setError);
        });
  });

  return (
    <Form onSubmit={onSend}>
      <PackageForm
        getValues={getValues}
        options={{}}
        register={register}
        errors={errors}
        control={control}
      />
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
