import { FilterI } from "components/Filter/types";
import { DriverFilter } from "Drivers/types/driver";

export const createFilterStructure = (params: DriverFilter): FilterI[] => {
  return [
    {
      type: "text",
      name: "name",
      label: "Nome",
      active: params?.name !== undefined,
      value: params?.name,
    },
    {
      type: "text",
      name: "surname",
      label: "Cognome",
      active: params?.surname !== undefined,
      value: params?.surname,
    },
    {
      type: "date",
      name: "date_of_birth",
      label: "Data di nascita",
      active: params?.date_of_birth !== undefined,
      value: params?.date_of_birth,
    },
    {
      type: "text",
      name: "device",
      label: "Dispositivo",
      active: params?.device !== undefined,
      value: params?.device,
    },
    {
      type: "boolean",
      name: "hide",
      label: "Nascosto",
      active: params?.hide !== undefined,
      value: params?.hide,
    },
  ];
};
