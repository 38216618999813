import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Delivery } from "../types/delivery";
import { useOptionsDelivery } from "../hooks/delivery";
import { DeliveryForm } from "./DeliveryForm";
import { useState } from "react";

interface Props {
  submitFunction: (delivery: Delivery) => Promise<any>;
  defaultValues?: Partial<Delivery>;
}

export const AddDeliveryForm = ({ submitFunction, defaultValues }: Props) => {
  const [defaultValuess, setDefaultValuess] = useState<Partial<Delivery>>(
    defaultValues ? defaultValues : {
      route_id: null, client_id: null, receiver_id: null
    }
  );
  const { errors, register, handleSubmit, setError, control } = useForm<
    Omit<Delivery, "client" | "route" | "receiver" | "packages" | "delivery_note_rows">
  >({
    defaultValues: defaultValuess
      ? defaultValuess
      : { route_id: null, client_id: null, receiver_id: null },
  });
  const { data: options } = useOptionsDelivery();
  //const [lastDocumentNumber, setLastDocumentNumber] = useState<string>("");

  const onSend = handleSubmit((data) => {
    //if (lastDocumentNumber !== data.document_number) {
    submitFunction(data)
      .then((resp) => {
        toast.success("Missione aggiunto!");
        //setDefaultValuess(resp);
        //setLastDocumentNumber(resp.document_number);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
    //}
  });

  return (
    <Form onSubmit={onSend}>
      {options && (
        <DeliveryForm
          register={register}
          options={options}
          control={control}
          errors={errors}
        //defaultValues={defaultValuess}
        />
      )}
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
