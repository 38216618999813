import React from "react";
import { Edit } from "react-feather";
import { IconButtonPermission } from "./IconButtonPermission";
interface Props {
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  permissions: string[];
  className?: string;
}
export const EditButton = (props: Props) => {
  return (
    <IconButtonPermission
      title="Modifica"
      color="warning"
      Icon={Edit}
      {...props}
    />
  );
};
