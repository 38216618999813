import { useQuery } from "react-query";
import { client } from "utils/auth-client";

export function useOptions(url: string, key: string) {
  return useQuery(
    ["options", key],
    () => client(url, { method: "OPTIONS" }).then((data) => data.actions.POST),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}
