import { UpdateNoteForm } from "Distribution/components/UpdateNoteForm";
import { useUpdateNote } from "Distribution/hooks/note";
import { Note } from "Distribution/types/note";

import { useLoadItem } from "shared/hooks/useLoadItem";
import { NOTES_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowNoteModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Note>(id, NOTES_API, "notes");
  const { mutateAsync } = useUpdateNote();

  return data && checkPermissions(["rosina.view_note"]) ? (
    <FullModal
      title="Modifica missione"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateNoteForm note={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
