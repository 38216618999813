import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Tabs } from "components/Tabs";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const TrailerForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Descrizione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Descrizione"
                        type="text"
                        register={register}
                        name="description"
                        errorMessage={errors.description?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.description}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Targa</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Targa"
                        type="text"
                        register={register}
                        name="plate"
                        errorMessage={errors.plate?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.plate}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dimensione</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Dimensione"
                      type="number"
                      register={register}
                      name="size"
                      errorMessage={errors.size?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Litri</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Litri"
                      type="number"
                      register={register}
                      name="liters"
                      errorMessage={errors.liters?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bancali</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Bancali"
                      type="number"
                      register={register}
                      name="pallets"
                      errorMessage={errors.pallets?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nascondi</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nascondi"
                        type="checkbox"
                        register={register}
                        name="hide"
                        errorMessage={errors.hide?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.hide ? ("Si") : ("No")
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note"]}>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
