import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Package } from "../types";
import { UpdatePayload } from "shared/types";
import { PackageForm } from "./PackageForm";
import { MESSAGES } from "utils/constants";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Package>,
    unknown
  >;
  package_item: Package;
}

export const UpdatePackageForm = ({ submitFunction, package_item }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
    getValues,
  } = useForm<Omit<Package, "price">>({
    defaultValues: package_item,
  });

  const onSend = handleSubmit((data) => {
    const confirm = window.confirm(MESSAGES.PACKAGES.EDIT_MESSAGE);
    confirm &&
      submitFunction({ id: package_item.id!, newValues: data })
        .then(() => {
          toast.success("Articolo modificato!");
        })
        .catch((errors) => {
          const data = errors.response.data;
          addServerErrors(data, setError);
        });
  });

  return (
    <Form onSubmit={onSend}>
      <PackageForm
        getValues={getValues}
        options={{}}
        register={register}
        errors={errors}
        control={control}
        defaultValues={package_item}
      />
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Modifica
        </Button>
      </div>
    </Form>
  );
};
