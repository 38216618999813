import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Driver } from "../types/driver";
import { useOptionsDriver } from "Drivers/hooks/driver";
import { DriverForm } from "./DriverForm";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Driver>,
    unknown
  >;
  driver: Driver;
}

export const UpdateDriverForm = ({ submitFunction, driver }: Props) => {
  const { control, errors, register, handleSubmit, setError } = useForm<Driver>(
    {
      defaultValues: driver,
    }
  );
  const { data: options } = useOptionsDriver();
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onSend = handleSubmit((data) => {
    submitFunction({ id: driver.id!, newValues: data })
      .then(() => {
        toast.success("Autista modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_driver"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <DriverForm
          editable={!disabled}
          defaultValues={driver}
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      <CheckPermissions permissions={["rosina.change_driver"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
