import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { filterJson } from "components/tables/filters";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { VEHICLES_API } from "utils/constants";
import { getDisplayValue } from "utils/helpers";
import { Vehicle } from "Vehicles/types/vehicle";

const key = "vehicles";
const url = VEHICLES_API;

export function useOptionsVehicle() {
  return useOptions(url, key);
}

export function useAddVehicle() {
  return useAdd<Vehicle>(url, key);
}

export function useDeleteVehicle() {
  return useDelete(url, key);
}

export function useUpdateVehicle() {
  return useUpdate<Vehicle>(url, key);
}

export function useListVehicle(
  params?: any,
  config?: UseQueryOptions<any, unknown, Vehicle[]>
) {
  return useList(url, key, params, config);
}

export function useSearchVehicles(params?: any) {
  return useSearchPaginated<Vehicle>(url, key, params);
}

export function useVehicle(
  id: string,
  config?: UseQueryOptions<any, unknown, Vehicle>
) {
  return useGetItem<Vehicle>(id, url, key, config);
}

export function useVehicleColumns(options: any) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteVehicle } = useDeleteVehicle();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteVehicle(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_vehicle"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("vehicle", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },
      {
        Header: "Targa",
        accessor: "plate",
        sortType: "basic",
      },
      {
        Header: "Stato",
        accessor: "status",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("status", cell.value, options),
      },
      {
        Header: "Dimensioni",
        accessor: "size",
        sortType: "basic",
      },
      {
        Header: "Litri",
        accessor: "liters",
        sortType: "basic",
      },
      {
        Header: "Proprietario",
        accessor: "owner",
        sortType: "basic",
      },
      {
        Header: "Media km/l",
        accessor: "km_l",
        sortType: "basic",
      },
      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
      {
        Header: "Autista",
        accessor: "default_driver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.value)
            return (
              <Button
                title={"Mostra autista"}
                color="link"
                onClick={() => setActiveItem("driver", cell.value.id)}
              >{`${cell.row.original.default_driver.name} ${cell.row.original.default_driver.surname}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
    ];
  }, [deleteVehicle, options, setActiveItem]);
}
