import React, { useRef, useState } from "react";
import { Edit2, X } from "react-feather";
import { Controller } from "react-hook-form";
import { Button } from "reactstrap";
import { getBase64 } from "utils/helpers";

interface Props {
  control: any;
  file?: string;
  errorMessage?: string;
  disabled?: boolean;
  accept?: string;
  name: string;
}
export const ImageField = ({
  control,
  file,
  errorMessage,
  disabled,
  accept,
  name,
}: Props) => {
  const refButton = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string | null>(file ? file : null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={undefined}
      render={({ onChange }) => (
        <div className="d-flex flex-column">
          <span>{fileName}</span>
          {errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
          <div className="d-flex flex-row mt-2">
            {!disabled && (
              <>
                <Button
                  className="edit-buttons"
                  color="primary"
                  onClick={() => {
                    if (refButton.current && refButton.current !== null)
                      refButton.current.click();
                  }}
                >
                  <Edit2 size={13} />
                </Button>
                <Button
                  className="edit-buttons"
                  onClick={() => {
                    setFileName("");
                    onChange(null);
                  }}
                  color="danger"
                >
                  <X size={13} />
                </Button>
              </>
            )}
            <input
              ref={refButton}
              onChange={(data) => {
                if (data.target.files) {
                  getBase64(data.target.files[0]).then((file) => {
                    setFileName(
                      data.target.files ? data.target.files[0].name : ""
                    );
                    onChange(file);
                  });
                } else onChange(data);
              }}
              type="file"
              accept={accept}
              hidden
            />
          </div>
        </div>
      )}
    />
  );
};
