import React from "react";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import { clientNoJson } from "utils/auth-client";
import {
  CheckCircle,
  MinusCircle
} from "react-feather";

function getFileNameFromContentDisposition(contentDisposition: any) {
  if (!contentDisposition) return null;

  const match = contentDisposition.match(/filename="?([^"]+)"?/);
  return match ? match[1] : null;
}

interface Props {
  urlDownload: string;
  title?: string;
  final?: boolean;
  ButtonDownload?: React.ComponentType;
}
export const DownloadFile = ({ final, title, urlDownload, ButtonDownload }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleClick = async () => {
    setLoading(true);
    setError(null);

    let res = null;

    try {
      // add any additional headers, such as authorization, as the second parameter to get below
      // also, remember to use responseType: 'blob' if working with blobs instead, and use res.blob() instead of res.data below
      res = await clientNoJson(urlDownload, {
        responseType: "arraybuffer",
        method: "GET",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
      return;
    }

    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  };

  if (error) {
    toast.error("Errore");
  }

  return (
    <div
      style={{ cursor: "pointer" }}
      className="d-flex flex-row align-items-center"
      onClick={() => {
        if (!loading) handleClick();
      }}
    >
      <div className="mr-2">
        {loading ? (
          <Spinner />
        ) : ButtonDownload ? (
          <ButtonDownload />
        ) : (
          <>
            {
              final != undefined ? (
                final ? <CheckCircle color="green" /> : <MinusCircle color="orange" />
              ) : (<></>)
            }
            <Button color="link" type="button">
              {title ? title : "Download"}
            </Button>
          </>

        )}
      </div>
    </div>
  );
};
