import { Invoice, InvoiceListingFilter } from "Billing/types/invoice";
import { Filter } from "components/Filter";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";
import { Table } from "components/tables/Table";
import { useInvoiceColumns } from "Billing/hooks/invoices";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<InvoiceListingFilter>) => void;
  onAddClick: () => void;
  invoices: Invoice[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;

  options: any;
}

export const InvoiceListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  invoices,
  page,
  pageCount,

  goToPage,
  options,
}: Props) => {
  const columns = useInvoiceColumns();
  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj, options)}
        />
        <CheckPermissions permissions={["rosina.change_invoice"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            Aggiungi
          </Button>
        </CheckPermissions>
      </div>
      <div className="row ">
        <div className="col-12">
          {invoices.length === 0 ? (
            <Alert color="primary">Nessuna fattura trovata </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={invoices}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
