import { NestedValue, useFieldArray, useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { OrderForm } from "./OrderForm";
import { DestinyTraction, Order } from "Traction/types";
import { useOptionsDestinyTraction } from "Traction/hooks/destinyTraction";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Order, unknown>;
}

export const AddOrderForm = ({ submitFunction }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
    getValues,
    watch,
  } = useForm<
    Omit<Order, "client" | "destinies"> & {
      destinies: NestedValue<DestinyTraction[]>;
    }
  >({
    defaultValues: {
      client_id: undefined,
      fill: "false",
      destinies: [{ type: "C" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "destinies",
    keyName: "key" as "id",
  });
  const { data: options } = useOptionsDestinyTraction();

  const onSend = handleSubmit((data) => {
    if (data.destinies[0].type === "S") {
      toast.error("La prima missione deve contentere un carico!");
    } else {
      if (
        data.destinies.length > 1 &&
        data.destinies.every((v, i, a) => {
          return !i || new Date(a[i - 1].date!) > new Date(v.date!);
        })
      ) {
        toast.error("La missioni devono essere ordinate temporalmente");
      } else {
        submitFunction(data)
          .then(() => {
            toast.success("Ordine aggiunto!");
          })
          .catch((errors) => {
            const data = errors.response.data;
            addServerErrors(data, setError);
          });
      }
    }
  });
  return (
    <Form onSubmit={onSend}>
      {options && (
        <OrderForm
          watch={watch}
          options={options}
          append={append}
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          fields={fields}
          remove={remove}
        />
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Salva
        </Button>
      </div>
    </Form>
  );
};
