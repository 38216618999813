import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Pagination, PaginationItem } from "reactstrap";

interface Props {
  canPrevPage: () => boolean;
  goPrevPage: () => void;
  page: number;
  pageCount: number;
  canNextPage: () => boolean;
  goNextPage: () => void;
}

export const CustomPagination = ({
  canPrevPage,
  goPrevPage,
  page,
  pageCount,
  canNextPage,
  goNextPage,
}: Props) => {
  return (
    <nav>
      <Pagination
        className="pagination pagination-sm justify-content-end my-3"
        listClassName="pagination-sm justify-content-end mb-0"
      >
        <PaginationItem disabled={!canPrevPage()}>
          <button className="page-link" onClick={() => goPrevPage()}>
            <ChevronLeft size={13} />
          </button>
        </PaginationItem>
        <span className="ml-2 mr-2   my-auto" style={{ fontSize: "small" }}>
          Pag: {page} di {pageCount}
        </span>

        <PaginationItem disabled={!canNextPage()}>
          <button className="page-link" onClick={() => goNextPage()}>
            <ChevronRight size={13} />
          </button>
        </PaginationItem>
      </Pagination>
    </nav>
  );
};
