import { UpdateDestinyTractionPalletForm } from "PalletsManagment/components/UpdateDestinyTractionPalletForm";
import { useUpdateDestinyTractionPallet } from "PalletsManagment/hooks/destinyTractionPallet";
import { DestinyTractionPallet } from "PalletsManagment/types";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { DESTINY_TRACTION_PALLET_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowDestinyTractionPalletModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<DestinyTractionPallet>(
    id,
    DESTINY_TRACTION_PALLET_API,
    "destiny-traction-pallets"
  );
  const { mutateAsync } = useUpdateDestinyTractionPallet();

  return data && checkPermissions(["rosina.view_destinytraction"]) ? (
    <FullModal
      title="Modifica"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateDestinyTractionPalletForm
        destinyTractionPallet={data}
        submitFunction={mutateAsync}
      />
    </FullModal>
  ) : null;
};
