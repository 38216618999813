import { UpdateRouteForm } from "Distribution/components/UpdateRouteForm";
import { useUpdateRoute } from "Distribution/hooks/route";
import { Route } from "Distribution/types/route";
import { useLoadItem } from "shared/hooks/useLoadItem";

import { ROUTES_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowRouteModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Route>(id, ROUTES_API, "routes");
  const { mutateAsync } = useUpdateRoute();

  return data && checkPermissions(["rosina.view_route"]) ? (
    <FullModal
      title="Modifica giro"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateRouteForm route={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
