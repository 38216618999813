import axios, { AxiosRequestConfig } from "axios";
import * as auth from "services/auth";
import { TOKEN_REFRESH_API } from "./constants";
import { hideLoading, showLoading } from "./loadingFunction";

const app = axios.create();
app.interceptors.request.use(
  async (config) => {
    const token = auth.getAccessToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
app.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    console.log(originalRequest.url);
    if (
      error.response.status === 401 &&
      originalRequest.url === TOKEN_REFRESH_API
    ) {
      auth.logout();
      window.location.reload();
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return app({
        url: TOKEN_REFRESH_API,
        method: "POST",
        data: {
          refresh: auth.getRefreshToken(),
        },
      }).then((res) => {
        if (res.status === 200) {
          const user = auth.getUser();
          user.access = res.data.access;
          localStorage.setItem("user", JSON.stringify(user));
          return app(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  }
);

export async function client(endpoint: string, config: AxiosRequestConfig) {
  if (config.method === "GET") {
    showLoading()
  }
  return app({
    ...config,
    url: endpoint,
  }).then(async (response) => {
    if (response.data.results && response.data.results.type && response.data.results.type === "FeatureCollection") {
      return await response.data.results.features;
    }
    return await response.data;
  }).finally(
    () => {
      if (config.method === "GET") {
        hideLoading()
      }
    }
  );
}

export async function clientNoJson(
  endpoint: string,
  config: AxiosRequestConfig
) {
  return app({
    ...config,
    url: endpoint,
  });
}
