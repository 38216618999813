import { Filter } from "components/Filter";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";

import { Table } from "components/tables/Table";
import classNames from "classnames";
import { FuelConsumption, FuelConsumptionFilter } from "FuelConspumption/types";
import { MESSAGES } from "utils/constants";
import { Column } from "react-table";
import React, { useCallback } from "react";
import { ArrowDown, DollarSign, Navigation, ZoomIn } from "react-feather";
import { StatsCard } from "components/StatsCard";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<FuelConsumptionFilter>) => void;
  onAddClick: () => void;
  fuelConsumptions: FuelConsumption[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  columns: Column[];
}

export const FuelConsumptionListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  fuelConsumptions,
  page,
  pageCount,
  columns,
  goToPage,
}: Props) => {
  const getAverageKmLiters = useCallback(() => {
    if (fuelConsumptions.length === 0) return 0;
    let total = 0;
    for (let i = 0; i < fuelConsumptions.length - 1; i++) {
      const average =
        (fuelConsumptions[i].km - fuelConsumptions[i + 1].km) /
        fuelConsumptions[i + 1].liters;
      total += average;
    }
    return (total / (fuelConsumptions.length - 1)).toFixed(2);
  }, [fuelConsumptions]);

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
        <CheckPermissions permissions={["rosina.change_fuelconsumption"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            {MESSAGES.GENERAL.NEW_ITEM}
          </Button>
        </CheckPermissions>
      </div>
      <div className="row mb-3">
        <div className="col-12 col-sm-12 col-lg-4 mb-2">
          <StatsCard
            icon={ZoomIn}
            title={"Numero operazioni"}
            number={fuelConsumptions.length}
          />
        </div>
        <div className="col-12 col-sm-12 col-lg-4 mb-2">
          <StatsCard
            icon={Navigation}
            title={"Totale km"}
            number={fuelConsumptions.reduce((tot: number, value) => {
              return tot + value.km;
            }, 0)}
          />
        </div>
        <div className="col-12 col-sm-12 col-lg-4 mb-2">
          <StatsCard
            icon={DollarSign}
            title={"Media km/l"}
            number={getAverageKmLiters()}
          />
        </div>
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {fuelConsumptions.length === 0 ? (
            <Alert color="primary">{MESSAGES.GENERAL.NO_ITEMS}</Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={fuelConsumptions}
                  columns={columns}
                  sortBy="-date"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
