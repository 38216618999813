import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { DateTimeField } from "components/forms/DateTimeField";

interface Props {
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const FuelConsumptionForm = ({
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      <>
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <table className={classNames("table_col")}>
            <tbody>
              <tr>
                <td>
                  <b>Autista</b>
                </td>
                <td>
                  <Autocomplete
                    errorMessage={errors.driver_id?.message}
                    disabled={!editable}
                    url={AUTOCOMPLETE_URLS.DRIVER}
                    control={control}
                    defaultValue={
                      defaultValues?.driver_id
                        ? defaultValues?.driver_id
                        : null
                    }
                    name="driver_id"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Veicolo</b>
                </td>
                <td>
                  <Autocomplete
                    errorMessage={errors.vehicle_id?.message}
                    disabled={!editable}
                    url={AUTOCOMPLETE_URLS.VEHICLE}
                    control={control}
                    defaultValue={
                      defaultValues?.vehicle_id
                        ? defaultValues?.vehicle_id
                        : null
                    }
                    name="vehicle_id"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>File di import</b>
                </td>
                <td>
                  <Autocomplete
                    errorMessage={errors.file_id?.message}
                    disabled={!editable}
                    url={AUTOCOMPLETE_URLS.FUEL_CONSUMPTION_FILE}
                    control={control}
                    defaultValue={
                      defaultValues?.file_id ? defaultValues?.file_id : null
                    }
                    name="file_id"
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <b>Data</b>
                </td>
                <td>
                  <DateTimeField
                    disabled={!editable}
                    control={control}
                    name="date"
                    errorMessage={errors.date?.message}
                  />
                </td>
              </tr>

            </tbody>
          </table>

          <table className={classNames("table_col")}>
            <tbody>
              <tr>
                <td>
                  <b>Fornitore</b>
                </td>
                <td>
                  <Autocomplete
                    errorMessage={errors.site_id?.message}
                    disabled={!editable}
                    url={AUTOCOMPLETE_URLS.FUEL_SITE}
                    control={control}
                    defaultValue={
                      defaultValues?.site_id ? defaultValues?.site_id : null
                    }
                    name="site_id"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Numero tessera</b>
                </td>
                <td>
                  {editable ? (
                    <FormField
                      placeholder="Numero tessera"
                      type="number"
                      register={register}
                      name="card_number"
                      errorMessage={errors.card_number?.message}
                    />
                  ) : defaultValues && !editable ? (
                    `${defaultValues.card_number}`
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Prezzo litro</b>
                </td>
                <td>
                  {editable ? (
                    <FormField
                      placeholder="Prezzo litro"
                      type="number"
                      register={register}
                      name="price"
                      errorMessage={errors.price?.message}
                    />
                  ) : defaultValues && !editable ? (
                    `${defaultValues.price}`
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Litri</b>
                </td>
                <td>
                  {editable ? (
                    <FormField
                      placeholder="Litri"
                      type="number"
                      register={register}
                      name="liters"
                      errorMessage={errors.liters?.message}
                    />
                  ) : defaultValues && !editable ? (
                    `${defaultValues.liters}`
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Km</b>
                </td>
                <td>
                  {editable ? (
                    <FormField
                      placeholder="Km"
                      type="number"
                      register={register}
                      name="km"
                      errorMessage={errors.km?.message}
                    />
                  ) : defaultValues && !editable ? (
                    `${defaultValues.km}`
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </>
  );
};
