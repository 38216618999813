import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";

import { PalletForm } from "./PalletForm";
import { MESSAGES } from "utils/constants";
import { Pallet } from "PalletsManagment/types";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Pallet>,
    unknown
  >;
  pallet: Pallet;
}

export const UpdatePalletForm = ({ submitFunction, pallet }: Props) => {
  const { errors, register, handleSubmit, setError } = useForm<Pallet>({
    defaultValues: pallet,
  });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };

  const onSend = handleSubmit((data) => {
    console.log(data);
    submitFunction({ id: pallet.id!, newValues: data })
      .then(() => {
        toast.success(MESSAGES.PALLET.UPDATE_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_pallet"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            {MESSAGES.GENERAL.UPDATE_ITEM}
          </Button>
        </div>
      </CheckPermissions>

      <PalletForm
        register={register}
        errors={errors}
        defaultValues={pallet}
        editable={!disabled}
      />
      <CheckPermissions permissions={["rosina.change_pallet"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
