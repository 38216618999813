import { Header } from "components/headers/Header";
import { Link } from "react-router-dom";

import {
  Map, Navigation, Trello, Truck, User,
} from "react-feather";

import * as auth from "services/auth";
import { Card } from "reactstrap";
import { checkPermissions } from "utils/helpers";

export const HomePage = () => {

  let user = auth.getUser();


  const shortCutList = [
    {
      name: "Pianificatore",
      permissions: [
        "rosina.view_delivery",
        "rosina.view_route",
        "rosina.view_note"],
      link: "/distribution/planner",
      icon: Map
    },
    {
      name: "Missioni",
      permissions: ["rosina.view_delivery"],
      link: "/distribution",
      icon: Navigation
    },
    {
      name: "Giri",
      permissions: ["rosina.view_route"],
      link: "/distribution/routes",
      icon: Navigation
    },
    {
      name: "Soggetti",
      permissions: ["rosina.view_subject"],
      link: "/resources/subjects",
      icon: User
    },
    {
      name: "Autisti",
      permissions: ["rosina.view_driver"],
      link: "/resources/drivers",
      icon: User
    },
    {
      name: "Veicoli",
      permissions: ["rosina.view_vehicle"],
      link: "/resources/vehicles",
      icon: Truck
    },
    {
      name: "Gest. magazzino",
      permissions: ["rosina.change_status"],
      link: "/warehouse/status",
      icon: Trello
    },
    {
      name: "Plan. Magazzino",
      permissions: ["rosina.change_sector"],
      link: "/warehouse/planner",
      icon: Trello
    },
    {
      name: "Art. magazzino",
      permissions: ["rosina.change_article"],
      link: "/warehouse/articles",
      icon: Trello
    },
  ]

  return (
    <>
      <Header />

      <h3 style={{ textAlign: "center" }}>Benvenuto, {user.username}!</h3>
      <div className="row">


        {
          shortCutList.map((val) => {
            return (checkPermissions(val.permissions) ?
              <div className="col-sm-12 col-md-6 col-lg-4" style={{ padding: "10px", textAlign: "center" }}>
                <Link
                  style={{ color: "black", fontSize: "1.2rem", width: "100%" }}
                  to={val.link}
                  className="d-flex flex-row justify-content-center align-items-baseline">
                  <Card style={{ padding: "10px", width: "100%" }}>

                    <h4>
                      {val.name}
                    </h4>
                    <h4>
                      <span>
                        <val.icon className="ml-2" size={50} />
                      </span>
                    </h4>
                  </Card>
                </Link>
              </div> : null
            )
          }

          )
        }

      </div>

    </>
  );
};
