import { SidebarNav } from "components/sidebars/SidebarNav";
import React, { useState } from "react";
import { SidebarProvider } from "shared/context/sidebar";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Loader from "react-loader-spinner";

interface Props {
  children: React.ReactNode;
}
export const DefaultLayout = ({ children }: Props) => {

  return (
    <SidebarProvider>
      <SidebarNav role="admin">
        <div id="content" className="container-fluid">
          <div id="main-content">{children}</div>
          <div id="loading-div"
            style={{
              position: "absolute", right: "0", top: 0,
              width: "100%", height: "100%", backgroundColor: "white",
              visibility: "hidden"
            }}>
            <div
              style={{
                zIndex: 100000,
                background: "#ffffffd1",
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              className="d-flex flex-row justify-content-center align-items-center h-100 animate animate__fadeIn"
            >
              <Loader
                type="MutatingDots"
                color="black"
                secondaryColor="#7166f9"
                height={100}
                width={100}
              />
            </div>
          </div>
        </div>
        <div className="footer mt-4">
          <div className="d-flex flex-row justify-content-center">
            © 2021
            <a target="__blank" className="ml-2" href="https://www.be20.it">
              <span>Be20 Innovation</span>
            </a>
          </div>
        </div>
      </SidebarNav>
    </SidebarProvider>
  );
};
