import { UpdateInvoice } from "Billing/components/UpdateInvoice";
import { useUpdateInvoice } from "Billing/hooks/invoices";
import { Invoice } from "Billing/types/invoice";
import { FullModal } from "components/FullModal";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { INVOICES_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";

export const ShowInvoiceModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Invoice>(id, INVOICES_API, "invoices");
  const { mutateAsync, isLoading } = useUpdateInvoice();

  return data && checkPermissions(["rosina.view_invoice"]) ? (
    <FullModal
      title="Modifica fattura"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateInvoice
        isUpdating={isLoading}
        invoiceId={data.id!}
        submitFunction={mutateAsync}
      />
    </FullModal>
  ) : null;
};
