import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Tabs } from "components/Tabs";
import { BooleanField } from "components/forms/BooleanField";
import { ArrayField } from "react-hook-form";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { SelectField } from "components/forms/SelectField";
import { DateTimeField } from "components/forms/DateTimeField";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";
import { useOptionsOrder } from "Traction/hooks/orders";

interface Props {
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  getValues: any;
  fields: Partial<ArrayField<Record<string, any>, "id">>[];
  remove: (index?: number | number[] | undefined) => void;
  append: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined
  ) => void;
  options: any;
  watch: any;
}

export const OrderForm = ({
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  getValues,
  fields,
  append,
  remove,
  options,
  watch,
}: Props) => {
  const { data: optionsOrder } = useOptionsOrder();

  return optionsOrder ? (
    <>
      <div className="d-flex flex-column flex-lg-row justify-content-between">
        <table className={classNames("table_col")}>
          <tbody>
            <tr>
              <td>
                <b>Cliente</b>
              </td>
              <td>
                <Autocomplete
                  disabled={!editable}
                  url={AUTOCOMPLETE_URLS.CLIENT}
                  control={control}
                  defaultValue={
                    defaultValues?.client_id ? defaultValues?.client_id : null
                  }
                  errorMessage={errors.client_id?.message}
                  name="client_id"
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Data per addebiti</b>
              </td>
              <td>
                <FormField
                  defaultValue={
                    defaultValues?.date ? defaultValues?.date : null
                  }
                  type={"date"}
                  register={register}
                  disabled={!editable}
                  name={"date"}
                  errorMessage={errors.date?.message}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>N. documento</b>
              </td>
              <td>
                <FormField
                  defaultValue={
                    defaultValues?.document_number
                      ? defaultValues?.document_number
                      : null
                  }
                  type={"text"}
                  register={register}
                  disabled={!editable}
                  name={"document_number"}
                  errorMessage={errors.document_number?.message}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Prezzo manuale</b>
              </td>
              <td>
                <FormField
                  defaultValue={
                    defaultValues?.value
                      ? defaultValues?.value
                      : null
                  }
                  type="number"
                  register={register}
                  disabled={!editable}
                  name={"value"}
                  errorMessage={errors.value?.message}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table className={classNames("table_col")}>
          <tbody>
            <tr>
              <td>
                <b>Tipo di trasporto</b>
              </td>
              <td>
                <SelectField
                  disabled={!editable}
                  placeholder="Tipo di trasporto"
                  options={optionsOrder.required_transport.choices}
                  register={register}
                  errorMessage={errors.required_transport?.message}
                  name="required_transport"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>N. ordine</b>
              </td>
              <td>
                <FormField
                  defaultValue={
                    defaultValues?.order_number
                      ? defaultValues?.order_number
                      : null
                  }
                  type={"text"}
                  register={register}
                  disabled={!editable}
                  name={"order_number"}
                  errorMessage={errors.order_number?.message}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Riempi</b>
              </td>
              <td>
                {editable ? (
                  <BooleanField
                    register={register}
                    name="fill"
                    errorMessage={errors.fill?.message}
                  />
                ) : defaultValues.fill && !editable ? (
                  defaultValues.fill
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5">
        <Tabs titles={["Missioni", "Note"]}>
          <div>
            <CheckPermissions permissions={["rosina.change_order"]}>
              <div className="d-flex flex-row justify-content-end">
                <Button
                  size="sm"
                  color="primary"
                  className="mb-3"
                  onClick={() =>
                    append({
                      subject_id: null,
                      vehicle_trailer_hooking_id: null,
                      vehicle_trailer_release_id: null,
                      trailer_id: null,
                    })
                  }
                >
                  Aggiungi
                </Button>
              </div>
            </CheckPermissions>
            <ListGroup>
              {fields.map((item, index) => {
                return (
                  <ListGroupItem key={item.key}>
                    <input
                      defaultValue={item.id}
                      type="hidden"
                      ref={register()}
                      name={`destinies[${index}].id`}
                    />
                    <div className="d-flex flex-column flex-lg-row justify-content-between">
                      <table className={classNames("table_col", "mb-2")}>
                        <tbody>
                          <tr>
                            <td>
                              <b>Tipo</b>
                            </td>
                            <td>
                              <SelectField
                                defaultValue={item.type}
                                register={register()}
                                disabled={!editable}
                                name={`destinies[${index}].type`}
                                options={options.type.choices}
                                errorMessage={
                                  errors.destinies?.[index]?.type?.message
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>
                                {
                                  getValues().destinies && getValues().destinies.length > index && (
                                    getValues().destinies[index].type === "S" ?
                                      "Punto Scarico" :
                                      getValues().destinies[index].type === "C" ?
                                        "Punto Carico" : "Punto Carico/Scarico"
                                  )}

                              </b>
                            </td>
                            <td>
                              <Autocomplete
                                url={AUTOCOMPLETE_URLS.SUBJECT}
                                defaultValue={
                                  item.destination_id
                                    ? item.destination_id
                                    : null
                                }
                                disabled={!editable}
                                name={`destinies[${index}].destination_id`}
                                control={control}
                                errorMessage={
                                  errors.destinies?.[index]?.destination_id
                                    ?.message
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Destinazione intermedia</b>
                            </td>
                            <td>
                              <Autocomplete
                                url={AUTOCOMPLETE_URLS.SUBJECT}
                                defaultValue={
                                  item.intermediate_destination_id
                                    ? item.intermediate_destination_id
                                    : null
                                }
                                disabled={!editable}
                                name={`destinies[${index}].intermediate_destination_id`}
                                control={control}
                                errorMessage={
                                  errors.destinies?.[index]
                                    ?.intermediate_destination_id?.message
                                }
                              />
                            </td>
                          </tr>

                        </tbody>
                      </table>
                      <table className={classNames("table_col", "mb-2")}>
                        <tbody>
                          {watch(`destinies[${index}].type`) === "C" ||
                            watch(`destinies[${index}].type`) === "E" ? (
                            <tr>
                              <td>
                                <b>Quantità carico</b>
                              </td>
                              <td>
                                <FormField
                                  defaultValue={item.quantity_load}
                                  type={"number"}
                                  register={register()}
                                  disabled={!editable}
                                  name={`destinies[${index}].quantity_load`}
                                  errorMessage={
                                    errors.destinies?.[index]?.quantity_load
                                      ?.message
                                  }
                                />
                              </td>
                            </tr>
                          ) : null}
                          {watch(`destinies[${index}].type`) === "S" ||
                            watch(`destinies[${index}].type`) === "E" ? (
                            <tr>
                              <td>
                                <b>Quantità scarico</b>
                              </td>
                              <td>
                                <FormField
                                  defaultValue={item.quantity_unload}
                                  type={"number"}
                                  register={register()}
                                  disabled={!editable}
                                  name={`destinies[${index}].quantity_unload`}
                                  errorMessage={
                                    errors.destinies?.[index]?.quantity_unload
                                      ?.message
                                  }
                                />
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td>
                              <b>Tipo quantità</b>
                            </td>
                            <td>
                              <SelectField
                                defaultValue={item.quantity_type}
                                register={register()}
                                disabled={!editable}
                                name={`destinies[${index}].quantity_type`}
                                options={options.quantity_type.choices}
                                errorMessage={
                                  errors.destinies?.[index]?.quantity_type
                                    ?.message
                                }
                              />
                            </td>
                          </tr>
                          {watch(`destinies[${index}].quantity_type`) ===
                            "B" && (
                              <tr>
                                <td>
                                  <b>Tipo pallet</b>
                                </td>
                                <td>
                                  <Autocomplete
                                    required
                                    disabled={!editable}
                                    url={AUTOCOMPLETE_URLS.PALLET}
                                    control={control}
                                    defaultValue={
                                      item?.pallet_type_id
                                        ? item?.pallet_type_id
                                        : null
                                    }
                                    errorMessage={errors.pallet_type_id?.message}
                                    name={`destinies[${index}].pallet_type_id`}
                                  />
                                </td>
                              </tr>
                            )}
                          <tr>
                            <td>
                              <b>Data</b>
                            </td>
                            <td>
                              <DateTimeField
                                defaultValue={item.date ? item.date : null}
                                control={control}
                                disabled={!editable}
                                name={`destinies[${index}].date`}
                                errorMessage={
                                  errors.destinies?.[index]?.date?.message
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {index >= 1 && (
                      <CheckPermissions permissions={["rosina.change_order"]}>
                        <div className="d-flex flex-row justify-content-end">
                          <Button
                            type="button"
                            size="sm"
                            color="danger"
                            onClick={() => remove(index)}
                          >
                            Rimuovi
                          </Button>
                        </div>
                      </CheckPermissions>
                    )}
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </div>
          <div>
            <table className={classNames("table_col", "mb-2")}>
              <tbody>
                <tr>
                  <td>
                    <b>Note</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Note"
                        type="textarea"
                        register={register}
                        errorMessage={errors.notes?.message}
                        name="notes"
                      />
                    ) : (
                      defaultValues && defaultValues.notes
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Tabs>
      </div>
    </>
  ) : null;
};
