import { UseQueryOptions } from "react-query";
import { useList } from "shared/hooks/useList";
import { TRAILERS_API } from "utils/constants";
import React, { useMemo } from "react";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { Trailer } from "Vehicles/types/trailer";
import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useGlobalModals } from "shared/context/globalModals";

const key = "trailers";
const url = TRAILERS_API;

export function useListTrailer(
  params?: any,
  config?: UseQueryOptions<any, unknown, Trailer[]>
) {
  return useList(url, key, { ...params, no_page: true }, config);
}

export function useOptionsTrailer() {
  return useOptions(url, key);
}

export function useAddTrailer() {
  return useAdd<Trailer>(url, key);
}

export function useDeleteTrailer() {
  return useDelete(url, key);
}

export function useUpdateTrailer() {
  return useUpdate<Trailer>(url, key);
}

export function useSearchTrailers(params?: any) {
  return useSearchPaginated<Trailer>(url, key, params);
}

export function useTrailer(
  id: string,
  config?: UseQueryOptions<any, unknown, Trailer>
) {
  return useGetItem<Trailer>(id, url, key, config);
}

export function useTrailerColumns() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteTrailer } = useDeleteTrailer();

  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteTrailer(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_trailer"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("trailer", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },
      {
        Header: "Targa",
        accessor: "plate",
        sortType: "basic",
      },

      {
        Header: "Dimensioni",
        accessor: "size",
        sortType: "basic",
      },
      {
        Header: "Litri",
        accessor: "liters",
        sortType: "basic",
      },

      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
    ];
  }, [deleteTrailer, setActiveItem]);
}
