import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { Header } from "components/headers/Header";
import { useAddStatus, useOptionsStatus, useSearchStatuss } from "../hooks/status";
import { StatusFilter, Status as StatusType, Article, ArticleFilter, Place, Floor } from "Warehouse/types";
import { StatusListPage } from "../components/StatusListPage";
import { AddStatusForm } from "../components/AddStatusForm";
import { Button, Modal } from "reactstrap";
import { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useArticle } from "Warehouse/Article/hooks/article";
import axios from "axios";
import { RESOLVE_QRCODE_API } from "utils/constants";
import { client } from "utils/auth-client";
import { toast } from "react-toastify";
import UIFx from "uifx";
import beepSound from '../../../components/sounds/beep.mp3';

export const Status = () => {
  const { paramsObj, onSearch } = useFilter<StatusFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchStatuss(paramsObj);
  const { mutateAsync } = useAddStatus();
  const { value: isOpen, toggle } = useToggler();

  const beep = new UIFx(beepSound,
    {
      volume: 1.0, // number between 0.0 ~ 1.0
      throttleMs: 100
    });

  const searchItems = (data: Partial<StatusFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsStatus();

  const [openScanPicking, setOpenScanPicking] = useState(false)
  const [openPicking, setOpenPicking] = useState(false)
  const [openScanStoring, setOpenScanStoring] = useState(false)
  const [openStoring, setOpenStoring] = useState(false)

  const [qrRead, setQrRead] = useState("")

  const [newStatusData, setNewStatusData] = useState({} as StatusType)

  var newFloor = {} as Floor;
  var newArticle = {} as Article;

  function isArticle(obj: any): obj is Article {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      'id' in obj &&
      'product' in obj &&
      'product_code' in obj
    );
  }

  return (
    <>
      <Modal
        style={{
          height: "200px", width: "240px",
          position: "fixed", top: 0, left: "50%",
          transform: "translate(-50%, 0%)"
        }}
        isOpen={openScanPicking}
        onClick={() => setOpenScanPicking(false)}
        toggle={() => setOpenScanPicking(false)}
      >
        <div style={{ height: "200px", width: "200px", margin: "0px 20px 0px 20px" }}>
          <QrReader
            onResult={(result: any, error: any) => {
              if (result && result.text) {
                beep.play();

                setQrRead(result.text);
                setOpenScanPicking(false)

                client(`${RESOLVE_QRCODE_API}${result.text}`, { method: "GET" })
                  .then(
                    ((res) => {

                      if (isArticle(res)) {
                        let newS: StatusType = {
                          article: res,
                          article_id: res.id,
                          status: "PIC",
                          date: new Date(),
                          note: ""
                        };
                        setNewStatusData(newS)
                      }
                      else {
                        toast.error(`Attenzione scansionare un articolo!`);
                      }

                      toggle()
                    }
                    )
                  )
                  .catch((error) => {
                    toast.error(`Risorsa ${result.text} non trovata!`);
                    console.log(error)
                  })
              }
            }}
            constraints={{ facingMode: 'environment' }}

          />
        </div>
      </Modal>
      <Modal
        style={{
          height: "200px", width: "240px",
          position: "fixed", top: 0, left: "50%",
          transform: "translate(-50%, 0%)"
        }}
        isOpen={openScanStoring}
        onClick={() => setOpenScanStoring(false)}
        toggle={() => setOpenScanStoring(false)}
      >
        <div style={{ height: "200px", width: "200px", margin: "0px 20px 0px 20px" }}>
          <QrReader
            onResult={(result: any, error: any) => {
              if (result && result.text) {

                beep.play();

                client(`${RESOLVE_QRCODE_API}${result.text}`, { method: "GET" })
                  .then(
                    ((res) => {
                      if (isArticle(res)) {
                        if (newFloor.id) {
                          setNewStatusData({
                            floor: newFloor,
                            floor_id: newFloor.id,
                            article: res,
                            article_id: res.id,
                            status: "STO",
                            date: new Date(),
                            note: ""
                          })
                          setOpenScanStoring(false)
                          toggle()
                          newFloor = {} as Floor;
                          newArticle = {} as Article
                        }
                        else {
                          newArticle = res as Article;
                          toast.success(`Articolo ${res.id} selezionato!`);
                        }
                      }
                      else {
                        if (newArticle.id) {
                          setNewStatusData({
                            floor: res,
                            floor_id: res.id,
                            article: newArticle,
                            article_id: newArticle.id,
                            status: "STO",
                            date: new Date(),
                            note: ""
                          })
                          setOpenScanStoring(false)
                          toggle()
                          newFloor = {} as Floor;
                          newArticle = {} as Article;
                        }
                        else {
                          newFloor = res as Floor;
                          toast.success(`Piano ${res.label} selezionato!`);
                        }
                      }
                    }
                    )
                  )
                  .catch((error) => {
                    toast.error(`Risorsa ${result.text} non trovata!`);
                    console.log(error)
                  })
              }
            }}
            constraints={{ facingMode: 'environment' }}
          />
        </div>
      </Modal>
      {data && data.results && options && (
        <StatusListPage
          options={options}
          states={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi stato" toggle={toggle} isOpen={isOpen}>
        <AddStatusForm submitFunction={mutateAsync} initData={newStatusData} toggleFunction={toggle} />
      </FullModal>
      <Button
        style={{ position: "fixed", bottom: 0, left: "50%", transform: "translate(-100%, 0%)" }}
        onClick={() => setOpenScanPicking(true)}
      >Picking</Button>

      <Button
        style={{ position: "fixed", bottom: 0, left: "50%", transform: "translate(100%, 0%)" }}
        onClick={() => setOpenScanStoring(true)}
      >Stroring</Button>

    </>
  );
};
