import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { filterJson } from "components/tables/filters";
import { FuelConsumption } from "FuelConspumption/types";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { FUEL_CONSUMPTION_API } from "utils/constants";
import { getDisplayDate } from "utils/helpers";

export const key = "fuel-consumption";
const url = FUEL_CONSUMPTION_API;

export function useOptionsFuelConsumption() {
  return useOptions(url, key);
}

export function useAddFuelConsumption() {
  return useAdd<FuelConsumption>(url, key, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function useDeleteFuelConsumption() {
  return useDelete(url, key);
}

export function useUpdateFuelConsumption() {
  return useUpdate<FuelConsumption>(url, key);
}

export function useListFuelConsumption(
  params?: any,
  config?: UseQueryOptions<any, unknown, FuelConsumption[]>
) {
  return useList(url, key, params, config);
}

export function useSearchFuelConsumptions(params?: any) {
  return useSearchPaginated<FuelConsumption>(url, key, params);
}

export function useFuelConsumption(
  id: string,
  config?: UseQueryOptions<any, unknown, FuelConsumption>
) {
  return useGetItem<FuelConsumption>(id, url, key, config);
}

export function useFuelConsumptionColumns() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteFuelConsumption } = useDeleteFuelConsumption();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteFuelConsumption(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_fuelconsumption"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() =>
                  setActiveItem("fuelConsumption", cell.row.original.id)
                }
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "Autista",
        accessor: "driver",
        sortType: "basic",
        Cell: (cell: any) => {
          return cell.value ? (
            <Button
              title={"Mostra autista"}
              color="link"
              onClick={() => setActiveItem("driver", cell.value.id)}
            >{`${cell.value.name} ${cell.value.surname}`}</Button>
          ) : null;
        },
        filter: filterJson
      },
      {
        Header: "Veicolo",
        accessor: "vehicle",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <Button
              title={"Mostra autista"}
              color="link"
              onClick={() => setActiveItem("vehicle", cell.value.id)}
            >{`${cell.value.plate}`}</Button>
          );
        },
        filter: filterJson
      },
      {
        Header: "Data",
        accessor: "date",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        Header: "Litri",
        accessor: "liters",
        sortType: "basic",
      },
      {
        Header: "Km veicolo",
        accessor: "km",
        sortType: "basic",
      },
      {
        Header: "Prezzo litro",
        accessor: "price",
        sortType: "basic",
      },
      {
        Header: "Fornitore",
        accessor: "site",
        sortType: "basic",
        Cell: (cell: any) => {
          return cell.value ? (
            <Button
              title={"Mostra fornitore"}
              color="link"
              onClick={() => setActiveItem("fuelSite", cell.value.id)}
            >{`${cell.value.code}: ${cell.value.description}`}</Button>
          ) : null;
        },
        filter: filterJson
      },
      {
        Header: "Km U.G.",
        accessor: "last_km",
        sortType: "basic",
        Cell: (cell: any) => {
          return (cell.value) ? (
            (cell.value > 0 && cell.value < 2000) ?
              <div style={{ color: "green" }}>
                <b>{cell.value.toFixed(2)}</b>
              </div> :
              <div style={{ color: "red" }}>
                <b>{cell.value.toFixed(2)}</b>
              </div>
          ) : <div style={{ color: "red" }}>
            <b>{cell.value}</b>
          </div>;
        },
      },
      {
        Header: "Km/l U.G",
        accessor: "last_km_l",
        sortType: "basic",
        Cell: (cell: any) => {
          return (cell.value) ? (
            (cell.value > 0 && cell.value > cell.row.values.vehicle.km_l) ?
              <div style={{ color: "green" }}>
                <b>{cell.value.toFixed(2)}</b>
              </div> :
              <div style={{ color: "red" }}>
                <b>{cell.value.toFixed(2)}</b>
              </div>
          ) : <div style={{ color: "red" }}>
            <b>{cell.value}</b>
          </div>;
        },
      },
      {
        Header: "File",
        accessor: "file_id",
        sortType: "basic",
      },
    ];
  }, [deleteFuelConsumption, setActiveItem]);
}
