import { Marker, Popup, TileLayer } from "react-leaflet";
import { MapContainer } from "react-leaflet";
import Sidebar from "react-sidebar";
import { Routing } from "../../components/Routing";
import { Fragment, useCallback, useMemo, useState } from "react";
import { ArrowRight, Edit, Send } from "react-feather";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { Segment } from "Traction/types";
import { Trailer } from "Vehicles/types/trailer";
import { Vehicle } from "Vehicles/types/vehicle";
import { ModalVehicle } from "../../components/ModalVehicle";
import { ModalDriver } from "components/ModalDriver";
import { useToggler } from "shared/hooks/useToggler";
import { Driver } from "Drivers/types/driver";
import { Button } from "reactstrap";
import { ModalTrailer } from "../components/ModalTrailer";
import { ModalSegments } from "../components/ModalSegments";
import { TableSegments } from "../components/TableSegments";
import L from "leaflet";
import { greenIcon, purpleIcon, yellowIcon } from "../../components/Icons";
import { redIcon } from "../../components/Icons";
import { toast } from "react-toastify";
import { useSearchSegment, useUpdateSegment } from "Traction/hooks/segments";
import { ModalEditOrderSegments } from "Traction/components/ModalEditOrderSegments";
import { useModalUpdate } from "shared/hooks/useModalUpdate";
import { client } from "utils/auth-client";
import { SEGMENTS_API } from "utils/constants";
import { useFilter } from "shared/hooks/useFilter";

export const TractionPlanner = () => {
  const [activeSegment, setActiveSegment] = useState<Segment>();
  const [selectedSegmentsids, setSelectedSegmentsIds] = useState<number[]>([]);
  const { mutateAsync: updateSegment } = useUpdateSegment();
  const queryClient = useQueryClient();
  const { data: lastPositions } = useQuery(
    ["last-positions"],
    () =>
      client(`${SEGMENTS_API}get_last_positions_trailer_vehicles/`, {
        method: "GET",
      }),
    { initialData: { trailers: [], vehicles: [] } }
  );
  const {
    toggle: toggleDriverModal,
    value: isOpenDriverModal,
    setTrue: openDriverModal,
  } = useToggler();
  const {
    toggle: toggleVehicleModal,
    value: isOpenVehicleModal,
    setTrue: openVehicleModal,
  } = useToggler();
  const {
    toggle: toggleTrailerModal,
    value: isOpenTrailerModal,
    setTrue: openTrailerModal,
  } = useToggler();
  const {
    toggle: toggleOrderModal,
    value: isOpenOrderModal,
    setTrue: openOrderModal,
  } = useToggler();
  const {
    activeItem: activeBreakSegment,
    setActiveItem: setActiveBreakSegment,
    isOpenUpdateModal: isOpenOrderSegmentModal,
    toggleUpdateModal: toggleOrderSegmentModal,
    closeModalUpdate: closeOrderSegmentModal,
  } = useModalUpdate<Segment>();

  const toggleFromSelectedSegments = (segment: Segment) => {
    setSelectedSegmentsIds((old) => {
      const indexOrder = old.findIndex((oldOrder) => oldOrder === segment.id);
      if (indexOrder >= 0) {
        const newList = [...old];
        newList.splice(indexOrder, 1);
        return newList;
      }
      return [...old, segment.id!];
    });
  };
  const { paramsObj, onSearch } = useFilter<{ order: number, order__date_after: Date }>();

  const { data, search, page, goToPage, pageCount } =
    useSearchSegment(paramsObj);
  const searchItems = (data: Partial<{ order: number }>) => {
    onSearch(data);
    search(data);
  };
  const segments = useMemo(() => (data ? data.results : []), [data]);

  const saveOrder = () => {
    if (
      activeSegment &&
      activeSegment.driver_id &&
      activeSegment.vehicle_id &&
      activeSegment.trailer_id
    ) {
      updateSegment({
        id: activeSegment.id!,
        newValues: {
          vehicle_id: activeSegment.vehicle_id,
          trailer_id: activeSegment.trailer_id,
          driver_id: activeSegment.driver_id,
        },
      })
        .then((data) => {
          toast.success("Fatto!");
          queryClient.invalidateQueries("last-positions");
          setActiveSegment(data);
        })
        .catch(() => {
          toast.error("Errore!");
        });
    } else {
      toast.warning("Controlla di aver selezionato tutte le info");
    }
  };

  const toggleActiveSegment = (segment: Segment) => {
    setActiveSegment((old) =>
      old && old.id === segment.id ? undefined : segment
    );
  };

  const onChangeDriver = (driver: Driver) => {
    if (activeSegment)
      setActiveSegment({
        ...activeSegment!,
        driver_id: driver.id,
        driver: driver,
      });
  };

  const onChangeVehicle = (vehicle: Vehicle) => {
    if (activeSegment) {
      let d = vehicle.default_driver ? vehicle.default_driver : undefined;
      let d_id = vehicle.driver_id;
      if (d_id) {
        setActiveSegment({
          ...activeSegment!,
          vehicle_id: vehicle.id,
          vehicle: vehicle,
          driver: d,
          driver_id: d_id,
        });
      }
      else {
        setActiveSegment({
          ...activeSegment!,
          vehicle_id: vehicle.id,
          vehicle: vehicle
        });
      }
    }


  };

  const onChangeTrailer = (trailer: Trailer) => {
    if (activeSegment)
      setActiveSegment({
        ...activeSegment!,
        trailer_id: trailer.id,
        trailer: trailer,
      });
  };

  const selectedSegments = useMemo(() => {
    return selectedSegmentsids
      .map((id) => segments!.find((segment: any) => segment.id === id))
      .filter((value) => !!value);
  }, [segments, selectedSegmentsids]);

  const getTraceColor = useCallback(
    (segment: Segment) => {
      return activeSegment && activeSegment.id === segment.id
        ? "#00e600"
        : undefined;
    },
    [activeSegment]
  );
  return (
    <>
      <Sidebar
        sidebar={
          <>
            <div style={{ overflowY: "auto", height: "100%" }}>
              <div style={{ marginBottom: "60px" }} className="px-4 pt-2 ">
                <div className="d-flex flex-row justify-content-end mb-3">
                  <Link
                    style={{ color: "#7166f9", fontSize: "1.2rem" }}
                    to="/login"
                    className="d-flex flex-row justify-content-center align-items-baseline"
                  >
                    Home
                    <span>
                      <ArrowRight className="ml-2" size={15} />
                    </span>
                  </Link>
                </div>

                <div
                  className={
                    "card__selection d-flex justify-content-between align-items-center"
                  }
                >
                  <h6>Tratte:</h6>
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <Button
                      title="Modifica"
                      onClick={openOrderModal}
                      className="edit-buttons ml-3"
                      color="warning"
                    >
                      <Edit size={13} />
                    </Button>
                  </div>
                </div>
                <div className="card__selection p-0 pb-3 mt-2">
                  <h6 className="pt-3 pl-3">Missioni:</h6>
                  <div
                    style={{ maxHeight: "600px", overflowY: "auto" }}
                    className="mt-3 "
                  >
                    <TableSegments
                      onShowSegmentOrderClick={setActiveBreakSegment}
                      onRowClick={toggleActiveSegment}
                      segments={selectedSegments}
                      selectedRow={activeSegment}
                    />
                  </div>
                </div>
                <div className="vertical-line" />

                <div
                  className={
                    "card__selection d-flex justify-content-between align-items-center"
                  }
                >
                  <h6>Motrice:</h6>
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <b>
                      {activeSegment && activeSegment.vehicle
                        ? activeSegment.vehicle.plate
                        : "-----"}
                    </b>
                    <Button
                      title="Modifica"
                      onClick={openVehicleModal}
                      className="edit-buttons ml-3"
                      color="warning"
                      disabled={!!!activeSegment}
                    >
                      <Edit size={13} />
                    </Button>
                  </div>
                </div>
                <div className="vertical-line" />
                <div
                  className={
                    "card__selection d-flex justify-content-between align-items-center"
                  }
                >
                  <h6>Autista:</h6>
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <b>
                      {activeSegment && activeSegment.driver
                        ? `${activeSegment.driver.name} ${activeSegment.driver.surname}`
                        : "-----"}
                    </b>
                    <Button
                      title="Modifica"
                      onClick={openDriverModal}
                      className="edit-buttons ml-3"
                      color="warning"
                      disabled={!!!activeSegment}
                    >
                      <Edit size={13} />
                    </Button>
                  </div>
                </div>
                <div className="vertical-line" />
                <div
                  className={
                    "card__selection d-flex justify-content-between align-items-center"
                  }
                >
                  <h6>Rimorchio:</h6>
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <b>
                      {activeSegment && activeSegment.trailer
                        ? activeSegment.trailer.plate
                        : "-----"}
                    </b>
                    <Button
                      title="Modifica"
                      onClick={openTrailerModal}
                      className="edit-buttons ml-3"
                      color="warning"
                      disabled={!!!activeSegment}
                    >
                      <Edit size={13} />
                    </Button>
                  </div>
                </div>
                <div className="vertical-line" />
                <Button
                  onClick={saveOrder}
                  block
                  color="success"
                  className="d-flex flex-row justify-content-between align-items-center bg-success-secondary mt-2"
                  disabled={
                    !!!activeSegment ||
                    !!!activeSegment.driver_id ||
                    !!!activeSegment.vehicle_id ||
                    !!!activeSegment.trailer_id
                  }
                >
                  SALVA <Send size={20} />
                </Button>
              </div>
            </div>
          </>
        }
        sidebarClassName={"bg-white"}
        docked={true}
        open={true}
        touch={false}
        pullRight={true}
        styles={{
          sidebar: { background: "white", width: "650px", zIndex: "1050" },
          overlay: { zIndex: "1040" },
        }}
        shadow={false}
      >
        <MapContainer
          doubleClickZoom={false}
          whenCreated={(map) => {
            map.on("click", function () { });
          }}
          center={[41.2925, 12.5736]}
          zoom={5}
          style={{ height: "100%" }}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          {lastPositions?.trailers.map((position: any) => (
            <Marker
              icon={purpleIcon}
              key={position.plate}
              position={[position.latitude, position.longitude]}
            >
              <Popup>Rimorchio: {position.plate}</Popup>
            </Marker>
          ))}
          {lastPositions?.vehicles.map((position: any) => (
            <Marker
              icon={yellowIcon}
              key={position.plate}
              position={[position.latitude, position.longitude]}
            >
              <Popup>Veicolo: {position.plate}</Popup>
            </Marker>
          ))}
          {selectedSegments.map((segment) => {
            const starting_point = L.latLng(
              segment.starting_point!.latitude,
              segment.starting_point!.longitude
            );
            const arrival_point = L.latLng(
              segment.arrival_point!.latitude,
              segment.arrival_point!.longitude
            );
            return (
              <Fragment key={segment.id}>
                <Marker icon={greenIcon} position={starting_point}>
                  <Popup>{segment.starting_point?.business_name}</Popup>
                </Marker>
                <Marker icon={redIcon} position={arrival_point}>
                  <Popup>{segment.arrival_point?.business_name}</Popup>
                </Marker>
                <Routing
                  color={getTraceColor(segment)}
                  points={[starting_point, arrival_point]}
                />
              </Fragment>
            );
          })}
        </MapContainer>
      </Sidebar>

      <ModalVehicle
        selectedVehicle={activeSegment?.vehicle}
        setVehicle={onChangeVehicle}
        toggle={toggleVehicleModal}
        isOpen={isOpenVehicleModal}
      />
      <ModalDriver
        selectedDriver={activeSegment?.driver}
        setDriver={onChangeDriver}
        toggle={toggleDriverModal}
        isOpen={isOpenDriverModal}
      />
      <ModalTrailer
        selectedVehicle={activeSegment?.trailer}
        setVehicle={onChangeTrailer}
        toggle={toggleTrailerModal}
        isOpen={isOpenTrailerModal}
      />
      <ModalSegments
        onSearch={searchItems}
        paramsObj={paramsObj}
        selectedSegments={selectedSegments}
        setSelectedSegments={toggleFromSelectedSegments}
        data={segments}
        toggle={toggleOrderModal}
        isOpen={isOpenOrderModal}
        page={page}
        pageCount={pageCount}
        goToPage={goToPage}
      />
      {activeBreakSegment && (
        <ModalEditOrderSegments
          onSuccess={(segment) => {
            setTimeout(() => {
              queryClient.invalidateQueries("segments");
              toggleFromSelectedSegments(segment);
              closeOrderSegmentModal();
            }, 700);
          }}
          segment={activeBreakSegment}
          toggle={toggleOrderSegmentModal}
          isOpen={isOpenOrderSegmentModal}
        />
      )}
    </>
  );
};
