import React, { createContext, Dispatch, useContext, useReducer } from "react";

interface StateI {
  open: boolean;
  docked: boolean;
  matched: boolean;
  loading: boolean;
}

const initialState: StateI = {
  open: true,
  docked: true,
  matched: false,
  loading: false,
};

type Action =
  | { type: "MATCHED_QUERY" }
  | { type: "NOT_MATCHED_QUERY" }
  | { type: "OPEN_SIDEBAR" }
  | { type: "TOGGLE_SIDEBAR" }
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setLoadingDone>
  | { type: "LOADING" }
  | { type: "LOADING_DONE" };

const sidebarReducer = (state: StateI, action: Action) => {
  switch (action.type) {
    case "MATCHED_QUERY":
      return { ...state, matched: true, docked: false, open: false };
    case "NOT_MATCHED_QUERY":
      return { ...state, matched: false, docked: true, open: true };
    case "OPEN_SIDEBAR":
      return { ...state, open: true };
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        open: !state.open,
        docked: !state.matched ? !state.open : false,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "LOADING_DONE":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const SidebarContext = createContext<{
  state: StateI;
  dispatch: Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

export const SidebarProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(sidebarReducer, initialState);

  return (
    <SidebarContext.Provider value={{ state, dispatch }}>
      {children}
    </SidebarContext.Provider>
  );
};

export function setLoading() {
  return { type: "LOADING" };
}

export function setLoadingDone() {
  return { type: "LOADING_DONE" };
}

export function useSidebar() {
  return useContext(SidebarContext);
}
