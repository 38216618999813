import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";

import { DestinyTractionPalletForm } from "./DestinyTractionPalletForm";
import { MESSAGES } from "utils/constants";
import { DestinyTractionPallet } from "PalletsManagment/types";
import { useOptionsDestinyTractionPallet } from "PalletsManagment/hooks/destinyTractionPallet";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<DestinyTractionPallet>,
    unknown
  >;
  destinyTractionPallet: DestinyTractionPallet;
}

export const UpdateDestinyTractionPalletForm = ({
  submitFunction,
  destinyTractionPallet,
}: Props) => {
  const { errors, register, handleSubmit, setError, control } =
    useForm<DestinyTractionPallet>({
      //impostare undefined in modo tale che l'immagine non venga inviata se non modificata,
      defaultValues: { ...destinyTractionPallet, image: undefined },
    });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };

  const onSend = handleSubmit((data) => {
    console.log(data);
    console.log(destinyTractionPallet);

    submitFunction({ id: destinyTractionPallet.id!, newValues: data })
      .then(() => {
        toast.success(MESSAGES.GENERAL.UPDATE_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });
  const { data: options } = useOptionsDestinyTractionPallet();
  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_delivery"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            {MESSAGES.GENERAL.UPDATE_ITEM}
          </Button>
        </div>
      </CheckPermissions>

      {options && (
        <DestinyTractionPalletForm
          options={options}
          control={control}
          register={register}
          errors={errors}
          defaultValues={destinyTractionPallet}
          editable={!disabled}
        />
      )}
      <CheckPermissions permissions={["rosina.change_delivery"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
