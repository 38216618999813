import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

interface Props {
  register: any;
  name: string;
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
}

export const CheckboxField = ({
  register,
  name,
  errorMessage,
  label,
  disabled,
}: Props) => {
  return (
    <FormGroup tag="fieldset">
      {label && <Label>{label}</Label>}
      <FormGroup check>
        <Label check>
        </Label>
        <input
          ref={register}
          disabled={disabled}
          type="checkbox"
          name={name}
        />
      </FormGroup>


      {errorMessage && (
        <FormFeedback style={{ display: "block" }}>{errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};
