import { useEffect, useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { client } from "utils/auth-client";
import { AUTOCOMPLETE_URLS, PLACE_API, } from "utils/constants";
import { Controller } from "react-hook-form";
import { Place } from "Warehouse/types";

interface Props {
  errorMessage?: string;
  disabled?: boolean;
  getValues?: any;
  control: any;
  name: string;
  defaultValue?: number | null;
  label?: string;
  setValue: any;
}

export const PlaceByRowAutocomplete = ({
  disabled,
  errorMessage,
  getValues,
  control,
  name,
  defaultValue,
  label,
  setValue
}: Props) => {
  var values = getValues();
  const [values2, setValues2] = useState(getValues());
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);


  let handleSearch = (query: string) => {
    setIsLoading(true);
    client(AUTOCOMPLETE_URLS.PLACE, {
      method: "GET",
      params: { floor: values2.floor_id, q: query },
    })
      .then((resp) => {
        const options = resp.results.map((i: Place) => {
          return {
            label: i.label,
            id: i.id,
          };
        });
        setOptions(options);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    values = getValues();
    setValues2(getValues());

    if (
      name in values &&
      values[name] !== undefined &&
      values[name] !== null &&
      values[name] !== ""
    ) {
      client(`${PLACE_API}${values[name]}/`, {
        method: "get",
      }).then((data) => {
        setOptions([
          {
            id: data.id,
            label: data.label,
          },
        ]);
      });
    }
  }, [getValues().floor_id, name]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ name, onChange }) => {
        return (
          <FormGroup>
            <AsyncTypeahead
              clearButton
              id={name.toString()}
              isLoading={isLoading}
              onSearch={handleSearch}
              onChange={(data: any) => {
                if (data.length > 0) {
                  onChange(data[0].id);
                } else {
                  onChange(null);
                }
              }}
              options={options}
              minLength={0}
              disabled={disabled}
              selected={options.filter(
                (option: any) => option.id === getValues()[name]
              )}
              placeholder="Cerca posto"
            />
            {errorMessage !== "" && (
              <FormFeedback style={{ display: "block" }}>
                {errorMessage}
              </FormFeedback>
            )}
          </FormGroup>
        );
      }}
    />
  );
};
