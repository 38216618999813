import { useToggler } from "shared/hooks/useToggler";

import { NoteFilter } from "../types/note";
import {
  useAddNote,
  useNoteColumns,
  useOptionsNote,
  useSearchNotes,
} from "../hooks/note";
import { NoteListPage } from "Distribution/components/NoteListPage";
import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { AddNoteForm } from "Distribution/components/AddNoteForm";

export const Notes = () => {
  const { paramsObj, onSearch } = useFilter<NoteFilter>();
  const { data, search, page, goToPage, pageCount } = useSearchNotes(paramsObj);
  const { mutateAsync } = useAddNote();
  const { value: isOpen, toggle } = useToggler();
  const { data: options } = useOptionsNote();

  const columns = useNoteColumns(options);
  const searchItems = (data: Partial<NoteFilter>) => {
    onSearch(data);
    search(data);
  };
  return (
    <>
      {data && options && (
        <NoteListPage
          notes={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          columns={columns}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi giro" toggle={toggle} isOpen={isOpen}>
        <AddNoteForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
