import { FilterI } from "components/Filter/types";
import { ProductFilter } from "Warehouse/types";

export const createFilterStructure = (params: ProductFilter): FilterI[] => {
  return [
    {
      type: "autocomplete-client",
      name: "client",
      label: "Cliente",
      active: params?.client !== undefined,
      value: params?.client,
    },
    {
      type: "autocomplete-product-type",
      name: "product_type",
      label: "Tipo prodotto",
      active: params?.product_type !== undefined,
      value: params?.product_type,
    },
    {
      type: "text",
      name: "name",
      label: "Nome",
      active: params?.name !== undefined,
      value: params?.name,
    },
    {
      type: "text",
      name: "description",
      label: "Descrizione",
      active: params?.description !== undefined,
      value: params?.description,
    },
  ];
};
