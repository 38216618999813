import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { Price } from "Items/types/price";
import React, { useMemo } from "react";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { toast } from "react-toastify";
import { useGlobalModals } from "shared/context/globalModals";
import { useList } from "shared/hooks/useList";
import { UpdatePayload } from "shared/types";
import { client } from "utils/auth-client";
import { PRICES_API } from "utils/constants";
import { filterJson } from "components/tables/filters";

const url = PRICES_API;
const key = "prices";

export function useAddPrice() {
  const queryClient = useQueryClient();

  return useMutation(
    (driver: Price) =>
      client(url, {
        method: "POST",
        data: driver,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
    }
  );
}

export function useColumnsPrices() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deletePrice } = useDeletePrice();

  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deletePrice(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_price"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("price", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.client)
            return `${cell.row.original.client.id}: ${cell.row.original.client.business_name}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Tipo addebito",
        accessor: "charge_type",
        sortType: "basic",
      },
      {
        Header: "Oggetto",
        accessor: "item",
        sortType: "basic",
        Cell: (cell: any) => {
          return cell.row.original.item
            ? `${cell.row.original.item.code}: ${cell.row.original.item.description}`
            : null;
        },
      },
      {
        Header: "Data inizio validità",
        accessor: "start_date",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.value ? new Date(cell.value).toLocaleString() : null,
      },

      {
        Header: "Valido da --> a",
        accessor: "locality_info",
        sortType: "basic",
        Cell: (cell: any) => {
          return cell.row.original.locality_info
            ;
        },
      },
      {
        Header: "Prezzo",
        accessor: "price",
        sortType: "basic",
      },
      {
        Header: "Tipo quantità",
        accessor: "quantity_type",
        sortType: "basic",
      },
      {
        Header: "Quantità minima",
        accessor: "minimum_quantity",
        sortType: "basic",
      },
      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
    ];
  }, [deletePrice, setActiveItem]);
}

export function useDeletePrice() {
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) =>
      client(`${url}${id}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
      onError: () => {
        toast.error("Impossibile eliminare");
      },
    }
  );
}

export function useListPrices(
  params?: any,
  config?: UseQueryOptions<any, unknown, Price[]>
) {
  return useList(url, key, params, config);
}
export function useOptionsPrice() {
  return useQuery(
    ["options", "price"],
    () => client(url, { method: "OPTIONS" }).then((data) => data.actions.POST),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}
export function useSearchPrices(
  parameters: any & { page: number },
  config?: UseQueryOptions<
    any,
    unknown,
    {
      results: never[];
      count: number;
    }
  >
) {
  return useQuery(
    [key, parameters],
    () => client(url, { method: "GET", params: parameters }),
    {
      initialData: { results: [], count: 0 },
      keepPreviousData: true,
      ...config,
    }
  );
}
export function useUpdatePrice() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdatePayload<Price>) =>
      client(`${url}${data.id}/`, {
        method: "PATCH",
        data: data.newValues,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
    }
  );
}
