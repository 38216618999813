import { useCallback, useEffect, useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { client } from "utils/auth-client";
import { Controller } from "react-hook-form";
import { PAGE_SIZE } from "utils/constants";
import { useRef } from "react";
import _ from "lodash";

interface Props {
  errorMessage?: string;
  disabled?: boolean;
  control: any;
  name: string;
  label?: string;
  required?: boolean;
  defaultValue?: number | null;
  placeholder?: string;
  url: string;
  multiple?: boolean;
}

export const Autocomplete = ({
  disabled,
  errorMessage,
  control,
  name,
  label,
  required = false,
  defaultValue,
  placeholder,
  url,
  multiple,
}: Props) => {
  const _isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<{ id: number; label: string }[]>([]);
  const [allFound, setAllFound] = useState<{ id: number; label: string }[]>([]);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<string>("");
  const [paginate, setPaginate] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  const handleSearch = useCallback(
    (query: string) => {
      if (_isMounted.current) {
        setIsLoading(true);
        setPage(1);
      }
      searchElements({ q: query, page: 1 }, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  const searchElements = (params: any, discardOld?: boolean) => {
    client(url, {
      method: "GET",
      params: params,
    }).then((resp) => {
      const options = resp.results;
      if (_isMounted.current) {
        setPaginate(!!resp.next);
        setOptions((old) =>
          _.uniqBy([...(discardOld ? [] : old), ...options], "id")
        );
        setAllFound((old) => _.uniqBy([...old, ...options], "id"));
        setIsLoading(false);
      }
    });
  };

  const handlePagination = (e: any, numresults: number) => {
    if (_isMounted.current) setIsLoading(true);
    client(url, {
      method: "GET",
      params: { q: query, page: page + 1 },
    }).then((resp) => {
      const options = resp.results;
      if (_isMounted.current) {
        setPage((oldPage) => oldPage + 1);
        setPaginate(!!resp.next);
        setOptions((oldOptions) => oldOptions.concat(options));
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (
      (Array.isArray(defaultValue) && defaultValue.length === 0) ||
      !defaultValue
    ) {
      searchElements({ page: 1 });
    } else {
      if (Array.isArray(defaultValue)) {
        for (const value of defaultValue) {
          client(`${url}${value}/`, {
            method: "GET",
          }).then((resp) => {
            if (_isMounted.current) {
              setOptions((old) => _.uniqBy([...old, resp], "id"));
              setAllFound((old) => _.uniqBy([...old, resp], "id"));
            }
          });
        }
      } else {
        if (defaultValue) {
          client(`${url}${defaultValue}/`, {
            method: "GET",
          }).then((resp) => {
            if (_isMounted.current) {
              setOptions([resp]);
              setAllFound([resp]);
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, url]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ name, onChange, value }) => {
        return (
          <FormGroup>
            {label && <Label>{label}</Label>}
            <AsyncTypeahead
              multiple={multiple}
              clearButton
              inputProps={{ required: required }}
              id={name.toString()}
              paginate={paginate}
              maxResults={PAGE_SIZE - 1}
              useCache={false}
              isLoading={isLoading}
              onInputChange={(input, e) => {
                setQuery(input);
              }}
              filterBy={() => true}
              onPaginate={handlePagination}
              onSearch={handleSearch}
              onChange={(data: any) => {
                if (data.length > 0) {
                  if (multiple) {
                    onChange(data.map((value: any) => value.id));
                  } else {
                    onChange(data[0].id);
                  }
                } else {
                  if (multiple) {
                    onChange([]);
                  } else {
                    onChange(null);
                  }
                }
              }}
              options={options}
              minLength={0}
              disabled={disabled}
              selected={allFound.filter((option: any) =>
                Array.isArray(value)
                  ? value.includes(option.id)
                  : parseInt(option.id) === parseInt(value)
              )}
              placeholder={placeholder}
            />
            {errorMessage !== "" && (
              <FormFeedback style={{ display: "block" }}>
                {errorMessage}
              </FormFeedback>
            )}
          </FormGroup>
        );
      }}
    />
  );
};
