import { TableDnd } from "components/tables/TableDnd";
import { DeliveryGroup } from "DeliveryPlanner/types";
import React, { useMemo } from "react";
import { Trash } from "react-feather";
import { Button } from "reactstrap";

interface Props {
  data: DeliveryGroup[];
  onOrdering: (dragIndex: number, hoverIndex: number) => void;
  onRowClick: (keyLatLon: string) => void;
  onDeleteItem: (clusterKey: string) => void;
  onDrop: () => void;
  endTableRef?: any;
}

export const TableGroupedDeliveries = (props: Props) => {
  const { onDeleteItem, onDrop, endTableRef } = props;
  const columns = useMemo(() => {
    return [
      {
        accessor: "__id",
        Header: "",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-between">
              <Button
                title={"Elimina"}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteItem(cell.row.original.id);
                }}
                className="edit-buttons"
                color={"danger"}
              >
                <Trash size={13} />
              </Button>
            </div>
          );
        },
      },
      {
        Header: "Destinatario",
        accessor: "receiver",
        sortType: "basic",
      },
      {
        Header: "Peso",
        accessor: "weight",
        sortType: "basic",
      },
      {
        Header: "N. colli",
        accessor: "packages_number",
        sortType: "basic",
      },

      {
        Header: "Indirizzo",
        accessor: "address",
        sortType: "basic",
      },
    ];
  }, [onDeleteItem]);

  return (
    <TableDnd
      setData={props.onOrdering}
      columns={columns}
      data={props.data}
      onDrop={onDrop}
      onRowClick={(row) => props.onRowClick(row.id)}
      endTableRef={endTableRef}
    />
  );
};
