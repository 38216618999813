import { FilterI } from "components/Filter/types";
import { DestinyTractionFilter } from "Traction/types";

export const createFilterStructure = (
  params: DestinyTractionFilter
): FilterI[] => {
  return [
    {
      type: "date",
      name: "created_date_after",
      label: "Data creazione dal",
      active: params?.created_date_after !== undefined,
      value: params?.created_date_after,
    },

    {
      type: "date",
      name: "created_date_before",
      label: "Data creazione al",
      active: params?.created_date_before !== undefined,
      value: params?.created_date_before,
    },
    {
      type: "date",
      name: "date_after",
      label: "Data esecuzione dal",
      active: params?.date_after !== undefined,
      value: params?.date_after,
    },

    {
      type: "date",
      name: "order__date_after",
      label: "Data ordine dal",
      active: params?.order__date_after !== undefined,
      value: params?.order__date_after,
    },
    {
      type: "date",
      name: "order__date_before",
      label: "Data ordine al",
      active: params?.order__date_before !== undefined,
      value: params?.order__date_before,
    },

    {
      type: "date",
      name: "date_before",
      label: "Data esecuzione al",
      active: params?.date_before !== undefined,
      value: params?.date_before,
    },
    {
      type: "autocomplete-vehicle",
      name: "vehicle_trailer_release",
      label: "Veicolo sgancio",
      active: params?.vehicle_trailer_release !== undefined,
      value: params?.vehicle_trailer_release,
    },
    {
      type: "autocomplete-vehicle",
      name: "vehicle_trailer_hooking",
      label: "Veicolo aggancio",
      active: params?.vehicle_trailer_hooking !== undefined,
      value: params?.vehicle_trailer_hooking,
    },
    {
      type: "autocomplete-driver",
      name: "driver",
      label: "Autista",
      active: params?.driver !== undefined,
      value: params?.driver,
    },
  ];
};
