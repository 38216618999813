import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { FuelSite } from "FuelConspumption/types";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { FUEL_SITE_API } from "utils/constants";

export const key = "fuel-sites";
const url = FUEL_SITE_API;

export function useOptionsFuelSite() {
  return useOptions(url, key);
}

export function useAddFuelSite() {
  return useAdd<FuelSite>(url, key, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function useDeleteFuelSite() {
  return useDelete(url, key);
}

export function useUpdateFuelSite() {
  return useUpdate<FuelSite>(url, key);
}

export function useListFuelSite(
  params?: any,
  config?: UseQueryOptions<any, unknown, FuelSite[]>
) {
  return useList(url, key, params, config);
}

export function useSearchFuelSites(params?: any) {
  return useSearchPaginated<FuelSite>(url, key, params);
}

export function useFuelSite(
  id: string,
  config?: UseQueryOptions<any, unknown, FuelSite>
) {
  return useGetItem<FuelSite>(id, url, key, config);
}

export function useFuelSiteColumns(
  deleteFuelSite: (id: number) => void,
  setActiveFuelSite: (item: FuelSite) => void
) {
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteFuelSite(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_price"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveFuelSite(cell.row.original)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "Codice",
        accessor: "code",
        sortType: "basic",
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },
    ];
  }, [deleteFuelSite, setActiveFuelSite]);
}
