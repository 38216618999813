import { useToggler } from "shared/hooks/useToggler";
import { AddVehicleForm } from "../components/AddVehicleForm";

import { VehicleFilter } from "../types/vehicle";
import { useFilter } from "shared/hooks/useFilter";
import {
  useAddVehicle,
  useOptionsVehicle,
  useSearchVehicles,
} from "Vehicles/hooks/vehicle";
import { VehicleListPage } from "Vehicles/components/VehicleListPage";
import { FullModal } from "components/FullModal";

export const Vehicles = () => {
  const { paramsObj, onSearch } = useFilter<VehicleFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchVehicles(paramsObj);
  const { mutateAsync } = useAddVehicle();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<VehicleFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsVehicle();

  return (
    <>
      {data && data.results && options && (
        <VehicleListPage
          options={options}
          vehicles={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi veicolo" toggle={toggle} isOpen={isOpen}>
        <AddVehicleForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
