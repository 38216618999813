import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { FloorForm } from "./FloorForm";
import { CheckPermissions } from "components/CheckPermissions";
import { useOptionsFloor } from "../hooks/floor";
import { Floor } from "Warehouse/types";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Floor>,
    unknown
  >;
  floor: Floor;
}

export const UpdateFloorForm = ({ submitFunction, floor }: Props) => {
  const { control, errors, register, handleSubmit, setError } = useForm<Floor>(
    {
      defaultValues: floor,
    }
  );
  const { data: options } = useOptionsFloor();
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onSend = handleSubmit((data) => {
    submitFunction({ id: floor.id!, newValues: data })
      .then(() => {
        toast.success("Piano modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_floor"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <FloorForm
          editable={!disabled}
          defaultValues={floor}
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      <CheckPermissions permissions={["rosina.change_floor"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
