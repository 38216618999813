import { SimpleTable } from "components/tables/SimpleTable";
import { useMemo } from "react";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { Segment } from "Traction/types";
import { getDisplayDate } from "utils/helpers";
import { filterJson } from "components/tables/filters";

interface Props {
  segments: Segment[];
  onRowClick: (row: Segment) => void;
  selectedRow?: Segment;
  onShowSegmentOrderClick: (order: Segment) => void;
}

export const TableSegments = (props: Props) => {
  const { segments, onShowSegmentOrderClick } = props;
  const { setActiveItem } = useGlobalModals();
  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "__s",
        Cell: (cell: any) => {
          if (cell.row.original.starting_point) {
            return (
              <Button
                title={"Spezza"}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  onShowSegmentOrderClick(cell.row.original);
                }}
              >
                Spezza
              </Button>
            );
          }
          return null;
        },
        sortType: "basic",
        filter: filterJson
      },
      {
        Header: "N. ordine",
        accessor: "order",
        sortType: "basic",
      },
      {
        accessor: "starting_point",
        Header: "Punto di partenza",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.starting_point) {
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.starting_point.id)
                }
              >{`${cell.row.original.starting_point.id}: ${cell.row.original.starting_point.business_name}`}</Button>
            );
          }
          return null;
        },
        filter: filterJson
      },
      {
        accessor: "arrival_point",
        Header: "Punto di arrivo",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.starting_point) {
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.arrival_point.id)
                }
              >{`${cell.row.original.arrival_point.id}: ${cell.row.original.arrival_point.business_name}`}</Button>
            );
          }
          return null;
        },
        filter: filterJson
      },
      {
        Header: "Autista",
        accessor: "driver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.driver)
            return `${cell.row.original.driver.name} ${cell.row.original.driver.surname}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Veicolo",
        accessor: "vehicle",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.vehicle)
            return `${cell.row.original.vehicle.plate} ${cell.row.original.vehicle.description}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Rimorchio",
        accessor: "trailer",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.trailer)
            return `${cell.row.original.trailer.plate} ${cell.row.original.trailer.description}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Data",
        accessor: "date",
        sortType: "basic",
        Cell: (cell: any) => {
          return getDisplayDate(cell.value);
        },
        filter: filterJson
      },
    ];
  }, [onShowSegmentOrderClick, setActiveItem]);

  return (
    <SimpleTable
      onRowClick={props.onRowClick}
      columns={columns}
      data={segments}
      selectedRow={props.selectedRow}
    />
  );
};
