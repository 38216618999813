import { useImportFileColumns, useSearchImport } from "../hooks/import";
import { HistoryImportFilter } from "Import/types";
import { ListHistoryImportPage } from "Import/components/ListHistoryImportPage";
import { useFilter } from "shared/hooks/useFilter";

export const HistoryImport = () => {
  const { paramsObj, onSearch } = useFilter<HistoryImportFilter>();
  const { data, search, page, pageCount, goToPage } =
    useSearchImport(paramsObj);
  const columns = useImportFileColumns();
  const searchItems = (data: Partial<HistoryImportFilter>) => {
    onSearch(data);
    search(data);
  };
  return (
    <>
      {data && data.results && (
        <ListHistoryImportPage
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          page={page}
          pageCount={pageCount}
          files={data.results}
          goToPage={goToPage}
          columns={columns}
        />
      )}
    </>
  );
};
