import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { UpdatePayload } from "shared/types";
import { client } from "utils/auth-client";

export function useUpdate<T>(
  url: string,
  key: string,
  config?: UseMutationOptions<any, unknown, UpdatePayload<T>>
) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdatePayload<T>) =>
      client(`${url}${data.id}/`, {
        method: "PATCH",
        data: data.newValues,
      }),
    config
      ? config
      : {
          onSuccess: () => {
            queryClient.refetchQueries([key], { active: true });
          },
        }
  );
}
