import { NestedValue, useFieldArray, useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { SpecificationNote, Subject } from "Subjects/types";
import {
  useOptionsSpecificationNotes,
  useOptionsSubject,
} from "Subjects/hooks";
import { SubjectForm } from "Subjects/components/SubjectForm";
import { MESSAGES } from "utils/constants";
import { UpdatePayload } from "shared/types";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Subject>,
    unknown
  >;
  subject: Subject;
}

export const UpdateSubjectForm = ({ submitFunction, subject }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    getValues,
    watch,
  } = useForm<
    Omit<Subject, "main_subject" | "payment_terms" | "specification_notes"> & {
      type: NestedValue<string[]>;
      specification_notes: NestedValue<SpecificationNote[]>;
    }
  >({
    defaultValues: subject,
  });
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "specification_notes",
    keyName: "key" as "id",
  });
  const { data: options } = useOptionsSubject();
  const { data: optionsNotes } = useOptionsSpecificationNotes();

  const onSend = handleSubmit((data) => {
    submitFunction({ id: subject.id!, newValues: data })
      .then((data) => {
        toast.success(MESSAGES.GENERAL.UPDATE_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && optionsNotes && (
        <SubjectForm
          optionNotes={optionsNotes}
          fields={fields}
          remove={remove}
          prepend={prepend}
          control={control}
          options={options}
          register={register}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
      )}
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          {MESSAGES.GENERAL.UPDATE_ITEM}
        </Button>
      </div>
    </Form>
  );
};
