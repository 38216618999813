import { useLoadItem } from "shared/hooks/useLoadItem";
import { UpdateVehicleForm } from "Vehicles/components/UpdateVehicleForm";

import { VEHICLES_API } from "utils/constants";
import { FullModal } from "../../components/FullModal";
import { useUpdateVehicle } from "Vehicles/hooks/vehicle";
import { Vehicle } from "Vehicles/types/vehicle";
import { checkPermissions } from "utils/helpers";

export const ShowVehicleModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Vehicle>(id, VEHICLES_API, "vehicles");
  const { mutateAsync } = useUpdateVehicle();

  return data && checkPermissions(["rosina.view_vehicle"]) ? (
    <FullModal
      title="Modifica automezzo"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateVehicleForm vehicle={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
