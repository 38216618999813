import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { TERMS_PAYMENT_API } from "utils/constants";
import { TermsPayment } from "../types/termspayment";

const key = "terms-payment";

export function useOptionsTermsPayment() {
  return useOptions(TERMS_PAYMENT_API, key);
}

export function useAddTermsPayment() {
  return useAdd<TermsPayment>(TERMS_PAYMENT_API, key);
}

export function useListTermsPayment(
  params?: any,
  config?: UseQueryOptions<any, unknown, TermsPayment[]>
) {
  return useList(TERMS_PAYMENT_API, key, params, config);
}
