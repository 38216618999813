import { useLoadItem } from "shared/hooks/useLoadItem";

import { PRODUCT_TYPE_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { useUpdateProductType } from "../hooks/productType";
import { ProductType } from "Warehouse/types";
import { FullModal } from "components/FullModal";
import { UpdateProductTypeForm } from "./UpdateProductTypeForm";

export const ShowProductTypeModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<ProductType>(id, PRODUCT_TYPE_API, "product_types");
  const { mutateAsync } = useUpdateProductType();

  return data && checkPermissions(["rosina.view_producttype"]) ? (
    <FullModal
      title="Modifica tipo prodotto"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateProductTypeForm productType={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
