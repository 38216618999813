import { UpdateItemForm } from "Items/components/UpdateItemForm";
import { useUpdateItem } from "Items/hooks/item";
import { Item } from "Items/types/item";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { ITEMS_API } from "utils/constants";
import { FullModal } from "components/FullModal";
import { checkPermissions } from "utils/helpers";

export const ShowItemModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Item>(id, ITEMS_API, "items");
  const { mutateAsync } = useUpdateItem();

  return data && checkPermissions(["rosina.view_item"]) ? (
    <FullModal
      title="Modifica articolo"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateItemForm item={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
