import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { client } from "utils/auth-client";
import { PACKAGES_API } from "utils/constants";

export function useDeletePackage() {
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) =>
      client(`${PACKAGES_API}${id}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["deliveries"], { active: true });
      },
      onError: () => {
        toast.error("Impossibile eliminare");
      },
    }
  );
}
