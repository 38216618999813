import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { useOptionsTermsPayment } from "Billing/hooks/termspayments";
import { TermsPayment } from "Billing/types/termspayment";
import { TermsPaymentForm } from "./TermsPaymentForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, TermsPayment, unknown>;
  onSuccess?: () => void;
}

export const AddTermsPaymentForm = ({ submitFunction, onSuccess }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
    getValues,
  } = useForm<Omit<TermsPayment, "terms">>({
    defaultValues: { terms_id: null },
  });

  const { data: options } = useOptionsTermsPayment();
  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Condizione di pagamento aggiunto!");
        onSuccess && onSuccess();
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form
      onSubmit={(e) => {
        e?.preventDefault();
        onSend();
        e?.stopPropagation();
      }}
    >
      {options && (
        <TermsPaymentForm
          options={options}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
        />
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
