import { useEffect, useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { client } from "utils/auth-client";
import { PRICES_API, PRICES_CLIENT_API } from "utils/constants";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Price } from "Items/types/price";

interface Props {
  errorMessage?: string;
  disabled?: boolean;
  getValues?: any;
  control: any;
  name: string;
  defaultValue?: number | null;
  label?: string;
}

export const PricesClientAutocomplete = ({
  disabled,
  errorMessage,
  getValues,
  control,
  name,
  defaultValue,
  label,
}: Props) => {
  const values = getValues();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const filterBy = () => true;

  const handleSearch = (query: string) => {
    setIsLoading(true);
    client(PRICES_CLIENT_API, {
      method: "GET",
      params: { id: query, delivery: values.delivery_id },
    })
      .then((resp) => {
        const options = resp.results.map((i: Price) => {
          const weights =
            i.charge_type === "DI"
              ? `${i.min_weight}-${i.max_weight} kg`
              : "";
          const provinces = i.provinces_names ? `(${i.provinces_names})` : "";
          return {
            label: `${i.charge_type} ${i.item?.code} ${i.client?.business_name ? i.client?.business_name : ""
              } ${i.price}€ ${provinces} ${weights}`,
            id: i.id,
          };
        });
        setOptions(options);
        setIsLoading(false);
      })
      .catch(() => {
        if (!toast.isActive("message-price"))
          toast.error("Nessun prezzo per questo cliente", {
            toastId: "message-price",
          });
      });
  };

  useEffect(() => {
    const values = getValues();
    if (
      name in values &&
      values[name] !== undefined &&
      values[name] !== null &&
      values[name] !== ""
    ) {
      client(`${PRICES_API}${values[name]}/`, {
        method: "get",
      }).then((data) => {
        setOptions([
          {
            id: data.id,
            label: `${data.charge_type} ${data.item?.code} ${data.client?.business_name ? data.client?.business_name : ""
              } ${data.price} (${data.provinces_names})`,
          },
        ]);
      });
    }
  }, [getValues, name]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ name, onChange }) => {
        return (
          <FormGroup>
            {label && <Label>Prezzo</Label>}
            <AsyncTypeahead
              clearButton
              id={name.toString()}
              filterBy={filterBy}
              isLoading={isLoading}
              onSearch={handleSearch}
              onChange={(data: any) => {
                if (data.length > 0) {
                  onChange(data[0].id);
                } else {
                  onChange(null);
                }
              }}
              options={options}
              minLength={1}
              disabled={disabled}
              selected={options.filter(
                (option: any) => option.id === getValues()[name]
              )}
              placeholder="Cerca prezzo"
            />
            {errorMessage !== "" && (
              <FormFeedback style={{ display: "block" }}>
                {errorMessage}
              </FormFeedback>
            )}
          </FormGroup>
        );
      }}
    />
  );
};
