import { useToggler } from "shared/hooks/useToggler";
import { AddTrailerForm } from "../components/AddTrailerForm";
import { TrailerFilter } from "../types/trailer";
import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { useSearchTrailers, useAddTrailer } from "Vehicles/hooks/trailer";
import { TrailerListPage } from "Vehicles/components/TrailerListPage";

export const Trailers = () => {
  const { paramsObj, onSearch } = useFilter<TrailerFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchTrailers(paramsObj);
  const { mutateAsync } = useAddTrailer();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<TrailerFilter>) => {
    onSearch(data);
    search(data);
  };

  return (
    <>
      {data && data.results && (
        <TrailerListPage
          trailers={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi veicolo" toggle={toggle} isOpen={isOpen}>
        <AddTrailerForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
