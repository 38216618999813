import { Table } from "components/tables/Table";
import { useColumnsPrices } from "Items/hooks/price";
import { Price } from "Items/types/price";

import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { setPage } from "shared/reducers/searchReducer";
import { PRICES_API } from "utils/constants";

interface Props {
  clientId: string | number;
}

export const SubjectPrices = (props: Props) => {
  const { data, page, pageCount } = useSearchPaginated<Price>(
    PRICES_API,
    "prices",
    { client: props.clientId }
  );
  const columns = useColumnsPrices();

  return (
    <div>
      {data && (
        <Table
          pageIndex={page}
          pageCount={pageCount}
          setPage={(page: number) => setPage(page)}
          data={data.results}
          columns={columns}
          sortBy="surname"
        />
      )}
    </div>
  );
};
