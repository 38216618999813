import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { ARTICLE_API } from "utils/constants";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { useOptionsStatus } from "Warehouse/Status/hooks/status";
import { Article } from "../../types";

const key = "articles";
const url = ARTICLE_API;

export function useOptionsArticle() {
  return useOptions(url, key);
}

export function useAddArticle() {
  return useAdd<Article>(url, key);
}

export function useDeleteArticle() {
  return useDelete(url, key);
}

export function useUpdateArticle() {
  return useUpdate<Article>(url, key);
}

export function useListArticle(
  params?: any,
  config?: UseQueryOptions<any, unknown, Article[]>
) {
  return useList(url, key, params, config);
}

export function useSearchArticles(params?: any) {
  return useSearchPaginated<Article>(url, key, params);
}

export function useArticle(
  id: string,
  config?: UseQueryOptions<any, unknown, Article>
) {
  return useGetItem<Article>(id, url, key, config);
}

export function useArticleColumns(
  options: any
) {
  const { data: optionsStatus } = useOptionsStatus();
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteArticle } = useDeleteArticle();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteArticle(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_article"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("article", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Ultimo stato",
        accessor: "status",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("status", cell.value, optionsStatus),
      },
      {
        Header: "Prodotto",
        accessor: "product",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.product)
            return (
              <>
                {`${cell.row.original.product.label}`}
              </>
            );
          return "";
        },
      },
      {
        Header: "Codice prodotto",
        accessor: "product_code",
        sortType: "basic",
      },
      {
        Header: "Note",
        accessor: "note",
        sortType: "basic",
      },

    ];
  }, [deleteArticle, options, optionsStatus, setActiveItem]);
}
