import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { FormField } from "components/forms/FormField";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const PlaceForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Piano</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.FLOOR}
                      control={control}
                      defaultValue={
                        defaultValues?.floor_id
                          ? defaultValues?.floor_id
                          : null
                      }
                      errorMessage={errors.floor_id?.message}
                      name="floor_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Posizione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Posizione"
                        type="number"
                        register={register}
                        name="position"
                        errorMessage={errors.position?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.position}`
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>


          </div>
        </>
      )}
    </>
  );
};
