import { InvoiceListPage } from "Billing/components/InvoiceListPage/InvoiceListPage";
import { NewInvoice } from "Billing/components/NewInvoice";
import { useOptionsInvoice, useSearchInvoice } from "Billing/hooks/invoices";
import { InvoiceListingFilter } from "Billing/types/invoice";
import { FullModal } from "components/FullModal";
import { useFilter } from "shared/hooks/useFilter";
import { useToggler } from "shared/hooks/useToggler";

export const Invoices = () => {
  const { paramsObj, onSearch } = useFilter<InvoiceListingFilter>();
  const {
    data,
    search,

    page,
    goToPage,
    pageCount,
  } = useSearchInvoice(paramsObj);

  const searchItems = (data: Partial<InvoiceListingFilter>) => {
    onSearch(data);
    search(data);
  };

  const { value: isOpen, toggle, setTrue: openAdd } = useToggler();
  const { data: options } = useOptionsInvoice();
  return (
    <>
      {data && data.results && options && (
        <InvoiceListPage
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          page={page}
          pageCount={pageCount}
          onAddClick={openAdd}
          invoices={data.results}
          goToPage={goToPage}
          options={options}
        />
      )}

      <FullModal toggle={toggle} isOpen={isOpen} title="Aggiungi fattura">
        <NewInvoice invoice={{}} onSuccess={() => toggle()} />
      </FullModal>
    </>
  );
};
