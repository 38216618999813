import { UpdateDeliveryForm } from "Distribution/components/UpdateDeliveryForm";
import { useUpdateDelivery } from "Distribution/hooks/delivery";
import { Delivery } from "Distribution/types/delivery";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { DELIVERIES_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowDeliveryModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Delivery>(id, DELIVERIES_API, "deliveries");
  const { mutateAsync } = useUpdateDelivery();

  return data && checkPermissions(["rosina.view_delivery"]) ? (
    <FullModal
      title="Modifica missione"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateDeliveryForm delivery={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
