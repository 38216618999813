import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { FLOOR_API } from "utils/constants";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { Floor } from "../../types";

const key = "floors";
const url = FLOOR_API;

export function useOptionsFloor() {
  return useOptions(url, key);
}

export function useAddFloor() {
  return useAdd<Floor>(url, key);
}

export function useDeleteFloor() {
  return useDelete(url, key);
}

export function useUpdateFloor() {
  return useUpdate<Floor>(url, key);
}

export function useListFloor(
  params?: any,
  config?: UseQueryOptions<any, unknown, Floor[]>
) {
  return useList(url, key, params, config);
}

export function useSearchFloors(params?: any) {
  return useSearchPaginated<Floor>(url, key, params);
}

export function useFloor(
  id: string,
  config?: UseQueryOptions<any, unknown, Floor>
) {
  return useGetItem<Floor>(id, url, key, config);
}

export function useFloorColumns(
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteFloor } = useDeleteFloor();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteFloor(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_floor"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("floor", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Nome",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Tipo coda",
        accessor: "tile",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("tile", cell.value, options),
      },
      {
        Header: "Numero posizioni",
        accessor: "number",
        sortType: "basic",
      },
    ];
  }, [deleteFloor, options, setActiveItem]);
}
