import { UpdatePriceForm } from "Items/components/UpdatePriceForm";
import { useUpdatePrice } from "Items/hooks/price";
import { Price } from "Items/types/price";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { PRICES_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowPriceModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Price>(id, PRICES_API, "prices");
  const { mutateAsync } = useUpdatePrice();

  return data && checkPermissions(["rosina.view_price"]) ? (
    <FullModal
      title="Modifica prezzo"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdatePriceForm price={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
