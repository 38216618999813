import { useLoadItem } from "shared/hooks/useLoadItem";
import { UpdateDriverForm } from "Drivers/components/UpdateDriverForm";

import { DRIVERS_API } from "utils/constants";
import { FullModal } from "../../components/FullModal";
import { useUpdateDriver } from "Drivers/hooks/driver";
import { Driver } from "Drivers/types/driver";
import { checkPermissions } from "utils/helpers";

export const ShowDriverModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Driver>(id, DRIVERS_API, "drivers");
  const { mutateAsync } = useUpdateDriver();

  return data && checkPermissions(["rosina.view_driver"]) ? (
    <FullModal
      title="Modifica autista"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateDriverForm driver={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
