import React, { memo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SubRoute } from "shared/types";

/*
 * This is the route utility component used for generating
 * routes and child routes it only requires routes array and basePath
 */
function MapRoutes({ routes }: { routes: SubRoute[] }) {
  return (
    <Switch>
      {routes.map((route) => {
        /*
         * some variables are used by below code
         * some are not used by the code but destructure due to remove from rest object
         * just make sure that rest object only contain props that supported by react-router's route component
         * you may find props list here https://reactrouter.com/web/api/Route
         */
        const {
          path,
          component: Component,
          children,
          name,
          icon,
          permissions,
          ...rest
        } = route;

        return (
          <Route {...rest} key={path} path={`${path}`}>
            <Component routes={children} />
          </Route>
        );
      })}
      <Redirect to={routes[0].path} />
    </Switch>
  );
}

export default memo(MapRoutes);
