import { FilterI } from "components/Filter/types";
import { FloorFilter } from "Warehouse/types";

export const createFilterStructure = (params: FloorFilter): FilterI[] => {
  return [
    {
      type: "autocomplete-row",
      name: "row",
      label: "Riga",
      active: params?.row !== undefined,
      value: params?.row,
    },
    {
      type: "text",
      name: "name",
      label: "Nome",
      active: params?.name !== undefined,
      value: params?.name,
    },
  ];
};
