import { NestedValue, useFieldArray, useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { SpecificationNote, Subject } from "../types";
import {
  useOptionsSpecificationNotes,
  useOptionsSubject,
} from "Subjects/hooks";
import { SubjectForm } from "./SubjectForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Subject, unknown>;
  onSuccess: () => void;
}

export const AddSubjectForm = ({ submitFunction, onSuccess }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    getValues,
    watch,
  } = useForm<
    Omit<Subject, "main_subject" | "payment_terms" | "specification_notes"> & {
      type: NestedValue<string[]>;
      specification_notes: NestedValue<SpecificationNote[]>;
    }
  >({
    defaultValues: {
      latitude: 41.2925,
      longitude: 12.5736,
      specification_notes: [],
    },
  });
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "specification_notes",
    keyName: "key" as "id",
  });
  const { data: options } = useOptionsSubject();
  const { data: optionsNotes } = useOptionsSpecificationNotes();
  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Soggetto aggiunto!");
        onSuccess();
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && optionsNotes && (
        <SubjectForm
          optionNotes={optionsNotes}
          prepend={prepend}
          control={control}
          options={options}
          register={register}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          fields={fields}
          remove={remove}
          watch={watch}
        />
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
