import { useOptionsSegment, useSearchSegment } from "../hooks/segments";
import { SegmentListPage } from "../components/SegmentListPage";
import { useFilter } from "shared/hooks/useFilter";

export const Segments = () => {
  const { paramsObj, onSearch } = useFilter<{ order: number }>();
  const { data, search, page, goToPage, pageCount } =
    useSearchSegment(paramsObj);

  const searchItems = (data: Partial<{ order: number }>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsSegment();
  return (
    <>
      {data && data.results && options && (
        <SegmentListPage
          options={options}
          segments={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
    </>
  );
};
