import { useMutation, useQueryClient } from "react-query";
import { client } from "utils/auth-client";
import { DELIVERY_NOTE_ROWS_API, PACKAGES_API } from "utils/constants";
import { DeliveryNoteRow } from "../types";

export function useAddDeliveryNoteRow() {
  const queryClient = useQueryClient();

  return useMutation(
    (delivery_note_row: DeliveryNoteRow) =>
      client(DELIVERY_NOTE_ROWS_API, {
        method: "POST",
        data: delivery_note_row,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["deliveries"], { active: true });
      },
    }
  );
}
