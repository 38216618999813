import { useEffect, useRef, useState } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "./MapControls.css";

export const MapLegend = () => {
    const context = useLeafletContext();
    const legendControl = useRef<L.Control>();
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (context.map) {
            legendControl.current = new L.Control({ position: "bottomleft" });

            legendControl.current.onAdd = () => {
                const div = L.DomUtil.create("div", "info-legend");

                div.innerHTML = `<div style="display:flex;justify-content:space-between;align-items:center;gap:20px"><span><h4>Legenda</h4></span><span>${show ? "&#x25B2;" : "&#x25BC;"
                    }</span></div>
        <div class="legend-container ${show ? "" : "hide-control"}">
        <div style="background-color: #6699ff; height: 30px"/><span>Settori</span></div>
        <div style="background-color: #ff5050; height: 30px"/><span>Righe</span></div>
        <div style="background-color: lightblue; height: 30px"/><span>Occupato</span></div>
        <div style="background-color: lightgreen; height: 30px"/><span>Libero</span></div>
        </div>`;
                div.onclick = () => setShow((value) => !value);
                return div;
            };

            legendControl.current.addTo(context.map);
            return () => {
                legendControl.current &&
                    context.map.removeControl(legendControl.current);
            };
        }
    }, [context.map, show]); //here add map
    return null;
};
