import { useQuery, UseQueryOptions } from "react-query";
import { client } from "utils/auth-client";
import { DELIVERIES_PLANNER_API } from "utils/constants";
import { DeliveryPlan } from "DeliveryPlanner/types";
import { Route } from "Distribution/types/route";

export function useDeliveriesPlanner(
  routes?: Route[],
  config?: UseQueryOptions<any, unknown, DeliveryPlan[]>
) {
  return useQuery(
    ["deliveries-planner", routes],
    () => {
      let data = {};
      if (routes) {
        data = { params: { route_id: routes.map((route) => route.id) } };
      }
      return client(`${DELIVERIES_PLANNER_API}`, { method: "GET", ...data });
    },
    {
      initialData: [] as DeliveryPlan[],
      keepPreviousData: true,
      ...config,
    }
  );
}
