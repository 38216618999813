import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { IconButton } from "components/buttons/IconButton";
import { DownloadFile } from "components/DownloadFile";
import { useMemo } from "react";
import { File, List } from "react-feather";
import { useMutation, useQueryClient, UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { ROUTES_API } from "utils/constants";
import { getDisplayOnlyDate, getDisplayValue } from "utils/helpers";
import { Route } from "../types/route";
import Checkbox from "react-three-state-checkbox";
import { AxiosRequestConfig } from "axios";
import { client } from "utils/auth-client";
import { useGlobalModals } from "shared/context/globalModals";
import { useHistory } from "react-router";
import { filterJson } from "components/tables/filters";
import { getSelectableColumn } from "components/tables/selectableColumn";

const key = "routes";
const url = ROUTES_API;

export function useOptionsRoute() {
  return useOptions(url, key);
}

export function useAddRoute() {
  return useAdd<Route>(url, key);
}

export function useDeleteRoute() {
  return useDelete(url, key);
}

export function useUpdateRoute() {
  return useUpdate<Route>(url, key);
}

export function useListRoute(
  params?: any,
  config?: UseQueryOptions<any, unknown, Route[]>
) {
  return useList(url, key, params, config);
}

export function useSearchRoutes(params?: any) {
  return useSearchPaginated<Route>(url, key, params);
}

export function useRoute(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Route>
) {
  return useGetItem<Route>(id, url, key, config);
}

export function useSendRoutesTrackMe(requestConfig?: AxiosRequestConfig) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { ids: number[] }) => {
      return client(`${ROUTES_API}send_routes_trackme/`, {
        method: "POST",
        data: data,
        ...(requestConfig ? requestConfig : {}),
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
    }
  );
}
export function useRouteColumns(options: any) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteRoute } = useDeleteRoute();
  const history = useHistory();
  return useMemo(() => {
    const onDelete = (id: number) => {
      const r = window.confirm("Rimuovere elemento?");
      if (r) deleteRoute(id);
    };
    return [
      getSelectableColumn(),
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_route"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("route", cell.row.original.id)}
                permissions={[]}
              />
              <IconButton
                Icon={List}
                title={"Mostra missioni"}
                onClick={() =>
                  history.push("/distribution?route=" + cell.row.original.id)
                }
              />
              <DownloadFile
                ButtonDownload={() => (
                  <IconButton Icon={File} title={"Scarica borderau"} />
                )}
                urlDownload={`${ROUTES_API}${cell.row.original.id}/get_pdf_borderau/`}
              />
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
      {
        Header: "Stato",
        accessor: "status",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("status", cell.value, options),
      },
      {
        Header: "Origine",
        accessor: "origin",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("origin", cell.value, options),
      },
      {
        Header: "Ordine del giro",
        accessor: "round_order",
        sortType: "basic",
      },
      {
        Header: "Dispositivo",
        accessor: "device",
        sortType: "basic",
      },
      {
        Header: "Distanza totale",
        accessor: "total_distance",
        sortType: "basic",
      },
      {
        Header: "Data pianificata",
        accessor: "planned_date",
        sortType: "basic",
        Cell: (cell: any) => getDisplayOnlyDate(cell.value),
      },
      {
        Header: "Inviato",
        accessor: "sent",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("sent", cell.value, options),
      },
      {
        accessor: "print_with_qr",
        Header: "",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <DownloadFile
              title={"Stampa giro"}
              urlDownload={`${ROUTES_API}print_route_with_qr/${cell.row.original.id}`}
            />
          );
        },
      },
      {
        Header: "Autista",
        accessor: "driver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.driver)
            return (
              <Button
                title={"Mostra autista"}
                color="link"
                onClick={() =>
                  setActiveItem("driver", cell.row.original.driver.id)
                }
              >{`${cell.row.original.driver.name} ${cell.row.original.driver.surname}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Veicolo",
        accessor: "vehicle",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.vehicle)
            return (
              <Button
                title={"Mostra veicolo"}
                color="link"
                onClick={() =>
                  setActiveItem("vehicle", cell.row.original.vehicle.id)
                }
              >{`${cell.row.original.vehicle.plate} ${cell.row.original.vehicle.description}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
    ];
  }, [deleteRoute, history, options, setActiveItem]);
}
