import { useToggler } from "shared/hooks/useToggler";

import { FullModal } from "components/FullModal";
import { MESSAGES } from "utils/constants";

import {
  useAddPallet,
  useOptionsPallet,
  usePalletColumns,
  useSearchPallets,
} from "PalletsManagment/hooks/pallet";
import { PalletListPage } from "PalletsManagment/components/PalletsListPage";
import { AddPalletForm } from "PalletsManagment/components/AddPalletForm";

export const Pallets = () => {
  const { data, page, pageCount, goToPage } = useSearchPallets();

  const { mutateAsync } = useAddPallet();
  const { value: isOpen, toggle } = useToggler();

  const columns = usePalletColumns();

  const { data: options } = useOptionsPallet();
  return (
    <>
      {data && data.results && options && (
        <PalletListPage
          onAddClick={toggle}
          columns={columns}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
          pallets={data.results}
        />
      )}

      <FullModal
        toggle={toggle}
        isOpen={isOpen}
        title={MESSAGES.PALLET.MODAL_NEW}
      >
        <AddPalletForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
