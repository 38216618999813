import { useToggler } from "shared/hooks/useToggler";
import { AddRouteForm } from "../components/AddRouteForm";
import { Route, RouteFilter } from "../types/route";
import {
  useAddRoute,
  useOptionsRoute,
  useSearchRoutes,
  useSendRoutesTrackMe,
} from "../hooks/route";
import { RouteListPage } from "Distribution/components/RouteListPage";
import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { useState } from "react";

export const Routes = () => {
  const { paramsObj, onSearch } = useFilter<RouteFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchRoutes(paramsObj);
  const { mutateAsync } = useAddRoute();
  const { value: isOpen, toggle } = useToggler();
  const { mutateAsync: sendToTrackme } = useSendRoutesTrackMe();
  const [selectedRows, setSelectedRows] = useState<Route[]>([]);
  const { data: options } = useOptionsRoute();

  const searchItems = (data: Partial<RouteFilter>) => {
    onSearch(data);
    search(data);
  };
  return (
    <>
      {data && options && (
        <RouteListPage
          sendRoutesTrackMe={sendToTrackme}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          routes={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
          options={options}
        />
      )}

      <FullModal title="Aggiungi giro" toggle={toggle} isOpen={isOpen}>
        <AddRouteForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
