import React from "react";
import { Badge } from "reactstrap";

interface Props {
  types: string[];
}

export const SubjectTypePills = ({ types }: Props) => {
  return (
    <>
      {types.map((type) => (
        <Badge pill key={type} className="mr-1">
          {type}
        </Badge>
      ))}
    </>
  );
};
