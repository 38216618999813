import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { DRIVERS_API } from "utils/constants";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { Driver } from "../types/driver";

const key = "drivers";
const url = DRIVERS_API;

export function useOptionsDriver() {
  return useOptions(url, key);
}

export function useAddDriver() {
  return useAdd<Driver>(url, key);
}

export function useDeleteDriver() {
  return useDelete(url, key);
}

export function useUpdateDriver() {
  return useUpdate<Driver>(url, key);
}

export function useListDriver(
  params?: any,
  config?: UseQueryOptions<any, unknown, Driver[]>
) {
  return useList(url, key, params, config);
}

export function useSearchDrivers(params?: any) {
  return useSearchPaginated<Driver>(url, key, params);
}

export function useDriver(
  id: string,
  config?: UseQueryOptions<any, unknown, Driver>
) {
  return useGetItem<Driver>(id, url, key, config);
}

export function useDriverColumns(
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteDriver } = useDeleteDriver();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteDriver(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_driver"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("driver", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Nome",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Cognome",
        accessor: "surname",
        sortType: "basic",
      },
      {
        Header: "Sesso",
        accessor: "gender",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("gender", cell.value, options),
      },
      {
        Header: "Data di nascita",
        accessor: "date_of_birth",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        Header: "Telefono",
        accessor: "phone_number",
        sortType: "basic",
      },
      {
        Header: "Patente",
        accessor: "license",
        sortType: "basic",
      },
      {
        Header: "Dispositivo",
        accessor: "device",
        sortType: "basic",
      },
    ];
  }, [deleteDriver, options, setActiveItem]);
}
