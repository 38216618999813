// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, setFilter },
}: any) {
  return (
    <input
      style={{ height: "25px" }}
      className="form-control table-input"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}
