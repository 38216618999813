import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Input } from "reactstrap";
import { UseMutateAsyncFunction, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";



import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { AUTOCOMPLETE_URLS, CHARGES_API } from "utils/constants";
import { useOptionsCharge } from "Billing/hooks/charges";
import { Charge } from "Billing/types/charge";
import { AxiosRequestConfig } from "axios";
import { client } from "utils/auth-client";
import { setFormValues } from "shared/reducers/searchReducer";
import { CheckboxField } from "components/forms/CheckboxField";


interface Props {
  charges: Charge[],
  closeModal?: any
}

export const HideOrShowChargesForm = ({ charges, closeModal }: Props) => {

  function useAddToInvoice(requestConfig?: AxiosRequestConfig) {
    const queryClient = useQueryClient();
    return useMutation(
      (data: { ids: number[], hide: boolean }) => {
        return client(`${CHARGES_API}hide_or_show/`, {
          method: "POST",
          data: data,
          ...(requestConfig ? requestConfig : {}),
        });
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries([], { active: true });
          if (closeModal) {
            closeModal()
          }
        }
      },
    );
  }

  const handleSubmit2 = (event: any) => {
    let hide = event.target.elements.hide.checked;
    window.alert()
    event.preventDefault();
    addToInvoice(
      {
        ids: charges.map((charge) => charge.id!),
        hide: hide
      }
    ).catch(
      (e) => {
        toast.error(e.response.data[0])
      }
    )
  }


  const { mutateAsync: addToInvoice } = useAddToInvoice();

  const { data: options } = useOptionsCharge();

  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
  } = useForm();

  return (
    <Form onSubmit={handleSubmit2}>
      {(
        <>
          <h5>Nascondi?</h5>
          <CheckboxField
            register={register}
            name="hide"
            errorMessage={errors.hide?.message}
          />
        </>
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
