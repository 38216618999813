import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { CollapsableCard } from "components/CollapsableCard";
import { Pill } from "components/Pill";
import { SimpleTable } from "components/tables/SimpleTable";
import { UseMutateFunction } from "react-query";
import { Column } from "react-table";
import { Order } from "Traction/types";

interface Props {
  order: Order;
  columns: Column[];
  setActiveOrder: (oder: Order) => void;
  deleteFunction: UseMutateFunction<any, unknown, number, unknown>;
}

export const OrderListItem = ({
  order,
  columns,
  setActiveOrder,
  deleteFunction,
}: Props) => {
  return (
    <CollapsableCard
      title={
        <div
          style={{ flex: "1 1 auto" }}
          className="d-flex flex-row justify-content-between align-items-center"
        >
          <div>
            <b>{`${order.id}: ${order.client?.business_name}`}</b>
          </div>
          <div className="d-flex flex-row align-items-center">
            <span>{new Date(order.date).toLocaleString("it-IT", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            })}</span>
            {order.fill && <Pill className="ml-2 mr-0" text={`Riempi`} />}

            <EditButton
              className=" mx-1 ml-2"
              onClick={(e) => {
                e!.stopPropagation();
                setActiveOrder(order);
              }}
              permissions={[]}
            />
            <DeleteButton
              className="mx-1"
              permissions={["rosina.change_price"]}
              onClick={(e) => {
                e!.stopPropagation();
                let r = window.confirm("Sei sicuro di voler eliminare?");
                if (r) deleteFunction(order.id!);
              }}
            />
          </div>
        </div>
      }
    >
      <div className="p-2" style={{ maxHeight: "700px", overflow: "auto" }}>
        {order.destinies && (
          <SimpleTable columns={columns} data={order.destinies} />
        )}
      </div>
    </CollapsableCard>
  );
};
