import { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Delivery } from "Distribution/types/delivery";
import { useOptionsDelivery } from "Distribution/hooks/delivery";
import { DeliveryForm } from "./DeliveryForm";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Delivery>,
    unknown
  >;
  delivery: Delivery;
}

export const UpdateDeliveryForm = ({ submitFunction, delivery }: Props) => {
  const { errors, register, handleSubmit, setError, control } = useForm<
    Omit<Delivery, "client" | "receiver" | "packages" | "route">
  >({
    defaultValues: delivery,
  });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const { data: options } = useOptionsDelivery();

  const onSend = handleSubmit((data) => {
    console.log(data);
    submitFunction({ id: delivery.id!, newValues: data })
      .then(() => {
        toast.success("Missione modificata!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {delivery.editable && (
        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <div className="d-flex flex-row justify-content-end">
            <Button size="sm" onClick={toggleDisable} color="primary">
              Modifica
            </Button>
          </div>
        </CheckPermissions>
      )}

      {options && (
        <DeliveryForm
          register={register}
          options={options}
          control={control}
          errors={errors}
          editable={!disabled}
          defaultValues={delivery}
        />
      )}

      {delivery.editable && (
        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <div className="d-flex justify-content-end py-3">
            <Button type="submit" color="success" disabled={disabled}>
              Invia
            </Button>
          </div>
        </CheckPermissions>
      )}
    </Form>
  );
};
