import classNames from "classnames";

import { TermsPaymentAutocomplete } from "components/autocompletes/TermsPaymentAutocomplete";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { Tabs } from "components/Tabs";
import { getDisplayValue } from "utils/helpers";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { SimpleTable } from "components/tables/SimpleTable";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { Autocomplete } from "components/autocompletes/Autocomplete";

interface Props {
  setValue: any;
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  watch?: any;
  getValues: any;
}

export const InvoiceForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  getValues,
}: Props) => {
  const columns = useMemo(() => {
    return [
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        accessor: "description",
        Header: "Descrizone",
        sortType: "basic",
      },
      {
        accessor: "quantity",
        Header: "Quantità di addebito",
        sortType: "basic",
      },
      {
        accessor: "price",
        Header: "Prezzo",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.row.original.price ? cell.row.original.price.price : null,
      },
      {
        accessor: "amount",
        Header: "Importo",
        sortType: "basic",
      },
      { accessor: "taxable", Header: "Imponibile", sortType: "basic" },
      {
        accessor: "item",
        Header: "Articolo",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.row.original.price.label
            ? cell.row.original.price.label
            : null,
      },
    ];
  }, []);
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Cliente</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.subject_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.SUBJECT}
                      control={control}
                      defaultValue={
                        defaultValues?.subject_id
                          ? defaultValues?.subject_id
                          : null
                      }
                      name="subject_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data fattura</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Data fattura"
                        type="date"
                        register={register}
                        name="invoice_date"
                        errorMessage={errors.invoice_date?.message}
                      />
                    ) : defaultValues.invoice_date && !editable ? (
                      defaultValues.invoice_date
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Stato fattura</b>
                  </td>
                  <td>
                    {editable ? (
                      <SelectField
                        disabled={!editable}
                        placeholder="Stato fattura"
                        options={options.invoice_status.choices}
                        register={register}
                        errorMessage={errors.invoice_status?.message}
                        name="invoice_status"
                      />
                    ) : defaultValues && !editable ? (
                      getDisplayValue(
                        "invoice_status",
                        defaultValues.invoice_status,
                        options
                      )
                    ) : null}
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Imponibile</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Imponibile"
                        type="number"
                        register={register}
                        name="taxable"
                        errorMessage={errors.taxable?.message}
                      />
                    ) : defaultValues.taxable && !editable ? (
                      defaultValues.taxable
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Importo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Importo"
                        type="number"
                        register={register}
                        name="amount"
                        errorMessage={errors.amount?.message}
                      />
                    ) : defaultValues.amount && !editable ? (
                      defaultValues.amount
                    ) : null}
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Data scadenza</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Data scadenza"
                        type="date"
                        register={register}
                        name="expiration_date"
                        errorMessage={errors.expiration_date?.message}
                      />
                    ) : defaultValues.expiration_date && !editable ? (
                      defaultValues.expiration_date
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Termini di pagamento</b>
                  </td>
                  <td>
                    <TermsPaymentAutocomplete
                      defaultValue={
                        defaultValues && defaultValues.terms_payment_id
                          ? defaultValues.terms_payment_id
                          : null
                      }
                      name="terms_payment_id"
                      control={control}
                      disabled={!editable}
                      errorMessage={errors.terms_payment_id?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo fattura</b>
                  </td>
                  <td>
                    {editable ? (
                      <SelectField
                        disabled={!editable}
                        placeholder="Tipo fattura"
                        options={options.invoice_type.choices}
                        register={register}
                        errorMessage={errors.invoice_type?.message}
                        name="invoice_type"
                      />
                    ) : defaultValues && !editable ? (
                      getDisplayValue(
                        "invoice_type",
                        defaultValues.invoice_type,
                        options
                      )
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs
              titles={
                defaultValues && defaultValues.invoice_lines
                  ? ["Righe fattura", "Note"]
                  : ["Note"]
              }
            >
              {
                defaultValues && defaultValues.invoice_lines && (
                  <div>
                    <SimpleTable
                      style={{ maxHeight: "400px" }}
                      columns={columns}
                      data={defaultValues.invoice_lines}
                    />
                  </div>
                )
              }
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Note soggetto</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note soggetto"
                            type="textarea"
                            register={register}
                            errorMessage={errors.subject_notes?.message}
                            name="subject_notes"
                          />
                        ) : (
                          defaultValues && defaultValues.subject_notes
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Note corpo</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note corpo"
                            type="textarea"
                            register={register}
                            errorMessage={errors.body_notes?.message}
                            name="body_notes"
                          />
                        ) : (
                          defaultValues && defaultValues.body_notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
