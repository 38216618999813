import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { SEGMENTS_API } from "utils/constants";
import { Segment } from "Traction/types";
import { useMemo } from "react";
import { Button } from "reactstrap";
import { getDisplayDate } from "utils/helpers";
import { useGlobalModals } from "shared/context/globalModals";
import { DeleteButton } from "components/buttons/DeleteButton";
import { filterJson } from "components/tables/filters";

const key = "segments";
const url = SEGMENTS_API;

export function useOptionsSegment() {
  return useOptions(url, key);
}

export function useAddSegment() {
  return useAdd<Segment>(url, key);
}

export function useDeleteSegment() {
  return useDelete(url, key);
}

export function useUpdateSegment() {
  return useUpdate<Segment>(url, key);
}

export function useListSegment(
  params?: any,
  config?: UseQueryOptions<any, unknown, Segment[]>
) {
  return useList(url, key, params, config);
}

export function useSearchSegment(searchParams?: any) {
  console.log(searchParams)
  return useSearchPaginated<Segment>(url, key, searchParams);
}

export function useSegment(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Segment>
) {
  return useGetItem<Segment>(id, url, key, config);
}

export function useSegmentColumns() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteDelivery } = useDeleteSegment();

  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteDelivery(id);
    };
    return [
      {
        accessor: "__id",
        Header: "",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <>
              <DeleteButton
                className="ml-2"
                permissions={["rosina.change_segment"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
            </>
          );
        },
      },
      {
        Header: "N. ordine",
        accessor: "order",
        sortType: "basic",
      },

      {
        accessor: "starting_point",
        Header: "Punto di partenza",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.starting_point) {
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.starting_point.id)
                }
              >{`${cell.row.original.starting_point.id}: ${cell.row.original.starting_point.business_name}`}</Button>
            );
          }
          return null;
        },
        filter: filterJson
      },
      {
        accessor: "arrival_point",
        Header: "Punto di arrivo",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.starting_point) {
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.arrival_point.id)
                }
              >{`${cell.row.original.arrival_point.id}: ${cell.row.original.arrival_point.business_name}`}</Button>
            );
          }
          return null;
        },
        filter: filterJson
      },
      {
        Header: "Autista",
        accessor: "driver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.driver)
            return `${cell.row.original.driver.name} ${cell.row.original.driver.surname}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Veicolo",
        accessor: "vehicle",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.vehicle)
            return `${cell.row.original.vehicle.plate} ${cell.row.original.vehicle.description}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Rimorchio",
        accessor: "trailer",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.trailer)
            return `${cell.row.original.trailer.plate} ${cell.row.original.trailer.description}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Data",
        accessor: "date",
        sortType: "basic",
        Cell: (cell: any) => {
          return getDisplayDate(cell.value);
        },
        filter: filterJson
      },
      {
        Header: "Data orine",
        accessor: "order__date",
        sortType: "basic",
        Cell: (cell: any) => {
          return getDisplayDate(cell.value);
        },
        filter: filterJson
      }
    ];
  }, [deleteDelivery, setActiveItem]);
}
