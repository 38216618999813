import React, { useEffect, useMemo } from "react";
import { CSSProperties } from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { useTable, useFilters, useSortBy, useRowSelect } from "react-table";
import { Row, Col } from "reactstrap";
import { DefaultColumnFilter } from "./DefaultColumnFilter";

interface Props {
  columns: any;
  data: any;
  style?: CSSProperties;
  onRowClick?: (row: any) => void;
  selectedRow?: any,
  selectedRows?: any[],
  setSelectedRows?: any;
}

export const SimpleTable = ({
  columns,
  data,
  onRowClick,
  setSelectedRows,
  selectedRow,
  selectedRows,
  style = {},
}: Props) => {
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    rows
  } =
    useTable(
      {
        columns,
        data,
        autoResetSortBy: false,
        defaultColumn, // Be sure to pass the defaultColumn option
      },
      useFilters, // useFilters!
      useSortBy,
      useRowSelect
    );

  useEffect(() => {
    if (setSelectedRows)
      setSelectedRows(selectedFlatRows.map((row) => row.original));
  }, [setSelectedRows, selectedFlatRows]);

  return (
    <>
      <div
        style={{
          maxHeight: "700px",
          paddingLeft: "5px",
          paddingRight: "5px",
          ...style,
        }}
        className="table-responsive"
      >
        <table {...getTableProps()} className="table table-flush">
          <thead>
            {headerGroups.map((headerGroup) => [
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="d-flex align-items-center">
                      {column.render("Header")}
                      <span className="ml-2">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDown />
                          ) : (
                            <ArrowUp />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>,
              <tr key={"secondary-row"}>
                {headerGroup.headers.map((column, index) => (
                  <th key={"filter-" + index.toString()}>
                    <Row>
                      <Col xs="12">
                        {column.canFilter ? column.render("Filter") : null}
                      </Col>
                    </Row>
                  </th>
                ))}
              </tr>,
            ])}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  onClick={() => {
                    onRowClick && onRowClick(row.original);
                  }}
                  style={{
                    backgroundColor:
                      selectedRow && selectedRow.id === (row.original as any).id
                        ? `var(--primarybg)`
                        : "unset",
                  }}
                >

                  {row.cells.map((cell) => {
                    return (
                      <td
                        data-label={
                          typeof cell.column.Header === "string"
                            ? cell.column.Header
                            : "Seleziona"
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
