import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { ChevronsDown, Icon } from "react-feather";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { NavLink as NavLinkRRD, useLocation } from "react-router-dom";
import { ChildRoute } from "shared/types";

interface Props {
  childs: ChildRoute[];
  Icon: Icon;
  title: string;
}

export const CustomDropdownMenu = ({ childs, Icon, title }: Props) => {
  const location = useLocation();
  const isActive = useCallback(() => {
    for (let i = 0; i < childs.length; i++) {
      if (location.pathname === childs[i].path) return true;
    }
    return false;
  }, [childs, location.pathname]);

  useEffect(() => {
    setIsOpen(isActive());
  }, [location.pathname, isActive]);
  
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((old) => !old);
  return (
    <>
      <NavItem
        onClick={toggle}
        className={classNames("nav-link", { active: isActive() })}
      >
        <Icon size={24} />
        <span>{title}</span>
        <div className=" ml-auto">
          <ChevronsDown size={15} />
        </div>
      </NavItem>
      <Collapse isOpen={isOpen}>
        <ul className="collapse-menu">
          {childs.map((child, key) => {
            return child.hideHeader ? null : (
              <NavItem key={child.path}>
                <NavLink
                  to={child.path}
                  exact
                  tag={NavLinkRRD}
                  activeClassName="active"
                >
                  <span>{child.name}</span>
                </NavLink>
              </NavItem>
            );
          })}
        </ul>
      </Collapse>
    </>
  );
};
