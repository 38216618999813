import { Filter } from "components/Filter";
import { Alert, Button, Card, CardBody, Modal } from "reactstrap";
import { createFilterStructure } from "./filter";

import { Table } from "components/tables/Table";
import classNames from "classnames";

import React, { useState } from "react";
import { CheckPermissions } from "components/CheckPermissions";
import { Status, StatusFilter } from "Warehouse/types";
import { useStatusColumns, useStatusColumns2 } from "Warehouse/Status/hooks/status";
import { QrReader } from "react-qr-reader";
import { Link } from "react-router-dom";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<StatusFilter>) => void;
  onAddClick: () => void;
  states: Status[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  options: any;
}

export const StatusListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  states,
  page,
  pageCount,
  goToPage,
  options,
}: Props) => {
  const columns = useStatusColumns2(options);

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">

        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj, options)}
        />
        <Link
          style={{ color: "#7166f9", fontSize: "1.2rem" }}
          to="/"
          className="d-flex flex-row justify-content-center align-items-baseline"
        >
          <h4>Home</h4>

        </Link>

        <CheckPermissions permissions={["rosina.change_status"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            Aggiungi
          </Button>
        </CheckPermissions>
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {states.length === 0 ? (
            <Alert color="primary">Nessuno stato trovato </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={states}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
