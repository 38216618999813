import { FilterI } from "components/Filter/types";
import { TrailerFilter } from "Vehicles/types/trailer";

export const createFilterStructure = (params: TrailerFilter): FilterI[] => {
  return [
    {
      type: "text",
      name: "plate",
      label: "Targa",
      active: params?.plate !== undefined,
      value: params?.plate,
    },
    {
      type: "boolean",
      name: "hide",
      label: "Nascosto",
      active: params?.hide !== undefined,
      value: params?.hide,
    },
  ];
};
