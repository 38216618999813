import { useMutation, useQueryClient } from "react-query";
import { client } from "utils/auth-client";

export function useAddFiles(url: string, name: string, key?: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (files: File[]) => {
      const formData = new FormData();
      files.forEach((file) => formData.append(name, file));

      return client(url, {
        method: "POST",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
    }
  );
}
