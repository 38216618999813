import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const DriverForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Nome</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nome"
                        type="text"
                        register={register}
                        name="name"
                        errorMessage={errors.name?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.name}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Cognome</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Cognome"
                        type="text"
                        register={register}
                        name="surname"
                        errorMessage={errors.surname?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.surname}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Sesso</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Sesso"
                      options={options.gender.choices}
                      register={register}
                      errorMessage={errors.gender?.message}
                      name="gender"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data di nascita</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Data di nascita"
                      type="date"
                      register={register}
                      name="date_of_birth"
                      errorMessage={errors.date_of_birth?.message}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Telefono</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Telefono"
                      type="tel"
                      register={register}
                      name="phone_number"
                      errorMessage={errors.phone_number?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dispositivo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Dispositivo"
                        type="text"
                        register={register}
                        name="device"
                        errorMessage={errors.device?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.device}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Numero tessera carburante</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Numero tessera carburante"
                      type="number"
                      register={register}
                      name="fuel_card_number"
                      errorMessage={errors.fuel_card_number?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Patente</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Patente"
                      options={options.license.choices}
                      register={register}
                      errorMessage={errors.license?.message}
                      name="license"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nascondi</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nascondi"
                        type="checkbox"
                        register={register}
                        name="hide"
                        errorMessage={errors.hide?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.hide ? ("Si") : ("No")
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
