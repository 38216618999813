import { UpdateFuelConsumptionForm } from "FuelConspumption/components/UpdateFuelConsumptionForm";
import { useUpdateFuelConsumption } from "FuelConspumption/hooks/fuelConsumption";
import { FuelConsumption } from "FuelConspumption/types";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { FUEL_CONSUMPTION_API } from "utils/constants";
import { FullModal } from "components/FullModal";
import { checkPermissions } from "utils/helpers";

export const ShowFuelConsumptionModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<FuelConsumption>(
    id,
    FUEL_CONSUMPTION_API,
    "fuel-consumption"
  );
  const { mutateAsync } = useUpdateFuelConsumption();

  return data && checkPermissions(["rosina.view_fuelconsumption"]) ? (
    <FullModal
      title="Modifica consumo"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateFuelConsumptionForm driver={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
