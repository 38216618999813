import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Trailer } from "../types/trailer";
import { TrailerForm } from "./TrailerForm";
import { useOptionsTrailer } from "Vehicles/hooks/trailer";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Trailer, unknown>;
}

export const AddTrailerForm = ({ submitFunction }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
  } = useForm<Trailer>();
  const { data: options } = useOptionsTrailer();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Rimorchio aggiunto!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && (
        <TrailerForm
          options={options}
          control={control}
          register={register}
          errors={errors}
        />
      )}
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
