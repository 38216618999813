import { FilterI } from "components/Filter/types";
import { PlaceFilter } from "Warehouse/types";

export const createFilterStructure = (params: PlaceFilter): FilterI[] => {
  return [
    {
      type: "autocomplete-floor",
      name: "floor",
      label: "Piano",
      active: params?.floor !== undefined,
      value: params?.floor,
    },
  ];
};
