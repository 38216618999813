import { useLoadItem } from "shared/hooks/useLoadItem";
import { UpdateSubjectForm } from "Subjects/components/UpdateSubjectForm";
import { useUpdateSubject } from "Subjects/hooks";
import { Subject } from "Subjects/types";
import { SUBJECTS_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowSubjectModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Subject>(id, SUBJECTS_API, "subejcts");
  const { mutateAsync } = useUpdateSubject();

  return data && checkPermissions(["rosina.view_subject"]) ? (
    <FullModal
      title="Modifica soggetto"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateSubjectForm subject={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
