import { SimpleTable } from "components/tables/SimpleTable";
import { useListDriver } from "Drivers/hooks/driver";
import { Driver } from "Drivers/types/driver";
import { filter } from "lodash";
import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  selectedDriver: Driver | undefined;
  setDriver: (vehicle: Driver) => void;
}

export const ModalDriver = ({
  toggle,
  isOpen,
  selectedDriver,
  setDriver,
}: Props) => {
  const { data } = useListDriver({ no_page: true, hide: false });
  const columns = useMemo(() => {
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              onChange={() => {
                setDriver(cell.row.original);
              }}
              checked={
                selectedDriver
                  ? cell.row.original.id === selectedDriver.id
                  : false
              }
            />
          );
        },
      },
      {
        Header: "Nome",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Cognome",
        accessor: "surname",
        sortType: "basic",
      },

      {
        Header: "Telefono",
        accessor: "phone_number",
        sortType: "basic",
      },
      {
        Header: "Patente",
        accessor: "license",
        sortType: "basic",
      },
      {
        Header: "Dispositivo",
        accessor: "device",
        sortType: "basic",
      },
    ];
  }, [selectedDriver, setDriver]);
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <span className="modal-title">Seleziona autista</span>
      </ModalHeader>
      <ModalBody>
        <SimpleTable data={data} columns={columns} />
      </ModalBody>
    </Modal>
  );
};
