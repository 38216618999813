import React, { useContext, useEffect } from "react";
import { NavLink as NavLinkRRD, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import "./SidebarNav.css";
import Sidebar from "react-sidebar";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { SidebarContext } from "shared/context/sidebar";
import { X } from "react-feather";
import { LoadingScreen } from "components/LoadingScreen";
import { getAllowedRoutes } from "utils/helpers";
import { routes } from "routes";
import { SubRoute } from "shared/types";
import { CustomDropdownMenu } from "./CustomDropdownMenu";

interface Props {
  role: string;
  children: React.ReactNode;
}

export const SidebarNav = (props: Props) => {
  const { state, dispatch } = useContext(SidebarContext);
  const location = useLocation();
  const { width } = useWindowDimensions();
  const breakpoint = 800;

  useEffect(() => {
    if (width <= breakpoint) {
      dispatch({ type: "MATCHED_QUERY" });
    } else {
      dispatch({ type: "NOT_MATCHED_QUERY" });
    }
  }, [dispatch, width]);

  const createLinks = () => {
    const routesSidebar = routes.reduce((array: SubRoute[], element) => {
      return array.concat(getAllowedRoutes(element.subRoutes));
    }, []);
    return routesSidebar.map((prop, key: any) => {
      if (prop.children && prop.children.length > 0) {
        return (
          <CustomDropdownMenu
            key={key}
            Icon={prop.icon}
            childs={getAllowedRoutes(prop.children)}
            title={prop.name}
          />
        );
      }
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.path}
            exact
            tag={NavLinkRRD}
            activeClassName="active"
            isActive={() => location.pathname.includes(prop.path)}
          >
            <prop.icon />
            <span>{prop.name}</span>
          </NavLink>
        </NavItem>
      );
    });
  };

  return (
    <>
      <LoadingScreen visible={state.loading} />
      <Sidebar
        sidebar={
          <div className="pt-3 d-flex flex-column h-100">
            {state.matched ? (
              <div className="d-flex flex-row justify-content-end">
                <button
                  onClick={() => {
                    dispatch({ type: "TOGGLE_SIDEBAR" });
                  }}
                  className="btn btn-link sidebar_close_button"
                >
                  <X size={15} />
                </button>
              </div>
            ) : null}
            <h5 className="text-center pb-3 pt-3 px-3">ROSINA LOGISTICA</h5>
            <div
              className="flex-grow"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Nav vertical>{createLinks()}</Nav>
            </div>
          </div>
        }
        sidebarClassName={"sidebar  bg-white"}
        docked={state.docked}
        onSetOpen={() => {
          dispatch({ type: "OPEN_SIDEBAR" });
        }}
        rootId="the-content"
        open={state.open}
        touch={false}
        styles={{
          sidebar: {
            background: "white",
            width: "230px",
            zIndex: "1050",
            overflow: "hidden",
          },
          overlay: { zIndex: "1040" },
        }}
      >
        {props.children}
      </Sidebar>
    </>
  );
};
