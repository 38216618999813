import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { client } from "utils/auth-client";

export function useDelete(url: string, key: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) =>
      client(`${url}${id}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
        toast.success("Fatto!");
      },
      onError: () => {
        toast.error("Impossibile eliminare");
      },
    }
  );
}
