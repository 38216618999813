import { FilterI } from "components/Filter/types";
import { HistoryImportFilter } from "Import/types";

export const createFilterStructure = (
  params: HistoryImportFilter
): FilterI[] => {
  return [
    {
      type: "autocomplete-client",
      name: "client",
      label: "Cliente",
      active: params?.client !== undefined,
      value: params?.client,
    },

    {
      type: "date",
      name: "created_date",
      label: "Data di creazione dal",
      active: params?.created_date !== undefined,
      value: params?.created_date,
    },
  ];
};
