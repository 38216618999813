import { Trash2 } from "react-feather";
import { IconButtonPermission } from "./IconButtonPermission";

interface Props {
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  permissions: string[];
  className?: string;
}

export const DeleteButton = (props: Props) => {
  return (
    <IconButtonPermission
      title="Elimina"
      color="danger"
      Icon={Trash2}
      {...props}
    />
  );
};
