import { FilterI } from "components/Filter/types";
import { VehicleFilter } from "Vehicles/types/vehicle";

export const createFilterStructure = (
  params: VehicleFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "text",
      name: "plate",
      label: "Targa",
      active: params?.plate !== undefined,
      value: params?.plate,
    },
    {
      type: "options",
      name: "status",
      label: "Stato",
      options: options.status.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.status !== undefined,
      value: params?.status,
    },
    {
      type: "boolean",
      name: "hide",
      label: "Nascosto",
      active: params?.hide !== undefined,
      value: params?.hide,
    },
  ];
};
