import { ShowChargeModal } from "Billing/components/ShowChargeModal";
import { ShowInvoiceModal } from "Billing/components/ShowInvoiceModal";
import { ShowDeliveryModal } from "Distribution/components/ShowDeliveryModal";
import { ShowNoteModal } from "Distribution/components/ShowNoteModal";
import { ShowRouteModal } from "Distribution/components/ShowRouteModal";
import { ShowDriverModal } from "Drivers/components/ShowDriverModal";
import { ShowFuelConsumptionModal } from "FuelConspumption/components/ShowFuelConsumptionModal";
import { ShowFuelSiteModal } from "FuelConspumption/components/ShowFuelSiteModal";
import { ShowImportedFileModal } from "Import/components/ShowImportedFileModal";
import { ShowItemModal } from "Items/components/ShowItemModal";
import { ShowPriceModal } from "Items/components/ShowPriceModal";
import { ShowDestinyTractionPalletModal } from "PalletsManagment/components/ShowDestinyTractionPalletModal";
import { ShowPalletModal } from "PalletsManagment/components/ShowPalletModal";
import React, { createContext } from "react";
import { useState } from "react";
import { ShowSubjectModal } from "Subjects/components/ShowSubjectModal";
import { ShowOrderModal } from "Traction/components/ShowOrderModal";
import { ShowTrailerModal } from "Vehicles/components/ShowTrailerModal";
import { ShowVehicleModal } from "Vehicles/components/ShowVehicleModal";
import { ShowArticleModal } from "Warehouse/Article/components/ShowArticleModal";
import { ShowFloorModal } from "Warehouse/Floor/components/ShowFloorModal";
import { ShowPlaceModal } from "Warehouse/Place/components/ShowPlaceModal";
import { ShowProductModal } from "Warehouse/Product/components/ShowProductModal";
import { ShowProductTypeModal } from "Warehouse/ProductType/components/ShowProductTypeModal";
import { ShowStatusModal } from "Warehouse/Status/components/ShowStatusModal";

interface ModalItems {
  product_type: number | string | undefined;
  product: number | string | undefined;
  article: number | string | undefined;
  floor: number | string | undefined;
  place: number | string | undefined;
  status: number | string | undefined;
  driver: number | string | undefined;
  vehicle: number | string | undefined;
  route: number | string | undefined;
  delivery: number | string | undefined;
  subject: number | string | undefined;
  note: number | string | undefined;
  trailer: number | string | undefined;
  item: number | string | undefined;
  charge: number | string | undefined;
  invoice: number | string | undefined;
  price: number | string | undefined;
  import: number | string | undefined;
  fuelConsumption: number | string | undefined;
  fuelSite: number | string | undefined;
  pallet: number | string | undefined;
  destinyTractionPallet: number | string | undefined;
  order: number | string | undefined;
}
const defaultActiveItems: ModalItems = {
  product_type: undefined,
  product: undefined,
  article: undefined,
  floor: undefined,
  place: undefined,
  status: undefined,
  driver: undefined,
  vehicle: undefined,
  route: undefined,
  delivery: undefined,
  subject: undefined,
  note: undefined,
  trailer: undefined,
  item: undefined,
  charge: undefined,
  invoice: undefined,
  price: undefined,
  import: undefined,
  fuelConsumption: undefined,
  fuelSite: undefined,
  pallet: undefined,
  destinyTractionPallet: undefined,
  order: undefined,
};
const GlobalModalsContext = createContext<{
  setActiveItem: (
    key: keyof ModalItems,
    value: string | number | undefined
  ) => void;
}>({
  setActiveItem: () => { },
});

export const GlobalModalsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeItem, setActiveItem] = useState<ModalItems>(defaultActiveItems);
  const setItem = (
    key: keyof ModalItems,
    value: string | number | undefined
  ) => {
    setActiveItem((old) => ({ ...old, [key]: value }));
  };
  return (
    <GlobalModalsContext.Provider value={{ setActiveItem: setItem }}>
      {children}
      <ShowDeliveryModal
        setId={(value) => setItem("delivery", value)}
        id={activeItem.delivery}
      />
      <ShowDriverModal
        setId={(value) => setItem("driver", value)}
        id={activeItem.driver}
      />
      <ShowVehicleModal
        setId={(value) => setItem("vehicle", value)}
        id={activeItem.vehicle}
      />
      <ShowRouteModal
        setId={(value) => setItem("route", value)}
        id={activeItem.route}
      />
      <ShowSubjectModal
        setId={(value) => setItem("subject", value)}
        id={activeItem.subject}
      />
      <ShowNoteModal
        setId={(value) => setItem("note", value)}
        id={activeItem.note}
      />
      <ShowTrailerModal
        setId={(value) => setItem("trailer", value)}
        id={activeItem.trailer}
      />
      <ShowItemModal
        setId={(value) => setItem("item", value)}
        id={activeItem.item}
      />
      <ShowChargeModal
        setId={(value) => setItem("charge", value)}
        id={activeItem.charge}
      />
      <ShowInvoiceModal
        setId={(value) => setItem("invoice", value)}
        id={activeItem.invoice}
      />
      <ShowPriceModal
        setId={(value) => setItem("price", value)}
        id={activeItem.price}
      />
      <ShowImportedFileModal
        setId={(value) => setItem("import", value)}
        id={activeItem.import}
      />
      <ShowFuelConsumptionModal
        setId={(value) => setItem("fuelConsumption", value)}
        id={activeItem.fuelConsumption}
      />
      <ShowFuelSiteModal
        setId={(value) => setItem("fuelSite", value)}
        id={activeItem.fuelSite}
      />
      <ShowPalletModal
        setId={(value) => setItem("pallet", value)}
        id={activeItem.pallet}
      />
      <ShowDestinyTractionPalletModal
        setId={(value) => setItem("destinyTractionPallet", value)}
        id={activeItem.destinyTractionPallet}
      />
      <ShowOrderModal
        setId={(value) => setItem("order", value)}
        id={activeItem.order}
      />
      <ShowStatusModal
        setId={(value) => setItem("status", value)}
        id={activeItem.status}
      />
      <ShowPlaceModal
        setId={(value) => setItem("place", value)}
        id={activeItem.place}
      />
      <ShowFloorModal
        setId={(value) => setItem("floor", value)}
        id={activeItem.floor}
      />
      <ShowArticleModal
        setId={(value) => setItem("article", value)}
        id={activeItem.article}
      />
      <ShowProductModal
        setId={(value) => setItem("product", value)}
        id={activeItem.product}
      />
      <ShowProductTypeModal
        setId={(value) => setItem("product_type", value)}
        id={activeItem.product_type}
      />
    </GlobalModalsContext.Provider>
  );
};

export function useGlobalModals() {
  const context = React.useContext(GlobalModalsContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
