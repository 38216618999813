import { Filter } from "components/Filter";
import { Alert, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";
import { Table } from "components/tables/Table";
import { Column } from "react-table";
import { HistoryImportFilter, ImportFile } from "Import/types";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<HistoryImportFilter>) => void;
  files: ImportFile[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  columns: Column[];
}

export const ListHistoryImportPage = ({
  urlParamsObj,
  onSearch,
  files,
  page,
  pageCount,
  goToPage,
  columns,
}: Props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-start my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
      </div>
      <div className="row ">
        <div className="col-12">
          {files.length === 0 ? (
            <Alert color="primary">Nessun file trovato </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={files}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
