import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Item } from "../types/item";
import { ItemForm } from "./ItemForm";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Item>,
    unknown
  >;
  item: Item;
}

export const UpdateItemForm = ({ submitFunction, item }: Props) => {
  const { errors, register, handleSubmit, setError, control } = useForm<Item>({
    defaultValues: item,
  });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };

  const onSend = handleSubmit((data) => {
    console.log(data);
    submitFunction({ id: item.id!, newValues: data })
      .then(() => {
        toast.success("Oggetto modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_item"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      <ItemForm
        control={control}
        register={register}
        errors={errors}
        editable={!disabled}
        defaultValues={item}
      />
      <CheckPermissions permissions={["rosina.change_item"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
