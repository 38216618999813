import React from "react";
import Loader from "react-loader-spinner";

interface Props {
  visible: boolean;
}

export const LoadingScreen = ({ visible }: Props) => {
  return (
    <>
      {visible && (
        <div
          style={{
            zIndex: 100000,
            background: "#ffffffd1",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
          className="d-flex flex-row justify-content-center align-items-center h-100 animate animate__fadeIn"
        >
          <Loader
            type="MutatingDots"
            color="black"
            secondaryColor="#7166f9"
            height={100}
            width={100}
          />
        </div>
      )}
    </>
  );
};
