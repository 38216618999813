import { useQuery, UseQueryOptions } from "react-query";
import { client } from "utils/auth-client";

export function useList<T>(
  url: string,
  key: string,
  parameters?: any,
  config?: UseQueryOptions<any, unknown, T>
) {
  return useQuery(
    parameters ? [key, parameters] : [key],
    () => client(url, { method: "GET", params: parameters ? parameters : {} }),
    {
      keepPreviousData: true,
      ...config,
    }
  );
}
