import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { filterJson } from "components/tables/filters";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { PRODUCT_API } from "utils/constants";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { Product } from "../../types";

const key = "products";
const url = PRODUCT_API;

export function useOptionsProduct() {
  return useOptions(url, key);
}

export function useAddProduct() {
  return useAdd<Product>(url, key);
}

export function useDeleteProduct() {
  return useDelete(url, key);
}

export function useUpdateProduct() {
  return useUpdate<Product>(url, key);
}

export function useListProduct(
  params?: any,
  config?: UseQueryOptions<any, unknown, Product[]>
) {
  return useList(url, key, params, config);
}

export function useSearchProducts(params?: any) {
  return useSearchPaginated<Product>(url, key, params);
}

export function useProduct(
  id: string,
  config?: UseQueryOptions<any, unknown, Product>
) {
  return useGetItem<Product>(id, url, key, config);
}

export function useProductColumns(
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteProduct } = useDeleteProduct();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteProduct(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_product"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("product", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.client)
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.client.id)
                }
              >{`${cell.row.original.client.id}: ${cell.row.original.client.business_name}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Tipo prodotto",
        accessor: "product_type",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.product_type)
            return (
              <>
                {`${cell.row.original.product_type.label}`}
              </>
            );
          return "";
        },
      },
      {
        Header: "Nome",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },

    ];
  }, [deleteProduct, options, setActiveItem]);
}
