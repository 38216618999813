import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Pallet } from "../types";
import { PalletForm } from "./PalletForm";
import { MESSAGES } from "utils/constants";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Pallet, unknown>;
}

export const AddPalletForm = ({ submitFunction }: Props) => {
  const { errors, register, handleSubmit, setError } = useForm<Pallet>();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success(MESSAGES.PALLET.ADD_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <PalletForm register={register} errors={errors} />
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          {MESSAGES.GENERAL.NEW_ITEM}
        </Button>
      </div>
    </Form>
  );
};
