export const filterJson = (rows: any, id: any, filterValue: any) => {
    if (id.length > 0) {
        return rows.filter((row: any) => {
            const rowValue = JSON.stringify(row.values[id[0]]).toLowerCase()
            return rowValue.includes(filterValue.toLowerCase())
        })

    }
    else {
        return rows
    }
}