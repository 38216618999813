import classNames from "classnames";
import { FormField } from "components/forms/FormField";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const FuelSiteForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Codice</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Codice"
                        type="text"
                        register={register}
                        name="code"
                        errorMessage={errors.code?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.code}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Descrizione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Descrizione"
                        type="text"
                        register={register}
                        name="description"
                        errorMessage={errors.description?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.description}`
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
