import { useLoadItem } from "shared/hooks/useLoadItem";
import { TRAILERS_API } from "utils/constants";
import { UpdateTrailerForm } from "Vehicles/components/UpdateTrailerForm";
import { useUpdateTrailer } from "Vehicles/hooks/trailer";
import { Trailer } from "Vehicles/types/trailer";
import { FullModal } from "components/FullModal";
import { checkPermissions } from "utils/helpers";

export const ShowTrailerModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Trailer>(id, TRAILERS_API, "trailers");
  const { mutateAsync } = useUpdateTrailer();

  return data && checkPermissions(["rosina.view_trailer"]) ? (
    <FullModal
      title="Modifica rimorchio"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateTrailerForm trailer={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
