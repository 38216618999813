import React from "react";
import { useForm } from "react-hook-form";
import { LoginPayload } from "shared/types";
import { Button, Form, FormGroup, Label } from "reactstrap";

interface Props {
  submitFunction: (data: LoginPayload) => void;
}

export const LoginForm = ({ submitFunction }: Props) => {
  const { register, handleSubmit } = useForm<LoginPayload>();

  const onSend = handleSubmit(submitFunction);

  return (
    <Form onSubmit={onSend}>
      <FormGroup>
        <Label for="username">Username</Label>
        <input
          className="form-control"
          ref={register}
          type="text"
          name="username"
          required
          autoComplete="username"
        />
      </FormGroup>
      <FormGroup>
        <Label for="username">Password</Label>
        <input
          className="form-control"
          ref={register}
          type="password"
          name="password"
          autoComplete="current-password"
          required
        />
      </FormGroup>
      <Button className="w-100" type="submit" color="primary">
        Login
      </Button>
    </Form>
  );
};
