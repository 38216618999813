import { FilterI } from "components/Filter/types";
import { FuelConsumptionFilter } from "FuelConspumption/types";

export const createFilterStructure = (
  params: FuelConsumptionFilter
): FilterI[] => {
  return [
    {
      type: "date",
      name: "date_after",
      label: "Dal",
      active: params?.date_after !== undefined,
      value: params?.date_after,
    },
    {
      type: "date",
      name: "date_before",
      label: "Al",
      active: params?.date_before !== undefined,
      value: params?.date_before,
    },
    {
      type: "autocomplete-vehicle",
      name: "vehicle",
      label: "Veicolo",
      active: params?.vehicle !== undefined,
      value: params?.vehicle,
    },
    {
      type: "autocomplete-driver",
      name: "driver",
      label: "Autista",
      active: params?.driver !== undefined,
      value: params?.driver,
    },
  ];
};
