import { Note, NoteFilter } from "Distribution/types/note";
import { Filter } from "components/Filter";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";
import { Table } from "components/tables/Table";
import { Column } from "react-table";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<NoteFilter>) => void;
  onAddClick: () => void;
  notes: Note[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  columns: Column[];
}

export const NoteListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  notes,
  page,
  pageCount,
  goToPage,
  columns,
}: Props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
        <Button onClick={() => onAddClick()} size="sm" color="primary">
          Aggiungi
        </Button>
      </div>
      <div className="row ">
        <div className={"col-12"}>
          {notes.length === 0 ? (
            <Alert color="primary">Nessuna bolla trovata </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={notes}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
