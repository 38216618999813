import { useLoadItem } from "shared/hooks/useLoadItem";

import { STATUS_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { useUpdateStatus } from "../hooks/status";
import { Status } from "Warehouse/types";
import { FullModal } from "components/FullModal";
import { UpdateStatusForm } from "./UpdateStatusForm";

export const ShowStatusModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Status>(id, STATUS_API, "status");
  const { mutateAsync } = useUpdateStatus();

  return data && checkPermissions(["rosina.view_status"]) ? (
    <FullModal
      title="Modifica status"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateStatusForm status={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
