import { Filter } from "components/Filter";
import { Alert, Button, ButtonGroup, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";
import { Table } from "components/tables/Table";
import { CustomPagination } from "components/CustomPagination";
import { useState } from "react";
import { AlignJustify, Grid } from "react-feather";
import { SubjectsSimpleCard } from "../SubjectsSimpleCard";
import { Column } from "react-table";
import { Subject, SubjectFilter } from "Subjects/types";
import React from "react";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<SubjectFilter>) => void;
  subjects: Subject[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  canPrevPage: () => boolean;
  goPrevPage: () => void;
  canNextPage: () => boolean;
  goNextPage: () => void;
  columns: Column[];
  options: any;
}

export const SubjectListPage = ({
  urlParamsObj,
  onSearch,
  subjects,
  page,
  pageCount,
  goToPage,
  columns,
  canPrevPage,
  goPrevPage,
  canNextPage,
  goNextPage,
  options,
}: Props) => {
  const [typeView, setTipeView] = useState<"grid" | "table">("grid");
  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3 align-items-center">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={
            createFilterStructure(urlParamsObj, options)
          }
        />
        <div>
          <ButtonGroup size="sm">
            <Button
              size="sm"
              color="primary"
              active={typeView === "table"}
              onClick={() => setTipeView("table")}
              title="Tabella"
            >
              <AlignJustify size={13} />
            </Button>
            <Button
              size="sm"
              color="primary"
              active={typeView === "grid"}
              onClick={() => setTipeView("grid")}
              title={"Griglia"}
            >
              <Grid size={13} />
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="row ">
        {subjects.length === 0 ? (
          <div className="col-12">
            <Alert color="primary">Nessun soggetto trovato </Alert>
          </div>
        ) : typeView === "grid" ? (
          <>
            <div className="col-12">
              <div className="row">
                {subjects.map((subject: Subject) => {
                  return (
                    <div
                      key={subject.id!}
                      className="col-12 col-sm-6  col-md-4 my-2"
                    >
                      <SubjectsSimpleCard subject={subject} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-12">
              <CustomPagination
                page={page}
                pageCount={pageCount}
                canNextPage={canNextPage}
                canPrevPage={canPrevPage}
                goNextPage={goNextPage}
                goPrevPage={goPrevPage}
              />
            </div>
          </>
        ) : (
          <div className="col-12 ">
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={subjects}
                  columns={columns}
                  sortBy="surname"
                />
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};
