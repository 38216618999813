import {
  useAddFuelSite,
  useDeleteFuelSite,
  useFuelSiteColumns,
  useOptionsFuelSite,
  useSearchFuelSites,
  useUpdateFuelSite,
} from "../hooks/fuelSite";
import React from "react";
import { FullModal } from "components/FullModal";
import { AddFuelSiteForm } from "FuelConspumption/components/AddFuelSiteForm";
import { FuelSiteListPage } from "FuelConspumption/components/FuelSiteListPage";
import { UpdateFuelSiteForm } from "FuelConspumption/components/UpdateFuelSiteForm";
import { FuelSite } from "FuelConspumption/types";
import { useModalUpdate } from "shared/hooks/useModalUpdate";
import { useToggler } from "shared/hooks/useToggler";
import { MESSAGES } from "utils/constants";

export const FuelSites = () => {
  const { data, page, goToPage, pageCount } = useSearchFuelSites();
  const { mutateAsync } = useAddFuelSite();
  const { value: isOpen, toggle } = useToggler();
  const { mutate: deleteFuelSite } = useDeleteFuelSite();
  const { mutateAsync: updateFuelSite } = useUpdateFuelSite();
  const {
    activeItem: activeFuelSite,
    setActiveItem: setActiveFuelSite,
    isOpenUpdateModal,
    toggleUpdateModal,
  } = useModalUpdate<FuelSite>();

  const columns = useFuelSiteColumns(deleteFuelSite, setActiveFuelSite);
  const { data: options } = useOptionsFuelSite();
  return (
    <>
      {data && data.results && options && (
        <FuelSiteListPage
          fuelSites={data.results}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          columns={columns}
          goToPage={goToPage}
        />
      )}
      <FullModal
        title={MESSAGES.FUEL_SITE.MODAL_NEW}
        toggle={toggle}
        isOpen={isOpen}
      >
        <AddFuelSiteForm submitFunction={mutateAsync} />
      </FullModal>

      {activeFuelSite && (
        <FullModal
          title={MESSAGES.FUEL_SITE.MODAL_UPDATE}
          toggle={toggleUpdateModal}
          isOpen={isOpenUpdateModal}
        >
          <UpdateFuelSiteForm
            fuelSite={activeFuelSite}
            submitFunction={updateFuelSite}
          />
        </FullModal>
      )}
    </>
  );
};
