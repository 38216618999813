import { ChargeFilter } from "Billing/types/charge";
import { FilterI } from "components/Filter/types";

export const createFilterStructure = (
  params: ChargeFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "date",
      name: "charge_date_after",
      label: "Dal",
      active: params?.charge_date_after !== undefined,
      value: params?.charge_date_after,
    },

    {
      type: "date",
      name: "charge_date_before",
      label: "Al",
      active: params?.charge_date_before !== undefined,
      value: params?.charge_date_before,
    },
    {
      type: "options",
      name: "price__charge_type",
      label: "Tipo addebito",
      options: options.charge_type.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.price__charge_type !== undefined,
      value: params?.price__charge_type,
    },
    {
      type: "boolean",
      name: "invoiced",
      label: "Fatturati",
      active: params?.invoice !== undefined,
      value: params?.invoice,
    },
    {
      type: "autocomplete-client",
      name: "client",
      label: "Cliente",
      active: params?.client !== undefined,
      value: params?.client,
    },
    {
      type: "autocomplete-invoice",
      name: "invoice",
      label: "Fattura",
      active: params?.invoice !== undefined,
      value: params?.invoice,
    },
    {
      type: "text",
      name: "sub_client",
      label: "Subcliente",
      active: params?.sub_client !== undefined,
      value: params?.sub_client,
    },
    {
      type: "boolean",
      name: "hide",
      label: "Nascosti",
      active: params?.hide !== undefined,
      value: params?.hide,
    },
  ];
};
