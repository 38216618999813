import React, { useEffect, useState } from "react";
import { Button, FormFeedback, FormGroup, Label } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller } from "react-hook-form";
import { Plus } from "react-feather";
import { FullModal } from "components/FullModal";
import { useToggler } from "shared/hooks/useToggler";
import { AddTermsPaymentForm } from "Billing/components/AddTermsPaymentForm";
import {
  useAddTermsPayment,
  useListTermsPayment,
} from "Billing/hooks/termspayments";

interface Props {
  errorMessage?: string;
  disabled?: boolean;
  getValues?: any;
  control: any;
  name: string;
  label?: string;
  defaultValue?: any;
}

export const TermsPaymentAutocomplete = ({
  disabled,
  errorMessage,
  control,
  name,
  label,
  defaultValue,
}: Props) => {
  const [options, setOptions] = useState<any>([]);

  const { mutateAsync } = useAddTermsPayment();
  const { value: isOpen, toggle } = useToggler();
  const { refetch } = useListTermsPayment(undefined, {
    enabled: false,
    onSuccess: (data) => {
      setOptions(
        data.map((data: any) => ({
          id: data.id,
          label: `${data.terms.description} ${data.method}`,
        }))
      );
    },
  });
  useEffect(() => {
    refetch();
  }, [refetch]);
  const onSuccess = () => {
    refetch();
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ name, onChange, value }) => {
          return (
            <FormGroup>
              {label && <Label>{label}</Label>}

              <div className="d-flex flex-row">
                <div>
                  <Typeahead
                    clearButton
                    id={name.toString()}
                    onChange={(data: any) => {
                      if (data.length > 0) {
                        onChange(data[0].id);
                      } else {
                        onChange(null);
                      }
                    }}
                    options={options}
                    disabled={disabled}
                    selected={options.filter(
                      (option: any) => option.id === value
                    )}
                    placeholder="Cerca termine di pagamento"
                  />
                  {errorMessage !== "" && (
                    <FormFeedback style={{ display: "block" }}>
                      {errorMessage}
                    </FormFeedback>
                  )}
                </div>
                <div>
                  <Button
                    type="button"
                    className="edit-buttons ml-2"
                    color="success"
                  >
                    <Plus size={13} onClick={toggle} />
                  </Button>
                </div>
              </div>
            </FormGroup>
          );
        }}
      />
      <FullModal
        isOpen={isOpen}
        toggle={toggle}
        title="Aggiungi termine di pagamento"
      >
        <AddTermsPaymentForm
          onSuccess={onSuccess}
          submitFunction={mutateAsync}
        />
      </FullModal>
    </>
  );
};
