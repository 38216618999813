import { Filter } from "components/Filter";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";

import { Table } from "components/tables/Table";
import classNames from "classnames";

import { MESSAGES } from "utils/constants";
import { Column } from "react-table";
import { DestinyTractionPallet } from "PalletsManagment/types";
import { DestinyTractionFilter } from "Traction/types";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<DestinyTractionFilter>) => void;
  onAddClick: () => void;
  destinyTractionPallet: DestinyTractionPallet[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  columns: Column[];
}

export const PalletTransactionListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  destinyTractionPallet,
  page,
  pageCount,
  columns,
  goToPage,
}: Props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
        <CheckPermissions permissions={["rosina.change_destinytraction"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            {MESSAGES.GENERAL.NEW_ITEM}
          </Button>
        </CheckPermissions>
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {destinyTractionPallet.length === 0 ? (
            <Alert color="primary">{MESSAGES.GENERAL.NO_ITEMS}</Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={destinyTractionPallet}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
