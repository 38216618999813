import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Subject } from "Subjects/types";
import { SubjectTypePills } from "../SubjectTypePills";
import styles from "./SubjectsSimpleCard.module.css";

interface Props {
  subject: Subject;
}

export const SubjectsSimpleCard = ({ subject }: Props) => {
  const history = useHistory();
  return (
    <Card
      style={{ cursor: "pointer", backgroundColor: subject.main_subject_id ? "lightgray" : "" }}
      onClick={() => {
        history.push(`/resources/subjects/${subject.id}`);
      }}
      className={styles.card_subjects}
    >
      <CardBody
        className={classNames(
          "d-flex flex-row justify-content-around",
          styles.card_subjects__body
        )}
      >
        <div
          className={classNames(
            "d-flex flex-column justify-content-between",
            styles.card_subject__descriptions
          )}
        >
          <span className={styles.card_subject__business_name}>
            {subject.id}{subject.business_name} {
              subject.main_subject_id ? `(Principale: ${subject.main_subject_id})` : ""
            }
          </span>
          <span
            className={styles.card_subject__info}
          >{`${subject.address} ${subject.locality}, ${subject.province}`}</span>
          <span className={styles.card_subject__info}>
            {subject.phone_number}
          </span>
          <span className={styles.card_subject__info}>
            <SubjectTypePills types={subject.type} />
          </span>
        </div>
      </CardBody>
    </Card>
  );
};
