import { useForm } from "react-hook-form";
import { DateField, DateTimeField } from "components/forms/DateTimeField";
import { Form } from "reactstrap";
import { ImportFile } from "Import/types";
import { IconButton } from "components/buttons/IconButton";
import { Save } from "react-feather";
import { useMutation, useQueryClient } from "react-query";
import { client } from "utils/auth-client";
import { IMPORTED_FILES_API } from "utils/constants";
import { toast } from "react-toastify";
interface Props {
  file: ImportFile;
}

type Payload = {
  invoice_date: null | Date | string;
};
export const SendFileInvoiceDateButton = ({ file }: Props) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (data: Payload) =>
      client(`${IMPORTED_FILES_API}${file.id}/edit_file_invoice_date/`, {
        method: "POST",
        data: data,
      }),
    {
      onSuccess: () => {
        toast.success("Fatto");
        queryClient.refetchQueries(["history-imports"], { active: true });
      },
      onError: () => {
        toast.error("Errore");
      },
    }
  );

  const { control, handleSubmit } = useForm<Payload>({
    defaultValues: { invoice_date: file.invoice_date },
  });
  const onSend = handleSubmit((data: Payload) => {
    mutate(data);
  });

  return (
    <Form onSubmit={onSend}>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <DateField
          className="mb-0"
          control={control}
          name="invoice_date"
        />
        <div className="ml-2">
          <IconButton type="submit" Icon={Save}></IconButton>
        </div>
      </div>
    </Form>
  );
};
