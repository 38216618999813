import { SimpleTable } from "components/tables/SimpleTable";
import { useDelivery } from "Distribution/hooks/delivery";
import React, { useMemo } from "react";
import { Edit, Trash2 } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useModalUpdate } from "shared/hooks/useModalUpdate";
import { useToggler } from "shared/hooks/useToggler";
import { MESSAGES } from "utils/constants";
import { useDeleteDeliveryNoteRow } from "DeliveryNoteRows/hooks/useDeleteDeliveryNoteRow";
import { useUpdateDeliveryNoteRow } from "DeliveryNoteRows/hooks/useUpdateDeliveryNoteRows";
import { useAddDeliveryNoteRow } from "DeliveryNoteRows/hooks/useAddDeliveryNoteRow";
import { AddDeliveryNoteRowForm } from "DeliveryNoteRows/components/AddDeliveryNoteRowForm";
import { UpdateDeliveryNoteRowForm } from "DeliveryNoteRows/components/UpdateDeliveryNoteRowForm";
import { DeliveryNoteRow } from "DeliveryNoteRows/types";

interface Props {
  id_delivery: number;
}

export const DeliveryNoteRowsCard = ({ id_delivery }: Props) => {
  const { mutate: deleteDeliveryNoteRow } = useDeleteDeliveryNoteRow();
  const { value: isOpen, toggle } = useToggler();
  const { data: delivery } = useDelivery(id_delivery);
  const { mutateAsync } = useAddDeliveryNoteRow();
  const { mutateAsync: updateDeliveryNoteRow } = useUpdateDeliveryNoteRow();

  const {
    activeItem: activeDeliveryNoteRow,
    setActiveItem: setActiveDeliveryNoteRow,
    isOpenUpdateModal,
    toggleUpdateModal,
  } = useModalUpdate<DeliveryNoteRow>();
  const columns = useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm(MESSAGES.GENERAL.UPDATE_ITEM);
      if (r) deleteDeliveryNoteRow(id);
    };
    return [
      ...(delivery?.editable
        ? [
          {
            accessor: "__id",
            Header: "Modifica",
            sortType: "basic",
            Cell: (cell: any) => {
              return (
                <div className="d-flex flex-row justify-content-between">
                  <Button
                    onClick={() => {
                      onDelete(cell.row.original.id);
                    }}
                    className="edit-buttons"
                    color="danger"
                  >
                    <Trash2 size={13} />
                  </Button>
                  <Button
                    onClick={() => setActiveDeliveryNoteRow(cell.row.original)}
                    className="edit-buttons"
                    color="warning"
                  >
                    <Edit size={13} />
                  </Button>
                </div>
              );
            },
          },
        ]
        : []),
      {
        accessor: "description",
        Header: "Descrizione",
        sortType: "basic",
      },
      {
        accessor: "quantity",
        Header: "Quantità",
        sortType: "basic",
      },

    ];
  }, [deleteDeliveryNoteRow, delivery?.editable, setActiveDeliveryNoteRow]);
  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h5>Articoli</h5>
            {delivery?.editable && (
              <Button onClick={() => toggle()} color="primary">
                Aggiungi
              </Button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          {delivery && (
            <SimpleTable data={delivery.delivery_note_rows} columns={columns} />
          )}
        </CardBody>
      </Card>
      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>
          <span className="modal-title">Aggiungi riga</span>
        </ModalHeader>
        <ModalBody>
          {delivery && (
            <AddDeliveryNoteRowForm delivery={delivery} submitFunction={mutateAsync} />
          )}
        </ModalBody>
      </Modal>
      <Modal toggle={toggleUpdateModal} isOpen={isOpenUpdateModal}>
        <ModalHeader toggle={toggleUpdateModal}>
          <span className="modal-title">Modifica riga</span>
        </ModalHeader>
        <ModalBody>
          {activeDeliveryNoteRow && (
            <UpdateDeliveryNoteRowForm
              delivery_note_row={activeDeliveryNoteRow}
              submitFunction={updateDeliveryNoteRow}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
