import { CheckPermissions } from "components/CheckPermissions";
import { Tabs } from "components/Tabs";
import React, { useEffect, useState } from "react";
import { NestedValue, useFieldArray, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { SubjectForm } from "Subjects/components/SubjectForm";
import { SubjectTypePills } from "Subjects/components/SubjectTypePills";
import {
  useDeleteSubject,
  useOptionsSpecificationNotes,
  useOptionsSubject,
  useSubject,
  useUpdateSubject,
} from "Subjects/hooks";

import { SpecificationNote, Subject } from "Subjects/types";
import { addServerErrors } from "utils/helpers";
import { SubjectPrices } from "../components/SubjectPrices";

export const SubjectPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: subject } = useSubject(id);
  const { mutateAsync: updateSubject } = useUpdateSubject();
  const { mutate: deleteSubject } = useDeleteSubject();
  const history = useHistory();
  const {
    errors,
    control,
    getValues,
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
  } = useForm<
    Omit<Subject, "main_subject" | "payment_terms" | "specification_notes"> & {
      type: NestedValue<string[]>;
      specification_notes: NestedValue<SpecificationNote[]>;
    }
  >({
    defaultValues: subject,
  });
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "specification_notes",
    keyName: "key" as "id",
  });
  const { data: options } = useOptionsSubject();
  const [editing, setEditing] = useState<boolean>(false);

  const onSend = handleSubmit((data) => {
    updateSubject({ id: subject!.id!, newValues: data })
      .then(() => {
        setEditing(false);
        toast.success("Soggetto modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
        console.log(errors);
      });
  });
  const onDelete = (id: number) => {
    var r = window.confirm("Rimuovere elemento?");
    if (r) {
      deleteSubject(id);
      history.push("/subjects");
    }
  };

  const [tabs, setTabs] = useState<{
    titles: string[];
    tabs: React.ReactElement[];
  }>({ titles: [], tabs: [] });

  useEffect(() => {
    reset(subject);
  }, [reset, subject]);

  useEffect(() => {
    const titles: string[] = [];
    const tabs: React.ReactElement[] = [];
    if (subject && subject.type.includes("cliente")) {
      titles.push("Prezzi");
      tabs.push(<SubjectPrices key="prices" clientId={subject.id!} />);
    }
    setTabs({ titles: titles, tabs: tabs });
  }, [subject]);
  const { data: optionsNotes } = useOptionsSpecificationNotes();

  return (
    <>
      {subject && (
        <div className="row">
          <div className="col-12 mb-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Breadcrumb>
                <BreadcrumbItem tag={Link} to="/subjects">
                  Anagrafica
                </BreadcrumbItem>
                <BreadcrumbItem active>{subject!.business_name}</BreadcrumbItem>
              </Breadcrumb>
              <CheckPermissions permissions={["rosina.change_subject"]}>
                <div>
                  <ButtonGroup size="sm">
                    {editing ? (
                      <>
                        <Button
                          color="danger"
                          onClick={() => setEditing(false)}
                          className=""
                        >
                          Annulla
                        </Button>
                        <Button color="success" onClick={onSend}>
                          Salva
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            onDelete(subject!.id!);
                          }}
                          color="primary"
                          size="sm"
                        >
                          Elimina
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => setEditing(true)}
                          className=""
                        >
                          Modifica
                        </Button>
                      </>
                    )}
                  </ButtonGroup>
                </div>
              </CheckPermissions>
            </div>
          </div>

          <div className="col-12">
            <Card className="pb-4">
              <CardHeader>
                <h5>{subject.business_name}</h5>
                <span>
                  <SubjectTypePills types={subject.type} />
                </span>
              </CardHeader>
              <CardBody>
                {options && optionsNotes && (
                  <SubjectForm
                    optionNotes={optionsNotes}
                    fields={fields}
                    remove={remove}
                    prepend={prepend}
                    editable={editing}
                    control={control}
                    options={options}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    defaultValues={subject}
                  />
                )}
              </CardBody>
            </Card>
          </div>
          {tabs.titles.length > 0 && tabs.tabs.length > 0 && (
            <div className="col-12 mt-4">
              <Tabs titles={tabs.titles}>{tabs.tabs}</Tabs>
            </div>
          )}
        </div>
      )}
    </>
  );
};
