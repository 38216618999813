import { SimpleTable } from "components/tables/SimpleTable";
import { useDelivery } from "Distribution/hooks/delivery";
import { useAddPackage } from "Packages/hooks/useAddPackage";
import { useDeletePackage } from "Packages/hooks/useDeletePackage";
import { useUpdatePackage } from "Packages/hooks/useUpdatePackages";
import { Package } from "Packages/types";
import React, { useMemo } from "react";
import { Edit, Trash2 } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useModalUpdate } from "shared/hooks/useModalUpdate";
import { useToggler } from "shared/hooks/useToggler";
import { MESSAGES } from "utils/constants";
import { AddPackageForm } from "../../Packages/components/AddPackageForm";
import { UpdatePackageForm } from "../../Packages/components/UpdatePackageForm";

interface Props {
  id_delivery: number;
}

export const PackagesCard = ({ id_delivery }: Props) => {
  const { mutate: deletePackage } = useDeletePackage();
  const { value: isOpen, toggle } = useToggler();
  const { data: delivery } = useDelivery(id_delivery);
  const { mutateAsync } = useAddPackage();
  const { mutateAsync: updatePackage } = useUpdatePackage();

  const {
    activeItem: activePackage,
    setActiveItem: setActivePackage,
    isOpenUpdateModal,
    toggleUpdateModal,
  } = useModalUpdate<Package>();
  const columns = useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm(MESSAGES.PACKAGES.EDIT_MESSAGE);
      if (r) deletePackage(id);
    };
    return [
      ...(delivery?.editable
        ? [
          {
            accessor: "__id",
            Header: "Modifica",
            sortType: "basic",
            Cell: (cell: any) => {
              return (
                <div className="d-flex flex-row justify-content-between">
                  <Button
                    onClick={() => {
                      onDelete(cell.row.original.id);
                    }}
                    className="edit-buttons"
                    color="danger"
                  >
                    <Trash2 size={13} />
                  </Button>
                  <Button
                    onClick={() => setActivePackage(cell.row.original)}
                    className="edit-buttons"
                    color="warning"
                  >
                    <Edit size={13} />
                  </Button>
                </div>
              );
            },
          },
        ]
        : []),
      {
        accessor: "items_number",
        Header: "Numero articoli",
        sortType: "basic",
      },
      {
        accessor: "quantity",
        Header: "Quantità",
        sortType: "basic",
      },
      {
        accessor: "price_amount",
        Header: "Prezzo",
        sortType: "basic",
        Cell: (cell: any) => {
          return `${cell.row.original.price.price}`;
        },
      },
      {
        accessor: "quantity_type",
        Header: "Prezzo",
        sortType: "basic",
        Cell: (cell: any) => {
          return `${cell.row.original.price.quantity_type}`;
        },
      },
      {
        accessor: "charge_type",
        Header: "Tipo addebito",
        sortType: "basic",
        Cell: (cell: any) => {
          return `${cell.row.original.price.charge_type}`;
        },
      },
    ];
  }, [deletePackage, delivery?.editable, setActivePackage]);
  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h5>Articoli</h5>
            {delivery?.editable && (
              <Button onClick={() => toggle()} color="primary">
                Aggiungi
              </Button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          {delivery && (
            <SimpleTable data={delivery.packages} columns={columns} />
          )}
        </CardBody>
      </Card>
      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>
          <span className="modal-title">Aggiungi oggetto</span>
        </ModalHeader>
        <ModalBody>
          {delivery && (
            <AddPackageForm delivery={delivery} submitFunction={mutateAsync} />
          )}
        </ModalBody>
      </Modal>
      <Modal toggle={toggleUpdateModal} isOpen={isOpenUpdateModal}>
        <ModalHeader toggle={toggleUpdateModal}>
          <span className="modal-title">Modifica oggetto</span>
        </ModalHeader>
        <ModalBody>
          {activePackage && (
            <UpdatePackageForm
              package_item={activePackage}
              submitFunction={updatePackage}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
