import React from "react";
import { Icon } from "react-feather";

interface Props {
  number: string | number;
  title: string;
  icon: Icon;
  color?: string;
}
export const StatsCard = (props: Props) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span className="mr-3">
            <props.icon size={40} color={props.color ? props.color : undefined} />
          </span>
          <div className="d-flex flex-column text-right">
            <span
              style={{
                fontSize: "2rem",
                fontWeight: 600,
              }}
            >
              {props.number}
            </span>
            <span style={{ color: "grey" }}>{props.title}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
