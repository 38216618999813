import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { Tabs } from "components/Tabs";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { DateTimeField } from "components/forms/DateTimeField";
import { Package } from "react-feather";
import { PackagesCard } from "./PackagesCard";
import { DeliveryNoteRowsCard } from "./DeliveryNoteRowsCard";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const DeliveryForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Numero documento</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Numero documento"
                        type="text"
                        register={register}
                        name="document_number"
                        errorMessage={errors.document_number?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.document_number}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data documento</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Data documento"
                        type="date"
                        register={register}
                        name="document_date"
                        errorMessage={errors.document_date?.message}
                      />
                    ) : defaultValues.document_date && !editable ? (
                      defaultValues.document_date
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Primo riferimento missione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Primo riferimento missione"
                        type="text"
                        register={register}
                        name="first_delivery_reference"
                        errorMessage={errors.first_delivery_reference?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.first_delivery_reference}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Secondo riferimento missione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Secondo riferimento missione"
                        type="text"
                        register={register}
                        name="second_delivery_reference"
                        errorMessage={errors.second_delivery_reference?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.second_delivery_reference}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Riferimento protocollo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Riferimento protocollo"
                        type="text"
                        register={register}
                        name="protocol_reference"
                        errorMessage={errors.protocol_reference?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.protocol_reference}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Indirizzo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Indirizzo"
                        type="text"
                        register={register}
                        name="address"
                        errorMessage={errors.address?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.address}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>CAP</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="CAP"
                        type="text"
                        register={register}
                        name="postal_code"
                        errorMessage={errors.postal_code?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.postal_code}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Località</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Località"
                        type="text"
                        register={register}
                        name="locality"
                        errorMessage={errors.locality?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.locality}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Provincia</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Provincia"
                        type="text"
                        register={register}
                        name="province"
                        errorMessage={errors.province?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.province}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Peso</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Peso"
                        type="number"
                        register={register}
                        name="weight"
                        errorMessage={errors.weight?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.weight}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Volume</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Volume"
                        type="number"
                        register={register}
                        name="volume"
                        errorMessage={errors.volume?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.volume}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Numero oggetti</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Numero oggetti"
                        type="number"
                        register={register}
                        name="packages_number"
                        errorMessage={errors.packages_number?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.packages_number}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Sequenza</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Sequenza"
                        type="number"
                        register={register}
                        name="sequence"
                        errorMessage={errors.sequence?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.sequence}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Stato</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Stato"
                      options={options.status.choices}
                      register={register}
                      errorMessage={errors.status?.message}
                      name="status"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Tipo missione</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Tipo missione"
                      options={options.type.choices}
                      register={register}
                      errorMessage={errors.type?.message}
                      name="type"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data fatturazione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Data fatturazione"
                        type="date"
                        register={register}
                        name="invoice_date"
                        errorMessage={errors.invoice_date?.message}
                      />
                    ) : defaultValues.invoice_date && !editable ? (
                      defaultValues.invoice_date
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Cliente</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.CLIENT}
                      control={control}
                      defaultValue={
                        defaultValues?.client_id
                          ? defaultValues?.client_id
                          : null
                      }
                      errorMessage={errors.client_id?.message}
                      name="client_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Sub cliente</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Sub cliente"
                        type="text"
                        register={register}
                        name="sub_client"
                        errorMessage={errors.sub_client?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.sub_client}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Destinatario</b>
                  </td>
                  <td>
                    <Autocomplete
                      errorMessage={errors.receiver_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.SUBJECT}
                      control={control}
                      defaultValue={
                        defaultValues?.receiver_id
                          ? defaultValues?.receiver_id
                          : null
                      }
                      name="receiver_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Giro</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      errorMessage={errors.route_id?.message}
                      url={AUTOCOMPLETE_URLS.ROUTE}
                      control={control}
                      defaultValue={
                        defaultValues?.route_id ? defaultValues?.route_id : null
                      }
                      name="route_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nascondi</b>
                  </td>
                  <td>
                    <input
                      ref={register}
                      disabled={!editable}
                      type="checkbox"
                      name="hide"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Forza visualizzazione/Anticipa missione</b>
                  </td>
                  <td>
                    <input
                      ref={register}
                      disabled={!editable}
                      type="checkbox"
                      name="force"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bolla inviata</b>
                  </td>
                  <td>
                    <DateTimeField
                      disabled={!editable}
                      control={control}
                      name="bill_sent"
                      errorMessage={errors.bill_sent?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data evasione</b>
                  </td>
                  <td>
                    <DateTimeField
                      disabled={!editable}
                      control={control}
                      name="evasion_date"
                      errorMessage={errors.evasion_date?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data missione</b>
                  </td>
                  <td>
                    <DateTimeField
                      disabled={!editable}
                      control={control}
                      name="delivery_date"
                      errorMessage={errors.delivery_date?.message}
                    />
                  </td>
                </tr>
                {(!defaultValues || !defaultValues.id) && (
                  <tr>
                    <td>
                      <b>
                        Aggiungi come consegna (seleziona articolo consegna)
                      </b>
                    </td>
                    <td>
                      <Autocomplete
                        disabled={!editable}
                        url={AUTOCOMPLETE_URLS.ITEM}
                        control={control}
                        name="item_of_delivery"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note", "Servizi collegati", "Righe bolla"]}>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Servizi collegati</b>
                      </td>
                      <td>
                        {defaultValues && defaultValues.id ? (
                          <PackagesCard id_delivery={defaultValues.id} />
                        ) : (null)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Righe bolle</b>
                      </td>
                      <td>
                        {defaultValues && defaultValues.id ? (
                          <DeliveryNoteRowsCard id_delivery={defaultValues.id} />
                        ) : (null)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
