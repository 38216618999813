import { CheckPermissions } from "components/CheckPermissions";
import { Icon } from "react-feather";
import { IconButton } from "./IconButton";

interface Props {
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  permissions: string[];
  Icon: Icon;
  color?: string;
  title?: string;
  className?: string;
}
export const IconButtonPermission = ({
  onClick,
  permissions,
  Icon,
  color,
  title,
  className,
}: Props) => {
  return (
    <CheckPermissions permissions={permissions}>
      <IconButton
        className={className}
        Icon={Icon}
        onClick={onClick}
        color={color ? color : "primary"}
        title={title}
      />
    </CheckPermissions>
  );
};
