import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { useAddArticle, useOptionsArticle, useSearchArticles } from "../hooks/article";
import { ArticleFilter } from "Warehouse/types";
import { AddArticleForm } from "../components/AddArticleForm";
import { ArticleListPage } from "../components/ArticleListPage/ArticleListPage";

export const Article = () => {
  const { paramsObj, onSearch } = useFilter<ArticleFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchArticles(paramsObj);
  const { mutateAsync } = useAddArticle();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<ArticleFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsArticle();
  return (
    <>
      {data && data.results && options && (
        <ArticleListPage
          options={options}
          articles={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi articolo" toggle={toggle} isOpen={isOpen}>
        <AddArticleForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
