import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { DownloadFile } from "components/DownloadFile";
import {
  DestinyTractionPallet,
  DestinyTractionPalletSubject,
} from "PalletsManagment/types";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { DESTINY_TRACTION_PALLET_API } from "utils/constants";
import { getDisplayValue } from "utils/helpers";

const key = "destiny-traction-pallets";
const url = DESTINY_TRACTION_PALLET_API;

export function useOptionsDestinyTractionPallet() {
  return useOptions(url, key);
}

export function useAddDestinyTractionPallet() {
  return useAdd<DestinyTractionPallet>(url, key);
}

export function useDeleteDestinyTractionPallet() {
  return useDelete(url, key);
}

export function useUpdateDestinyTractionPallet() {
  return useUpdate<DestinyTractionPallet>(url, key);
}

export function useListDestinyTractionPallet(
  params?: any,
  config?: UseQueryOptions<any, unknown, DestinyTractionPallet[]>
) {
  return useList(url, key, params, config);
}

export function useSearchDestinyTractionPallets(params?: any) {
  return useSearchPaginated<DestinyTractionPallet>(url, key, params);
}

export function useDestinyTractionPallet(
  id: string | number,
  config?: UseQueryOptions<any, unknown, DestinyTractionPallet>
) {
  return useGetItem<DestinyTractionPallet>(id, url, key, config);
}

export function useDestinyTractionPalletColumns(options: any) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteDestinyTractionPallet } =
    useDeleteDestinyTractionPallet();
  return useMemo(() => {
    const onDelete = (id: number) => {
      const r = window.confirm("Rimuovere elemento?");
      if (r) deleteDestinyTractionPallet(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_destinytraction"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() =>
                  setActiveItem("destinyTractionPallet", cell.row.original.id)
                }
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "Tipo",
        accessor: "type",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("type", cell.value, options),
      },
      {
        Header: "Soggetto",
        accessor: "subject",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.row.original.subject ? (
            <Button
              title={"Mostra soggetto"}
              color="link"
              onClick={() =>
                setActiveItem("subject", cell.row.original.subject.id)
              }
            >{`${cell.row.original.subject.id}: ${cell.row.original.subject.business_name}`}</Button>
          ) : null,
      },
      {
        Header: "Tipo pallet",
        accessor: "pallet_type",
        sortType: "basic",
        Cell: (cell: any) => (cell.value ? cell.value.code : ""),
      },
      {
        Header: "Buono",
        accessor: "voucher",
        sortType: "basic",
      },
      {
        Header: "Resi",
        accessor: "returned",
        sortType: "basic",
      },
      {
        Header: "Giacenza",
        accessor: "stock",
        sortType: "basic",
      },
      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
      {
        Header: "Immagine",
        accessor: "image",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.value ? (
            <DownloadFile
              title={cell.value}
              urlDownload={`${DESTINY_TRACTION_PALLET_API}${cell.row.original.id}/download/`}
            />
          ) : (
            ""
          ),
      },
    ];
  }, [deleteDestinyTractionPallet, options, setActiveItem]);
}

export function useDestinyTractionPalletSubjectColumns() {
  return useMemo(() => {
    return [
      {
        Header: "Soggetto",
        accessor: "subject",
        sortType: "basic",
        Cell: (cell: any) =>
          `${cell.row.original.subject_id}: ${cell.row.original.subject__business_name}`,
      },

      {
        Header: "Totale",
        accessor: "total",
        sortType: "basic",
      },
    ];
  }, []);
}

export function useListDestinyTractionPalletSubject(
  params?: any,
  config?: UseQueryOptions<any, unknown, DestinyTractionPalletSubject[]>
) {
  return useList(`${url}subject_report/`, `${key}-subjects`, params, config);
}
