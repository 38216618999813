import React, { useMemo } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { DrawControl } from "../../components/DrawControls";
import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { SelectField } from "components/forms/SelectField";
import { TermsPaymentAutocomplete } from "components/autocompletes/TermsPaymentAutocomplete";
import { Tabs } from "components/Tabs";
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { ArrayField } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { BooleanField } from "components/forms/BooleanField";

interface Props {
  options: any;
  optionNotes: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  setValue: any;
  watch: any;
  getValues?: any;
  fields: Partial<ArrayField<Record<string, any>, "id">>[];
  remove: (index?: number | number[] | undefined) => void;
  prepend: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined
  ) => void;
}

export const SubjectForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  setValue,
  watch,
  getValues,
  fields,
  prepend,
  optionNotes,
  remove,
}: Props) => {
  const onMarkerUpdate = (latLng: { lat: number; lng: number }) => {
    setValue("latitude", latLng.lat);
    setValue("longitude", latLng.lng);
  };

  const drawOptions = useMemo<L.Control.DrawOptions>(() => {
    return {
      polygon: false,
      polyline: false,
      circle: false,
      rectangle: false,
      circlemarker: false,
      marker: false,
    };
  }, []);

  const latitude = watch("latitude");
  const longitude = watch("longitude");

  const defaultMarker = useMemo<{ lat: number; lng: number }>(() => {
    return getValues && latitude && longitude
      ? {
        lat: latitude,
        lng: longitude,
      }
      : {
        lat: 41.2925,
        lng: 12.5736,
      };
  }, [getValues, latitude, longitude]);

  const editOptions = useMemo<{}>(() => {
    return {};
  }, []);
  const type = watch("type");
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-row justify-content-end mb-3">
            <ButtonGroup size="sm">
              <Button
                disabled={!editable}
                color="primary"
                size="sm"
                onClick={() => {
                  const values = getValues();
                  if (
                    values.locality &&
                    values.province &&
                    values.address &&
                    values.postal_code
                  ) {
                    const url = "https://nominatim.openstreetmap.org/search";
                    axios({
                      method: "GET",
                      url: url,
                      params: {
                        street: values.address,
                        city: values.locality,
                        postalcode: values.postal_code,
                        county: values.province,
                        format: "jsonv2",
                      },
                    })
                      .then((response) => {
                        const data = response.data;
                        if (data.length > 0) {
                          setValue("latitude", data[0].lat);
                          setValue("longitude", data[0].lon);
                          toast.success("Fatto!");
                        } else {
                          toast.warning("Nessun risultato trovato!");
                        }
                      })
                      .catch(() => toast.error("Errore!"));
                  } else {
                    toast.error(
                      "Inserire indirizzo, localita, cap e provincia, prima di calcolare le coordinate"
                    );
                  }
                }}
              >
                Calcola coordinate
              </Button>
              <Button
                color="primary"
                disabled={!editable}
                size="sm"
                onClick={() => {
                  const values = getValues();
                  console.log(values);
                  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    (
                      values.address +
                      " " +
                      values.locality +
                      " " +
                      values.postal_code +
                      " " +
                      values.province
                    ).replaceAll("null", "")
                  )}`;

                  window.open(url, "_blank");
                }}
                aria-label="Apri google maps"
              >
                Gmaps
              </Button>
            </ButtonGroup>
          </div>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Ragione sociale</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Ragione sociale"
                        type="text"
                        register={register}
                        name="business_name"
                        errorMessage={errors.business_name?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.business_name
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Indirizzo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Indirizzo"
                        type="text"
                        register={register}
                        name="address"
                        errorMessage={errors.address?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.address
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>CAP</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="CAP"
                        type="text"
                        register={register}
                        name="postal_code"
                        errorMessage={errors.postal_code?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.postal_code
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Località</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Località"
                        type="text"
                        register={register}
                        name="locality"
                        errorMessage={errors.locality?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.locality
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Provincia</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Provincia"
                        type="text"
                        register={register}
                        name="province"
                        errorMessage={errors.province?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.province
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nazione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nazione"
                        type="text"
                        register={register}
                        name="nation"
                        errorMessage={errors.nation?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.nation
                    ) : null}
                  </td>
                </tr>
                {editable && (
                  <tr>
                    <td>
                      <b></b>
                    </td>
                    <td>
                      {editable ? (
                        <FormGroup>
                          <Input
                            onChange={(event) => {
                              const value = event.target.value;
                              const coords = value
                                .replaceAll(" ", "")
                                .split(",");
                              if (value.includes(",") && coords.length === 2) {
                                console.log(coords);
                                setValue("latitude", coords[0]);
                                setValue("longitude", coords[1]);
                              }
                            }}
                            placeholder={"Incolla coordinate da gmaps"}
                          />
                        </FormGroup>
                      ) : defaultValues && !editable ? (
                        defaultValues.latitude
                      ) : null}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>Latitudine</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Latitudine"
                        type="number"
                        register={register}
                        name="latitude"
                        errorMessage={errors.latitude?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.latitude
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Longitudine</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Longitudine"
                        type="number"
                        register={register}
                        name="longitude"
                        errorMessage={errors.longitude?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.longitude
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Altitudine</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Altitudine"
                        type="number"
                        register={register}
                        name="altitude"
                        errorMessage={errors.altitude?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.altitude
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nascondi</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nascondi"
                        type="checkbox"
                        register={register}
                        name="hide"
                        errorMessage={errors.hide?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.hide ? ("Si") : ("No")
                    ) : null}

                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>P.Iva</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="P.Iva"
                        type="text"
                        register={register}
                        name="vat_number"
                        errorMessage={errors.vat_number?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.vat_number
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Cod. fiscale</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Cod. fiscale"
                        type="text"
                        register={register}
                        name="fiscal_code"
                        errorMessage={errors.fiscal_code?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.fiscal_code
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Telefono</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        disabled={!editable}
                        placeholder="Telefono"
                        type="tel"
                        register={register}
                        name="phone_number"
                        errorMessage={errors.phone_number?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.phone_number
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Fax</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        disabled={!editable}
                        placeholder="Fax"
                        type="tel"
                        register={register}
                        name="fax"
                        errorMessage={errors.fax?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.fax
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Email</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        disabled={!editable}
                        placeholder="Email"
                        type="email"
                        register={register}
                        name="email"
                        errorMessage={errors.email?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.email
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Cod. sdi</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        disabled={!editable}
                        placeholder="Cod. sdi"
                        type="text"
                        register={register}
                        name="cod_sdi"
                        errorMessage={errors.cod_sdi?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.cod_sdi
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Riferimento esterno</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        disabled={!editable}
                        placeholder="Riferimento esterno"
                        type="text"
                        register={register}
                        name="external_reference"
                        errorMessage={errors.external_reference?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.external_reference
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Ragione sociale stampa</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        disabled={!editable}
                        placeholder="Ragione sociale stampa"
                        type="text"
                        register={register}
                        name="business_name_print"
                        errorMessage={errors.business_name_print?.message}
                      />
                    ) : defaultValues && !editable ? (
                      defaultValues.business_name_print
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Soggetto principale</b>
                  </td>
                  <td>
                    {
                      //TODO:da cambiare url}
                    }
                    <Autocomplete
                      errorMessage={errors.main_subject_id?.message}
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.SUBJECT}
                      control={control}
                      defaultValue={
                        defaultValues?.main_subject_id
                          ? defaultValues?.main_subject_id
                          : null
                      }
                      name="main_subject_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Tipo"
                      multiple
                      options={options.type.child.choices}
                      register={register}
                      errorMessage={errors.type?.message}
                      name="type"
                    />
                  </td>
                </tr>
                {type && type.includes("cliente") && (
                  <tr>
                    <td>
                      <b>Termine di pagamento</b>
                    </td>
                    <td>
                      <TermsPaymentAutocomplete
                        defaultValue={
                          defaultValues && defaultValues.payment_terms_id
                            ? defaultValues.payment_terms_id
                            : null
                        }
                        getValues={getValues}
                        control={control}
                        name="payment_terms_id"
                        errorMessage={errors.payment_terms_id?.message}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note fisse capitolato"]}>
              <div>
                <div className="d-flex flex-row justify-content-end">
                  {editable && (
                    <Button
                      size="sm"
                      color="primary"
                      className="mb-3"
                      onClick={() =>
                        prepend({
                          subject_id: null,
                          vehicle_trailer_hooking_id: null,
                          vehicle_trailer_release_id: null,
                          trailer_id: null,
                        })
                      }
                    >
                      Aggiungi
                    </Button>
                  )}
                </div>
                <ListGroup>
                  {fields.map((item, index) => {
                    return (
                      <ListGroupItem key={item.key}>
                        <input
                          defaultValue={item.id}
                          type="hidden"
                          ref={register()}
                          name={`specification_notes[${index}].id`}
                        />
                        <div className="d-flex flex-column flex-lg-row justify-content-between">
                          <table className={classNames("table_col", "mb-2")}>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Cliente</b>
                                </td>
                                <td>
                                  <Autocomplete
                                    defaultValue={
                                      item.client ? item.client : null
                                    }
                                    disabled={!editable}
                                    name={`specification_notes[${index}].client`}
                                    url={AUTOCOMPLETE_URLS.CLIENT}
                                    control={control}
                                    errorMessage={
                                      errors.specification_notes?.[index]
                                        ?.client?.message
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Tipo</b>
                                </td>
                                <td>
                                  <SelectField
                                    defaultValue={item.type}
                                    register={register()}
                                    disabled={!editable}
                                    name={`specification_notes[${index}].type`}
                                    options={optionNotes.type.choices}
                                    errorMessage={
                                      errors.specification_notes?.[index]?.type
                                        ?.message
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Note</b>
                                </td>
                                <td>
                                  <FormField
                                    placeholder="Note"
                                    type="textarea"
                                    register={register()}
                                    disabled={!editable}
                                    defaultValue={item.notes}
                                    name={`specification_notes[${index}].notes`}
                                    errorMessage={
                                      errors.specification_notes?.[index]?.notes
                                        ?.message
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                          <Button
                            type="button"
                            size="sm"
                            color="danger"
                            onClick={() => remove(index)}
                          >
                            Rimuovi
                          </Button>
                        </div>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </div>
            </Tabs>
          </div>
        </>
      )}
      <div className="row mt-3">
        <div className="col-12">
          <div className="d-flex flex-column">
            <MapContainer
              center={[41.2925, 12.5736]}
              zoom={5}
              style={{ height: "300px" }}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <DrawControl
                drawOptions={drawOptions}
                defaultMarker={defaultMarker}
                onMarkerUpdate={onMarkerUpdate}
                edit={editOptions}
              />
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  );
};
