import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";

import { FuelSiteForm } from "./FuelSiteForm";
import { useOptionsFuelSite } from "FuelConspumption/hooks/fuelSite";
import { FuelSite } from "FuelConspumption/types";
import { MESSAGES } from "utils/constants";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<FuelSite>,
    unknown
  >;
  fuelSite: FuelSite;
}

export const UpdateFuelSiteForm = ({ submitFunction, fuelSite }: Props) => {
  const { control, errors, register, handleSubmit, setError } =
    useForm<FuelSite>({
      defaultValues: fuelSite,
    });
  const { data: options } = useOptionsFuelSite();
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onSend = handleSubmit((data) => {
    submitFunction({ id: fuelSite.id!, newValues: data })
      .then(() => {
        toast.success("Autista modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_fuelsite"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            {MESSAGES.GENERAL.UPDATE_ITEM}
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <FuelSiteForm
          editable={!disabled}
          defaultValues={fuelSite}
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      <CheckPermissions permissions={["rosina.change_fuelsite"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
