import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Note } from "../types/note";
import { useOptionsNote } from "../hooks/note";
import { NoteForm } from "./NoteForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Note, unknown>;
}

export const AddNoteForm = ({ submitFunction }: Props) => {
  const { watch, errors, register, handleSubmit, setError, control } = useForm<
    Omit<Note, "delivery">
  >({
    defaultValues: { delivery_id: null },
  });
  const { data: options } = useOptionsNote();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Bolla aggiunta!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && (
        <NoteForm
          register={register}
          options={options}
          control={control}
          errors={errors}
          watch={watch}
        />
      )}
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
