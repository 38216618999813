import Checkbox from "react-three-state-checkbox";


export function getSelectableColumn() {

    return {
        id: "_selector",
        disableResizing: true,
        disableGroupBy: true,
        minWidth: 45,
        width: 45,
        maxWidth: 45,
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
            </>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: any) => (
            <Checkbox {...row.getToggleRowSelectedProps()} />
        ),
    }
}