import { useMutation, useQueryClient } from "react-query";
import { client } from "utils/auth-client";
import { PACKAGES_API } from "utils/constants";
import { Package } from "../types";

export function useAddPackage() {
  const queryClient = useQueryClient();

  return useMutation(
    (package_item: Package) =>
      client(PACKAGES_API, {
        method: "POST",
        data: package_item,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["deliveries"], { active: true });
      },
    }
  );
}
