import { useMemo } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { Segment } from "Traction/types";
import { getDisplayDate } from "utils/helpers";
import { Filter } from "components/Filter";
import { Table } from "components/tables/Table";
import { DeleteButton } from "components/buttons/DeleteButton";
import { useDeleteSegment } from "Traction/hooks/segments";
import { filterJson } from "components/tables/filters";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  selectedSegments: Segment[];
  setSelectedSegments: (segment: Segment) => void;
  data: any;
  onSearch: (data: any) => void;
  paramsObj: any;
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
}

export const ModalSegments = ({
  toggle,
  isOpen,
  selectedSegments,
  setSelectedSegments,
  data,
  onSearch,
  paramsObj,
  page,
  goToPage,
  pageCount,
}: Props) => {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteDelivery } = useDeleteSegment();

  const columns = useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteDelivery(id);
    };
    return [
      {
        accessor: "__id",
        Header: "",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <>
              <input
                type="checkbox"
                onChange={() => {
                  setSelectedSegments(cell.row.original);
                }}
                checked={
                  !!selectedSegments.find(
                    (order) => order.id === cell.row.original.id
                  )
                }
              />
              <DeleteButton
                className="ml-2"
                permissions={["rosina.change_segment"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
            </>
          );
        },
      },
      {
        Header: "N. ordine",
        accessor: "order",
        sortType: "basic",
      },

      {
        accessor: "starting_point",
        Header: "Punto di partenza",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.starting_point) {
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.starting_point.id)
                }
              >{`${cell.row.original.starting_point.id}: ${cell.row.original.starting_point.business_name}`}</Button>
            );
          }
          return null;
        },
        filter: filterJson
      },
      {
        accessor: "arrival_point",
        Header: "Punto di arrivo",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.starting_point) {
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.arrival_point.id)
                }
              >{`${cell.row.original.arrival_point.id}: ${cell.row.original.arrival_point.business_name}`}</Button>
            );
          }
          return null;
        },
        filter: filterJson
      },
      {
        Header: "Autista",
        accessor: "driver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.driver)
            return `${cell.row.original.driver.name} ${cell.row.original.driver.surname}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Veicolo",
        accessor: "vehicle",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.vehicle)
            return `${cell.row.original.vehicle.plate} ${cell.row.original.vehicle.description}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Rimorchio",
        accessor: "trailer",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.trailer)
            return `${cell.row.original.trailer.plate} ${cell.row.original.trailer.description}`;
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Data car/scar",
        accessor: "date",
        sortType: "basic",
        Cell: (cell: any) => {
          return getDisplayDate(cell.value);
        },
        filter: filterJson
      },
      {
        Header: "Data ordine",
        accessor: "order__date",
        sortType: "basic",
        Cell: (cell: any) => {
          return getDisplayDate(cell.value);
        },
        filter: filterJson
      },
    ];
  }, [deleteDelivery, selectedSegments, setActiveItem, setSelectedSegments]);
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <span className="modal-title">Seleziona tratte</span>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-row justify-content-between my-3">
          <Filter
            paramsObj={paramsObj}
            onFilterChange={onSearch}
            filterStructure={[
              {
                type: "autocomplete-order",
                name: "order",
                label: "Ordine",
                active: paramsObj?.order !== undefined,
                value: paramsObj?.order,
              },
              {
                type: "date",
                name: "date_after",
                label: "Dal",
                active: paramsObj?.date_after !== undefined,
                value: paramsObj?.date_after,
              },

              {
                type: "date",
                name: "date_before",
                label: "Al",
                active: paramsObj?.date_before !== undefined,
                value: paramsObj?.date_before,
              },
              {
                type: "date",
                name: "order__date_after",
                label: "Data ordine dal",
                active: paramsObj?.order__date_after !== undefined,
                value: paramsObj?.order__date_after,
              },

              {
                type: "date",
                name: "order__date_before",
                label: "Data ordine al",
                active: paramsObj?.order__date_before !== undefined,
                value: paramsObj?.order__date_before,
              },
            ]}
          />
        </div>
        <Table
          pageIndex={page}
          pageCount={pageCount}
          setPage={goToPage}
          data={data}
          columns={columns}
          sortBy="date"
        />
      </ModalBody>
    </Modal>
  );
};
