import React from "react";
import Loader from "react-loader-spinner";

export const LoadingSpinner = () => {
  return (
    <div className="d-flex justify-content-center">
      <Loader
        type="MutatingDots"
        color="black"
        secondaryColor="#7166f9"
        height={100}
        width={100}
      />
    </div>
  );
};
