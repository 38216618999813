import { FilterI } from "components/Filter/types";

export const createFilterStructure = (params: any): FilterI[] => {
  return [
    {
      type: "autocomplete-order",
      name: "order",
      label: "Ordine",
      active: params?.order !== undefined,
      value: params?.order,
    },
    {
      type: "autocomplete-driver",
      name: "driver",
      label: "Autista",
      active: params?.driver !== undefined,
      value: params?.driver,
    },
    {
      type: "autocomplete-vehicle",
      name: "vehicle",
      label: "Veicolo",
      active: params?.vehicle !== undefined,
      value: params?.vehicle,
    },
    {
      type: "autocomplete-trailer",
      name: "trailer",
      label: "Rimorchio",
      active: params?.vehicle !== undefined,
      value: params?.vehicle,
    },
    {
      type: "date",
      name: "order__date_after",
      label: "Data ordine dal",
      active: params?.order__date_after !== undefined,
      value: params?.order__date_after,
    },

    {
      type: "date",
      name: "order__date_before",
      label: "Data ordine al",
      active: params?.order__date_before !== undefined,
      value: params?.order__date_before,
    },
  ];
};
