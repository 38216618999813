import { filterJson } from "components/tables/filters";
import React, { useMemo } from "react";
import { Eye } from "react-feather";
import { UseQueryOptions } from "react-query";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { SPECIFICATION_NOTE_API, SUBJECTS_API } from "utils/constants";
import { Subject } from "./types";

const key = "subjects";
const url = SUBJECTS_API;

export function useOptionsSubject() {
  return useOptions(url, key);
}
export function useOptionsSpecificationNotes() {
  return useOptions(SPECIFICATION_NOTE_API, "specification-notes");
}
export function useAddSubject() {
  return useAdd<Subject>(url, key);
}

export function useDeleteSubject() {
  return useDelete(url, key);
}

export function useUpdateSubject() {
  return useUpdate<Subject>(url, key);
}

export function useListSubject(
  params?: any,
  config?: UseQueryOptions<any, unknown, Subject[]>
) {
  return useList(url, key, params, config);
}

export function useSearchSubject(params?: any) {
  return useSearchPaginated<Subject>(url, key, params);
}

export function useSubject(
  id: string,
  config?: UseQueryOptions<any, unknown, Subject>
) {
  return useGetItem<Subject>(id, url, key, config);
}

export function useSubjectColumns() {
  return useMemo(() => {
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <Button
                to={`/resources/subjects/${cell.row.original.id}`}
                tag={Link}
                className="edit-buttons"
                color="primary"
              >
                <Eye size={13} />
              </Button>
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "Nome",
        accessor: "first_name",
        sortType: "basic",
      },
      {
        Header: "Cognome",
        accessor: "surname",
        sortType: "basic",
      },
      {
        Header: "Principale",
        accessor: "is_main",
        sortType: "basic",
      },
      {
        Header: "Tipo",
        accessor: "type",
        sortType: "basic",
      },
      {
        Header: "Ragione sociale",
        accessor: "business_name",
        sortType: "basic",
      },
      {
        Header: "Indirizzo",
        accessor: "address",
        sortType: "basic",
      },
      {
        Header: "Località",
        accessor: "locality",
        sortType: "basic",
      },
      {
        Header: "Provincia",
        accessor: "province",
        sortType: "basic",
      },
      {
        Header: "Codice postale",
        accessor: "postal_code",
        sortType: "basic",
      },
      {
        Header: "Latitudine",
        accessor: "latitude",
        sortType: "basic",
      },
      {
        Header: "Longitudine",
        accessor: "longitude",
        sortType: "basic",
      },
      {
        Header: "P.iva",
        accessor: "vat_number",
        sortType: "basic",
      },
      {
        Header: "Telefono",
        accessor: "phone_number",
        sortType: "basic",
      },
      {
        Header: "Fax",
        accessor: "fax",
        sortType: "basic",
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
      },
      {
        Header: "Pec",
        accessor: "pec",
        sortType: "basic",
      },
      {
        Header: "Riferimento esterno",
        accessor: "external_reference",
        sortType: "basic",
      },
      {
        Header: "Soggetto principale",
        accessor: "main_subject",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.main_subject)

            return `${cell.row.original.main_subject.id}: ${cell.row.original.main_subject.business_name}`;
          return "";
        },
        filter: filterJson
      },
    ];
  }, []);
}
