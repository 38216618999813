import { FilterI } from "components/Filter/types";
import { RouteFilter } from "Distribution/types/route";

export const createFilterStructure = (
  params: RouteFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "number",
      name: "id",
      label: "ID",
      active: params?.id !== undefined,
      value: params?.id,
    },
    {
      type: "options",
      name: "status",
      label: "Stato",
      options: options.status.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.status !== undefined,
      value: params?.status,
    },
    {
      type: "autocomplete-driver",
      name: "driver",
      label: "Autista",
      active: params?.driver !== undefined,
      value: params?.driver,
    },
    {
      type: "autocomplete-vehicle",
      name: "vehicle",
      label: "Veicolo",
      active: params?.vehicle !== undefined,
      value: params?.vehicle,
    },

    {
      type: "date",
      name: "planned_date",
      label: "Data pianificata",
      active: params?.planned_date !== undefined,
      value: params?.planned_date,
    },
  ];
};
