import { Filter } from "components/Filter";
import { Table } from "components/tables/Table";
import { createFilterStructure } from "Distribution/components/RouteListPage/filter";
import { UpdateRouteForm } from "Distribution/components/UpdateRouteForm";
import {
  useOptionsRoute,
  useSearchRoutes,
  useUpdateRoute,
} from "Distribution/hooks/route";
import { Route, RouteFilter } from "Distribution/types/route";
import React, { useMemo } from "react";
import { Edit } from "react-feather";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useFilter } from "shared/hooks/useFilter";
import { useModalUpdate } from "shared/hooks/useModalUpdate";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  routes: Route[];
  setRoute: (route: Route) => void;
}

export const ModalRoute = ({ toggle, isOpen, routes, setRoute }: Props) => {
  const { paramsObj, onSearch } = useFilter<RouteFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchRoutes(paramsObj);
  const {
    activeItem: activeRoute,
    setActiveItem: setActiveRoute,
    isOpenUpdateModal,
    toggleUpdateModal,
  } = useModalUpdate<Route>();
  const { mutateAsync: updateRoute } = useUpdateRoute();
  const columns = useMemo(() => {
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-between">
              <input
                type="checkbox"
                onChange={() => {
                  setRoute(cell.row.original);
                }}
                checked={
                  !!routes.find((item) => item.id === cell.row.original.id)
                }
              />
              <Button
                onClick={() => setActiveRoute(cell.row.original)}
                className="edit-buttons"
                color="warning"
              >
                <Edit size={13} />
              </Button>
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
      {
        Header: "Stato",
        accessor: "status",
        sortType: "basic",
      },
      {
        Header: "Origine",
        accessor: "origin",
        sortType: "basic",
      },
      {
        Header: "Ordine del giro",
        accessor: "round_order",
        sortType: "basic",
      },
      {
        Header: "Dispositivo",
        accessor: "device",
        sortType: "basic",
      },
      {
        Header: "Distanza totale",
        accessor: "total_distance",
        sortType: "basic",
      },
      {
        Header: "Data pianificata",
        accessor: "planned_date",
        sortType: "basic",
      },
      {
        Header: "Inviato",
        accessor: "sent",
        sortType: "basic",
      },

      {
        Header: "Autista",
        accessor: "driver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.driver)
            return `${cell.row.original.driver.name} ${cell.row.original.driver.surname}`;
          return "";
        },
      },
      {
        Header: "Veicolo",
        accessor: "vehicle",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.vehicle)
            return `${cell.row.original.vehicle.plate} ${cell.row.original.vehicle.description}`;
          return "";
        },
      },
    ];
  }, [routes, setActiveRoute, setRoute]);
  const { data: options } = useOptionsRoute();

  const searchItems = (data: Partial<RouteFilter>) => {
    onSearch(data);
    search(data);
  };
  return (
    <>
      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>
          <span className="modal-title">Seleziona automezzo</span>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row justify-content-between my-3">
            {options && (
              <Filter
                paramsObj={paramsObj}
                onFilterChange={searchItems}
                filterStructure={createFilterStructure(paramsObj, options)}
              />
            )}
          </div>
          <div className="row ">
            <div className={"col-12"}>
              {data && data.results.length === 0 ? (
                <Alert color="primary">Nessun giro trovato </Alert>
              ) : (
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={data?.results}
                  columns={columns}
                  sortBy="id"
                />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal toggle={toggleUpdateModal} isOpen={isOpenUpdateModal}>
        <ModalHeader toggle={toggleUpdateModal}>
          <span className="modal-title">Modifica giro</span>
        </ModalHeader>
        <ModalBody>
          {activeRoute && (
            <UpdateRouteForm route={activeRoute} submitFunction={updateRoute} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
