import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import {
  setFormValues,
  setNumberOfPages,
  setPage,
  useSearchPaginationReducer,
} from "shared/reducers/searchReducer";
import { client } from "utils/auth-client";
import { getPageCount } from "utils/helpers";

export function useSearchPaginated<T>(
  url: string,
  key: string,
  searchParameters?: any,
  config?: UseQueryOptions<
    any,
    unknown,
    {
      results: T[];
      count: number;
    } & any
  >
) {
  const [stateSearchPagination, dispatch] =
    useSearchPaginationReducer<Partial<T>>();
  const searchParams = useMemo(() => {
    return searchParameters
      ? { ...searchParameters, page: stateSearchPagination.page }
      : {
          ...stateSearchPagination.searchValues,
          page: stateSearchPagination.page,
        };
  }, [
    searchParameters,
    stateSearchPagination.page,
    stateSearchPagination.searchValues,
  ]);
  const data = useQuery(
    [key, searchParams],
    () => client(url, { method: "GET", params: searchParams }),
    {
      initialData: { results: [] as T[], count: 0 },
      keepPreviousData: true,
      onSettled: (data) => {
        setPagesLength(data ? data.count : 0);
      },
      ...config,
    }
  );
  const setPagesLength = (numResults: number) => {
    dispatch(setNumberOfPages(getPageCount(numResults)));
  };
  const goNextPage = () => {
    dispatch(setPage(stateSearchPagination.page + 1));
  };
  const goPrevPage = () => {
    dispatch(setPage(stateSearchPagination.page - 1));
  };

  const canNextPage = () => {
    return stateSearchPagination.page + 1 <= stateSearchPagination.pageCount;
  };

  const canPrevPage = () => {
    return stateSearchPagination.page - 1 > 0;
  };
  const search = (data: any) => {
    dispatch(setFormValues(data));
  };
  const goToPage = (page: number) => {
    dispatch(setPage(page));
  };
  const { page, pageCount } = stateSearchPagination;
  return {
    ...data,
    search,
    goToPage,
    page,
    pageCount,
    goNextPage,
    goPrevPage,
    canNextPage,
    canPrevPage,
  };
}
