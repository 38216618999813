import classNames from "classnames";
import { FormField } from "components/forms/FormField";

interface Props {
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const ItemForm = ({
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      <>
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <table className={classNames("table_col")}>
            <tbody>
              <tr>
                <td>
                  <b>Descrizione</b>
                </td>
                <td>
                  {editable ? (
                    <FormField
                      disabled={!editable}
                      placeholder="Descrizione"
                      type="text"
                      register={register}
                      name="description"
                      errorMessage={errors.description?.message}
                    />
                  ) : defaultValues && !editable ? (
                    `${defaultValues.description}`
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Codice</b>
                </td>
                <td>
                  {editable ? (
                    <FormField
                      placeholder="Codice"
                      type="text"
                      register={register}
                      name="code"
                      errorMessage={errors.code?.message}
                    />
                  ) : defaultValues && !editable ? (
                    `${defaultValues.code}`
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </>
  );
};
