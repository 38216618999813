function showHide(b: boolean) {

    let d = document.getElementById("loading-div");
    if (d) {
        if (b === true) {
            d.style.visibility = "visible";
        }
        else {
            d.style.visibility = "hidden";
        }
    }
}

export function showLoading() {
    showHide(true)
}

export function hideLoading() {
    showHide(false)
}