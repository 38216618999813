import { Route, RouteFilter } from "Distribution/types/route";
import { Filter } from "components/Filter";
import { UseMutateAsyncFunction } from "react-query";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";
import { Table } from "components/tables/Table";
import { useRouteColumns } from "Distribution/hooks/route";
import classNames from "classnames";
import { CheckPermissions } from "components/CheckPermissions";
import { toast } from "react-toastify";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<RouteFilter>) => void;
  onAddClick: () => void;
  routes: Route[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  options: any;
  setSelectedRows: (routes: Route[]) => void;
  selectedRows: Route[];
  sendRoutesTrackMe: UseMutateAsyncFunction<
    any,
    unknown,
    {
      ids: number[];
    },
    unknown
  >;
}

export const RouteListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  routes,
  page,
  pageCount,
  goToPage,
  options,
  setSelectedRows,
  selectedRows,
  sendRoutesTrackMe,
}: Props) => {
  const columns = useRouteColumns(options);

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj, options)}
        />
        <div className="d-flex flex-row align-items-center">
          <CheckPermissions permissions={["rosina.change_route"]}>
            <Button onClick={() => onAddClick()} size="sm" color="primary">
              Aggiungi
            </Button>
          </CheckPermissions>
          <CheckPermissions permissions={["rosina.change_route"]}>
            <Button
              disabled={selectedRows.length <= 0}
              onClick={() =>
                sendRoutesTrackMe({
                  ids: selectedRows.map((route) => route.id!),
                }).then((result: any) => {
                  if (result.done === true) {
                    toast.success("Fatto!");
                  } else {
                    toast.error("Errore!");
                  }
                })
              }
              size="sm"
              color="primary"
              className="ml-2"
            >
              Invia a TrackMe
            </Button>
          </CheckPermissions>
        </div>
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {routes.length === 0 ? (
            <Alert color="primary">Nessun giro trovato </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  setSelectedRows={setSelectedRows}
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={routes}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
