import { FilterI } from "components/Filter/types";
import { DeliveryFilter } from "Distribution/types/delivery";

export const createFilterStructure = (
  params: DeliveryFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "date",
      name: "document_date",
      label: "Data documento",
      active: params?.document_date !== undefined,
      value: params?.document_date,
    },
    {
      type: "date",
      name: "delivery_date",
      label: "Data missione",
      active: params?.delivery_date !== undefined,
      value: params?.delivery_date,
    },
    {
      type: "date",
      name: "created_date",
      label: "Data creazione",
      active: params?.created_date !== undefined,
      value: params?.created_date,
    },
    {
      type: "date",
      name: "invoice_date",
      label: "Data fatturazione",
      active: params?.invoice_date !== undefined,
      value: params?.invoice_date,
    },
    {
      type: "text",
      name: "id",
      label: "ID",
      active: params?.id !== undefined,
      value: params?.id,
    },
    {
      type: "text",
      name: "document_number",
      label: "Numero documento",
      active: params?.document_number !== undefined,
      value: params?.document_number,
    },
    {
      type: "autocomplete-imported-files",
      name: "import_file",
      label: "File ID",
      active: params?.import_file !== undefined,
      value: params?.import_file,
    },
    {
      type: "autocomplete-route",
      name: "route",
      label: "Giro ID",
      active: params?.route !== undefined,
      value: params?.route,
    },

    {
      type: "autocomplete-subject",
      name: "receiver",
      label: "Destinatario",
      active: params?.receiver !== undefined,
      value: params?.receiver,
    },
    {
      type: "autocomplete-client",
      name: "client",
      label: "Cliente",
      active: params?.client !== undefined,
      value: params?.client,
    },
    {
      type: "boolean",
      name: "bill_sent",
      label: "Bolla inviata",
      active: params?.bill_sent !== undefined,
      value: params?.bill_sent,
    },
    {
      type: "boolean",
      name: "no_route",
      label: "Senza giro",
      active: params?.no_route !== undefined,
      value: params?.no_route,
    },
    {
      type: "options",
      name: "type",
      label: "Tipo",
      options: options.type.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.type !== undefined,
      value: params?.type,
    },
  ];
};
