import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { TermsDescription } from "Billing/types/termsdescription";
import { TermsDescriptionForm } from "./TermsDescriptionForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    TermsDescription,
    unknown
  >;
  onSuccess?: () => void;
}

export const AddTermsDescriptionForm = ({
  submitFunction,
  onSuccess,
}: Props) => {
  const { errors, register, handleSubmit, setError, control } = useForm<
    Omit<TermsDescription, "terms">
  >();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Descrizione condizione di pagamento aggiunta!");
        onSuccess && onSuccess();
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form
      onSubmit={(e) => {
        e?.preventDefault();
        onSend();
        e?.stopPropagation();
      }}
    >
      <TermsDescriptionForm
        options={{}}
        register={register}
        errors={errors}
        control={control}
      />
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
