import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { Header } from "components/headers/Header";
import { useAddFloor, useOptionsFloor, useSearchFloors } from "../hooks/floor";
import { FloorFilter } from "Warehouse/types";
import { FloorListPage } from "../components/FloorListPage";
import { AddFloorForm } from "../components/AddFloorForm";

export const Floors = () => {
  const { paramsObj, onSearch } = useFilter<FloorFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchFloors(paramsObj);
  const { mutateAsync } = useAddFloor();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<FloorFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsFloor();
  return (
    <>
      {data && data.results && options && (
        <FloorListPage
          options={options}
          floors={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi piano" toggle={toggle} isOpen={isOpen}>
        <AddFloorForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
