import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { PRODUCT_TYPE_API } from "utils/constants";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { ProductType } from "../../types";

const key = "product_types";
const url = PRODUCT_TYPE_API;

export function useOptionsProductType() {
  return useOptions(url, key);
}

export function useAddProductType() {
  return useAdd<ProductType>(url, key);
}

export function useDeleteProductType() {
  return useDelete(url, key);
}

export function useUpdateProductType() {
  return useUpdate<ProductType>(url, key);
}

export function useListProductType(
  params?: any,
  config?: UseQueryOptions<any, unknown, ProductType[]>
) {
  return useList(url, key, params, config);
}

export function useSearchProductTypes(params?: any) {
  return useSearchPaginated<ProductType>(url, key, params);
}

export function useProductType(
  id: string,
  config?: UseQueryOptions<any, unknown, ProductType>
) {
  return useGetItem<ProductType>(id, url, key, config);
}

export function useProductTypeColumns(
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteProductType } = useDeleteProductType();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteProductType(id);
    };
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_producttype"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("product_type", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Nome",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },

    ];
  }, [deleteProductType, options, setActiveItem]);
}
