import { useFilter } from "shared/hooks/useFilter";
import { SubjectListPage } from "Subjects/components/SubjectListPage";
import {
  useOptionsSubject,
  useSearchSubject,
  useSubjectColumns,
} from "Subjects/hooks";
import { SubjectFilter } from "Subjects/types";

export const SubjectsPage = () => {
  const { paramsObj, onSearch } = useFilter<SubjectFilter>();
  const {
    data,
    page,
    pageCount,
    search,
    goNextPage,
    goPrevPage,
    canNextPage,
    canPrevPage,
    goToPage,
  } = useSearchSubject(paramsObj);

  const searchItems = (data: Partial<SubjectFilter>) => {
    onSearch(data);
    search(data);
  };

  const columns = useSubjectColumns();
  const { data: options } = useOptionsSubject();
  return (
    <>
      {data && data.results && options && (
        <SubjectListPage
          options={options}
          columns={columns}
          onSearch={searchItems}
          page={page}
          pageCount={pageCount}
          goNextPage={goNextPage}
          goPrevPage={goPrevPage}
          urlParamsObj={paramsObj}
          subjects={data.results}
          canPrevPage={canPrevPage}
          canNextPage={canNextPage}
          goToPage={goToPage}
        />
      )}
    </>
  );
};
