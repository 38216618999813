import { FilterI } from "components/Filter/types";
import { NoteFilter } from "Distribution/types/note";

export const createFilterStructure = (params: NoteFilter): FilterI[] => {
  return [
    {
      type: "date",
      name: "created_date",
      label: "Data creazione dal",
      active: params?.created_date !== undefined,
      value: params?.created_date,
    },
    {
      type: "text",
      name: "id",
      label: "ID",
      active: params?.id !== undefined,
      value: params?.id,
    },

    {
      type: "autocomplete-delivery",
      name: "delivery",
      label: "Missione",
      active: params?.delivery !== undefined,
      value: params?.delivery,
    },
    {
      type: "boolean",
      name: "sent",
      label: "Inviata",
      active: params?.sent !== undefined,
      value: params?.sent,
    },
  ];
};
