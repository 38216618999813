import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { NOTES_API } from "utils/constants";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { Note } from "Distribution/types/note";
import { DownloadFile } from "components/DownloadFile";
import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";

const key = "notes";
const url = NOTES_API;

export function useOptionsNote() {
  return useOptions(url, key);
}

export function useAddNote() {
  return useAdd<Note>(url, key);
}

export function useDeleteNote() {
  return useDelete(url, key);
}

export function useUpdateNote() {
  return useUpdate<Note>(url, key);
}

export function useListNote(
  params?: any,
  config?: UseQueryOptions<any, unknown, Note[]>
) {
  return useList(url, key, params, config);
}

export function useSearchNotes(params: any) {
  return useSearchPaginated<Note>(url, key, params);
}

export function useNote(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Note>
) {
  return useGetItem<Note>(id, url, key, config);
}

export function useNoteColumns(options: any) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteNote } = useDeleteNote();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteNote(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_note"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("note", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        accessor: "delivery",
        Header: "Missione",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.value)
            return (
              <Button
                title={"Mostra missione"}
                color="link"
                onClick={() => setActiveItem("delivery", cell.value.id)}
              >{`${cell.value.id}`}</Button>
            );
          return "";
        },
      },
      {
        Header: "Fianle",
        accessor: "final",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("final", cell.value, options),
      },
      {
        Header: "Inviata",
        accessor: "sent",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        accessor: "file",
        Header: "File",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.value ? (
            <DownloadFile
              title={cell.value}
              urlDownload={`${NOTES_API}${cell.row.original.id}/download/`}
            />
          ) : (
            ""
          ),
      },
      {
        accessor: "notes",
        Header: "Note",
        sortType: "basic",
      },
    ];
  }, [deleteNote, setActiveItem]);
}
