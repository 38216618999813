import { NestedValue, useFieldArray, useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { OrderForm } from "./OrderForm";
import { DestinyTraction, Order } from "Traction/types";
import { UpdatePayload } from "shared/types";
import { useOptionsDestinyTraction } from "Traction/hooks/destinyTraction";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";
import { useState } from "react";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Order>,
    unknown
  >;
  order: Order;
}

export const UpdateOrderForm = ({ submitFunction, order }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
    getValues,
    watch,
  } = useForm<
    Omit<Order, "client" | "destinies"> & {
      destinies: NestedValue<DestinyTraction[]>;
    }
  >({
    defaultValues: { ...order, fill: order.fill.toString() },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "destinies",
    keyName: "key" as "id",
  });
  const { data: options } = useOptionsDestinyTraction();

  const onSend = handleSubmit((data) => {
    if (data.destinies[0].type === "S") {
      toast.error("La prima missione deve contentere un carico!");
    } else {
      if (
        data.destinies.length > 1 &&
        data.destinies.every((v, i, a) => {
          return !i || new Date(a[i - 1].date!) > new Date(v.date!);
        })
      ) {
        toast.error("La missioni devono essere ordinate temporalmente");
      } else {
        submitFunction({ id: order.id!, newValues: { ...data } })
          .then(() => {
            toast.success("Ordine modificato!");
          })
          .catch((errors) => {
            const data = errors.response.data;
            addServerErrors(data, setError);
          });
      }
    }
  });
  const [disabled, setDisabled] = useState(true);

  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_delivery"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <OrderForm
          watch={watch}
          options={options}
          append={append}
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          fields={fields}
          remove={remove}
          defaultValues={order}
          editable={!disabled}
        />
      )}

      <CheckPermissions permissions={["rosina.change_order"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success">
            Salva
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
