import { LoadingScreen } from "components/LoadingScreen";
import React, { createContext } from "react";
import { useState } from "react";

const IsLoadingContext = createContext<{
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isLoading: false,
  setIsLoading: () => {},
});
export const IsLoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <IsLoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <LoadingScreen visible={isLoading} />

      {children}
    </IsLoadingContext.Provider>
  );
};

export function useIsLoading() {
  const context = React.useContext(IsLoadingContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
