import { FilterI } from "components/Filter/types";
import { ArticleFilter } from "Warehouse/types";

export const createFilterStructure = (params: ArticleFilter): FilterI[] => {
  return [
    {
      type: "autocomplete-product",
      name: "product",
      label: "Prodotto",
      active: params?.product !== undefined,
      value: params?.product,
    },
    {
      type: "text",
      name: "product_code",
      label: "Codice prodotto",
      active: params?.product_code !== undefined,
      value: params?.product_code,
    },
    {
      type: "text",
      name: "note",
      label: "Note",
      active: params?.note !== undefined,
      value: params?.note,
    },
  ];
};
