import React, { memo } from "react";
import { Switch, Route } from "react-router-dom";
import { RouteLayout } from "shared/types";
import { getAllowedRoutes } from "utils/helpers";
import MapRoutes from "./MapRoutes";

function MapLayoutRoutes({ routes }: { routes: RouteLayout[] }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        const { layout: Layout, subRoutes } = route;
        const allowedRoutes = getAllowedRoutes(subRoutes);
        const pathArray = allowedRoutes.map((route: any) => route.path);
        return (
          <Route key={i} path={pathArray}>
            {Layout ? (
              <Layout>
                <MapRoutes routes={allowedRoutes} />
              </Layout>
            ) : (
              <MapRoutes routes={allowedRoutes} />
            )}
          </Route>
        );
      })}
    </Switch>
  );
}

export default memo(MapLayoutRoutes);
