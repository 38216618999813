import { DeleteButton } from "components/buttons/DeleteButton";
import { DownloadFile } from "components/DownloadFile";
import { FuelConsumptionFile } from "FuelConspumption/types";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { FUEL_CONSUMPTION_FILES_API } from "utils/constants";
import { getDisplayDate } from "utils/helpers";

export const key = "fuel-consumption-files";
const url = FUEL_CONSUMPTION_FILES_API;

export function useOptionsFuelConsumptionFile() {
  return useOptions(url, key);
}

export function useAddFuelConsumptionFile() {
  return useAdd<FuelConsumptionFile>(url, key, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function useDeleteFuelConsumptionFile() {
  return useDelete(url, key);
}

export function useUpdateFuelConsumptionFile() {
  return useUpdate<FuelConsumptionFile>(url, key);
}

export function useListFuelConsumptionFile(
  params?: any,
  config?: UseQueryOptions<any, unknown, FuelConsumptionFile[]>
) {
  return useList(url, key, params, config);
}

export function useSearchFuelConsumptionFiles(params?: any) {
  return useSearchPaginated<FuelConsumptionFile>(url, key, params);
}

export function useFuelConsumptionFile(
  id: string,
  config?: UseQueryOptions<any, unknown, FuelConsumptionFile>
) {
  return useGetItem<FuelConsumptionFile>(id, url, key, config);
}

export function useFuelConsumptionFileColumns(
  deleteFuelConsumptionFile: (id: number) => void
) {
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteFuelConsumptionFile(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_fuelconsumptionfile"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
      },
      {
        Header: "File",
        accessor: "file",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <DownloadFile
              title={cell.value}
              urlDownload={`${FUEL_CONSUMPTION_FILES_API}${cell.row.original.id}/download/`}
            />
          );
        },
      },
      {
        Header: "Data di export",
        accessor: "export_date",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        Header: "Data di creazione",
        accessor: "created_date",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
    ];
  }, [deleteFuelConsumptionFile]);
}
