import { SubjectNotFound } from "Import/types";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { client } from "utils/auth-client";
import { ASSIGN_MISSING_SUBJECT_API } from "utils/constants";

export function useAssignMissingSubject() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: {
      not_found_subject: string | number;
      subject_id: string | number;
    }) =>
      client(ASSIGN_MISSING_SUBJECT_API, {
        method: "POST",
        data: data,
      }),
    {
      onSuccess: (data) => {
        console.log(data);
        toast.success("Fatto!");
        queryClient.setQueryData<SubjectNotFound[]>(
          "missing-subjects",
          (old) => {
            if (old)
              return [
                ...old.filter((missing_subject) => {
                  return (
                    missing_subject.not_found_subject.delivery__address !==
                      data.address &&
                    missing_subject.not_found_subject.business_name !==
                      data.business_name &&
                    missing_subject.not_found_subject.delivery__locality !==
                      data.locality
                  );
                }),
              ];
            return [];
          }
        );
      },
      onError: () => {
        toast.error("Errore!");
      },
    }
  );
}
