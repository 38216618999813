import { InvoiceListingFilter } from "Billing/types/invoice";
import { FilterI } from "components/Filter/types";

export const createFilterStructure = (
  params: InvoiceListingFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "date",
      name: "invoice_date_after",
      label: "Dal",
      active: params?.invoice_date_after !== undefined,
      value: params?.invoice_date_after,
    },

    {
      type: "date",
      name: "invoice_date_before",
      label: "Al",
      active: params?.invoice_date_before !== undefined,
      value: params?.invoice_date_before,
    },
    {
      type: "options",
      name: "invoice_status",
      label: "Tipo addebito",
      options: options.invoice_status.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.invoice_status !== undefined,
      value: params?.invoice_status,
    },

    {
      type: "autocomplete-subject",
      name: "subject",
      label: "Soggetto",
      active: params?.subject !== undefined,
      value: params?.subject,
    },
  ];
};
