import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { AddFuelConsumptionFileForm } from "FuelConspumption/components/AddFuelConsumptionFileForm";
import {
  useSearchFuelConsumptionFiles,
  useDeleteFuelConsumptionFile,
  useOptionsFuelConsumptionFile,
  useFuelConsumptionFileColumns,
  key,
} from "FuelConspumption/hooks/fuelConsumptionFile";
import { FuelConsumptionFileFilter } from "FuelConspumption/types";
import { FuelConsumptionFileListPage } from "FuelConspumption/components/FuelConsumptionFileListPage";
import { useAddFiles } from "Import/hooks/useAddFiles";
import { FUEL_CONSUMPTION_FILES_API } from "utils/constants";

export const FuelConsumptionFiles = () => {
  const { paramsObj, onSearch } = useFilter<FuelConsumptionFileFilter>();
  const {
    data,
    search,
    page,
    goToPage,
    pageCount,
  } = useSearchFuelConsumptionFiles(paramsObj);
  const { mutateAsync } = useAddFiles(FUEL_CONSUMPTION_FILES_API, "file", key);
  const { value: isOpen, toggle } = useToggler();
  const { mutate: deleteFuelConsumptionFile } = useDeleteFuelConsumptionFile();

  const columns = useFuelConsumptionFileColumns(deleteFuelConsumptionFile);
  const searchItems = (data: Partial<FuelConsumptionFileFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsFuelConsumptionFile();
  return (
    <>
      {data && data.results && options && (
        <FuelConsumptionFileListPage
          fuelConsumptionFiles={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          columns={columns}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi autista" toggle={toggle} isOpen={isOpen}>
        <AddFuelConsumptionFileForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
