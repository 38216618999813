import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { AddFuelConsumptionForm } from "FuelConspumption/components/AddFuelConsumptionForm";
import { FuelConsumptionListPage } from "FuelConspumption/components/FuelConsumptionListPage";
import {
  useSearchFuelConsumptions,
  useAddFuelConsumption,
  useFuelConsumptionColumns,
} from "FuelConspumption/hooks/fuelConsumption";
import { FuelConsumptionFilter } from "FuelConspumption/types";
import { MESSAGES } from "utils/constants";

export const FuelConsumptions = () => {
  const { paramsObj, onSearch } = useFilter<FuelConsumptionFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchFuelConsumptions(paramsObj);
  const { mutateAsync } = useAddFuelConsumption();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<FuelConsumptionFilter>) => {
    onSearch(data);
    search(data);
  };
  const columns = useFuelConsumptionColumns();
  return (
    <>
      {data && data.results && (
        <FuelConsumptionListPage
          fuelConsumptions={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          columns={columns}
          goToPage={goToPage}
        />
      )}
      <FullModal
        title={MESSAGES.FUEL_CONSUMPTION.MODAL_NEW}
        toggle={toggle}
        isOpen={isOpen}
      >
        <AddFuelConsumptionForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
