import { useAddInvoice, useOptionsInvoice } from "Billing/hooks/invoices";
import { Charge } from "Billing/types/charge";
import { Invoice } from "Billing/types/invoice";
import { NestedValue, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, Form } from "reactstrap";
import { addServerErrors } from "utils/helpers";
import { InvoiceForm } from "./InvoiceForm";

interface Props {
  invoice: Partial<Invoice>;
  onSuccess?: () => void;
}

export const NewInvoice = ({ invoice, onSuccess }: Props) => {
  const {
    register,
    errors,
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    getValues,
  } = useForm<
    Omit<
      Invoice,
      "subject" | "terms_payment" | "invoice_reference" | "charge_ids"
    > & {
      charges: NestedValue<Charge[]>;
    }
  >({ defaultValues: invoice });

  const { data: options } = useOptionsInvoice();
  const { mutateAsync } = useAddInvoice();
  const history = useHistory();

  const onSend = handleSubmit((data) => {
    const newData: Invoice = { ...data, charge_ids: invoice.charge_ids };

    mutateAsync(newData)
      .then((data) => {
        onSuccess ? onSuccess() : history.push(`/billing/invoices`);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });
  return (
    <Form onSubmit={onSend}>
      <InvoiceForm
        getValues={getValues}
        options={options}
        register={register}
        control={control}
        errors={errors}
        defaultValues={invoice}
        watch={watch}
        setValue={setValue}
      />
      <div className="d-flex flex-row justify-content-end align-items-center mt-3">
        <div>
          <Button size="sm" color="success" type="submit">
            Salva
          </Button>
        </div>
      </div>
    </Form>
  );
};
