import { SimpleTable } from "components/tables/SimpleTable";
import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useListVehicle } from "Vehicles/hooks/vehicle";
import { Vehicle } from "Vehicles/types/vehicle";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  selectedVehicle: Vehicle | undefined;
  setVehicle: (vehicle: Vehicle) => void;
}

export const ModalVehicle = ({
  toggle,
  isOpen,
  selectedVehicle,
  setVehicle,
}: Props) => {
  const { data } = useListVehicle({ no_page: true, hide: false });
  const columns = useMemo(() => {
    return [
      {
        accessor: "id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              onChange={() => {
                setVehicle(cell.row.original);
              }}
              checked={
                selectedVehicle
                  ? cell.row.original.id === selectedVehicle.id
                  : false
              }
            />
          );
        },
      },
      {
        Header: "Targa",
        accessor: "plate",
        sortType: "basic",
      },
      {
        Header: "Stato",
        accessor: "status",
        sortType: "basic",
      },
      {
        Header: "Dimensioni",
        accessor: "size",
        sortType: "basic",
      },
      {
        Header: "Litri",
        accessor: "liters",
        sortType: "basic",
      },
      {
        Header: "Autista",
        accessor: "default_driver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.default_driver)
            return `${cell.row.original.default_driver.name} ${cell.row.original.default_driver.surname}`;
          return "";
        },
      },
    ];
  }, [selectedVehicle, setVehicle]);
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <span className="modal-title">Seleziona automezzo</span>
      </ModalHeader>
      <ModalBody>
        <SimpleTable data={data} columns={columns} />
      </ModalBody>
    </Modal>
  );
};
