
export function getBoleanColumn(props: {
    accessor: string,
    Header: string,
    sortType: string,
}) {

    return {
        accessor: props.accessor,
        Header: props.Header,
        sortType: props.sortType,
        Cell: (cell: any) =>
            cell.row.original[props.accessor]
                ? "Si"
                : "No",
    }
}