import { Cluster, DeliveryGroup, DeliveryPlan } from "./types";

import create from "zustand";
import produce from "immer";
import { devtools } from "zustand/middleware";
import _ from "lodash";
import { Delivery } from "Distribution/types/delivery";
import { Vehicle } from "Vehicles/types/vehicle";
import { Driver } from "Drivers/types/driver";
import { Route } from "Distribution/types/route";
import { randomColorExcludeBlack } from "utils/helpers";
import {
  deliveriesNoWithdrawals,
  getAddedDeliveries,
  getDeliveriesGrouped,
} from "./utils";

interface DeliveryPlannerState {
  colors: string[];
  addedDeliveries: Delivery[];
  clusters: Cluster;
  activeClusterKey?: string;
  groupedDeliveries: DeliveryGroup[];
  editMode: boolean;
  vehicle: Vehicle | undefined;
  driver: Driver | undefined;
  routes: Route[];
  selectedRoute: Route | undefined;
  setGroupedDeliveries: (groupedDeliveries: DeliveryGroup[]) => void;
  onActiveRouteChange: (
    editMode: boolean,
    selectedRoute: Route | undefined
  ) => void;
  toggleEditMode: () => void;
  toggleDelivery: (segment: Delivery) => void;
  deleteDeliveries: (clusterKey: string) => void;
  addDeliveries: (clusterKey: string, hideWithdrawals: boolean) => void;
  initPlanner: (segments: Delivery[]) => void;
  setActiveClusterKey: (key: string) => void;
  resetActiveClusterKey: () => void;
  reorderGroupedDeliveries: (dragIndex: number, hoverIndex: number) => void;
  updateSelectedDriver: (driver: Driver | undefined) => void;

  updateSelectedVehicle: (driver: Vehicle | undefined) => void;
  updateSelectedRoute: (route: Route | undefined) => void;
  toggleSelectedRoutes: (route: Route) => void;
  groupDeliveries: (addedDeliveries: any) => void;
  setSelectedRoutes: (routes: Route[]) => void;
  focusCluster: (keyLatLon: string) => void;
}

export const useStore = create<DeliveryPlannerState>(
  devtools((set, get) => {
    return {
      addedDeliveries: [],
      startingPoints: {},
      editMode: false,
      vehicle: undefined,
      clusters: {},
      groupedDeliveries: [],
      activeClusterKey: undefined,
      colors: [],
      driver: undefined,
      routes: [],
      selectedRoute: undefined,

      focusCluster: (keyLatLon) => {
        set(
          produce((state) => {
            Object.keys(state.clusters).forEach((key) => {
              if (key !== keyLatLon) state.clusters[key].focused = false;
              else {
                state.clusters[key].focused =
                  !state.clusters[keyLatLon].focused;
              }
            });
          })
        );
      },
      toggleEditMode: () =>
        set(
          produce((state) => {
            state.editMode = !state.editMode;
          })
        ),
      updateSelectedDriver: (driver) =>
        set(
          produce((state) => {
            state.driver = driver as Driver;
          })
        ),

      updateSelectedRoute: (route) =>
        set(
          produce((state) => {
            state.selectedRoute = route;
            if (state.selectedRoute) {
              state.vehicle = state.selectedRoute.vehicle;
              state.driver = state.selectedRoute.driver;
              state.finalPointId = state.selectedRoute.final_point_id;
              state.startPointId = state.selectedRoute.start_point_id;
            }
          })
        ),
      setSelectedRoutes: (routes) =>
        set(
          produce((state) => {
            state.routes = routes;
            state.colors = randomColorExcludeBlack(state.routes.length);
          })
        ),
      toggleSelectedRoutes: (route) =>
        set(
          produce((state) => {
            const exists = state.routes.find(
              (oldRoute: Route) => oldRoute.id === route.id
            );
            state.routes = exists
              ? state.routes.filter(
                  (oldRoute: Route) => oldRoute.id !== route.id
                )
              : [...state.routes, route];
            state.colors = randomColorExcludeBlack(state.routes.length);
          })
        ),
      updateSelectedVehicle: (vehicle) =>
        set(
          produce((state) => {
            state.vehicle = vehicle as Vehicle;
            if (state.vehicle.default_driver) {
              state.driver = state.vehicle.default_driver;
            }
          })
        ),
      reorderGroupedDeliveries: (dragIndex, hoverIndex) => {
        const groupedDeliveries = [...get().groupedDeliveries];
        const delivery = { ...groupedDeliveries[dragIndex] }; //trasportando
        groupedDeliveries.splice(dragIndex, 1);
        groupedDeliveries.splice(hoverIndex, 0, delivery);
        set(
          produce((state) => {
            state.addedDeliveries = getAddedDeliveries(
              groupedDeliveries,
              state.addedDeliveries
            );
            state.groupedDeliveries = groupedDeliveries;
          })
        );
      },
      setGroupedDeliveries: (groupedDeliveries) => {
        set(
          produce((state) => {
            state.addedDeliveries = getAddedDeliveries(
              groupedDeliveries,
              state.addedDeliveries
            );
            state.groupedDeliveries = groupedDeliveries;
          })
        );
      },
      addDeliveries: (clusterKey, hideWithdrawals) => {
        const selectedRoute = get().selectedRoute;
        const deliveries = hideWithdrawals
          ? deliveriesNoWithdrawals(get().clusters[clusterKey].deliveries)
          : get().clusters[clusterKey].deliveries;
        const deliveriesToAdd = deliveries.filter(
          (segment) =>
            !segment.route_id ||
            (selectedRoute && segment.route_id === selectedRoute.id)
        );
        const addedDeliveries = _.unionWith(
          get().addedDeliveries,
          deliveriesToAdd,
          (value, other) => value.id === other.id
        );

        get().groupDeliveries(addedDeliveries);
      },

      deleteDeliveries: (clusterKey) => {
        const addedDeliveries = get().addedDeliveries.filter((delivery) => {
          const key = `${delivery.receiver?.latitude},${delivery.receiver?.longitude}`;
          return key !== clusterKey;
        });
        const groupedDeliveries = getDeliveriesGrouped(addedDeliveries);
        set(
          produce((state) => {
            state.addedDeliveries = getAddedDeliveries(
              groupedDeliveries,
              addedDeliveries
            );
            state.groupedDeliveries = groupedDeliveries;
            state.clusters[clusterKey].focused = false;
          })
        );
      },
      resetActiveClusterKey: () =>
        set(
          produce((state) => {
            state.activeClusterKey = undefined;
          })
        ),
      setActiveClusterKey: (key) =>
        set(
          produce((state) => {
            state.activeClusterKey = key;
          })
        ),
      toggleDelivery: (delivery) => {
        const addedDeliveries = [...get().addedDeliveries];
        const selectedRoute = get().selectedRoute;
        const indexSegment = addedDeliveries.findIndex(
          (s) => s.id === delivery.id
        );
        if (
          indexSegment === -1 &&
          (!delivery.route_id ||
            (selectedRoute && delivery.route_id === selectedRoute.id))
        ) {
          addedDeliveries.push(delivery);
        } else {
          addedDeliveries.splice(indexSegment, 1);
        }

        get().groupDeliveries(addedDeliveries);
      },
      onActiveRouteChange: (editMode, selectedRoute) => {
        let addedDeliveries: Delivery[] = [];
        const clusters = get().clusters;
        if (editMode && selectedRoute) {
          addedDeliveries = Object.keys(clusters).reduce(
            (activeDeliveries, key) => {
              const deliveries = clusters[key].deliveries.filter(
                (delivery) => delivery.route_id === selectedRoute.id
              );
              addedDeliveries.push(...deliveries);
              return activeDeliveries;
            },
            addedDeliveries
          );
        }
        get().groupDeliveries(addedDeliveries);
      },
      initPlanner: (deliveries) => {
        const clustersGroup: Cluster = {};
        for (const delivery of deliveries) {
          const key = `${delivery.receiver?.latitude},${delivery.receiver?.longitude}`;
          if (!(key in clustersGroup)) {
            clustersGroup[key] = { deliveries: [], focused: false };
          }

          clustersGroup[key].deliveries.push(delivery as DeliveryPlan);
        }
        console.log();
        set(
          produce((state) => {
            state.activeClusterKey = undefined;
            state.addedDeliveries = [];
            state.clusters = clustersGroup;
            state.selectedRoute = undefined;
            state.groupedDeliveries = [];
          })
        );
      },
      groupDeliveries: (addedDeliveries: Delivery[]) => {
        const groupedDeliveries = getDeliveriesGrouped(addedDeliveries);
        set(
          produce((state) => {
            state.addedDeliveries = getAddedDeliveries(
              groupedDeliveries,
              addedDeliveries
            );
            state.groupedDeliveries = groupedDeliveries;
          })
        );
      },
    };
  })
);
