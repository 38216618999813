import classNames from "classnames";
import React from "react";
import { Icon } from "react-feather";
import { Button } from "reactstrap";

interface Props {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  Icon: Icon;
  color?: string;
  title?: string;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}
export const IconButton = ({
  onClick,
  Icon,
  color,
  title,
  className,
  disabled,
  type = "button",
}: Props) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      className={classNames("edit-buttons", className)}
      color={color ? color : "primary"}
      title={title}
      disabled={disabled}
    >
      <Icon size={13} />
    </Button>
  );
};
