import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

interface Props {
  register: any;
  name: string;
  type: InputType;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  hidden?: boolean;
  defaultValue?: any;
  required?: boolean;
}

export const FormField = ({
  errorMessage,
  register,
  name,
  type,
  label,
  placeholder,
  disabled = false,
  hidden = false,
  required = false,
  defaultValue,
}: Props) => {
  return (
    <>
      {hidden ? (
        <Input
          placeholder={placeholder}
          disabled={disabled}
          invalid={errorMessage !== undefined}
          className="form-control"
          step="any"
          name={name}
          type={type}
          hidden={true}
          innerRef={register}
        />
      ) : (
        <FormGroup>
          {label && <Label>{label}</Label>}
          <Input
            disabled={disabled}
            placeholder={placeholder}
            invalid={errorMessage !== undefined}
            defaultValue={defaultValue}
            step="any"
            name={name}
            type={type}
            innerRef={register}
            required={required}
          />
          {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
        </FormGroup>
      )}
    </>
  );
};
