import { Filter } from "components/Filter";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";

import { Table } from "components/tables/Table";
import classNames from "classnames";
import { useTrailerColumns } from "Vehicles/hooks/trailer";
import { TrailerFilter, Trailer } from "Vehicles/types/trailer";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<TrailerFilter>) => void;
  onAddClick: () => void;
  trailers: Trailer[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
}

export const TrailerListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  trailers,
  page,
  pageCount,
  goToPage,
}: Props) => {
  const columns = useTrailerColumns();
  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
        <CheckPermissions permissions={["rosina.change_trailer"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            Aggiungi
          </Button>
        </CheckPermissions>
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {trailers.length === 0 ? (
            <Alert color="primary">Nessun rimorchio trovato </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={trailers}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
