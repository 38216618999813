import { useMutation, useQueryClient } from "react-query";
import { UpdatePayload } from "shared/types";
import { client } from "utils/auth-client";
import { DELIVERY_NOTE_ROWS_API } from "utils/constants";
import { DeliveryNoteRow } from "../types";

export function useUpdateDeliveryNoteRow() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdatePayload<DeliveryNoteRow>) =>
      client(`${DELIVERY_NOTE_ROWS_API}${data.id}/`, {
        method: "PATCH",
        data: data.newValues,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["deliveries"], { active: true });
      },
    }
  );
}
