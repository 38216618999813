import { filterJson } from "components/tables/filters";
import React, { useMemo } from "react";
import { UseMutationOptions, UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useUpdate } from "shared/hooks/useUpdate";
import { UpdatePayload } from "shared/types";
import { DestinyTraction } from "Traction/types";
import { DESTINY_TRACTION_API } from "utils/constants";

export const key = "destiny-tractions";
const url = DESTINY_TRACTION_API;

export function useOptionsDestinyTraction() {
  return useOptions(DESTINY_TRACTION_API, "destiny-tractions");
}

export function useListDestinyTraction(
  params?: any,
  config?: UseQueryOptions<any, unknown, DestinyTraction[]>
) {
  return useList(url, key, params, config);
}

export function useUpdateDestinyTraction(
  config?: UseMutationOptions<any, unknown, UpdatePayload<DestinyTraction>>
) {
  return useUpdate<DestinyTraction>(url, key, config);
}

export function useDestinyTractionColumns() {
  const { setActiveItem } = useGlobalModals();

  return useMemo(() => {
    return [
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        accessor: "date",
        Header: "Data",
        sortType: "basic",
      },
      {
        accessor: "type",
        Header: "Tipo",
        sortType: "basic",
      },
      {
        accessor: "destination",
        Header: "Luogo",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.destination) {
            return (
              <Button
                title={"Mostra destinaziopne"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.destination.id)
                }
              >{`${cell.row.original.destination.id}: ${cell.row.original.destination.business_name}`}</Button>
            );
          }
          return null;
        },
        filter: filterJson
      },
      {
        accessor: "quantity_type",
        Header: "UM merce",
        sortType: "basic",
      },
      {
        accessor: "quantity_load",
        Header: "Q.tà Caricata",
        sortType: "basic",
      },
      {
        accessor: "quantity_unload",
        Header: "Q.tà Scaricata",
        sortType: "basic",
      },

    ];
  }, [setActiveItem]);
}
