import { difference, intersection } from "lodash";
import randomColor from "randomcolor";
import { toast } from "react-toastify";
import * as auth from "services/auth";
import { Subject } from "Subjects/types";
import { PAGE_SIZE } from "./constants";

export const checkPermissions = (permission: string[]) => {
  let user = auth.getUser();
  return user && difference(permission, user.permissions).length === 0;
};

export function isArrayWithLength(arr: any) {
  return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes: any) {
  let user = auth.getUser();
  if (user) {
    const roles = user.permissions;
    return routes.filter(({ permissions }: any) => {
      if (!permissions) return true;
      else if (!isArrayWithLength(permissions)) return true;
      else return intersection(permissions, roles).length;
    });
  }
  return [];
}

export function addServerErrors<T>(errors: any, setError: any) {
  try {
    if ("detail" in errors) {
      toast.error(errors.detail);
      return;
    }
  } catch (error) {
    toast.error("Errore");
    return;
  }
  if ("non_field_errors" in errors) {
    let text = errors.non_field_errors.map((error: string) => (
      <li key={error}>{error}</li>
    ));
    toast.error(<ul>{text}</ul>, { autoClose: false });
    return;
  }

  return Object.keys(errors).forEach((key) => {
    if (typeof errors[key as keyof T][0] !== "string") {
      errors[key as keyof T].forEach((item: any, index: number) => {
        Object.keys(item).forEach((key2) => {
          setError(`${key}[${index}].${key2}`, {
            type: "server",
            message: item[key2 as keyof T]!.join(". "),
          });
        });
      });
    } else {
      setError(key as keyof T, {
        type: "server",
        message: errors[key as keyof T]!.join(". "),
      });
    }
  });
}

export function getPageCount(numberOfElements: number) {
  return Math.ceil(numberOfElements / PAGE_SIZE);
}

export function arrayToFloat(values: string[]) {
  return values.map((value) => parseFloat(value));
}

export function checkTypeSubject(subject: Subject | undefined, type: string) {
  return !!(subject && subject.type.includes(type));
}

export const getDisplayValue = (key: string, value: any, options: any) => {
  if (typeof value === "boolean") return value === true ? "Si" : "No";
  if (typeof value === "number") return value.toFixed(2);

  if (options && key in options) {
    if ("child" in options[key] && "choices" in options[key].child) {
      let displayName = options[key].child.choices.find((element: any) => {
        return element.value === value;
      });

      if (displayName) return displayName.display_name;
    }
    if ("choices" in options[key]) {
      let displayName = options[key].choices.find((element: any) => {
        return element.value === value;
      });
      if (displayName) return displayName.display_name;
    }
  }
  return value;
};
export const getDisplayDate = (date: Date | null | undefined) => {
  return date ? new Date(date).toLocaleString() : null;
};

export const getDisplayDateSplit = (date: Date | null | undefined) => {
  if (date) {

    let date_l = new Date(date).toLocaleString().split(",");
    return (
      <>
        <div>{date_l[0]}</div>
        <div>{date_l[1]}</div>
      </>
    )
  }

  return null;
};
export const getDisplayOnlyDate = (date: Date | null | undefined | string) => {
  return date ? new Date(date).toLocaleDateString() : null;
};

export function filterNonNull(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
}

export function sortAscending<T>(a: T, b: T) {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
}

export function randomColorExcludeBlack(length: number) {
  let colors = randomColor({ luminosity: "light", count: length });
  if (colors.includes("#000000")) randomColorExcludeBlack(length);
  return colors;
}

export const getBase64 = (file: any) =>
  new Promise(function (resolve, reject) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
