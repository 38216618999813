import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { Status } from "Warehouse/types";
import { useOptionsStatus } from "../hooks/status";
import { StatusForm } from "./StatusForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Status, unknown>;
  initData?: Status;
  toggleFunction?: any;
}

export const AddStatusForm = ({ submitFunction, initData, toggleFunction }: Props) => {
  const { control, errors, register, handleSubmit, setError, getValues, setValue } = useForm<Status>(
    {
      defaultValues: initData,
    }
  );

  const { data: options } = useOptionsStatus();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Stato aggiunto!");
        if (toggleFunction) {
          toggleFunction(false)
        }
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && (
        <StatusForm
          options={options}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
