import { useToggler } from "shared/hooks/useToggler";

import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { useAddPlace, useOptionsPlace, useSearchPlaces } from "../hooks/place";
import { PlaceFilter } from "Warehouse/types";
import { AddPlaceForm } from "../components/AddPlaceForm";
import { PlaceListPage } from "../components/PlaceListPage/PlaceListPage";

export const Places = () => {
  const { paramsObj, onSearch } = useFilter<PlaceFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchPlaces(paramsObj);
  const { mutateAsync } = useAddPlace();
  const { value: isOpen, toggle } = useToggler();

  const searchItems = (data: Partial<PlaceFilter>) => {
    onSearch(data);
    search(data);
  };
  const { data: options } = useOptionsPlace();
  return (
    <>
      {data && data.results && options && (
        <PlaceListPage
          options={options}
          places={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
      <FullModal title="Aggiungi posto" toggle={toggle} isOpen={isOpen}>
        <AddPlaceForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
