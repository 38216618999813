import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { DateTimeField } from "components/forms/DateTimeField";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useAddSegment, useUpdateSegment } from "Traction/hooks/segments";
import { Segment } from "Traction/types";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  toggle: () => void;
  isOpen: boolean;
  segment: Segment;
  onSuccess: (segment: Segment) => void;
}

export const ModalEditOrderSegments = ({
  toggle,
  isOpen,
  segment,
  onSuccess,
}: Props) => {
  const { errors, handleSubmit, control } = useForm<any>();
  const { mutateAsync: addSegment } = useAddSegment();
  const { mutateAsync: editSegment } = useUpdateSegment();

  const onSend = handleSubmit((data) => {
    const arrivalPointFirstSegment = segment.arrival_point_id;
    editSegment(
      {
        id: segment.id!,
        newValues: { arrival_point_id: data.destination },
      },
      {
        onSuccess: () => {
          addSegment({
            starting_point_id: data.destination,
            arrival_point_id: arrivalPointFirstSegment,
            date: data.date,
            order: segment.order,
          })
            .then((data) => {
              toast.success("Segmento spezzato!");
              onSuccess(data);
            })
            .catch(() => {
              toast.error("Errore!");
            });
        },
        onError: () => {
          toast.error("Errore!");
        },
      }
    );
  });
  return (
    <Modal toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <span className="modal-title">Spezza tratta</span>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSend}>
          <div className="d-flex flex-column flex-lg-row justify-content-between ">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Destinazione</b>
                  </td>
                  <td>
                    <Autocomplete
                      required
                      url={AUTOCOMPLETE_URLS.SUBJECT}
                      control={control}
                      name="destination"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data</b>
                  </td>
                  <td>
                    <DateTimeField
                      control={control}
                      name="date"
                      errorMessage={errors.date?.message}
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end  mt-2">
            <Button type="submit" color="success">
              Aggiungi
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};
