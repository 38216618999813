import { useLoadItem } from "shared/hooks/useLoadItem";

import { PRODUCT_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { useUpdateProduct } from "../hooks/product";
import { Product } from "Warehouse/types";
import { FullModal } from "components/FullModal";
import { UpdateProductForm } from "./UpdateProductForm";

export const ShowProductModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Product>(id, PRODUCT_API, "products");
  const { mutateAsync } = useUpdateProduct();

  return data && checkPermissions(["rosina.view_product"]) ? (
    <FullModal
      title="Modifica prodotto"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateProductForm product={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
