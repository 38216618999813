import { UpdatePalletForm } from "PalletsManagment/components/UpdatePalletForm";
import { useUpdatePallet } from "PalletsManagment/hooks/pallet";
import { Pallet } from "PalletsManagment/types";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { PALLETS_API } from "utils/constants";
import { FullModal } from "components/FullModal";
import { checkPermissions } from "utils/helpers";

export const ShowPalletModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Pallet>(id, PALLETS_API, "pallets");
  const { mutateAsync } = useUpdatePallet();

  return data && checkPermissions(["rosina.view_pallet"]) ? (
    <FullModal
      title="Modifica pallet"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdatePalletForm pallet={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
