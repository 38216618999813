import { LoginPayload } from "shared/types";
import React, { createContext, useState } from "react";
import { IAuthContext, IUser } from "./types";
import * as auth from "services/auth";
import history from "historyRouter";

const AuthContext = createContext<IAuthContext>({
  user: undefined,
  login: () => {},
  logout: () => {},
  updateToken: () => {},
  error: "",
});

const userStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user")!)
  : undefined;

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser | undefined>(userStorage);
  const [error, setError] = useState("");
  const login = (form: LoginPayload) => {
    auth
      .login(form)
      .then((user: IUser) => {
        setError("");
        setUser(user);
      })
      .catch(() => {
        setError("Credenziali errate");
      });
  };
  const logout = () => {
    auth.logout().then(() => {
      setUser(undefined);
      history.push("login");
    });
  };
  const updateToken = (token: string) => {
    console.log(token);
    if (user) setUser({ ...user, access: token });
  };
  return (
    <AuthContext.Provider value={{ user, login, logout, error, updateToken }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, AuthContext, useAuth };
