import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { Pallet } from "PalletsManagment/types";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { PALLETS_API } from "utils/constants";

const key = "pallets";
const url = PALLETS_API;

export function useOptionsPallet() {
  return useOptions(url, key);
}

export function useAddPallet() {
  return useAdd<Pallet>(url, key);
}

export function useDeletePallet() {
  return useDelete(url, key);
}

export function useUpdatePallet() {
  return useUpdate<Pallet>(url, key);
}

export function useListPallet(
  params?: any,
  config?: UseQueryOptions<any, unknown, Pallet[]>
) {
  return useList(url, key, params, config);
}

export function useSearchPallets(params?: any) {
  return useSearchPaginated<Pallet>(url, key, params);
}

export function usePallet(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Pallet>
) {
  return useGetItem<Pallet>(id, url, key, config);
}

export function usePalletColumns() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deletePallet } = useDeletePallet();
  return useMemo(() => {
    const onDelete = (id: number) => {
      const r = window.confirm("Rimuovere elemento?");
      if (r) deletePallet(id);
    };
    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_pallet"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("pallet", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        Header: "Codice",
        accessor: "code",
        sortType: "basic",
      },
      {
        Header: "Descrizione",
        accessor: "description",
        sortType: "basic",
      },
      {
        Header: "Quantità",
        accessor: "quantity",
        sortType: "basic",
      },
      {
        Header: "Totale da transazioni",
        accessor: "total",
        sortType: "basic",
      },
    ];
  }, [deletePallet, setActiveItem]);
}
