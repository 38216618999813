import { useEffect, useState } from "react";
import { NestedValue, useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form, Spinner } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { InvoiceForm } from "./InvoiceForm";
import { Charge } from "Billing/types/charge";
import { useInvoice, useOptionsInvoice } from "Billing/hooks/invoices";
import { Invoice } from "Billing/types/invoice";
import { LoadingSpinner } from "components/LoadingSpinner";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Invoice>,
    unknown
  >;
  invoiceId: number;
  isUpdating: boolean;
}

export const UpdateInvoice = ({
  submitFunction,
  invoiceId,
  isUpdating,
}: Props) => {
  const { data: invoice, isLoading } = useInvoice(invoiceId);
  const {
    errors,
    register,
    handleSubmit,
    setError,
    getValues,
    control,
    watch,
    setValue,
    reset,
  } = useForm<
    Omit<
      Invoice,
      "subject" | "terms_payment" | "invoice_reference" | "charge_ids"
    > & {
      charges: NestedValue<Charge[]>;
    }
  >({
    defaultValues: invoice,
  });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const { data: options } = useOptionsInvoice();

  const onSend = handleSubmit((data) => {
    console.log(data);
    submitFunction({ id: invoice?.id!, newValues: data })
      .then(() => {
        toast.success("Fattura modificata!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  useEffect(() => {
    reset(invoice);
  }, [invoice, reset]);

  return (
    <Form onSubmit={onSend}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <CheckPermissions permissions={["rosina.change_invoice"]}>
            <div className="d-flex flex-row justify-content-end">
              {
                ["CR", "ST"].includes(invoice!.invoice_status) && (
                  <Button size="sm" onClick={toggleDisable} color="primary">
                    Modifica
                  </Button>
                )
              }

            </div>
          </CheckPermissions>
          {invoice && (
            <InvoiceForm
              getValues={getValues}
              options={options}
              register={register}
              control={control}
              errors={errors}
              defaultValues={invoice}
              watch={watch}
              editable={!disabled}
              setValue={setValue}
            />
          )}
          <CheckPermissions permissions={["rosina.change_invoice"]}>
            <div className="d-flex justify-content-end py-3">
              <Button
                type="submit"
                color="success"
                disabled={disabled || isUpdating}
              >
                <div className="d-flex flex-row">
                  <span>{isUpdating ? "Loading..." : "Invia"}</span>
                  {isUpdating && (
                    <div className="ml-3">
                      <Spinner
                        style={{ width: "1rem", height: "1rem" }}
                        type="grow"
                        color="light"
                      />
                    </div>
                  )}
                </div>
              </Button>
            </div>
          </CheckPermissions>
        </>
      )}
    </Form>
  );
};
