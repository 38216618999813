import { UpdateImportFileForm } from "Import/components/UpdateImportFileForm";
import { useUpdateImport } from "Import/hooks/import";
import { ImportFile } from "Import/types";
import { useLoadItem } from "shared/hooks/useLoadItem";
import { IMPORTED_FILES_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";

export const ShowImportedFileModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<ImportFile>(
    id,
    IMPORTED_FILES_API,
    "history-imports"
  );
  const { mutateAsync } = useUpdateImport();

  return data && checkPermissions(["rosina.view_importedfile"]) ? (
    <FullModal
      title="Modifica missione"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateImportFileForm importedFile={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
