import { Invoice } from "Billing/types/invoice";
import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { filterJson } from "components/tables/filters";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { Button } from "reactstrap";
import { useGlobalModals } from "shared/context/globalModals";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { INVOICES_API } from "utils/constants";
import { getDisplayValue } from "utils/helpers";
import { IconButton } from "components/buttons/IconButton";
import { DownloadFile } from "components/DownloadFile";
import { File, Code, List } from "react-feather";

const key = "invoices";
const url = INVOICES_API;

export function useOptionsInvoice() {
  return useOptions(url, key);
}

export function useAddInvoice() {
  return useAdd<Invoice>(url, key);
}

export function useDeleteInvoice() {
  return useDelete(url, key);
}

export function useUpdateInvoice() {
  return useUpdate<Invoice>(url, key);
}

export function useListInvoice(
  params?: any,
  config?: UseQueryOptions<any, unknown, Invoice[]>
) {
  return useList(url, key, params, config);
}

export function useSearchInvoice(searchParams?: any) {
  return useSearchPaginated<Invoice>(url, key, searchParams);
}

export function useInvoice(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Invoice>
) {
  return useGetItem<Invoice>(id, url, key, config);
}

export function useInvoiceColumns() {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteFunction } = useDeleteInvoice();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteFunction(id);
    };

    return [
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              <DeleteButton
                permissions={["rosina.change_invoice"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("invoice", cell.row.original.id)}
                permissions={[]}
              />
              <DownloadFile
                ButtonDownload={() => (
                  <IconButton Icon={Code} title={"Scarica xml"} />
                )}
                urlDownload={`${INVOICES_API}${cell.row.original.id}/generate_xml_invoice/`}
              />
              <DownloadFile
                ButtonDownload={() => (
                  <IconButton Icon={File} title={"Scarica pdf"} />
                )}
                urlDownload={`${INVOICES_API}${cell.row.original.id}/generate_pdf_invoice/`}
              />

              <DownloadFile
                ButtonDownload={() => (
                  <IconButton Icon={List} title={"Scarica pdf dettagli"} />
                )}
                urlDownload={`${INVOICES_API}${cell.row.original.id}/generate_pdf_invoice_detail/`}
              />
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        accessor: "sequence",
        Header: "Sequenza",
        sortType: "basic",
      },

      {
        accessor: "subject",
        Header: "Soggetto",
        sortType: "basic",
        Cell: (cell: any) => (
          <Button
            title={"Mostra soggetto"}
            color="link"
            onClick={() =>
              setActiveItem("subject", cell.row.original.subject.id)
            }
          >
            {cell.row.original.subject.business_name}
          </Button>
        ),
        filter: filterJson
      },
      {
        accessor: "amount",
        Header: "Importo",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("amount", cell.value, {}),
      },
      {
        accessor: "invoice_status",
        Header: "Stato",
        sortType: "basic",
      },

      {
        accessor: "taxable",
        Header: "Imponibile",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("taxable", cell.value, {}),
      },
    ];
  }, [deleteFunction, setActiveItem]);
}
