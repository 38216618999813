import { useReducer } from "react";

export interface IStateSearchPagination<T> {
  page: number;
  pageCount: number;
  searchValues: T;
}

export function setFormValues<T>(values: T) {
  return <const>{
    type: "SET_FORM_VALUES",
    values,
  };
}

export function setPage(page: number) {
  return <const>{
    type: "SET_PAGE",
    page,
  };
}

export function setNumberOfPages(value: number) {
  return <const>{
    type: "SET_PAGE_COUNT",
    value,
  };
}

type Action = ReturnType<
  typeof setFormValues | typeof setPage | typeof setNumberOfPages
>;

function reducer<T>(state: IStateSearchPagination<T>, action: Action) {
  switch (action.type) {
    case "SET_FORM_VALUES":
      return { ...state, page: 1, searchValues: action.values as T };
    case "SET_PAGE":
      return { ...state, page: action.page };
    case "SET_PAGE_COUNT":
      return { ...state, pageCount: action.value };
    default:
      throw new Error();
  }
}

export function useSearchPaginationReducer<T>() {
  const state: IStateSearchPagination<T> = {
    page: 1,
    searchValues: {} as T,
    pageCount: 0,
  };
  return useReducer<React.Reducer<IStateSearchPagination<T>, Action>>(
    reducer,
    state
  );
}
