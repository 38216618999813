import { createFilterStructure } from "./filter";
import { Charge, ChargeFilter, GroupedChargeListItem } from "Billing/types/charge";
import { Filter } from "components/Filter";
import { Column } from "react-table";
import { Alert, Button, Form } from "reactstrap";
import { GroupedChargesCard } from "../GroupedChargesCard";
import React, { useEffect } from "react";
import { CustomPagination } from "components/CustomPagination";
import { CheckPermissions } from "components/CheckPermissions";
import { DownloadFile } from "components/DownloadFile";
import { AUTOCOMPLETE_URLS, CHARGES_API } from "utils/constants";
import { useMutation, useQueryClient } from "react-query";
import { client } from "utils/auth-client";
import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { useToggler } from "shared/hooks/useToggler";
import { FullModal } from "components/FullModal";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { AddChargeToInvoiceForm } from "Billing/views/Charges/AddChargeToInvoiceForm";
import { RemoveChargeFromInvoiceForm } from "Billing/views/Charges/RemoveChargeFromInvoiceForm";
import { HideOrShowChargesForm } from "Billing/views/Charges/HideOrShowChargesForm";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<ChargeFilter>) => void;
  onAddClick: () => void;
  groupedCharges: {
    [key: string]: GroupedChargeListItem;
  };
  canPrevPage: () => boolean;
  goPrevPage: () => void;
  page: number;
  pageCount: number;
  canNextPage: () => boolean;
  goNextPage: () => void;
  options: any;
  optionsPrice: any;
  columns: Column[];
  selectedRows: Charge[];
  setSelectedRows: (charges: Charge[]) => void;
}

export const ChargesPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  groupedCharges,
  options,
  optionsPrice,
  columns,
  canPrevPage,
  goPrevPage,
  page,
  pageCount,
  canNextPage,
  goNextPage,
  selectedRows,
  setSelectedRows
}: Props) => {
  useEffect(() => {
    onSearch({
      charge_date_after: new Date().toISOString().split('T')[0] as any,
      charge_date_before: new Date().toISOString().split('T')[0] as any
    })
  }, [])


  const { value: isOpen, toggle, setFalse, setTrue } = useToggler();
  const { value: isOpen2, toggle: toggle2, setFalse: setFalse2, setTrue: setTrue2 } = useToggler();
  const { value: isOpen3, toggle: toggle3, setFalse: setFalse3, setTrue: setTrue3 } = useToggler();

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj, optionsPrice)}
        />
        <div className="d-flex flex-row justify-content-around align-items-center">
          <DownloadFile
            ButtonDownload={() => (
              <Button type={"button"} size="sm" color="primary">
                Scarica PDF
              </Button>
            )}
            urlDownload={`${CHARGES_API}get_pdf/${window.location.search}`}
          />

          <div>
            <CheckPermissions permissions={["rosina.change_charge"]}>
              <Button onClick={() => onAddClick()} size="sm" color="primary">
                Aggiungi
              </Button>
            </CheckPermissions>
          </div>
          <div>
            <CheckPermissions permissions={["rosina.change_route"]}>
              <Button
                disabled={selectedRows.length <= 0}
                onClick={() => setTrue3()}
                size="sm"
                color="secondary"
                className="ml-2"
              >
                Mostra/Nascondi
              </Button>
            </CheckPermissions>
          </div>
          <div>
            <CheckPermissions permissions={["rosina.change_route"]}>
              <Button
                disabled={selectedRows.length <= 0}
                onClick={() => setTrue()}
                size="sm"
                color="primary"
                className="ml-2"
              >
                Unisci a fattura
              </Button>
            </CheckPermissions>
          </div>
          <div>
            <CheckPermissions permissions={["rosina.change_route"]}>
              <Button
                disabled={selectedRows.length <= 0}
                onClick={() => setTrue2()}
                size="sm"
                color="secondary"
                className="ml-2"
              >
                Rimuovi da fattura
              </Button>
            </CheckPermissions>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-12">
          {Object.keys(groupedCharges).length === 0 ? (
            <Alert color="primary">Nessun addebito trovato</Alert>
          ) : (
            Object.values(groupedCharges).map((item) => (
              <>
                <GroupedChargesCard
                  key={item.title}
                  data={item}
                  columns={columns}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  sortBy="id"
                />
              </>

            ))
          )}
        </div>
        <div className="col-12">
          <CustomPagination
            canPrevPage={canPrevPage}
            goPrevPage={goPrevPage}
            page={page}
            pageCount={pageCount}
            canNextPage={canNextPage}
            goNextPage={goNextPage}
          />
        </div>
      </div>
      <FullModal title="Aggiungi a fattura" toggle={toggle} isOpen={isOpen}>
        <AddChargeToInvoiceForm charges={selectedRows} closeModal={setFalse} />
      </FullModal>

      <FullModal title="Rimuovi da fattura" toggle={toggle2} isOpen={isOpen2}>
        <RemoveChargeFromInvoiceForm charges={selectedRows} closeModal={setFalse2} />
      </FullModal>

      <FullModal title="Rimuovi da fattura" toggle={toggle3} isOpen={isOpen3}>
        <HideOrShowChargesForm charges={selectedRows} closeModal={setFalse3} />
      </FullModal>
    </>

  );
};
