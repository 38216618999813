import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { ORDERS_API } from "utils/constants";
import { Order } from "Traction/types";

const key = "orders";
const url = ORDERS_API;

export function useOptionsOrder() {
  return useOptions(url, key);
}

export function useAddOrder() {
  return useAdd<Order>(url, key);
}

export function useDeleteOrder() {
  return useDelete(url, key);
}

export function useUpdateOrder() {
  return useUpdate<Order>(url, key);
}

export function useListOrder(
  params?: any,
  config?: UseQueryOptions<any, unknown, Order[]>
) {
  return useList(url, key, params, config);
}

export function useSearchOrder(searchParams?: any) {
  return useSearchPaginated<Order>(url, key, searchParams);
}

export function useOrder(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Order>
) {
  return useGetItem<Order>(id, url, key, config);
}
