import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const ProductForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Cliente</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.CLIENT}
                      control={control}
                      defaultValue={
                        defaultValues?.client_id
                          ? defaultValues?.client_id
                          : null
                      }
                      errorMessage={errors.client_id?.message}
                      name="client_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo prodotto</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.PRODUCT_TYPE}
                      control={control}
                      defaultValue={
                        defaultValues?.product_type_id
                          ? defaultValues?.product_type_id
                          : null
                      }
                      errorMessage={errors.product_type_id?.message}
                      name="product_type_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nome</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Nome"
                        type="text"
                        register={register}
                        name="name"
                        errorMessage={errors.name?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.name}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Descrizione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Descrizione"
                        type="text"
                        register={register}
                        name="description"
                        errorMessage={errors.description?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.description}`
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
