import React from "react";
import { Redirect } from "react-router";
import { routes } from "routes";
import { checkPermissions } from "utils/helpers";
import MapLayoutRoutes from "./MapLayoutRoutes";

export const PrivateRoutes = () => {
  return !checkPermissions([]) ? (
    <Redirect to="/login" />
  ) : (
    <MapLayoutRoutes routes={routes} />
  );
};
