import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useList } from "shared/hooks/useList";
import { TERMS_DESCRIPTION_API } from "utils/constants";
import { TermsDescription } from "../types/termsdescription";

const key = "terms-description";

export function useAddTermsDescription() {
  return useAdd<TermsDescription>(TERMS_DESCRIPTION_API, key);
}

export function useListTermsDescription(
  params?: any,
  config?: UseQueryOptions<any, unknown, TermsDescription[]>
) {
  return useList(TERMS_DESCRIPTION_API, key, params, config);
}
