import { useTable } from "react-table";
import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Move } from "react-feather";

interface Props {
  columns: any;
  data: any;
  setData: (dragIndex: number, hoverIndex: number) => void;
  onRowClick?: (row: any) => void;
  onDrop?: () => void;
  endTableRef?: any;
}

export const TableDnd = ({
  columns,
  data,
  setData,
  onRowClick,
  onDrop,
  endTableRef,
}: Props) => {
  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable({
    data: data,
    columns,
    disableSortBy: true,
    getRowId,
  });

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    setData(dragIndex, hoverIndex);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <table {...getTableProps()} className={"table-flush table-sm table"}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Row
                onDrop={onDrop}
                index={i}
                row={row}
                moveRow={moveRow}
                onRowClick={onRowClick}
                {...row.getRowProps()}
                endTableRef={endTableRef}
              />
            );
          })}
          <tr ref={endTableRef}></tr>
        </tbody>
      </table>
    </DndProvider>
  );
};

const DND_ITEM_TYPE = "row";

const Row = ({ row, index, moveRow, onRowClick, onDrop }: any) => {
  const dropRef = React.useRef<any>(null);
  const dragRef = React.useRef<any>(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop(item: any, monitor) {
      onDrop && onDrop();
    },
    hover(item: any, monitor) {
      if (!dropRef.current) {
        return;
      }
      // @ts-ignore
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves

      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      if (clientOffset) {
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
      }
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr
      ref={dropRef}
      style={{ opacity }}
      onClick={() => {
        if (onRowClick) onRowClick(row.original);
      }}
    >
      {row.cells.map((cell: any, index: number) => {
        if (index === 0) {
          return (
            <td
              {...cell.getCellProps()}
              className="d-flex flex-row justify-content-around align-items-center"
            >
              <span className="mr-4" ref={dragRef}>
                <Move size={13} />
              </span>
              {cell.render("Cell")}
            </td>
          );
        }
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
      })}
    </tr>
  );
};
