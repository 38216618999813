import { NewInvoice } from "Billing/components/NewInvoice";
import { useChargeColumns, useListCharge } from "Billing/hooks/charges";
import { Charge } from "Billing/types/charge";
import { Invoice, InvoiceCalculateFilter } from "Billing/types/invoice";

import { CollapsableCard } from "components/CollapsableCard";
import { Filter } from "components/Filter";
import { FullModal } from "components/FullModal";
import { SimpleTable } from "components/tables/SimpleTable";
import { useOptionsPrice } from "Items/hooks/price";
import { useEffect, useState } from "react";
import { Alert, Button } from "reactstrap";
import { useFilter } from "shared/hooks/useFilter";
import { useModalUpdate } from "shared/hooks/useModalUpdate";
import { getDisplayValue } from "utils/helpers";
import { createFilterStructure } from "./filter";

export const CalculateInvoices = () => {
  const { data: optionsPrice } = useOptionsPrice();
  const [groupedData, setGroupedData] = useState<any>();

  const { paramsObj, onSearch } = useFilter<InvoiceCalculateFilter>();
  const { data: options } = useOptionsPrice();
  useEffect(() => {
    onSearch({
      charge_date_after: new Date().toISOString().split('T')[0] as any,
      charge_date_before: new Date().toISOString().split('T')[0] as any
    })
  }, [])
  useListCharge(
    { ...paramsObj, invoiced: false, no_page: true, hide: false },
    {
      onSuccess: (data) => {
        setGroupedData(
          data.reduce(function (rv: any, x) {
            if (!rv[x.client_id]) {
              rv[x.client_id] = {
                [x.price!.charge_type]: [],
                title: x.client?.business_name,
                client: x.client,
              };
            }
            if (!rv[x.client_id][x.price!.charge_type]) {
              rv[x.client_id][x.price!.charge_type] = [];
            }
            rv[x.client_id][x.price!.charge_type].push(x);
            return rv;
          }, {})
        );
      },
    }
  );

  const columns = useChargeColumns(options);

  const { activeItem, setActiveItem, isOpenUpdateModal, toggleUpdateModal } =
    useModalUpdate<Partial<Invoice>>();

  const onClickCreateInvoice = (groupedData: any) => {
    let charges: Charge[] = [];
    let taxable = 0;
    let amount = 0;
    for (const key1 in groupedData) {
      if (key1 !== "title" && key1 !== "client") {
        for (let i = 0; i < groupedData[key1].length; i++) {
          charges.push(groupedData[key1][i]);
          taxable += groupedData[key1][i].taxable;
          amount += groupedData[key1][i].amount;
        }
      }
    }
    setActiveItem({
      subject_id: groupedData.client.id,
      terms_payment_id: groupedData.client.payment_terms_id,
      charges: charges,
      charge_ids: charges.map((charge) => charge.id!),
      taxable: taxable,
      amount: amount,
      invoice_status: "CR",
      invoice_type: "FT",
    });
  };
  const renderGroupedData = () => {
    const items = Object.keys(groupedData).map((key) => {
      let amount = 0;
      let taxable = 0;
      const innerList = Object.keys(groupedData[key]).map((keyType) => {
        if (keyType === "title" || keyType === "client") return null;
        const total = groupedData[key][keyType].reduce(
          (total: number, x: Charge) => {
            return total + x.amount;
          },
          0
        );
        const total_taxable = groupedData[key][keyType].reduce(
          (total_taxable: number, x: Charge) => {
            return total_taxable + x.taxable;
          },
          0
        );
        amount += total;
        taxable += total_taxable;
        return (
          <CollapsableCard
            classNameHeader="card_header_primary"
            key={keyType}
            title={
              <div
                style={{ flex: "auto" }}
                className="d-flex flex-row justify-content-between align-items-center"
              >
                <b>{getDisplayValue("charge_type", keyType, optionsPrice)}</b>
                <div>
                  <div
                    style={{
                      border: "2px solid rgb(113, 102, 249)",
                      color: "rgb(113, 102, 249)",
                      padding: "5px 15px",
                      borderRadius: "10rem",
                      marginRight: "10px",
                    }}
                  >
                    {total_taxable.toFixed(2)}€
                  </div>
                </div>
              </div>
            }
          >
            <div
              className="p-2"
              style={{ maxHeight: "700px", overflow: "auto" }}
            >
              <b>{groupedData[key][keyType].length}</b> righe da fatturare
            </div>
            {/*
            <div
              className="p-2"
              style={{ maxHeight: "700px", overflow: "auto" }}
            >
              <SimpleTable columns={columns} data={groupedData[key][keyType]} /> 
            </div>
            
            */}
          </CollapsableCard>
        );
      });
      return (
        <CollapsableCard
          title={
            <div
              style={{ flex: "1 1 auto" }}
              className="d-flex flex-row  align-items-center justify-content-between"
            >
              <div>
                <b>{groupedData[key].title}:</b>
                <span className="ml-2">{taxable.toFixed(2)}€</span>
              </div>

              <div className="mr-2">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickCreateInvoice(groupedData[key]);
                  }}
                  color="primary"
                  size="sm"
                >
                  Crea fattura
                </Button>
              </div>
            </div>
          }
          key={key}
        >
          <div className="px-3">{innerList}</div>
        </CollapsableCard>
      );
    });
    if (items.length > 0) return items;
    return <Alert color="primary">Nessun addebito da fatturare</Alert>;
  };
  return (
    <>
      {options && paramsObj && (
        <Filter
          paramsObj={paramsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(paramsObj, options)}
        />
      )}
      <div className="row mt-3">
        <div className="col-12">
          {groupedData && optionsPrice && renderGroupedData()}
        </div>
      </div>
      {activeItem && (
        <FullModal
          title="Crea fattura"
          toggle={toggleUpdateModal}
          isOpen={isOpenUpdateModal}
        >
          <NewInvoice invoice={activeItem} />
        </FullModal>
      )}
    </>
  );
};
