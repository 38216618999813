import { useQuery, UseQueryOptions } from "react-query";
import { client } from "utils/auth-client";
import { MISSING_SUBJECTS_API } from "utils/constants";

export function useMissingSubjects(config?: UseQueryOptions<any, unknown, []>) {
  return useQuery(
    "missing-subjects",
    () => client(MISSING_SUBJECTS_API, { method: "GET" }),
    {
      initialData: [],
      ...config,
    }
  );
}
