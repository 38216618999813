import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

interface Props {
  register: any;
  name: string;
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const BooleanField = ({
  register,
  name,
  errorMessage,
  label,
  disabled,
  required = false,
}: Props) => {
  return (
    <FormGroup tag="fieldset">
      {label && <Label>{label}</Label>}
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name={name}
            value={"true"}
            disabled={disabled}
            ref={register}
            required={required}
            innerRef={register}
          />
          Si
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            innerRef={register}
            type="radio"
            name={name}
            value={"false"}
            disabled={disabled}
            ref={register}
            required={required}
          />
          No
        </Label>
      </FormGroup>

      {errorMessage && (
        <FormFeedback style={{ display: "block" }}>{errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};
