import { useLoadItem } from "shared/hooks/useLoadItem";
import { useUpdateOrder } from "Traction/hooks/orders";
import { Order } from "Traction/types";
import { ORDERS_API } from "utils/constants";
import { checkPermissions } from "utils/helpers";
import { FullModal } from "../../components/FullModal";
import { UpdateOrderForm } from "./UpdateOrderForm";

export const ShowOrderModal = ({
  id,
  setId,
}: {
  id: number | string | undefined;
  setId: (id: number | string | undefined) => void;
}) => {
  const data = useLoadItem<Order>(id, ORDERS_API, "orders");
  const { mutateAsync } = useUpdateOrder();

  return data && checkPermissions(["rosina.view_order"]) ? (
    <FullModal
      title="Modifica ordine"
      toggle={() => {
        setId(undefined);
      }}
      isOpen={true}
    >
      <UpdateOrderForm order={data} submitFunction={mutateAsync} />
    </FullModal>
  ) : null;
};
