import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
}

export const ArticleForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Prodotto</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.PRODUCT}
                      control={control}
                      defaultValue={
                        defaultValues?.product_id
                          ? defaultValues?.product_id
                          : null
                      }
                      errorMessage={errors.product_id?.message}
                      name="product_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Codice prodotto</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Codice prodotto"
                        type="text"
                        register={register}
                        name="product_code"
                        errorMessage={errors.product_code?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.product_code}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Note</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Note"
                        type="text"
                        register={register}
                        name="note"
                        errorMessage={errors.note?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.note}`
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
