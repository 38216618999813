import { CollapsableCard } from "components/CollapsableCard";
import { SimpleTable } from "components/tables/SimpleTable";
import { ModalFileUpload } from "Import/components/ModalFileUpload";
import { useAddFiles } from "Import/hooks/useAddFiles";
import { useDeleteFile } from "Import/hooks/useDeleteFile";
import { useFiles } from "Import/hooks/useFiles";

import { ReportImport } from "Import/types";
import React, { useMemo, useState } from "react";
import { Minus, X, Play } from "react-feather";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { setLoading, setLoadingDone, useSidebar } from "shared/context/sidebar";
import { useToggler } from "shared/hooks/useToggler";
import { client } from "utils/auth-client";
import { EXECUTE_IMPORT_API, UPLOAD_API } from "utils/constants";
import "./Upload.css";

export const Upload = () => {
  const { data, refetch } = useFiles();
  const { mutateAsync } = useAddFiles(UPLOAD_API, "files", "files");
  const { mutate } = useDeleteFile();
  const { dispatch } = useSidebar();
  const columns = useMemo(() => {
    const removeFile = (fileName: string) => {
      let r = window.confirm("Vuoi eliminare il file?");
      if (r) mutate(fileName);
    };
    return [
      {
        Header: "",
        accessor: "__id",

        Cell: (cell: any) => {
          return (
            <Button
              onClick={() => removeFile(cell.row.original.name)}
              color="danger"
              className="edit-buttons"
            >
              <Minus size={13} />
            </Button>
          );
        },
      },
      {
        Header: "File",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Dimensione (MB)",
        accessor: "size",
        sortType: "basic",
      },

      {
        Header: "Ritiri import",
        Cell: (cell: any) => {
          return (
            <Button
              color="info"
              className="edit-buttons"
              onClick={() => { executeImport(cell.row.original.name, false, true) }}>
              <Play size={13} />
            </Button>
          );
        },
      },

      {
        Header: "Forza import",
        Cell: (cell: any) => {
          return (
            <Button
              color="warning"
              className="edit-buttons"
              onClick={() => { executeImport(cell.row.original.name, true, false) }}>
              <Play size={13} />
            </Button>
          );
        },
      },

      {
        Header: "Import",
        Cell: (cell: any) => {
          return (
            <Button
              color="success"
              className="edit-buttons"
              onClick={() => { executeImport(cell.row.original.name, false, false) }}>
              <Play size={13} />
            </Button>
          );
        },
      },


    ];
  }, [mutate]);
  const columnsDeliveries = useMemo(() => {
    return [
      {
        accessor: "document_number",
        Header: "Numero documento",
        sortType: "basic",
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.client)
            return `${cell.row.original.client.id}: ${cell.row.original.client.business_name}`;
          return "";
        },
      },
      {
        Header: "Destinatario",
        accessor: "receiver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.receiver)
            return `${cell.row.original.receiver.id}: ${cell.row.original.receiver.business_name}`;
          return "";
        },
      },
      {
        Header: "Peso",
        accessor: "weight",
        sortType: "basic",
      },
      {
        Header: "Numero colli",
        accessor: "packages_number",
        sortType: "basic",
      },

      {
        Header: "Indirizzo",
        accessor: "address",
        sortType: "basic",
      },
      {
        Header: "Località",
        accessor: "locality",
        sortType: "basic",
      },
      {
        Header: "Cap",
        accessor: "postal_code",
        sortType: "basic",
      },
      {
        Header: "Provincia",
        accessor: "province",
        sortType: "basic",
      },

      {
        Header: "Data documento",
        accessor: "document_date",
        sortType: "basic",
        Cell: (cell: any) =>
          cell.value ? new Date(cell.value).toLocaleString() : null,
      },
    ];
  }, []);
  const onSave = (files: File[]) => {
    console.log(files);
    mutateAsync(files)
      .then(() => toast.success("Fatto!"))
      .catch(() => toast.error("Errore!"));
  };
  const executeImport = (filename: string, forceImport: boolean, withdraw: boolean) => {
    dispatch(setLoading());
    client(EXECUTE_IMPORT_API, {
      method: "POST",
      data: JSON.stringify(
        { filename: filename, forceImport: forceImport, withdraw: withdraw }
      )
    }).then((data) => {
      console.log(data);
      refetch();
      setReportImport(data);
      dispatch(setLoadingDone());
      if ("subjects_missing" in data && data.subjects_missing > 0)
        window.open(window.location.origin + "/import/solver", "_blank");
    });
  };

  const [reportImport, setReportImport] = useState<ReportImport | undefined>(
    undefined
  );
  const { toggle, value: isOpen, setTrue: openModal } = useToggler();
  return (
    <>
      {reportImport && (
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-row justify-content-end my-2 align-items-center">
              <Button
                style={{
                  color: "red",
                  background: "white",
                  border: "none",
                }}
                className="edit-buttons"
                onClick={() => setReportImport(undefined)}
              >
                <X size="20" />
              </Button>
            </div>

            <Card>
              <CardHeader>
                <h5>Report import:</h5>
              </CardHeader>
              <CardBody>
                {reportImport.file_error.length > 0 && (
                  <CollapsableCard
                    title={`File errati: ${reportImport.file_error.length}`}
                  >
                    <ListGroup style={{ maxHeight: "300px", overflow: "auto" }}>
                      {reportImport.file_error.map((fileName) => (
                        <ListGroupItem key={fileName}>{fileName}</ListGroupItem>
                      ))}
                    </ListGroup>
                  </CollapsableCard>
                )}
                <div className="my-2">
                  <b>File con errori:</b>
                </div>
                {reportImport.info_import.map((info, key) => {
                  if (
                    info.error.length === 0 &&
                    info.existing.length === 0 &&
                    info.no_price.length === 0
                  )
                    return null;
                  return (
                    <CollapsableCard key={key} title={info.file_name}>
                      <div className="px-3">
                        {info.no_price.length > 0 && (
                          <div className="pt-2">
                            <div>
                              <b>Senza prezzo:</b>
                            </div>
                            <SimpleTable
                              style={{ maxHeight: "400px" }}
                              data={info.no_price}
                              columns={columnsDeliveries}
                            />
                          </div>
                        )}
                        {info.existing.length > 0 && (
                          <div className="pt-2">
                            <div>
                              <b>Esistenti:</b>
                            </div>
                            <SimpleTable
                              style={{ maxHeight: "400px" }}
                              data={info.existing}
                              columns={columnsDeliveries}
                            />
                          </div>
                        )}
                        {info.error.length > 0 && (
                          <div className="pt-3">
                            <div>
                              <b>Esistenti:</b>
                            </div>
                            <SimpleTable
                              style={{ maxHeight: "400px" }}
                              data={info.error}
                              columns={columnsDeliveries}
                            />
                          </div>
                        )}
                      </div>
                    </CollapsableCard>
                  );
                })}
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      <div className="row pt-5">
        <div className="col-12">
          <Card>
            <CardHeader>
              <div className="d-flex flex-row justify-content-between">
                <h5>File da caricare:</h5>
                <div className="d-flex flex-row justify-content-end">
                  <Button
                    size="sm"
                    className="mr-2"
                    color="primary"
                    onClick={openModal}
                  >
                    Carica
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {data && <SimpleTable data={data} columns={columns} />}
            </CardBody>
          </Card>
        </div>
      </div>
      <ModalFileUpload toggle={toggle} isOpen={isOpen} onSave={onSave} />
    </>
  );
};
