import { useToggler } from "shared/hooks/useToggler";
import {
  setNumberOfPages,
  useSearchPaginationReducer,
} from "shared/reducers/searchReducer";
import { getPageCount } from "utils/helpers";
import { AddItemForm } from "../components/AddItemForm";
import { Item } from "../types/item";
import { useSearchItems, useAddItem, useItemColumns } from "Items/hooks/item";
import { FullModal } from "components/FullModal";
import { ItemListPage } from "Items/components/ItemListPage";

export const ItemsPage = () => {
  const [stateSearchPagination, dispatch] =
    useSearchPaginationReducer<Partial<Item>>();

  const { data, page, pageCount, goToPage } = useSearchItems(
    {
      ...stateSearchPagination.searchValues,
      page: stateSearchPagination.page,
    },
    {
      onSuccess: (data) => {
        dispatch(setNumberOfPages(getPageCount(data!.count)));
      },
    }
  );
  const { mutateAsync } = useAddItem();
  const { value: isOpen, toggle } = useToggler();

  const columns = useItemColumns();
  return (
    <>
      {data && data.results && (
        <ItemListPage
          onAddClick={toggle}
          items={data.results}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
          columns={columns}
        />
      )}

      <FullModal title="Aggiungi articolo" toggle={toggle} isOpen={isOpen}>
        <AddItemForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
