import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { BooleanField } from "components/forms/BooleanField";
import { CheckboxField } from "components/forms/CheckboxField";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { Tabs } from "components/Tabs";
import React from "react";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  watch?: any;
}

export const PriceForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  watch,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Cliente</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.CLIENT}
                      control={control}
                      defaultValue={
                        defaultValues?.client_id
                          ? defaultValues?.client_id
                          : null
                      }
                      errorMessage={errors.client_id?.message}
                      name="client_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo addebito</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Tipo addebito"
                      options={options.charge_type.choices}
                      register={register}
                      errorMessage={errors.charge_type?.message}
                      name="charge_type"
                    />
                  </td>
                </tr>
                {watch("charge_type") === "DI" && (
                  <>
                    <tr>
                      <td>
                        <b>Peso minimo fascia</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Peso minimo fascia"
                            type="number"
                            register={register}
                            name="min_weight"
                            errorMessage={errors.min_weight?.message}
                          />
                        ) : defaultValues && !editable ? (
                          `${defaultValues.min_weight}`
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Peso massimo fascia</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Peso massimo fascia"
                            type="number"
                            register={register}
                            name="max_weight"
                            errorMessage={errors.max_weight?.message}
                          />
                        ) : defaultValues && !editable ? (
                          `${defaultValues.max_weight}`
                        ) : null}
                      </td>
                    </tr>
                  </>
                )}
                {watch("charge_type") !== "TR" && (
                  <tr>
                    <td>
                      <b>Oggetto</b>
                    </td>
                    <td>
                      <Autocomplete
                        disabled={!editable}
                        url={AUTOCOMPLETE_URLS.ITEM}
                        control={control}
                        defaultValue={
                          defaultValues?.item_id ? defaultValues?.item_id : null
                        }
                        errorMessage={errors.item_id?.message}
                        name="item_id"
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>Data inizio validità</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Data inizio validità"
                      type="date"
                      register={register}
                      name="start_date"
                      errorMessage={errors.start_date?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Iva</b>
                  </td>
                  <td>
                    <FormField
                      disabled={!editable}
                      placeholder="Iva"
                      type="number"
                      register={register}
                      name="vat"
                      errorMessage={errors.vat?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Riferimento normativo</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Riferimento normativo"
                      options={options.reference_regulation.choices}
                      register={register}
                      errorMessage={errors.reference_regulation?.message}
                      name="reference_regulation"
                      defaultValue={""}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Crea riga singola</b>
                  </td>
                  <td>
                    <CheckboxField
                      disabled={!editable}
                      register={register}
                      name="one_by_one"
                      errorMessage={errors.one_by_one?.message}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Prezzo</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Prezzo"
                        type="number"
                        register={register}
                        name="price"
                        errorMessage={errors.price?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.price}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Tipo quantità</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Tipo quantità"
                      options={options.quantity_type.choices}
                      register={register}
                      errorMessage={errors.quantity_type?.message}
                      name="quantity_type"
                    />
                  </td>
                </tr>
                {watch("charge_type") !== "TR" && (
                  <tr>
                    <td>
                      <b>Quantità minima</b>
                    </td>
                    <td>
                      {editable ? (
                        <FormField
                          placeholder="Quantità minima"
                          type="number"
                          register={register}
                          name="minimum_quantity"
                          errorMessage={errors.minimum_quantity?.message}
                        />
                      ) : defaultValues && !editable ? (
                        `${defaultValues.minimum_quantity}`
                      ) : null}
                    </td>
                  </tr>
                )}
                {watch("charge_type") !== "TR" && (
                  <>
                    {" "}
                    <tr>
                      <td>
                        <b>Province</b>
                      </td>
                      <td>
                        <Autocomplete
                          disabled={!editable}
                          url={AUTOCOMPLETE_URLS.PROVINCES}
                          control={control}
                          defaultValue={
                            defaultValues?.provinces
                              ? defaultValues?.provinces
                              : []
                          }
                          multiple
                          errorMessage={errors.provinces?.message}
                          name="provinces"
                        />
                      </td>
                    </tr>
                  </>
                )}

                {watch("charge_type") === "TR" && (
                  <>
                    <tr>
                      <td>
                        <b>Provincia iniziale</b>
                      </td>
                      <td>
                        <Autocomplete
                          disabled={!editable}
                          url={AUTOCOMPLETE_URLS.PROVINCES}
                          control={control}
                          defaultValue={
                            defaultValues?.starting_point_province
                              ? defaultValues?.starting_point_province
                              : null
                          }
                          errorMessage={errors.starting_point_province?.message}
                          name="starting_point_province"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Provincia finale</b>
                      </td>
                      <td>
                        <Autocomplete
                          disabled={!editable}
                          url={AUTOCOMPLETE_URLS.PROVINCES}
                          control={control}
                          defaultValue={
                            defaultValues?.arrival_point_province
                              ? defaultValues?.arrival_point_province
                              : null
                          }
                          errorMessage={errors.arrival_point_province?.message}
                          name="arrival_point_province"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Località iniziale</b>
                      </td>
                      <td>
                        <FormField
                          placeholder=""
                          type="text"
                          register={register}
                          name="starting_point_locality"
                          errorMessage={errors.starting_point_locality?.message}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Località finale</b>
                      </td>
                      <td>
                        <FormField
                          placeholder=""
                          type="text"
                          register={register}
                          name="arrival_point_locality"
                          errorMessage={errors.arrival_point_locality?.message}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Prezzo per destino intermedio</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Prezzo"
                            type="number"
                            register={register}
                            name="price_for_destiny"
                            errorMessage={errors.price_for_destiny?.message}
                          />
                        ) : defaultValues && !editable ? (
                          `${defaultValues.price_for_destiny}`
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Prezzo per trazione con ritorno</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Prezzo"
                            type="number"
                            register={register}
                            name="price_for_return"
                            errorMessage={errors.price_for_return?.message}
                          />
                        ) : defaultValues && !editable ? (
                          `${defaultValues.price_for_return}`
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Prezzo addizionale pallet DUSS</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Prezzo"
                            type="number"
                            register={register}
                            name="additional_price_duss_pallet"
                            errorMessage={
                              errors.additional_price_duss_pallet?.message
                            }
                          />
                        ) : defaultValues && !editable ? (
                          `${defaultValues.additional_price_duss_pallet}`
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tipo di trasporto</b>
                      </td>
                      <td>
                        <SelectField
                          disabled={!editable}
                          placeholder="Tipo di trasporto"
                          options={options.required_transport.choices}
                          register={register}
                          errorMessage={errors.required_transport?.message}
                          name="required_transport"
                          required
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Tabs titles={["Note"]}>
              <div>
                <table className={classNames("table_col")}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Note</b>
                      </td>
                      <td>
                        {editable ? (
                          <FormField
                            placeholder="Note"
                            type="textarea"
                            register={register}
                            errorMessage={errors.notes?.message}
                            name="notes"
                          />
                        ) : (
                          defaultValues && defaultValues.notes
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};
