import { SimpleTable } from "components/tables/SimpleTable";
import { useMissingSubjects } from "Import/hooks/useMissingSubjects";
import { SubjectNotFound } from "Import/types";
import React, { useEffect, useMemo } from "react";
import { Copy, Plus } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { setLoading, setLoadingDone, useSidebar } from "shared/context/sidebar";
import { useModalUpdate } from "shared/hooks/useModalUpdate";
import { useAddSubject } from "Subjects/hooks";
import { Subject } from "Subjects/types";
import { AddSubjectInitializeForm } from "../components/AddSubjectInitializeForm";
import { useAssignMissingSubject } from "../hooks/useAssignMissingSubject";

export const SubjectsSolver = () => {
  const { data, isFetching } = useMissingSubjects();
  const { mutate: assignSubject, isLoading } = useAssignMissingSubject();
  const { mutateAsync: addSubject } = useAddSubject();
  const { dispatch } = useSidebar();
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "id_missing",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <Button
              className="edit-buttons"
              color="primary"
              title="Assegna a questo soggetto"
              onClick={() => {
                assignSubject({
                  not_found_subject: cell.value as string,
                  subject_id: cell.row.original.subject.id,
                });
              }}
            >
              <Copy size={13} />
            </Button>
          );
        },
      },
      {
        Header: "Ragione sociale",
        accessor: "subject.business_name",
        sortType: "basic",
      },
      {
        Header: "Indirizzo",
        accessor: "subject.address",
        sortType: "basic",
      },
      {
        Header: "Località",
        accessor: "subject.locality",
        sortType: "basic",
      },
      {
        Header: "Similarità",
        accessor: "similarity",
        sortType: "basic",
        Cell: (cell: any) => {
          return `${Math.round(cell.value * 100)}%`;
        },
      },
    ],
    [assignSubject]
  );

  useEffect(() => {
    if (isFetching || isLoading) {
      dispatch(setLoading());
    } else {
      dispatch(setLoadingDone());
    }
  }, [dispatch, isFetching, isLoading]);

  const {
    isOpenUpdateModal,
    activeItem: activeMissingSubject,
    setActiveItem: setActiveMissingSubject,
    toggleUpdateModal,
  } = useModalUpdate<SubjectNotFound>();
  const onSubjectAdded = (subject: Subject) => {
    if (activeMissingSubject) {
      assignSubject({
        not_found_subject: activeMissingSubject.not_found_subject.id,
        subject_id: subject.id!,
      });
      setActiveMissingSubject(undefined);
    }
  };
  return (
    <>
      {data &&
        data.map((notFoundObject: SubjectNotFound, index) => {
          return (
            <Card className="mb-3" key={index}>
              <CardHeader>
                <div className="d-flex flex-row justify-content-between">
                  <h6>{`${notFoundObject.not_found_subject.business_name},
                   ${notFoundObject.not_found_subject.delivery__address} 
                   ${notFoundObject.not_found_subject.delivery__locality}`}</h6>
                  <Button
                    color="success"
                    title="Aggiungi soggetto"
                    className="edit-buttons"
                    onClick={() => setActiveMissingSubject(notFoundObject)}
                  >
                    <Plus size={13} />
                  </Button>
                </div>
              </CardHeader>
              {notFoundObject.results.length > 0 && (
                <CardBody>
                  <div>Soggetti simili:</div>
                  <SimpleTable
                    data={notFoundObject.results}
                    columns={columns}
                  />
                </CardBody>
              )}
            </Card>
          );
        })}
      {activeMissingSubject && (
        <Modal toggle={toggleUpdateModal} isOpen={isOpenUpdateModal}>
          <ModalHeader toggle={toggleUpdateModal}>
            <span className="modal-title">Aggiungi soggetto</span>
          </ModalHeader>
          <ModalBody>
            <AddSubjectInitializeForm
              postal_code={
                activeMissingSubject.not_found_subject.delivery__postal_code
              }
              address={activeMissingSubject.not_found_subject.delivery__address}
              locality={
                activeMissingSubject.not_found_subject.delivery__locality
              }
              province={
                activeMissingSubject.not_found_subject.delivery__province
              }
              business_name={
                activeMissingSubject.not_found_subject.business_name
              }
              submitFunction={addSubject}
              onSuccess={onSubjectAdded}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
