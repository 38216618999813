import classNames from "classnames";
import { PricesClientAutocomplete } from "components/autocompletes/PricesClientAutocomplete";
import { FormField } from "components/forms/FormField";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  getValues?: any;
}

export const DeliveryNoteRowForm = ({
  options,
  control,
  register,
  errors,
  getValues,
  defaultValues,
  editable = true,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Descrizione</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Descrizione"
                        type="text"
                        register={register}
                        name="description"
                        errorMessage={errors.description?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.description}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Quantità</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Quantità"
                        type="number"
                        register={register}
                        name="quantity"
                        errorMessage={errors.quantity?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.quantity}`
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
            <input type="hidden" name="delivery_id" ref={register} />
          </div>
        </>
      )}
    </>
  );
};
