import { FilterI } from "components/Filter/types";
import { StatusFilter } from "Warehouse/types";

export const createFilterStructure = (params: StatusFilter,
  options: any): FilterI[] => {
  return [
    {
      type: "autocomplete-place",
      name: "place",
      label: "Posto",
      active: params?.place !== undefined,
      value: params?.place,
    },
    {
      type: "autocomplete-article",
      name: "article",
      label: "Articolo",
      active: params?.article !== undefined,
      value: params?.article,
    },
    {
      type: "options",
      name: "status",
      label: "Stato",
      options: options.status.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.status !== undefined,
      value: params?.status,
    },
    {
      type: "date",
      name: "date",
      label: "Data",
      active: params?.date !== undefined,
      value: params?.date,
    },
    {
      type: "text",
      name: "note",
      label: "Note",
      active: params?.note !== undefined,
      value: params?.note,
    }
  ];
};
