import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePayload } from "shared/types";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { PlaceForm } from "./PlaceForm";
import { CheckPermissions } from "components/CheckPermissions";
import { useOptionsPlace } from "../hooks/place";
import { Place } from "Warehouse/types";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    UpdatePayload<Place>,
    unknown
  >;
  place: Place;
}

export const UpdatePlaceForm = ({ submitFunction, place }: Props) => {
  const { control, errors, register, handleSubmit, setError } = useForm<Place>(
    {
      defaultValues: place,
    }
  );
  const { data: options } = useOptionsPlace();
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onSend = handleSubmit((data) => {
    submitFunction({ id: place.id!, newValues: data })
      .then(() => {
        toast.success("Posto modificato!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <CheckPermissions permissions={["rosina.change_place"]}>
        <div className="d-flex flex-row justify-content-end">
          <Button size="sm" onClick={toggleDisable} color="primary">
            Modifica
          </Button>
        </div>
      </CheckPermissions>
      {options && (
        <PlaceForm
          editable={!disabled}
          defaultValues={place}
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      <CheckPermissions permissions={["rosina.change_place"]}>
        <div className="d-flex justify-content-end py-3">
          <Button type="submit" color="success" disabled={disabled}>
            Invia
          </Button>
        </div>
      </CheckPermissions>
    </Form>
  );
};
