import { AxiosRequestConfig } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { client } from "utils/auth-client";

export function useAdd<T>(
  url: string,
  key: string,
  requestConfig?: AxiosRequestConfig
) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: T) =>
      client(url, {
        method: "POST",
        data: data,
        ...(requestConfig ? requestConfig : {}),
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], { active: true });
      },
    }
  );
}
