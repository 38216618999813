import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { FuelConsumptionFile } from "FuelConspumption/types";
import { FuelConsumptionFileForm } from "./FuelConsumptionFileForm";
import { useOptionsFuelConsumptionFile } from "FuelConspumption/hooks/fuelConsumptionFile";
import { MESSAGES } from "utils/constants";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, File[], unknown>;
}

export const AddFuelConsumptionFileForm = ({ submitFunction }: Props) => {
  const {
    control,
    errors,
    register,
    handleSubmit,
    setError,
  } = useForm<FuelConsumptionFile>();

  const { data: options } = useOptionsFuelConsumptionFile();

  const onSend = handleSubmit((data) => {
    submitFunction([data.file[0] as File])
      .then(() => {
        toast.success(MESSAGES.FUEL_CONSUMPTION_FILE.ADD_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && (
        <FuelConsumptionFileForm
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          {MESSAGES.GENERAL.NEW_ITEM}
        </Button>
      </div>
    </Form>
  );
};
