import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { DestinyTractionPallet } from "../types";
import { MESSAGES } from "utils/constants";
import { DestinyTractionPalletForm } from "./DestinyTractionPalletForm";
import { useOptionsDestinyTractionPallet } from "PalletsManagment/hooks/destinyTractionPallet";

interface Props {
  submitFunction: UseMutateAsyncFunction<
    any,
    unknown,
    DestinyTractionPallet,
    unknown
  >;
}

export const AddDestinyTractionPalletForm = ({ submitFunction }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    control,
    setError,
  } = useForm<DestinyTractionPallet>({
    defaultValues: { date: null, image: null },
  });

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success(MESSAGES.GENERAL.ADD_SUCCESS);
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });
  const { data: options } = useOptionsDestinyTractionPallet();

  return (
    <Form onSubmit={onSend}>
      {options && (
        <DestinyTractionPalletForm
          options={options}
          register={register}
          control={control}
          errors={errors}
        />
      )}
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          {MESSAGES.GENERAL.NEW_ITEM}
        </Button>
      </div>
    </Form>
  );
};
