import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { PlaceByRowAutocomplete } from "components/autocompletes/PlaceByRowAutocomplete";
import { PlaceByRowSelect } from "components/autocompletes/PlaceByRowSelect";
import { FloorAutocomplete } from "components/autocompletes/FloorAutocomplete";
import { DateTimeField } from "components/forms/DateTimeField";
import { FormField } from "components/forms/FormField";
import { SelectField } from "components/forms/SelectField";
import { useEffect, useState } from "react";
import { AUTOCOMPLETE_URLS } from "utils/constants";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  getValues: any;
  setValue: any;
}

export const StatusForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  getValues,
  editable = true,
  setValue
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Piano</b>
                  </td>
                  <td>
                    {
                      /*
                      <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.FLOOR}
                      control={control}
                      defaultValue={
                        defaultValues?.floor_id
                          ? defaultValues?.floor_id
                          : null
                      }
                      errorMessage={errors.floor_id?.message}
                      name="floor_id"
                    />
                      */
                    }

                    <FloorAutocomplete
                      disabled={!editable}
                      control={control}
                      defaultValue={defaultValues?.floor_id
                        ? defaultValues?.floor_id
                        : null}
                      errorMessage={errors.floor_id?.message}
                      name="floor_id"
                      getValues={getValues}
                      setValue={setValue} />

                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Posto</b>
                  </td>
                  <td>


                    <PlaceByRowAutocomplete
                      disabled={!editable}
                      control={control}
                      defaultValue={
                        defaultValues?.place_id
                          ? defaultValues?.place_id
                          : null
                      }
                      errorMessage={errors.place_id?.message}
                      name="place_id"
                      getValues={getValues}
                      setValue={setValue}
                    />

                    {

                      /* 
                    <PlaceByRowSelect
                      control={control}
                      name="place_id"
                      getValues={getValues}
                      register={register}
                      errors={errors.place_id?.message}

                    />
                    
                    */
                    }


                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Articolo</b>
                  </td>
                  <td>
                    <Autocomplete
                      disabled={!editable}
                      url={AUTOCOMPLETE_URLS.ARTICLE}
                      control={control}
                      defaultValue={
                        defaultValues?.article_id
                          ? defaultValues?.article_id
                          : null
                      }
                      errorMessage={errors.article_id?.message}
                      name="article_id"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Stato</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Stato"
                      options={options.status.choices}
                      register={register}
                      errorMessage={errors.status?.message}
                      name="status"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Data</b>
                  </td>
                  <td>
                    <DateTimeField
                      disabled={!editable}
                      control={control}
                      name="date"
                      errorMessage={errors.date?.message}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Note</b>
                  </td>
                  <td>
                    {editable ? (
                      <FormField
                        placeholder="Note"
                        type="text"
                        register={register}
                        name="note"
                        errorMessage={errors.note?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.note}`
                    ) : null}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
