import React, { useContext } from "react";
import "./Header.css";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { SidebarContext } from "shared/context/sidebar";
import { NavLink as NavLinkRRD } from "react-router-dom";

import { ChevronDown, Menu } from "react-feather";
import { useAuth } from "shared/context/auth/auth-context";
import { ChildRoute } from "shared/types";

interface Props {
  paths?: Partial<ChildRoute>[];
}

export const Header = ({ paths }: Props) => {
  const { dispatch } = useContext(SidebarContext);
  const { logout, user } = useAuth();

  return (
    <Navbar
      className={"fixed-top container-nav d-flex "}
      expand="md"
      id="navbar-main"
    >
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex justify-content-around">
          <button
            className="btn  button_menu mr-2"
            onClick={() => {
              dispatch({ type: "TOGGLE_SIDEBAR" });
            }}
          >
            <Menu size={20} />
          </button>
          <div className="d-flex align-items-center">
            <Nav pills>
              {paths &&
                paths.map((path, index) => {
                  return path.hideHeader ? null : (
                    <NavLink
                      key={index}
                      to={path.path}
                      exact
                      tag={NavLinkRRD}
                      activeClassName="active"
                    >
                      {path.name}
                    </NavLink>
                  );
                })}
            </Nav>
          </div>
        </div>
        <div
          style={{ verticalAlign: "middle" }}
          className="d-flex flex-row justify-content-end align-items-center "
        >
          <span className="text-sm font-weight-bold mr-3">
            {user?.username}
          </span>
          <div>
            <Nav navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="toggler-user-menu  btn-sm">
                  <ChevronDown size={15} />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem href="/change-password">
                    <i className="ni ni-key-25" />
                    <span>Cambia password</span>
                  </DropdownItem>
                  <DropdownItem onClick={logout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </div>
        </div>
      </div>
    </Navbar>
  );
};
