import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface Props {
  children: React.ReactElement;
  title: string;
  isOpen: boolean;
  toggle: () => void;
}

export const FullModal = (props: Props) => {
  return (
    <Modal toggle={props.toggle} isOpen={props.isOpen}>
      <ModalHeader toggle={props.toggle}>
        <span className="modal-title">{props.title}</span>
      </ModalHeader>
      <ModalBody>{props.children}</ModalBody>
    </Modal>
  );
};
