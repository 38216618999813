import React from "react";
import { checkPermissions } from "utils/helpers";

interface Props {
  children: React.ReactNode;
  permissions: string[];
}
export const CheckPermissions = ({ permissions, children }: Props) => {
  return <>{checkPermissions(permissions) ? children : null}</>;
};
