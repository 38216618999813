import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { useOptionsCharge } from "Billing/hooks/charges";
import { Charge } from "Billing/types/charge";
import { ChargeForm } from "./ChargeForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Charge, unknown>;
}

export const AddChargeForm = ({ submitFunction }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
  } = useForm<
    Omit<Charge, "client" | "price" | "receiver" | "delivery" | "invoice">
  >({
    defaultValues: {
      client_id: undefined,
      receiver_id: undefined,
      price_id: undefined,
      delivery_id: undefined,
      invoice_id: undefined,
    },
  });
  const { data: options } = useOptionsCharge();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Addebito aggiunto!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      {options && (
        <ChargeForm
          options={options}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
