import { InvoiceCalculateFilter } from "Billing/types/invoice";
import { FilterI } from "components/Filter/types";

export const createFilterStructure = (
  params: InvoiceCalculateFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "date",
      name: "charge_date_after",
      label: "Dal",
      active: params?.charge_date_after !== undefined,
      value: params?.charge_date_after,
    },

    {
      type: "date",
      name: "charge_date_before",
      label: "Al",
      active: params?.charge_date_before !== undefined,
      value: params?.charge_date_before,
    },
    {
      type: "options",
      name: "price__charge_type",
      label: "Tipo addebito",
      options: options.charge_type.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.price__charge_type !== undefined,
      value: params?.price__charge_type,
    },

    {
      type: "autocomplete-client",
      name: "client",
      label: "Cliente",
      active: params?.client !== undefined,
      value: params?.client,
    },
    {
      type: "text",
      name: "sub_client",
      label: "Subcliente",
      active: params?.sub_client !== undefined,
      value: params?.sub_client,
    },
  ];
};
