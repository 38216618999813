import { CheckPermissions } from "components/CheckPermissions";
import { CustomPagination } from "components/CustomPagination";
import { Filter } from "components/Filter";
import React from "react";
import { UseMutateFunction } from "react-query";
import { Column } from "react-table";
import { Button } from "reactstrap";
import { Order, OrderFilter } from "Traction/types";
import { OrderListItem } from "../OrderListItem";
import { createFilterStructure } from "./filter";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<OrderFilter>) => void;
  onAddClick: () => void;
  orders: Order[];
  columns: Column[];
  canPrevPage: () => boolean;
  goPrevPage: () => void;
  page: number;
  pageCount: number;
  canNextPage: () => boolean;
  goNextPage: () => void;
  setActiveOrder: (order: Order) => void;
  deleteFunction: UseMutateFunction<any, unknown, number, unknown>;
}

export const OrderListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  orders,
  columns,
  canPrevPage,
  goPrevPage,
  page,
  pageCount,
  canNextPage,
  goNextPage,
  setActiveOrder,
  deleteFunction,
}: Props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
        <CheckPermissions permissions={["rosina.change_order"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            Aggiungi
          </Button>
        </CheckPermissions>
      </div>
      <div className="row ">
        <div className="col-12">
          {orders &&
            orders.map((item) => (
              <OrderListItem
                deleteFunction={deleteFunction}
                setActiveOrder={setActiveOrder}
                key={item.id}
                order={item}
                columns={columns}
              />
            ))}
        </div>
        <div className="col-12">
          <CustomPagination
            canPrevPage={canPrevPage}
            goPrevPage={goPrevPage}
            page={page}
            pageCount={pageCount}
            canNextPage={canNextPage}
            goNextPage={goNextPage}
          />
        </div>
      </div>
    </>
  );
};
