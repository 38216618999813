import { Header } from "components/headers/Header";
import MapRoutes from "components/MapRoutes";
import React from "react";
import { MainRouteProps } from "shared/types";
import { getAllowedRoutes } from "utils/helpers";

export const HeaderLayout = ({ routes }: MainRouteProps) => {
  const allowedRoutes = getAllowedRoutes(routes);
  return (
    <>
      <Header paths={allowedRoutes} />
      <MapRoutes routes={allowedRoutes} />
    </>
  );
};
