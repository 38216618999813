import React from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";

interface Props {
  register: any;
  name: string;
  errorMessage?: string;
  label?: string;
  options: {
    value: any;
    display_name: string;
  }[];
  disabled?: boolean;
  multiple?: boolean;
  placeholder?: string;
  defaultValue?: any;
  required?: boolean;
}

export const SelectField = ({
  register,
  name,
  label,
  options,
  errorMessage,
  placeholder,
  disabled = false,
  multiple = false,
  required = false,
  defaultValue,
}: Props) => {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <select
        defaultValue={defaultValue}
        placeholder={placeholder}
        multiple={multiple}
        disabled={disabled}
        name={name}
        ref={register}
        required={required}
        className="form-control"
      >
        <option value={""}>---</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.display_name}
          </option>
        ))}
      </select>
      {errorMessage && (
        <FormFeedback style={{ display: "block" }}>{errorMessage}</FormFeedback>
      )}
    </FormGroup>
  );
};
