import { FilterI } from "components/Filter/types";
import { SubjectFilter } from "Subjects/types";

export const createFilterStructure = (
  params: SubjectFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "number",
      name: "id",
      label: "ID",
      active: params?.id !== undefined,
      value: params?.id,
    },
    {
      type: "text",
      name: "business_name",
      label: "Ragione sociale",
      active: params?.business_name !== undefined,
      value: params?.business_name,
    },
    {
      type: "text",
      name: "first_name",
      label: "Nome",
      active: params?.first_name !== undefined,
      value: params?.first_name,
    },
    {
      type: "text",
      name: "surname",
      label: "Cognome",
      active: params?.surname !== undefined,
      value: params?.surname,
    },
    {
      type: "options",
      name: "type",
      label: "Tipo",
      options: options.type.child.choices as {
        value: any;
        display_name: string;
      }[],
      multiple: true,
      active: params?.type !== undefined,
      value: params?.type,
    },
    {
      type: "boolean",
      name: "hide",
      label: "Nascosto",
      active: params?.hide !== undefined,
      value: params?.hide,
    },
    {
      type: "boolean",
      name: "is_main",
      label: "E' principale",
      active: params?.is_main !== undefined,
      value: params?.is_main,
    },
    {
      type: "autocomplete-subject",
      name: "main_subject",
      label: "Soggetto principale",
      active: params?.main_subject !== undefined,
      value: params?.main_subject,
    },
  ];
};
