import { useToggler } from "shared/hooks/useToggler";

import { FullModal } from "components/FullModal";
import { MESSAGES } from "utils/constants";
import {
  useSearchDestinyTractionPallets,
  useAddDestinyTractionPallet,
  useDestinyTractionPalletColumns,
  useOptionsDestinyTractionPallet,
} from "PalletsManagment/hooks/destinyTractionPallet";
import { PalletTransactionListPage } from "PalletsManagment/components/PalletTransactionListPage";
import { DestinyTractionFilter } from "Traction/types";
import { useFilter } from "shared/hooks/useFilter";
import { AddDestinyTractionPalletForm } from "PalletsManagment/components/AddDestinyTractionPalletForm";

export const PalletTransactions = () => {
  const { paramsObj, onSearch } = useFilter<DestinyTractionFilter>();

  const { data, search, page, pageCount, goToPage } =
    useSearchDestinyTractionPallets(paramsObj);

  const { value: isOpen, toggle } = useToggler();
  const { mutateAsync } = useAddDestinyTractionPallet();

  const { data: options } = useOptionsDestinyTractionPallet();

  const columns = useDestinyTractionPalletColumns(options);
  const searchItems = (data: Partial<DestinyTractionFilter>) => {
    onSearch(data);
    search(data);
  };
  return (
    <>
      {data && data.results && options && (
        <PalletTransactionListPage
          onSearch={searchItems}
          urlParamsObj={paramsObj}
          onAddClick={toggle}
          columns={columns}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
          destinyTractionPallet={data.results}
        />
      )}

      <FullModal
        toggle={toggle}
        isOpen={isOpen}
        title={MESSAGES.GENERAL.NEW_ITEM}
      >
        <AddDestinyTractionPalletForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
