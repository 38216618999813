import { LoginForm } from "components/forms/LoginForm";
import React from "react";
import { LoginPayload } from "shared/types";
import "./Login.css";
import * as auth from "services/auth";
import { Redirect } from "react-router-dom";
import { useAuth } from "shared/context/auth/auth-context";

export const Login = () => {
  const { login, error } = useAuth();

  const onLogin = (form: LoginPayload) => login(form);

  const user = auth.getUser();

  if (user) return <Redirect to="/home" />;

  return (
    <div className=" h-100 d-flex flex-column flex-sm-row  my-auto">
      <div className="header_login d-flex align-items-center justify-content-center px-3 text-white">
        <h1>ROSINA LOGISTICA</h1>
      </div>
      <div className="form_login d-flex flex-column justify-content-center align-items-center">
        <div className="card card_login ">
          <div className="card-header">
            <h3>Login</h3>
          </div>
          <div className="card-body">
            {error !== "" && <p className="alert alert-danger">{error}</p>}
            <LoginForm submitFunction={onLogin} />
          </div>
        </div>
      </div>
    </div>
  );
};
