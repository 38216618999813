import classNames from "classnames";
import React, { CSSProperties, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";

interface Props {
  title: any;
  classNameHeader?: string;
  styleHeader?: CSSProperties;
}

export const CollapsableCard: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Card style={{ marginBottom: "1rem" }}>
      <CardHeader
        onClick={toggle}
        className={classNames(
          "d-flex flex-row justify-content-between font-weight-bold align-items-center",
          props.classNameHeader
        )}
        style={{ ...(props.styleHeader ? props.styleHeader : {}) }}
      >
        {props.title}
        <div style={{ flex: "0" }}>
          {isOpen ? <ChevronUp size={13} /> : <ChevronDown size={13} />}
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="p-0">{props.children}</CardBody>
      </Collapse>
    </Card>
  );
};
