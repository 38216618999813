import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";

import { Item } from "../types/item";
import { ItemForm } from "./ItemForm";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Item, unknown>;
}

export const AddItemForm = ({ submitFunction }: Props) => {
  const { errors, register, handleSubmit, setError, control } = useForm<Item>();

  const onSend = handleSubmit((data) => {
    submitFunction(data)
      .then(() => {
        toast.success("Oggetto aggiunto!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });

  return (
    <Form onSubmit={onSend}>
      <ItemForm control={control} register={register} errors={errors} />
      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Aggiungi
        </Button>
      </div>
    </Form>
  );
};
