import classNames from "classnames";
import { SelectField } from "components/forms/SelectField";
import { TermsDescriptionAutocomplete } from "components/autocompletes/TermsDescriptionAutocomplete";

interface Props {
  options: any;
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  getValues?: any;
}

export const TermsPaymentForm = ({
  options,
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  getValues,
}: Props) => {
  return (
    <>
      {options && (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <table className={classNames("table_col")}>
              <tbody>
                <tr>
                  <td>
                    <b>Descrizione termini di pagamento</b>
                  </td>
                  <td>
                    {editable ? (
                      <TermsDescriptionAutocomplete
                        control={control}
                        name="terms_id"
                        getValues={getValues}
                        errorMessage={errors.terms_id?.message}
                      />
                    ) : defaultValues && !editable ? (
                      `${defaultValues.terms_id}`
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Metodo di pagamento</b>
                  </td>
                  <td>
                    <SelectField
                      disabled={!editable}
                      placeholder="Metodo di pagamento"
                      options={options.method.choices}
                      register={register}
                      errorMessage={errors.method?.message}
                      name="method"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};
